import { BankIdScopes, BankIdClaims } from "Models/BankID";
import { PersonalDataFormModel } from "State/Contracts/Create/Models";
import { isNoU } from "Utils/ObjectUtils";
import { AnyObject, ObjectSchema, array, object, string } from "yup";

type Props = {
  requireEmail?: boolean;
  requirePhone?: boolean;
  customValidations?: AnyObject;
};

export const getPersonalDataSchema = ({
  requireEmail = true,
  requirePhone = true,
  customValidations = {},
}: Props = {}): ObjectSchema<PersonalDataFormModel> => {
  return object<PersonalDataFormModel>().shape({
    nationalities: array()
      .required()
      .min(1)
      .label(
        `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.Nationalities}`,
      ),
    personalIdentificationNumber: string()
      .required()
      .label(`${BankIdScopes.ProfileBirthNumber}:${BankIdClaims.BirthNumber}`),
    birthDate: string()
      .required()
      .label(`${BankIdScopes.ProfileBirthDate}:${BankIdClaims.BirthDate}`),
    sex: string()
      .oneOf(["M", "F"])
      .required()
      .label(`${BankIdScopes.ProfileGender}:${BankIdClaims.Gender}`),
    firstName: string()
      .min(2)
      .required()
      .label(`${BankIdScopes.ProfileName}:${BankIdClaims.GivenName}`),
    lastName: string()
      .min(2)
      .required()
      .label(`${BankIdScopes.ProfileName}:${BankIdClaims.FamilyName}`),
    titleBeforeName: string()
      .optional()
      .label(`${BankIdScopes.ProfileTitles}:${BankIdClaims.TitlePrefix}`),
    titleAfterName: string()
      .optional()
      .label(`${BankIdScopes.ProfileTitles}:${BankIdClaims.TitleSuffix}`),
    birthCity: string()
      .required()
      .label(
        `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.BirthPlace}`,
      ),
    birthCountry: string()
      .required()
      .label(
        `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.BirthCountry}`,
      ),
    email: requireEmail
      ? string()
          .transform(val => (isNoU(val) || val === "" ? undefined : val))
          .required()
          .email()
          .label(`${BankIdScopes.ProfileEmail}:${BankIdClaims.Email}`)
      : string()
          .transform(val => (isNoU(val) || val === "" ? undefined : val))
          .optional()
          .email()
          .label(`${BankIdScopes.ProfileEmail}:${BankIdClaims.Email}`),
    phone: requirePhone
      ? string()
          .transform(val => (isNoU(val) || val === "" ? undefined : val))
          .required()
          .label(
            `${BankIdScopes.ProfilePhoneNumber}:${BankIdClaims.PhoneNumber}`,
          )
      : string()
          .transform(val => (isNoU(val) || val === "" ? undefined : val))
          .optional()
          .label(
            `${BankIdScopes.ProfilePhoneNumber}:${BankIdClaims.PhoneNumber}`,
          ),
    ...customValidations,
  });
};
