import { ListItemButton, Typography } from "@mui/material";
import { LAYOUT_PADDING_X } from "Constants/Layout";

import { BackIcon } from "Components/Shared/Icons";
import { openExternalLinkTracked } from "Utils/TrackingUtils";
import { ReactNode, type FunctionComponent } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

type Props = {
  title: string;
  description?: string;
  link?: string;
  isExternal?: boolean;
  actionElement?: ReactNode;
  onClick?: () => void;
};

const StyledMenuItem = styled(ListItemButton)`
  padding-left: 0;
  padding-right: ${props => props.theme.spacing(LAYOUT_PADDING_X)};
  padding-top: ${props => props.theme.spacing(4)};
  padding-bottom: ${props => props.theme.spacing(3)};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.palette.text.secondary};
  margin-left: ${props => props.theme.spacing(LAYOUT_PADDING_X)};
  gap: ${props => props.theme.spacing(3)};
`;

const StyledBackIcon = styled(BackIcon)`
  height: 20px;
  width: 20px;
  min-width: 20px;
  transform: rotate(180deg);
  margin-left: ${props => props.theme.spacing(3)};
`;

export const MenuItem: FunctionComponent<Props> = ({
  title,
  description,
  link,
  isExternal,
  onClick,
  actionElement = <StyledBackIcon />,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (!link) {
      return;
    }

    if (isExternal) {
      openExternalLinkTracked(link);
    } else {
      navigate(link);
    }
  };

  return (
    <StyledMenuItem onClick={handleClick}>
      <div>
        <Typography variant="h4">{title}</Typography>
        {!!description && (
          <Typography color="text.secondary" marginTop={1}>
            {description}
          </Typography>
        )}
      </div>
      {actionElement}
    </StyledMenuItem>
  );
};
