import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import {
  EditPersonalDataRequest,
  EditPersonalDataVerificationType,
} from "Api/Api";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BankiDIcon } from "Components/Shared/Icons";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { PageTitle } from "Components/Shared/PageTitle";
import { useYupFormSchema } from "Hooks/More/PersonalData/EditPersonalData/PathSelection/useYupFormSchema";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useIsAnyContractBankIDVerified } from "Hooks/useIsAnyContractBankIDVerified";
import { CodeListItem } from "Models/CodeListItem";
import { FunctionComponent, ReactNode, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  EditPersonalDataSteps,
  PathSelectionFormModel,
  setEditPersonalDataActualStep,
  setEditPersonalDataFormData,
} from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

const StyledUnderline = styled.span`
  text-decoration: underline;
`;

const StyledBankIdNegativeIcon = styled(BankiDIcon)`
  display: block;
  height: 16px;
  margin-top: 3px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const PageResource = Resources.More.PersonalData.EditPersonalData;
const ComponentResources = PageResource.PathSelection;

export const PathSelectionStep: FunctionComponent = _ => {
  const { t } = useResource();
  const { lastRequest: defaultValues } = useAppSelector(
    s => s.client.personalData.edit,
  );

  const isAnyContractBankIDVerified = useIsAnyContractBankIDVerified();
  const pageTitle = usePageTitle();
  const dispatch = useAppDispatch();

  const schema = useYupFormSchema();
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<Pick<EditPersonalDataRequest, "verificationType">>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (formData: PathSelectionFormModel) => {
    dispatch(setEditPersonalDataFormData({ ...defaultValues, ...formData }));
    if (formData.verificationType === EditPersonalDataVerificationType.BankID) {
      dispatch(
        setEditPersonalDataActualStep(EditPersonalDataSteps.BankIDRedirect),
      );
      return;
    }

    dispatch(setEditPersonalDataActualStep(EditPersonalDataSteps.Modelling));
  };

  useEffect(() => {
    if (!isAnyContractBankIDVerified) {
      dispatch(setEditPersonalDataActualStep(EditPersonalDataSteps.Modelling));
    }
  }, [isAnyContractBankIDVerified, dispatch]);

  const paths = useMemo(() => {
    const bankIDPath: CodeListItem<
      EditPersonalDataVerificationType,
      ReactNode
    > = {
      code: EditPersonalDataVerificationType.BankID,
      name: (
        <div>
          <StyledBankIdNegativeIcon height={50} width={undefined} />
          <Typography component="span" variant="h4">
            {t(ComponentResources.BankID.Label[1])}
            <StyledUnderline>
              {t(ComponentResources.BankID.Label[2])}
            </StyledUnderline>
          </Typography>
          <BlInfoPopper color="primary" icon="info">
            {t(ComponentResources.BankID.Description)}
          </BlInfoPopper>
        </div>
      ),
    };

    const manualPath: CodeListItem<
      EditPersonalDataVerificationType,
      ReactNode
    > = {
      code: EditPersonalDataVerificationType.Manual,
      name: (
        <Typography component="span" variant="h4">
          {t(ComponentResources.Manual.Label)}
        </Typography>
      ),
      description: t(ComponentResources.Manual.Description),
    };

    return isAnyContractBankIDVerified
      ? [bankIDPath, manualPath]
      : [manualPath];
  }, [isAnyContractBankIDVerified, t]);

  return (
    <>
      <div>
        <PageTitle showOnDesktop showOnMobile={false}>
          {pageTitle}
        </PageTitle>

        <Typography marginBottom={8}>
          {t(ComponentResources.Description)}
        </Typography>

        <RadioButtons<PathSelectionFormModel, EditPersonalDataVerificationType>
          codeList={paths}
          control={control}
          name="verificationType"
          errors={errors}
          spacing={4}
        />
      </div>

      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        trackingTag={t(ComponentResources.Continue)}
        fullWidth
        disabled={!isValid}
      >
        {t(ComponentResources.Continue)}
      </PrimaryButton>
    </>
  );
};
