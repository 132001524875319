import { Link } from "@mui/material";
import { type FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";
import { PERSONAL_DATA_ANALYTICS_URL } from "Utils/ExternalLinkUtils";

export const PrivacyPolicyLink: FunctionComponent = _ => {
  const { t } = useResource();
  return (
    <Link
      target="_blank"
      href={PERSONAL_DATA_ANALYTICS_URL}
      fontWeight={600}
      color="secondary"
    >
      {t(Resources.Common.PrivacyPolicyLink)}
    </Link>
  );
};
