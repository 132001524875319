import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { Navigate, useNavigate, useParams } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useDesktop } from "Hooks/useDesktop";
import { useEffectOnce } from "react-use";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import {
  ContractDeactivateBankConnectionStep,
  resetContractDeactivateBankConnectionState,
  setContractDeactivateBankConnectionBankAccountID,
  setContractDeactivateBankConnectionContractID,
} from "State/Contracts/Contract/BankConnections/ContractDeactivateBankConnectionState";
import { BankConnectionInformation } from "Components/ContractBankConnections/Deactivate/Information/BankConnectionInformation";
import { getBankAccountsAsync } from "State/InvestmentsDetail/BankAccounts/BankAccountsState";
import { BankConnectionSignature } from "Components/ContractBankConnections/Deactivate/Signature/BankConnectionSignature";
import { InvestmentDetailTabs } from "Hooks/ContractDetail/useMenuTabs";
import { useBankConnections } from "Hooks/Contract/BankConnections/useBankConnections";

const PageResources = Resources.Contract.BankConnections.Deactivate;

export const DeactivateBankConnectionPage: FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const contractID = Number.parseInt(params.contractID as string);
  const bankAccountID = Number.parseInt(params.bankAccountID as string);
  const actualStep = useAppSelector(
    s => s.contracts.contract.bankConnections.deactivate.actualStep,
  );

  const { isDesktop } = useDesktop();
  const { canDeactivate } = useBankConnections();
  const {
    isLoading: isLoadingOverview,
    data: overview,
    error: overviewError,
  } = useAppSelector(state => state.investmentsDetail.overview);

  const {
    isLoading: isLoadingBankAccounts,
    data: bankAccounts,
    error: bankAccountsError,
  } = useAppSelector(s => s.investmentsDetail.bankAccounts);

  useEffectOnce(() => {
    dispatch(resetContractDeactivateBankConnectionState());
    dispatch(setContractDeactivateBankConnectionContractID(contractID));
    dispatch(setContractDeactivateBankConnectionBankAccountID(bankAccountID));
    dispatch(getOverviewAsync.request(contractID));
    dispatch(getBankAccountsAsync.request(contractID));
  });

  const isLoading =
    isLoadingOverview || !overview || isLoadingBankAccounts || !bankAccounts;

  if (!isLoading && !canDeactivate) {
    return (
      <Navigate
        to={getPath(
          AppRouting.ContractDetail,
          contractID,
          InvestmentDetailTabs.Accounts,
        )}
      />
    );
  }

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() =>
        navigate(
          getPath(
            AppRouting.ContractDetail,
            contractID,
            InvestmentDetailTabs.Accounts,
          ),
        )
      }
    >
      <LoadingWrapper
        isLoading={isLoading}
        error={overviewError ?? bankAccountsError}
        skeleton={LoadingSkeleton}
      >
        {actualStep === ContractDeactivateBankConnectionStep.Information && (
          <BankConnectionInformation
            contractID={contractID}
            bankAccountID={bankAccountID}
          />
        )}

        {actualStep === ContractDeactivateBankConnectionStep.Signature && (
          <BankConnectionSignature contractID={contractID} />
        )}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const LoadingSkeleton = (
  <>
    <div>
      <PrimarySkeleton
        fullWidth
        fullHeight={false}
        height={40}
        marginBottom={10}
      />
      <PrimarySkeleton
        fullWidth
        fullHeight={false}
        height={60}
        marginBottom={10}
      />
    </div>
    <PrimarySkeleton fullWidth fullHeight={false} height={60} />
  </>
);
