import { CodeListItem } from "Models/CodeListItem";
import { SexType } from "Models/Inputs";
import { ResourceDictionary } from "Translations/ResourceDictionary";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

type ListItem = CodeListItem<SexType>;
type Result = ListItem[];

const ResourceMap: {
  [key in SexType]: keyof ResourceDictionary["Forms"]["PersonalData"]["Sex"];
} = {
  F: "Female",
  M: "Male",
};

export const useSexCodeList = (): Result => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(ResourceMap).map<ListItem>(([key, value]) => ({
        code: key as SexType,
        name: t(Resources.Forms.PersonalData.Sex[value]),
      })),
    [t],
  );
};
