import { CurrencyCode } from "Api/Api";
import { BankConnectionFormModel } from "State/Contracts/Create/Models";
import { isValidCzechBankNumber } from "Utils/BankUtils";
import { validateIBAN } from "ibantools";
import { isNil } from "lodash-es";
import { ObjectSchema, ValidationError, object, string } from "yup";

type Props = {
  translates?: {
    required: string;
    invalidFormat: string;
    invalidBankAccount: string;
    czIbanDenied: string;
  };
  simpleBankNumberValidation?: boolean;
};

export const getBankConnectionSchema = ({
  translates,
  simpleBankNumberValidation,
}: Props): ObjectSchema<BankConnectionFormModel> => {
  return object<BankConnectionFormModel>().shape({
    bankConnectionType: string().oneOf(["iban", "bankNumber"]).required(),
    bankCurrency: string().oneOf(Object.values(CurrencyCode)).required(),
    selectedBank: string().when("bankConnectionType", {
      is: (bankConnectionType: string) => bankConnectionType === "bankNumber",
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    bankNumber: string().test(function (value, context) {
      const { bankConnectionType } = context.parent;

      if (
        bankConnectionType === "bankNumber" &&
        !isValidCzechBankNumber(value)
      ) {
        throw new ValidationError(
          translates?.invalidBankAccount ?? "Invalid bank account",
          undefined,
          "bankNumber",
        );
      }

      return true;
    }),
    swiftCode: string().test(function (value, context) {
      const { bankConnectionType } = context.parent;

      if (bankConnectionType === "bankNumber" && isNil(value)) {
        throw new ValidationError(
          translates?.required ?? "Required",
          undefined,
          "bankCode",
        );
      }

      return true;
    }),
    bankCode: string().optional(),
    country: string().optional(),
    iban: string()
      .max(34)
      .test(function (value, context) {
        const { bankConnectionType } = context.parent;

        if (
          bankConnectionType === "iban" &&
          !validateIBAN(value?.replace(/\s/g, "")).valid
        ) {
          throw new ValidationError(
            translates?.invalidFormat ?? "Invalid format",
            undefined,
            "iban",
          );
        }

        if (bankConnectionType === "iban" && value?.startsWith("CZ")) {
          throw new ValidationError(
            translates?.czIbanDenied ?? "CZ IBAN is not allowed",
            undefined,
            "iban",
          );
        }

        return true;
      }),
  });
};
