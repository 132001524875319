import { ReactNode, useEffect, type FunctionComponent } from "react";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { Resources, useResource } from "Translations/Resources";
import { useFormContext } from "react-hook-form";
import {
  AMLQuestionsFormModel,
  TaxResidencyEnum,
} from "State/Contracts/Create/Models";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { InputAdornment } from "@mui/material";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { CodeListItem } from "Models/CodeListItem";
import { LookupItem } from "Models/LookupItem";

export type TaxResidencyFieldsProps = {
  isTaxResidencyReadonly?: boolean;
  isTaxResidencyCountryReadonly?: boolean;
  isVatReadonly?: boolean;
  taxResidencies: CodeListItem<TaxResidencyEnum, ReactNode>[];
  taxResidencyCountries: LookupItem<string | number>[];
};

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

const OtherRequiredFields: TaxResidencyEnum[] = [
  TaxResidencyEnum.Other,
  TaxResidencyEnum.Slovakia,
];

export const TaxResidency: FunctionComponent<TaxResidencyFieldsProps> = ({
  isTaxResidencyReadonly = false,
  isTaxResidencyCountryReadonly = false,
  isVatReadonly = false,
  taxResidencies,
  taxResidencyCountries,
}) => {
  const { t } = useResource();
  const {
    control,
    formState: { errors },
    watch,
    trigger,
    resetField,
  } = useFormContext<AMLQuestionsFormModel>();

  const taxResidency = watch("taxResidency");
  const taxResidencyCountry = watch("taxResidencyCountry");

  useEffect(() => {
    if (taxResidency !== TaxResidencyEnum.Other) {
      resetField("taxResidencyCountry");
    }
    resetField("vatId");
  }, [taxResidency, resetField]);

  useEffect(() => {
    if (taxResidencyCountry) {
      trigger("taxResidencyCountry");
    }
  }, [taxResidencyCountry, trigger]);

  return (
    <div>
      <RadioButtons
        label={t(ComponentResources.TaxResidency)}
        control={control}
        errors={errors}
        name="taxResidency"
        codeList={taxResidencies}
        disabled={isTaxResidencyReadonly}
      />

      {taxResidency === TaxResidencyEnum.Other && (
        <DropdownInput
          control={control}
          errors={errors}
          name="taxResidencyCountry"
          label={t(ComponentResources.OtherTaxResidencySelectLabel)}
          options={taxResidencyCountries}
          disabled={isTaxResidencyCountryReadonly}
        />
      )}

      {!!taxResidency && OtherRequiredFields.includes(taxResidency) && (
        <BlInputFormField
          control={control}
          errors={errors}
          name="vatId"
          label={t(ComponentResources.VAT.Label)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <BlInfoPopper>{t(ComponentResources.VAT.Tooltip)}</BlInfoPopper>
              </InputAdornment>
            ),
          }}
          disabled={isVatReadonly}
        />
      )}
    </div>
  );
};
