import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { LookupItem } from "Models/LookupItem";
import { getBanksAsync } from "State/CodeLists/Banks/GetBanksState";
import { getBankUniqueCode } from "Utils/BankUtils";
import { useMemo } from "react";
import { useEffectOnce } from "react-use";

type UseBanksType = () => LookupItem[];

export const useBanksList: UseBanksType = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(s => s.codeList.banks);

  useEffectOnce(() => {
    if (!data?.bankList?.length && !isLoading) {
      dispatch(getBanksAsync.request());
    }
  });

  return useMemo<LookupItem<string>[]>(
    () =>
      data?.bankList?.map<LookupItem<string>>(bank => ({
        Name: `${bank.name} - ${bank.numericCode}`,
        Value: getBankUniqueCode(bank),
      })) ?? [],
    [data],
  );
};
