import {
  EmailVerificationCommand,
  EmailVerificationCommandResult,
  EmailVerificationCommandResultStatus,
  postAuthVerifyEmail,
  SignInStatus,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { NavigateFunction } from "react-router-dom";
import { setToken } from "Utils/LocalStorageUtils";
import { afterSignInSuccess } from "Utils/AuthUtils";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { logError } from "ErrorService";

export const verifyEmailAsync = createAsyncAction(
  "@auth/VERIFY_EMAIL_REQUEST",
  "@auth/VERIFY_EMAIL_SUCCESS",
  "@auth/VERIFY_EMAIL_FAILURE",
)<
  {
    command: EmailVerificationCommand;
    navigate: NavigateFunction;
    canUseBiometrics: boolean;
  },
  EmailVerificationCommandResult,
  Error
>();

function* verifyEmail(
  action: ReturnType<typeof verifyEmailAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postAuthVerifyEmail,
      action.payload.command,
    );

    if (error) {
      yield put(verifyEmailAsync.failure(error));
      return;
    }

    if (
      response.status === EmailVerificationCommandResultStatus.Success &&
      !!response.signInResult
    ) {
      if (response.signInResult.status !== SignInStatus.Success) {
        yield put(
          verifyEmailAsync.failure(
            new ApplicationError(response.signInResult!.status),
          ),
        );
        return;
      }

      if (!response.signInResult?.token) {
        logError(new Error("Token is missing in response"), {
          response: {
            status: response.status,
            signInResult: {
              status: response.signInResult?.status,
              error: response.signInResult?.error,
              errorMessage: response.signInResult?.errorMessage,
            },
            error: response.error,
          },
        });
      }

      yield put(verifyEmailAsync.success(response));
      if (!!response.signInResult) {
        setToken(response.signInResult!);
      }

      if (response.signInResult?.status === SignInStatus.Success) {
        yield* call(afterSignInSuccess, response.signInResult!);
      }

      if (action.payload.canUseBiometrics) {
        yield* call(() =>
          action.payload.navigate(getPath(AppRouting.Biometrics)),
        );
      } else {
        yield* call(() =>
          action.payload.navigate(getPath(AppRouting.Dashboard)),
        );
      }
      return;
    }

    yield put(verifyEmailAsync.failure(new ApplicationError(response.status)));
  } catch (err) {
    yield put(verifyEmailAsync.failure(err as Error));
  }
}
export function* verifyEmailSaga() {
  yield takeLatest(getType(verifyEmailAsync.request), verifyEmail);
}
