import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { ContractModellingItem, ContractTypeCode } from "Api/Api";
import { BackIcon } from "Components/Shared/Icons";
import { PaymentInformations } from "Components/Shared/PaymentInformations/PaymentInformations";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { useFormattedProductClass } from "Hooks/Products/useFormattedProductClass";
import { ISINPaymentInstruction } from "State/Contracts/Contract/PaymentInstructions/GetPaymentInstructionsState";
import { isNoU, notNoU } from "Utils/ObjectUtils";
import { type FunctionComponent } from "react";
import { useToggle } from "react-use";
import styled from "styled-components";

const DownIcon = styled(BackIcon)<{ $isOpened: boolean }>`
  transform: ${props => (props.$isOpened ? "rotate(90deg)" : "rotate(270deg)")};
  margin-right: ${props => props.theme.spacing(4)};
  margin-top: ${props => props.theme.spacing(1)};
`;

const MenuItemWrapper = styled(Grid)`
  cursor: pointer;
`;

const Spacing = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
`;

type Props = {
  contractID: number;
  isin: string;
  bankAccountRegion: string | null | undefined;
  paymentInstructions: ISINPaymentInstruction | undefined;
  contractTypeCode?: ContractTypeCode;
  isDip?: boolean;
  modellingItem?: ContractModellingItem;
  defaultIsOpened?: boolean;
};

export const ProductWithPaymentInfo: FunctionComponent<Props> = props => {
  const [isOpened, setIsOpened] = useToggle(props.defaultIsOpened ?? false);

  const {
    contractID,
    isin,
    bankAccountRegion,
    paymentInstructions,
    contractTypeCode,
    isDip,
    modellingItem,
  } = props;

  const defaultInstruction = paymentInstructions;
  const productClass = useFormattedProductClass(isin);

  const modellingPaymentsInfo = modellingItem
    ? {
        [modellingItem.isin]: {
          singleInvestment: modellingItem.singleInvestment || undefined,
          // if periodicalInvestment is 0, it should be undefined because we don't want to show it
          periodicalInvestment: modellingItem.periodicalInvestment || undefined,
        },
      }
    : undefined;

  const getIsinPaymentsInfo = () => {
    if (modellingPaymentsInfo) {
      return modellingPaymentsInfo;
    }

    return defaultInstruction?._isin
      ? {
          [defaultInstruction._isin]: {
            singleInvestment: 0,
          },
        }
      : {};
  };

  const showSingleInvestment =
    !modellingPaymentsInfo ||
    !defaultInstruction?._isin ||
    !!modellingPaymentsInfo[defaultInstruction._isin]?.singleInvestment;

  const showPeriodicalInvestment =
    !modellingPaymentsInfo ||
    !defaultInstruction?._isin ||
    !!modellingPaymentsInfo[defaultInstruction._isin]?.periodicalInvestment;

  return (
    <div>
      <MenuItemWrapper
        container
        justifyContent="space-between"
        onClick={setIsOpened}
      >
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <ProductLogo isin={isin} imageHeight={35} />
            </Grid>
            <Grid item>
              <Typography>{productClass}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item marginTop={2}>
          <DownIcon $isOpened={isOpened} />
        </Grid>
      </MenuItemWrapper>

      {isOpened && (
        <>
          {isNoU(defaultInstruction) && <LoadingSkeleton />}
          {notNoU(defaultInstruction) && (
            <PaymentInformations
              contractID={contractID}
              bankAccountRegion={bankAccountRegion}
              isinPaymentsInfo={getIsinPaymentsInfo()}
              isNoticeToFirstPaymentVisible={
                defaultInstruction.isNoticeToFirstPaymentVisible
              }
              contractPaymentMessage={
                defaultInstruction.contractPaymentMessage || undefined
              }
              defaultBankAccount={
                defaultInstruction.defaultBankAccount || undefined
              }
              paymentInstructions={defaultInstruction.paymentInstructions}
              contractTypeCode={contractTypeCode}
              documents={defaultInstruction.documents || undefined}
              isDip={isDip}
              showSingleInvestment={showSingleInvestment}
              showSingleInvestmentProductLogo={false}
              showPeriodicalInvestment={showPeriodicalInvestment}
              showPeriodicalInvestmentProductLogo={false}
              showDivider={false}
            />
          )}
        </>
      )}
      <Spacing />
      <Box marginY={5}>
        <Divider />
      </Box>
      <Spacing />
    </div>
  );
};

const LoadingSkeleton = () => (
  <Stack>
    <br />
    <PrimarySkeleton height={40} fullHeight={false} marginBottom={10} />
    <PrimarySkeleton height={40} fullHeight={false} marginBottom={10} />
    <PrimarySkeleton height={40} fullHeight={false} marginBottom={10} />
    <PrimarySkeleton height={160} width={160} marginBottom={10} />
  </Stack>
);
