import { getBankIdAuthNativeCallback } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { put, takeLatest } from "typed-redux-saga";

export const processBankIDNativeCallbackAsync = createAsyncAction(
  "@bankID/PROCESS_BANK_ID_NATIVE_CALLBACK_REQUEST",
  "@bankID/PROCESS_BANK_ID_NATIVE_CALLBACK_SUCCESS",
  "@bankID/PROCESS_BANK_ID_NATIVE_CALLBACK_FAILURE",
)<
  {
    processID: string;
    accessToken?: string | null;
    error?: string | null;
    errorDescription?: string | null;
    redirectFromWebToMobile: () => void;
  },
  void,
  void
>();

function* processBankIDNativeCallback(
  action: ReturnType<typeof processBankIDNativeCallbackAsync.request>,
): Generator {
  try {
    const {
      processID,
      accessToken,
      error: authError,
      errorDescription,
    } = action.payload;
    const { error } = yield* safeApiCall(
      getBankIdAuthNativeCallback,
      processID,
      accessToken,
      authError,
      errorDescription,
    );

    if (error) {
      yield put(processBankIDNativeCallbackAsync.failure());
      return;
    }

    yield put(processBankIDNativeCallbackAsync.success());
  } catch (err) {
    yield put(processBankIDNativeCallbackAsync.failure());
  } finally {
    action.payload.redirectFromWebToMobile();
  }
}

export function* watchProcessBankIDNativeCallbackSaga() {
  yield takeLatest(
    getType(processBankIDNativeCallbackAsync.request),
    processBankIDNativeCallback,
  );
}
