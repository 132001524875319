import { useSettings } from "Hooks/App/Settings/useSettings";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { WebViewMessageTypes } from "Models/WebViewModels";
import {
  useEffect,
  useMemo,
  useState,
  type FunctionComponent,
  type PropsWithChildren,
} from "react";
import { setBiometricsSignInType } from "State/Biometrics/BiometricsActions";
import { BiometricsSignInType } from "State/Biometrics/BiometricsReducer";
import { isWebView, postMessageFromWebView } from "Utils/WebViewUtils";

export const NativeVersionDetector: FunctionComponent<
  PropsWithChildren<{}>
> = ({ children }) => {
  // Compares the native version of the mobile app with the minimum supported native version of the web app.
  const validateVersion = (
    currentVersionString: string,
    supportedVersionString: string,
  ): boolean => {
    const currentVersionParts = currentVersionString.split(".").map(Number);
    const supportedVersionParts = supportedVersionString.split(".").map(Number);

    /// Compares the majority and minority versions of the native layers of a mobile application.
    /// The versioning of the native layer may differ in the minor version, if not set, it will default to 0 during the comparison.
    /// Example of version 1.0 versus version 1.0.127.
    for (
      let i = 0;
      i < Math.max(currentVersionParts.length, supportedVersionParts.length);
      i++
    ) {
      const currentVersionPart = currentVersionParts[i] || 0;
      const supportedVersionPart = supportedVersionParts[i] || 0;

      if (currentVersionPart > supportedVersionPart) {
        return true;
      }

      if (currentVersionPart < supportedVersionPart) {
        return false;
      }
    }

    return true;
  };

  const { settings } = useSettings();
  const { app, device } = useAppSelector(s => s.metadata.mobile);

  const dispatch = useAppDispatch();
  const isWebviewInitialized = useMemo(() => isWebView(), []);

  const [hidePrompt, setHidePrompt] = useState(false);

  useEffect(() => {
    if (hidePrompt) {
      return;
    }

    if (!isWebviewInitialized || !settings) {
      dispatch(setBiometricsSignInType(BiometricsSignInType.MANUAL));
      return;
    }

    setHidePrompt(true);

    if (!app.version || !device.os) {
      dispatch(
        setBiometricsSignInType(BiometricsSignInType.MANUAL_WITH_PROMPT),
      );
      return;
    }

    if (
      (device.os === "android" &&
        !validateVersion(app.version, settings.supportedAndroidVersion)) ||
      (device.os === "ios" &&
        !validateVersion(app.version, settings.supportedAppleVersion))
    ) {
      postMessageFromWebView({
        type: WebViewMessageTypes.UPDATE_REQUEST,
        payload: {},
      });
    } else {
      dispatch(
        setBiometricsSignInType(BiometricsSignInType.MANUAL_WITH_PROMPT),
      );
    }
  }, [
    isWebviewInitialized,
    hidePrompt,
    setHidePrompt,
    settings,
    app.version,
    device.os,
    dispatch,
  ]);

  return <>{children}</>;
};
