import {
  ContractTypeCode,
  ProductAllowedTransfersQueryResult,
  getProductIsinAllowedTransfersList,
} from "Api/Api";
import { produce } from "immer";
import { ProductsIsin, ProductsIsinValues } from "Models/Products";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { put, takeEvery } from "typed-redux-saga";
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

type ProductAllowedTransfersRequest = {
  isin: string;
  contractTypeCode?: ContractTypeCode | null | undefined;
  isDip?: boolean | null | undefined;
};

export type ProductAllowedTransfersStateType = {
  [key in ProductsIsin]: FetchStateType<ProductAllowedTransfersQueryResult>;
};

export const initialProductAllowedTransfersState =
  (): ProductAllowedTransfersStateType => {
    return ProductsIsinValues.reduce((state, key) => {
      state[key] = getFetchStateDefaultValue();
      return state;
    }, {} as ProductAllowedTransfersStateType);
  };

export type ProductAllowedTransfersActionType = ActionType<
  typeof productAllowedTransfersAsync
>;

type MetaType = {
  _isin: ProductsIsin;
};

export const productAllowedTransfersAsync = createAsyncAction(
  "@products/GET_PRODUCT_ALLOWED_TRANSFERS_REQUEST",
  "@products/GET_PRODUCT_ALLOWED_TRANSFERS_SUCCESS",
  "@products/GET_PRODUCT_ALLOWED_TRANSFERS_FAILURE",
)<
  [ProductAllowedTransfersRequest, MetaType],
  [ProductAllowedTransfersQueryResult, MetaType],
  [Error, MetaType]
>();

export function* watchProductAllowedTransfersSaga() {
  yield takeEvery(
    getType(productAllowedTransfersAsync.request),
    productAllowedTransfers,
  );
}

function* productAllowedTransfers(
  action: ReturnType<typeof productAllowedTransfersAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getProductIsinAllowedTransfersList,
      action.payload.isin,
      action.payload.contractTypeCode,
      action.payload.isDip,
    );

    if (error) {
      yield put(
        productAllowedTransfersAsync.failure(error, {
          _isin: action.meta._isin,
        }),
      );
      return;
    }

    yield put(
      productAllowedTransfersAsync.success(response, {
        _isin: action.meta._isin,
      }),
    );
  } catch (err) {
    yield put(
      productAllowedTransfersAsync.failure(err as Error, {
        _isin: action.meta._isin,
      }),
    );
  }
}

export const productAllowedTransfersReducer = createReducer<
  ProductAllowedTransfersStateType,
  ProductAllowedTransfersActionType
>(initialProductAllowedTransfersState())
  .handleAction(productAllowedTransfersAsync.request, (state, action) =>
    produce(state, draft => {
      draft[action.meta._isin] = handleActionRequest(state[action.meta._isin]);
    }),
  )
  .handleAction(productAllowedTransfersAsync.failure, (state, action) =>
    produce(state, draft => {
      draft[action.meta._isin] = handleActionFailure(
        state[action.meta._isin],
        action,
      );
    }),
  )
  .handleAction(productAllowedTransfersAsync.success, (state, action) =>
    produce(state, draft => {
      draft[action.meta._isin] = handleActionSuccess(
        state[action.meta._isin],
        action,
      );
    }),
  );
