import { EditPersonalDataVerificationType } from "Api/Api";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import {
  EditPersonalDataFormModel,
  PersonalDataFormModel,
} from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources =
  Resources.More.PersonalData.EditPersonalData.Modelling.PersonalData;

export const PersonalDataForm: FunctionComponent<
  Pick<EditPersonalDataFormModel, "verificationType">
> = ({ verificationType }) => {
  const { t } = useResource();
  const {
    control,
    formState: { errors },
  } = useFormContext<PersonalDataFormModel>();

  return (
    <InputHeaderWrapper header={t(ComponentResources.Header)} marginTop={0}>
      <BlInputFormField
        control={control}
        name="personalData.lastName"
        errors={errors}
        label={t(ComponentResources.LastName)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
      />
    </InputHeaderWrapper>
  );
};
