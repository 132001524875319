import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneVerification } from "Components/ContractCreate/Shared/PhoneVerification";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useYupFormSchema } from "Hooks/Profile/TwoFactorVerification/Verification/useYupFormSchema";
import { useAppSelector } from "Hooks/useAppSelector";
import { useContactConfirmation } from "Hooks/useContactConfirmation";
import { FunctionComponent, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { ContantVerificationFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { track } from "Utils/TrackingUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";

const PageResources = Resources.Profile.Settings.TwoFactorVerification;
const ComponentResources = PageResources.Verification;

export const VerificationStep: FunctionComponent = () => {
  const { t } = useResource();
  const { isPhoneVerified: isVerified } = useAppSelector(
    s => s.profile.twoFactorVerification,
  );

  const navigate = useNavigate();

  const schema = useYupFormSchema();
  const form = useForm<ContantVerificationFormModel>({
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    setError,
    formState: { isValid },
  } = form;

  const onSubmit = () => {
    track({
      category: "SMSVerification",
      event: "Submit",
    });
  };

  const {
    isLoadingSendCommand: isInitializing,
    isLoadingVerification: isVerifying,
    sendValidationCode,
    validateCode,
    requested,
    requestedNumber,
    validated: isValidated,
  } = useContactConfirmation({
    onConfirmAccept: () => handleSubmit(onSubmit)(),
    onConfirmReject: () => {
      setError("code", {
        message: t(ComponentResources.InvalidCode),
      });
    },
    active: true,
  });

  useEffect(() => {
    if (requested) {
      return;
    }

    if (!isVerified && !isValidated && requestedNumber) {
      sendValidationCode(requestedNumber);
    }
  }, [sendValidationCode, requested, requestedNumber, isVerified, isValidated]);

  const onSuccess = useCallback(() => {
    navigate(getPath(AppRouting.ProfileSettings), {
      state: { from: getPath(AppRouting.TwoFactorVerification) },
    });
  }, [navigate]);

  const onValidateCode = (formData: ContantVerificationFormModel) => {
    validateCode(formData.code, onSuccess);
  };

  useEffectOnce(() => reset());

  return (
    <>
      <div>
        <PageTitle showOnDesktop showOnMobile={false}>
          {t(PageResources.Title)}
        </PageTitle>

        <FormProvider {...form}>
          <PhoneVerification
            onSubmit={onSubmit}
            codeDetermination={t(ComponentResources.CodeDetermination)}
            phone={requestedNumber || ""}
            onResendCode={sendValidationCode}
            isLoading={isInitializing}
          />
        </FormProvider>
      </div>

      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onValidateCode)}
        isLoading={isInitializing || isVerifying}
        disabled={isVerified || !isValid}
        trackingTag={t(ComponentResources.Continue)}
      >
        {t(ComponentResources.Continue)}
      </PrimaryButton>
    </>
  );
};
