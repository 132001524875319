import {
  clientDocumentsReducer,
  ClientDocumentsState,
  watchClientDocumentsSaga,
} from "./Documents/ClientDocumentsReducer";
import { editAMLReducer, watchEditAMLSaga } from "./EditAML/EditAMLReducer";
import { combineReducers } from "redux";
import { EditAMLState } from "State/More/PersonalData/EditAML/EditAMLReducer";
import {
  editPersonalDataReducer,
  EditPersonalDataState,
  watchEditPersonalDataSaga,
} from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { all } from "typed-redux-saga";

export type PersonalDataState = {
  editAML: EditAMLState;
  edit: EditPersonalDataState;
  documents: ClientDocumentsState;
};

export function* watchPersonalDataSaga() {
  yield all([
    watchEditAMLSaga(),
    watchClientDocumentsSaga(),
    watchEditPersonalDataSaga(),
  ]);
}

export const personalDataReducer = combineReducers<PersonalDataState>({
  editAML: editAMLReducer,
  edit: editPersonalDataReducer,
  documents: clientDocumentsReducer,
});
