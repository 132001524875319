import {
  useState,
  useEffect,
  useRef,
  type FunctionComponent,
  type ReactNode,
  type PropsWithChildren,
  useCallback,
} from "react";
import { useSwipeable } from "react-swipeable";
import Arrow from "./arrow.png";
import ArrowContrast from "./arrowInverted.png";
import styled from "styled-components";
import { CircularProgress, Typography } from "@mui/material";
import { StyledLoadingIconWrapper } from "Components/Shared/Buttons/PrimaryButton";
import { track } from "Utils/TrackingUtils";
import { isNoU } from "Utils/ObjectUtils";

const dirTypes = ["Left", "Down", "Up"];

const findLeft = (element: HTMLDivElement | null) => {
  if (!element) {
    return 0;
  }

  var rec = element.getBoundingClientRect();
  return rec.left + window.scrollX;
};

const SliderWrapper = styled.div<{
  $disabled?: boolean;
  $hexBackgroundColor?: string | null | undefined;
}>`
  border: 1px solid ${props => props.theme.palette.primary.main}05;
  background: ${props =>
    isNoU(props.$hexBackgroundColor)
      ? props.theme.palette.primary.main
      : props.$hexBackgroundColor}40;
  min-width: 200px;
  max-height: 56px;
  height: 56px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  user-select: none;

  ${props =>
    props.$disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}

  * {
    box-sizing: border-box;
  }
`;

const SlideOverlay = styled.div<{
  $hexBackgroundColor?: string | null | undefined;
}>`
  position: absolute;
  padding-left: 3rem;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  background: ${props =>
    isNoU(props.$hexBackgroundColor)
      ? props.theme.palette.primary.main
      : props.$hexBackgroundColor};
  transition: width 0.2s ease-out;
  will-change: width;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 100px 100px 0;
`;

const SlideOverlayWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const SlideCaretWrapper = styled.div<{ $width: number; $maxWidth: number }>`
  position: absolute;
  width: ${({ $width }) => $width}px;
  max-width: ${({ $maxWidth }) => $maxWidth ?? 45}px;
  min-width: 0;
  right: 6px;
  top: 50%;
  height: 100%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
`;

const SlideOverlayText = styled.div<{ $hexColor?: string | null | undefined }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  color: ${props =>
    isNoU(props.$hexColor)
      ? props.theme.palette.secondary.main
      : props.$hexColor};
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

type Props = PropsWithChildren<{
  overlayText?: ReactNode;
  onSlideDone: Function;
  reset?: boolean;
  classList?: string;
  overlayClassList?: string;
  caretClassList?: string;
  delta?: number;
  minSlideWidth?: number;
  minSlideVelocity?: number;
  caret?: ReactNode;
  customCaretWidth?: number;
  disabled?: boolean;
  isLoading?: boolean;
  trackingTag?: string;
  hexColor?: string | null | undefined;
  hexBackgroundColor?: string | null | undefined;
}>;

export const BlSlideButton: FunctionComponent<Props> = ({
  children,
  overlayText,
  onSlideDone,
  reset,
  delta = 10,
  minSlideWidth = 0.6,
  minSlideVelocity = 0.6,
  caret = null,
  customCaretWidth = 40,
  disabled,
  isLoading,
  trackingTag,
  hexColor,
  hexBackgroundColor,
}) => {
  const [overlayWidth, setOverlayWidth] = useState(customCaretWidth);
  const [slideComplete, setSlideComplete] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (reset) {
      setSlideComplete(false);
      setOverlayWidth(customCaretWidth);
    }
  }, [reset, customCaretWidth]);

  const handleTrack = useCallback(() => {
    const tag =
      trackingTag ??
      (typeof overlayText === "string" ? `${overlayText}` : undefined);

    track({
      category: "Button",
      event: "SlideComplete",
      tag,
    });
  }, [overlayText, trackingTag]);

  const handlers = useSwipeable({
    onSwipedRight: data => {
      if (slideComplete) return;
      const butWidth = buttonRef.current?.offsetWidth ?? 0;
      if (data.velocity > minSlideVelocity) {
        setOverlayWidth(butWidth);
        setSlideComplete(true);
        setTimeout(() => onSlideDone(), 100);
        handleTrack();
      } else {
        const offsetLeft = findLeft(buttonRef.current);
        const startPos = Math.abs(data.initial[0] - offsetLeft);
        if (
          startPos <= 100 + customCaretWidth &&
          (data.event.type === "touchend"
            ? // @ts-ignore
              data.event.changedTouches[0].clientX - offsetLeft
            : // @ts-ignore
              data.event.offsetX) >
            minSlideWidth * butWidth
        ) {
          setOverlayWidth(butWidth);
          setSlideComplete(true);
          setTimeout(() => onSlideDone(), 100);
          handleTrack();
        } else setOverlayWidth(customCaretWidth);
      }
    },
    onSwiping: data => {
      if (slideComplete || dirTypes.includes(data.dir)) return;
      const offsetLeft = findLeft(buttonRef.current);
      const startPos = Math.abs(data.initial[0] - offsetLeft);

      if (!(startPos <= 100 + customCaretWidth)) {
        return;
      }

      if (data.event.type && data.event.type === "touchmove") {
        // @ts-ignore
        setOverlayWidth(data.event.touches[0].clientX - offsetLeft);
        // @ts-ignore
      } else if (data.event.offsetX > customCaretWidth) {
        // @ts-ignore
        setOverlayWidth(data.event.offsetX);
      }
    },
    delta,
    trackMouse: true,
  });

  return (
    <SliderWrapper
      {...handlers}
      ref={t => {
        handlers.ref(t);
        buttonRef.current = t;
      }}
      $disabled={disabled}
      $hexBackgroundColor={hexBackgroundColor}
    >
      <SlideOverlay
        $hexBackgroundColor={hexBackgroundColor}
        style={{ width: Math.max(overlayWidth, 55) }}
      >
        <SlideOverlayWrapper>
          <SlideCaretWrapper
            $width={customCaretWidth}
            $maxWidth={customCaretWidth}
          >
            {caret ? (
              caret
            ) : (
              <img
                src={hexColor && hexBackgroundColor ? Arrow : ArrowContrast}
                alt="caret"
                style={{ maxWidth: "100%" }}
              />
            )}
          </SlideCaretWrapper>
          {isLoading && (
            <StyledLoadingIconWrapper
              $hexColor={hexColor}
              $hexBackgroundColor={hexBackgroundColor}
            >
              <CircularProgress size={18} color="primary" />
            </StyledLoadingIconWrapper>
          )}
          {overlayText && (
            <SlideOverlayText style={{ width: overlayWidth }}>
              {overlayText}
            </SlideOverlayText>
          )}
        </SlideOverlayWrapper>
      </SlideOverlay>

      <Typography variant="body2" fontWeight={600}>
        {children}
      </Typography>
    </SliderWrapper>
  );
};
