import {
  AttachmentFormat,
  CreateContractDraftDocumentCommandResult,
  UploadAttachmentRequest,
  postAttachments,
} from "Api/Api";
import { deleteAttachmentAsync } from "State/Attachments/Upload/DeleteAttachmentState";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  safeApiCall,
} from "State/Utils";
import { getFileBase64 } from "Utils/FileUtils";
import { produce } from "immer";

import { call, put, takeEvery } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type UploadAttachmentMetaType = Pick<
  UploadAttachmentRequest,
  "attachmentGuid"
>;

export type UploadAttachmentDescriptor = Pick<
  UploadAttachmentRequest,
  "type" | "descriptor" | "format" | "mimeType" | "fileName"
>;

export type UploadAttachmentPayload = UploadAttachmentDescriptor &
  (
    | {
        file: File;
        format: AttachmentFormat.BASE64_ENCODED;
      }
    | { file: string; format: AttachmentFormat.JSON }
  );

type UploadAttachmentsStateType = UploadAttachmentDescriptor &
  UploadAttachmentMetaType;

export type UploadAttachmentsState = {
  [key: string]: FetchStateType<UploadAttachmentsStateType>;
};

export const initialUploadAttachmentState = (): UploadAttachmentsState => ({});

export const deleteAttachment = createAction(
  "@attachments/DELETE_ATTACHMENT",
)<UploadAttachmentMetaType>();

export const uploadAttachmentAsync = createAsyncAction(
  "@attachments/POST_ATTACHMENT_REQUEST",
  "@attachments/POST_ATTACHMENT_SUCCESS",
  "@attachments/POST_ATTACHMENT_FAILURE",
)<
  [UploadAttachmentPayload, UploadAttachmentMetaType],
  [
    CreateContractDraftDocumentCommandResult & UploadAttachmentPayload,
    UploadAttachmentMetaType,
  ],
  [Error, UploadAttachmentMetaType]
>();

export type UploadAttachmentActionType =
  | ActionType<typeof uploadAttachmentAsync>
  | ActionType<typeof deleteAttachment>
  | ActionType<typeof deleteAttachmentAsync>;

function* uploadAttachment(
  action: ActionType<typeof uploadAttachmentAsync.request>,
): Generator {
  try {
    const { format } = action.payload;

    let data: string = "";

    if (format === AttachmentFormat.BASE64_ENCODED) {
      data = yield* call(getFileBase64, action.payload.file);
    }

    if (format === AttachmentFormat.JSON) {
      data = action.payload.file;
    }

    const { response, error } = yield* safeApiCall(postAttachments, {
      ...action.payload,
      ...action.meta,
      data,
    });

    if (error) {
      yield put(uploadAttachmentAsync.failure(error, action.meta));
      return;
    }

    yield put(
      uploadAttachmentAsync.success(response, {
        ...action.meta,
        ...action.payload,
      }),
    );
  } catch (err) {
    yield put(uploadAttachmentAsync.failure(err as Error, action.meta));
  }
}

export function* watchUploadAttachmentSaga() {
  yield takeEvery(getType(uploadAttachmentAsync.request), uploadAttachment);
}

export const uploadAttachmentReducer = createReducer<
  UploadAttachmentsState,
  UploadAttachmentActionType
>(initialUploadAttachmentState())
  .handleAction(uploadAttachmentAsync.request, (state, action) =>
    produce(state, draft => {
      draft[action.meta.attachmentGuid] = handleActionRequest(
        getFetchStateDefaultValue(),
      );

      return draft;
    }),
  )
  .handleAction(uploadAttachmentAsync.failure, (state, action) =>
    produce(state, draft => {
      draft[action.meta.attachmentGuid] = handleActionFailure(
        draft[action.meta.attachmentGuid],
        action,
      );

      return draft;
    }),
  )
  .handleAction(uploadAttachmentAsync.success, (state, action) =>
    produce(state, draft => {
      draft[action.meta.attachmentGuid] = {
        isLoading: false,
        data: {
          ...action.payload,
          ...action.meta,
        },
        error: null,
      };

      return draft;
    }),
  )
  .handleAction(deleteAttachment, (state, action) =>
    produce(state, draft => {
      draft[action.payload.attachmentGuid] = getFetchStateDefaultValue();
      return draft;
    }),
  )
  .handleAction(deleteAttachmentAsync.request, (state, action) =>
    produce(state, draft => {
      draft[action.payload.guid] = getFetchStateDefaultValue();
      return draft;
    }),
  );
