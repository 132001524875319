import { Box, Link, Stack, Typography } from "@mui/material";
import {
  EmailIcon,
  OutgoingCallIcon,
  SolicitorIcon,
} from "Components/Shared/Icons";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, useRef } from "react";
import { IconButton } from "Components/ContractDetail/Shared/IconButton";
import styled from "styled-components";

const StyledSolicitorIcon = styled(SolicitorIcon)`
  height: 100px;
  width: 100px;
`;

const StyledDealerNameTypography = styled(Typography)`
  margin-top: ${props => props.theme.spacing(3)};
`;

const ComponentResources = Resources.More.Contacts.Information;

type Props = {
  title?: string;
  subtitle?: string;
  phone?: string;
  email?: string;
  isLoading?: boolean;
};

export const ContactInformation: FunctionComponent<Props> = ({
  title,
  subtitle,
  phone,
  email,
  isLoading = false,
}) => {
  const { t } = useResource();

  const emailRef = useRef<HTMLAnchorElement | null>(null);
  const phoneRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <Stack gap={16}>
      <StyledFlex $alignItems="center" $flexDirection="column">
        <StyledSolicitorIcon />
        <LoadingWrapper
          isLoading={isLoading}
          skeleton={
            <Box width="50%" marginTop={4}>
              <Typography variant="h3">
                <PrimarySkeleton variant="text" />
              </Typography>
              <PrimarySkeleton variant="text" />
              <PrimarySkeleton variant="text" />
              <PrimarySkeleton variant="text" />
            </Box>
          }
        >
          {title && (
            <StyledDealerNameTypography
              variant="h3"
              textAlign="center"
              marginBottom={subtitle ? 1 : 0}
            >
              {title}
            </StyledDealerNameTypography>
          )}
          {subtitle && <Typography textAlign="center">{subtitle}</Typography>}
          <Link
            target="_blank"
            href={`tel:${phone}`}
            marginTop={3}
            color="secondary"
            ref={phoneRef}
          >
            {phone}
          </Link>
          <Link
            target="_blank"
            href={`mailto:${email}`}
            color="secondary"
            marginTop={1}
            ref={emailRef}
          >
            {email}
          </Link>
        </LoadingWrapper>
      </StyledFlex>
      <StyledFlex $justifyContent="space-around">
        <IconButton
          label={t(ComponentResources.SendMail)}
          onClick={() => emailRef.current?.click()}
          icon={<EmailIcon />}
          disabled={isLoading || !email}
        />
        <IconButton
          label={t(ComponentResources.Call)}
          onClick={() => phoneRef.current?.click()}
          icon={<OutgoingCallIcon />}
          disabled={isLoading || !phone}
        />
      </StyledFlex>
    </Stack>
  );
};
