import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { About } from "Components/About/About";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { type FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { useDesktop } from "Hooks/useDesktop";

const PageResources = Resources.More.Menu.About;

export const AboutApplicationPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const navigate = useNavigate();

  return (
    <AuthenticatedLayout
      title={t(Resources.More.Menu.About.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.More))}
    >
      <About pageTitle={t(PageResources.Title)} />
    </AuthenticatedLayout>
  );
};
