import { GenerateQrCodeCommand } from "Api/Api";
import { Wrapper } from "Components/Shared/InvoiceQrCode/InvoiceQRCode";
import { SaveButton } from "Components/Shared/InvoiceQrCode/SaveButton";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  clearGenerateQrCode,
  postGenerateSingleInvestmentInvoiceQrCodeRequest,
  postGeneratePeriodicalInvestmentInvoiceQrCodeRequest,
} from "State/Payments/generateQrCode/GenerateQrCodeState";
import { notNoU } from "Utils/ObjectUtils";
import { useEffect, type FunctionComponent } from "react";

type Props = {
  size: number;
  showSaveButton?: boolean;
  isSingleInvestment: boolean;
} & GenerateQrCodeCommand;

export const SlovakiaInvoiceQrCode: FunctionComponent<Props> = ({
  showSaveButton,
  size,
  isSingleInvestment,
  ...props
}) => {
  const investmentType = isSingleInvestment
    ? "singleInvestment"
    : "periodicalInvestment";

  const dispatch = useAppDispatch();
  const { isLoading, data } = useAppSelector(
    s => s.payments.generateQrCode[investmentType],
  );

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = "invoice-qr-code.png";
    link.href = `data:image/png;base64,${data?.data}`;
    link.click();
  };

  useEffect(() => {
    if (isSingleInvestment) {
      dispatch(postGenerateSingleInvestmentInvoiceQrCodeRequest(props));
    } else {
      dispatch(postGeneratePeriodicalInvestmentInvoiceQrCodeRequest(props));
    }

    return () => {
      dispatch(clearGenerateQrCode(investmentType));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const showBySquareQrCode = notNoU(data?.data);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Wrapper>
        {showBySquareQrCode && (
          <div>
            <img
              height={size}
              width={size}
              src={`data:image/png;base64,${data?.data}`}
              alt="invoice qr code"
            />
          </div>
        )}
        {showBySquareQrCode && showSaveButton && (
          <SaveButton onDownload={onDownload} />
        )}
      </Wrapper>
    </LoadingWrapper>
  );
};
