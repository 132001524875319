import {
  NetIncomeType,
  SourceOfIncomeType,
  JobType,
  SourceOfResourcesType,
  CurrencyCode,
} from "Api/Api";
import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import { USA_COUNTRY_CODE } from "Constants/Countries";
import {
  type AMLQuestionsFormModel,
  TaxResidencyEnum,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { object, boolean, array, string, ObjectSchema, number } from "yup";

export const useYupFormSchema = (): ObjectSchema<AMLQuestionsFormModel> => {
  const { t } = useResource();

  return object<AMLQuestionsFormModel>().shape({
    annualIncome: string().oneOf(Object.values(NetIncomeType)).required(),
    businessSector: number().required(),
    sourceOfIncome: string()
      .oneOf(Object.values(SourceOfIncomeType))
      .required(),
    sourceOfIncomeOther: string().when("sourceOfIncome", {
      is: (val?: string) => val === SourceOfIncomeType.Other,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    jobPosition: string().oneOf(Object.values(JobType)).required(),
    sourceOfFunds: array()
      .min(1)
      .of(string().oneOf(Object.values(SourceOfResourcesType)).required())
      .required(),
    sourceOfFundsOther: string().when("sourceOfFunds", {
      is: (val?: SourceOfResourcesType[]) =>
        val?.includes(SourceOfResourcesType.Other),
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    fundsVolume: number().when("sourceOfFunds", {
      is: (val?: SourceOfResourcesType[]) =>
        !!val?.length && val.some(v => v !== SourceOfResourcesType.Savings),
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    fundsVolumeCurrency: string().oneOf(Object.values(CurrencyCode)).required(),
    politicallyExposed: boolean().required(),
    politicallyExposedDescription: string().when("politicallyExposed", {
      is: (val?: boolean) => val,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    taxResidency: string().oneOf(Object.values(TaxResidencyEnum)).required(),
    taxResidencyCountry: string()
      .when("taxResidency", {
        is: (val?: string) => val === TaxResidencyEnum.Other,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
      })
      .test(
        USA_NATIONALITY_FORBID_TYPE,
        t(Resources.Contract.NewContract.Shared.USACountryPermit),
        value => !(value === USA_COUNTRY_CODE),
      ),
    vatId: string().when("taxResidency", {
      is: (val?: TaxResidencyEnum) =>
        val &&
        [TaxResidencyEnum.Slovakia, TaxResidencyEnum.Other].includes(val),
      then: schema =>
        schema
          .required()
          .matches(
            /^[a-zA-Z0-9]{8,20}$/,
            t(Resources.Contract.NewContract.Shared.VATIdInvalid),
          ),
      otherwise: schema => schema.optional(),
    }),
  });
};
