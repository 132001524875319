import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  DocumentScanSide,
  Documents,
  DocumentsFormModel,
  NewContractStep,
} from "State/Contracts/Create/Models";
import { postDocumentsAsync } from "State/Contracts/Shared/Draft";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

export const useDocumentFile = (
  documentType: Documents,
  side: DocumentScanSide,
) => {
  const dispatch = useAppDispatch();
  const { setValue } = useFormContext<DocumentsFormModel>();

  const documents = useAppSelector(
    s => s.contracts.create.formData[NewContractStep.Documents],
  );
  const postFile = useAppSelector(s => s.contracts.draft.postDocuments);

  const fileDetail = documents?.[documentType]?.[side];
  const [guid, setGuid] = useState(fileDetail?.guid ?? uuid());
  const isLoading = !!postFile?.[guid]?.isLoading;

  const file = useMemo(() => {
    if (fileDetail?.fileName) {
      return new File([""], fileDetail.fileName);
    }

    return null;
  }, [fileDetail?.fileName]);

  // sync redux store with hook form
  useEffect(() => {
    if (isLoading) {
      return;
    }

    setValue(`${documentType}.${side}`, fileDetail, {
      shouldValidate: !!fileDetail,
    });
  }, [documentType, fileDetail, setValue, side, isLoading, guid]);

  const saveFile = (file: File) => {
    const newGuid = uuid();
    dispatch(
      postDocumentsAsync.request(
        { file, document: documentType, side },
        { guid: newGuid },
      ),
    );
    setGuid(newGuid);
  };

  return {
    file,
    saveFile,
    isSaving: isLoading,
  };
};
