import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { string, object, ObjectSchema, boolean } from "yup";
import { Box, Link, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import styled from "styled-components";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { FullWidthDiv } from "Components/Shared/StyledComponents";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { usePasswordValidation } from "Hooks/YupValidations/usePasswordValidation";
import {
  MAINTAINCE_SERVICE_PHONE,
  PERSONAL_DATA_ANALYTICS_URL,
  TERMS_AND_CONDITIONS_URL,
} from "Utils/ExternalLinkUtils";
import { AnalyticsDisclaimer } from "Components/Auth/Shared/AnalyticsDisclaimer";
import useHandleServerError from "Hooks/useHandleServerError";
import { DocumentLink } from "Components/Shared/DocumentLink";

type Props = {
  isLoading: boolean;
  serverError: Error | null;
  defaultLogin: string | null;
  onSubmit(FormModel: FormModel): void;
  onReset(): void;
};

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(2)};
`;

export type FormModel = {
  login: string;
  password: string;
  agreeWithAnalytics: boolean;
  agreeWithTermsAndConditions: boolean;
};

const PageResources = Resources.SignUp;

export const SignUpForm: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const passwordValidation = usePasswordValidation();

  const { isLoading, defaultLogin, serverError } = props;
  const validationSchema: ObjectSchema<FormModel> = object({
    login: string().email().required(),
    password: passwordValidation,
    agreeWithAnalytics: boolean().required(),
    agreeWithTermsAndConditions: boolean()
      .required()
      .isTrue(t(PageResources.TermsAndConditions.IsTrueMessage)),
  }).defined();

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      login: defaultLogin ?? "",
      password: "",
      agreeWithAnalytics: false,
      agreeWithTermsAndConditions: false,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  useHandleServerError({
    form,
    serverError,
    resource: PageResources.Error,
    key: "login",
    keyMap: {
      login: "username",
      password: "password",
    },
  });

  const onSubmit = (data: FormModel) => {
    props.onSubmit(data);
  };

  return (
    <>
      <div>
        <Typography component="h2" variant="h2">
          {t(PageResources.Title)}
        </Typography>
        <Typography paddingTop={2}>{t(PageResources.Label)}</Typography>
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
        >
          <FullWidthDiv>
            <BlInputFormField
              type="email"
              autoFocus
              control={control}
              name="login"
              autoComplete="username"
              errors={errors}
              label={t(Resources.Common.Email)}
              placeholder={t(Resources.Common.ExampleLogin)}
            />
            <BlInputFormField
              control={control}
              name="password"
              errors={errors}
              label={t(Resources.Common.Password)}
              type="password"
              autoComplete="current-password"
              placeholder="******"
            />
            <Typography marginTop={2} component="div">
              {t(PageResources.ContactSupport)}
              <Link target="_blank" href={`tel:${MAINTAINCE_SERVICE_PHONE}`}>
                {MAINTAINCE_SERVICE_PHONE}.
              </Link>
            </Typography>

            <Typography marginTop={5} marginBottom={2}>
              {t(PageResources.Analytics.Text)}
              &nbsp;
              <Link
                target="_blank"
                href={PERSONAL_DATA_ANALYTICS_URL}
                fontWeight={600}
                color="secondary"
              >
                {t(PageResources.Analytics.Link)}
              </Link>
            </Typography>

            <BlCheckboxFormField
              control={control}
              name="agreeWithAnalytics"
              errors={errors}
              label={
                <Box display="flex">
                  <Typography color="secondary">
                    {t(PageResources.Analytics.ConsentCheckBox)}
                  </Typography>
                </Box>
              }
            />

            <BlCheckboxFormField
              control={control}
              name="agreeWithTermsAndConditions"
              errors={errors}
              label={
                <Box display="flex">
                  <Typography color="secondary">
                    {t(Resources.SignUp.TermsAndConditions.ConsentCheckBoxText)}
                    &nbsp;
                    <DocumentLink
                      target="_blank"
                      href={TERMS_AND_CONDITIONS_URL}
                    >
                      {t(PageResources.TermsAndConditions.ConsentCheckBoxLink)}
                    </DocumentLink>
                  </Typography>
                </Box>
              }
            />
          </FullWidthDiv>
        </StyledForm>
      </div>

      <div>
        <AnalyticsDisclaimer />

        <PrimaryButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t(Resources.Common.Continue)}
        </PrimaryButton>
      </div>
    </>
  );
};
