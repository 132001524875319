import {
  ContractTypeCode,
  ProductListQueryResult,
  getProductList,
} from "Api/Api";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type GetProductsStateType = FetchStateType<ProductListQueryResult>;

export const getProductsState = (): GetProductsStateType =>
  getFetchStateDefaultValue();

export type GetProductsActionType = ActionType<typeof getProductsAsync>;

export const getProductsAsync = createAsyncAction(
  "@products/GET_PRODUCTS_REQUEST",
  "@products/GET_PRODUCTS_SUCCESS",
  "@products/GET_PRODUCTS_FAILURE",
)<
  {
    contractTypeCode?: ContractTypeCode;
    isDip?: boolean | null;
  },
  ProductListQueryResult,
  Error
>();

export function* getProductsSaga() {
  yield takeLeading(getType(getProductsAsync.request), getProducts);
}

function* getProducts(
  action: ReturnType<typeof getProductsAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getProductList,
      action.payload.contractTypeCode,
      action.payload.isDip,
    );

    if (error) {
      yield put(getProductsAsync.failure(error));
      return;
    }

    yield put(getProductsAsync.success(response));
  } catch (err) {
    yield put(getProductsAsync.failure(err as Error));
  }
}

export const getProductsReducer = createReducer<
  GetProductsStateType,
  GetProductsActionType
>(getProductsState())
  .handleAction(getProductsAsync.request, handleActionRequest)
  .handleAction(getProductsAsync.failure, handleActionFailure)
  .handleAction(getProductsAsync.success, handleActionSuccess);
