import { Box } from "@mui/material";
import { PersonalDocumentType } from "Api/Api";
import { DocumentInput } from "Components/ContractCreate/Documents/Components/DocumentInput";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { useDocumentsList } from "Hooks/Inputs/useDocumentsList";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  DocumentsFormModel,
  NewContractStep,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { parseDateToDocumentsDate } from "Utils/Contracts/NewContract/DateUtils";
import { isValid } from "date-fns";
import { useMemo, type FunctionComponent, FocusEvent } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  formType: keyof Pick<DocumentsFormModel, "firstDocument" | "secondDocument">;
};

const PageResources = Resources.Contract.NewContract.Documents;

export const DocumentForm: FunctionComponent<Props> = ({ formType }) => {
  const { t } = useResource();
  const documentsList = useDocumentsList();
  const countriesList = useCountriesList();
  const personalData = useAppSelector(
    s => s.contracts.create.formData[NewContractStep.PersonalData],
  );
  const isBankIDVerified = useIsBankIDVerified();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    setError,
    trigger,
  } = useFormContext<DocumentsFormModel>();

  const firstType = watch(`firstDocument.type`);
  const secondType = watch(`secondDocument.type`);
  const isFirstDocument = formType === "firstDocument";
  const countryIssue = watch(`${formType}.countryIssue`);
  const dateValidFrom = watch(`${formType}.dateValidFrom`);
  const isExpiryDateIndefinite = !!watch(`${formType}.isExpiryDateIndefinite`);
  const isExpiryDateIndefiniteVisible =
    !isFirstDocument &&
    !isBankIDVerified &&
    secondType === PersonalDocumentType.DrivingLicense;

  const filteredDocumentsList = useMemo(() => {
    if (formType === "firstDocument") {
      // czech nationality must have identity card as first document
      if (
        !isBankIDVerified &&
        personalData?.nationalities?.find(n => n.value === "CZ")
      ) {
        const identityCard = documentsList.find(
          ({ Value }) => Value === PersonalDocumentType.IdentityCard,
        );

        return identityCard ? [identityCard] : [];
      }
      return documentsList.filter(({ Value }) => Value !== secondType);
    } else {
      return documentsList.filter(({ Value }) => Value !== firstType);
    }
  }, [
    formType,
    isBankIDVerified,
    personalData?.nationalities,
    documentsList,
    secondType,
    firstType,
  ]);

  const onDateValidFromBlur = (event: FocusEvent<HTMLInputElement>) => {
    const dateValidFrom = new Date(event.target.value);
    const minDateValidFrom = new Date(1900, 11, 30);
    const maxDateValidFrom = new Date();
    if (isValid(dateValidFrom) && dateValidFrom > maxDateValidFrom) {
      setValue(
        `${formType}.dateValidFrom`,
        parseDateToDocumentsDate(maxDateValidFrom),
        {
          shouldValidate: true,
        },
      );
    } else if (isValid(dateValidFrom) && dateValidFrom < minDateValidFrom) {
      setValue(
        `${formType}.dateValidFrom`,
        parseDateToDocumentsDate(minDateValidFrom),
        {
          shouldValidate: true,
        },
      );
    } else {
      trigger(`${formType}.dateValidFrom`);
    }
  };

  const onDateExpiryBlur = (event: FocusEvent<HTMLInputElement>) => {
    const dateExpiry = new Date(event.target.value);
    const minDateExpiry = new Date();
    const maxDateExpiry = new Date(2999, 11, 30);
    if (isValid(dateExpiry) && dateExpiry > maxDateExpiry) {
      setValue(
        `${formType}.dateExpiry`,
        parseDateToDocumentsDate(maxDateExpiry),
        {
          shouldValidate: true,
        },
      );
    } else if (isValid(dateExpiry) && dateExpiry < minDateExpiry) {
      setValue(
        `${formType}.dateExpiry`,
        parseDateToDocumentsDate(minDateExpiry),
        {
          shouldValidate: true,
        },
      );
    } else {
      trigger(`${formType}.dateExpiry`);
    }
  };

  useMemo(() => {
    if (isExpiryDateIndefinite) {
      setValue(`${formType}.dateExpiry`, "");
    }

    if (isExpiryDateIndefinite && !isExpiryDateIndefiniteVisible) {
      setValue(`${formType}.isExpiryDateIndefinite`, false);
    }

    trigger(`${formType}.isExpiryDateIndefinite`);
    if (isExpiryDateIndefinite && !!countryIssue && !!dateValidFrom) {
      trigger(`${formType}.dateExpiry`);
    }
  }, [
    formType,
    countryIssue,
    dateValidFrom,
    isExpiryDateIndefinite,
    isExpiryDateIndefiniteVisible,
    setValue,
    trigger,
  ]);

  return (
    <>
      <DropdownInput
        control={control}
        errors={errors}
        name={`${formType}.type`}
        options={filteredDocumentsList}
        label={t(PageResources.DocumentType)}
        disabled={isBankIDVerified}
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name={`${formType}.number`}
        label={t(PageResources.DocumentNumber)}
        disabled={isBankIDVerified}
        mask={/^.+$/}
      />
      <BlInputFormField
        control={control}
        errors={errors}
        name={`${formType}.dateValidFrom`}
        type="date"
        label={t(PageResources.DocumentValidStart)}
        disabled={isBankIDVerified}
        onBlur={onDateValidFromBlur}
      />
      <BlInputFormField
        control={control}
        errors={errors}
        name={`${formType}.dateExpiry`}
        type="date"
        label={t(PageResources.DocumentExpireDate)}
        disabled={isBankIDVerified || isExpiryDateIndefinite}
        onBlur={onDateExpiryBlur}
      />
      {isExpiryDateIndefiniteVisible && (
        <Box marginBottom={1}>
          <BlCheckboxFormField
            control={control}
            name={`${formType}.isExpiryDateIndefinite`}
            label={t(PageResources.DocumentIsExpiryDateIndefinite)}
            value={false}
            errors={errors}
          />
        </Box>
      )}
      <DropdownInput
        control={control}
        errors={errors}
        name={`${formType}.countryIssue`}
        options={countriesList}
        label={t(PageResources.CountryIssue)}
        disabled={isBankIDVerified}
      />
      <BlInputFormField
        control={control}
        errors={errors}
        name={`${formType}.issuingAuthority`}
        label={t(PageResources.IssuingAuthority)}
        placeholder={t(PageResources.IssuingAuthorityPlaceholder)}
        disabled={isBankIDVerified}
      />
      {!isBankIDVerified && (
        <DocumentInput
          name={`${formType}.frontScan`}
          side="frontScan"
          documentType={isFirstDocument ? "firstDocument" : "secondDocument"}
          label={
            isFirstDocument
              ? t(PageResources.FirstDocumentFront)
              : t(PageResources.SecondDocumentFront)
          }
          setFormError={(error: string) =>
            setError(`${formType}.frontScan`, { message: error })
          }
        />
      )}
      {!isBankIDVerified && isFirstDocument && (
        <DocumentInput
          name={`${formType}.backScan`}
          side="backScan"
          documentType="firstDocument"
          label={t(PageResources.FirstDocumentBack)}
          setFormError={(error: string) =>
            setError(`${formType}.backScan`, { message: error })
          }
        />
      )}
    </>
  );
};
