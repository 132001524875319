import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";
import {
  PageTitleProps,
  PageTitle as SharedPageTitle,
} from "Components/Shared/PageTitle";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { FunctionComponent } from "react";

import { useModelingIsin } from "Hooks/useModelingIsin";
import { useDesktop } from "Hooks/useDesktop";

export const PageTitle: FunctionComponent<PageTitleProps> = props => {
  const isDip = useIsDipSelected();
  const isin = useModelingIsin();
  const { isDesktop } = useDesktop();

  if (!isDesktop) {
    return null;
  }

  return (
    <ContractGuideWrapper isDipWrapped={isDip} productIsin={isin}>
      <SharedPageTitle {...props} />
    </ContractGuideWrapper>
  );
};
