import { Typography } from "@mui/material";
import { ChartData, ChartDataProps } from "Components/Charts/Chart";
import { StyledDarkCard } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { nameof } from "Utils/ObjectUtils";
import { type FunctionComponent } from "react";
import { TooltipProps } from "recharts";
import styled from "styled-components";

export const StyledTooltipCard = styled(StyledDarkCard)`
  padding: 10px;
  border: 0;
`;

type Props = {
  data: TooltipProps<any, any>;
  currency: string;
};

export const ChartTooltip: FunctionComponent<Props> = props => {
  const { data, currency } = props;

  const { t } = useResource();

  if (!data.active) {
    return null;
  }

  const chartPayload = data.payload?.find(
    item => item.dataKey === nameof<ChartDataProps>("deposit"),
  ) as { payload: ChartData } | undefined;

  if (!chartPayload?.payload) {
    return null;
  }
  const payload = chartPayload.payload;

  const { deposit, expectedYieldMax } = payload;
  return (
    <>
      <StyledTooltipCard>
        <Typography>
          {t(Resources.Chart.Tooltip.Yield, {
            value: formatCurrency(expectedYieldMax, currency, {
              decimalPlaces: 2,
            }),
          })}
        </Typography>
        <Typography>
          {t(Resources.Chart.Tooltip.Deposit, {
            value: formatCurrency(deposit, currency, {
              decimalPlaces: 2,
            }),
          })}
        </Typography>
      </StyledTooltipCard>
    </>
  );
};
