import { produce } from "immer";
import { all } from "typed-redux-saga";
import { createReducer, ActionType, createAction } from "typesafe-actions";

export enum PurchaseStep {
  Configuration,
  Success,
}

export type PurchaseConfiguration = {
  isin: string;
  singleInvestment: number;
  periodicalInvestment: number;
  currency: string;
};

export type PurchaseState = {
  contractID: number | null;
  step: PurchaseStep;
  configuration: PurchaseConfiguration | null;
};

export const setPurchaseConfiguration = createAction(
  "@contract/SET_PURCHASE_CONFIGURATION",
)<PurchaseConfiguration>();

export const setPurchaseStep = createAction(
  "@contract/SET_PURCHASE_STEP",
)<PurchaseStep>();

export const setContractID = createAction("@contract/PURCHASE_SET_CONTRACT_ID")<
  number | null
>();

export type PurchaseAction =
  | ActionType<typeof setPurchaseStep>
  | ActionType<typeof setPurchaseConfiguration>
  | ActionType<typeof setContractID>;

export function* watchPurchaseStateSagas() {
  yield all([]);
}

export const purchaseReducer = createReducer<PurchaseState, PurchaseAction>({
  configuration: null,
  step: PurchaseStep.Configuration,
  contractID: null,
})
  .handleAction(setContractID, (state, action) => {
    return produce(state, draft => {
      draft.contractID = action.payload;
      return draft;
    });
  })
  .handleAction(setPurchaseConfiguration, (state, action) => {
    return produce(state, draft => {
      draft.configuration = action.payload;
      return draft;
    });
  })
  .handleAction(setPurchaseStep, (state, action) => {
    return produce(state, draft => {
      draft.step = action.payload;
      return draft;
    });
  });
