import { useAppDispatch } from "Hooks/useAppDispatch";
import { useResource } from "Translations/Resources";
import { initWebViewListener } from "Utils/WebViewUtils";
import { type FunctionComponent, PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";

export const WebViewWrapper: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useResource();

  useEffectOnce(() => {
    initWebViewListener(dispatch, navigate, t);
  });

  return <>{children}</>;
};
