import BigNumber from "bignumber.js";

export function performanceToPercent(performance: number | null | undefined) {
  return new BigNumber(performance ?? 0).minus(1).times(100).dp(2).toNumber();
}

export function numberToPercentage(value: number | null | undefined) {
  return new BigNumber(value ?? 0).times(100).dp(2).toNumber();
}

