import { SignatureFormBiometrics } from "Components/ContractBankConnections/Shared/SignatureFormBiometrics";
import { SignatureFormSms } from "Components/ContractBankConnections/Shared/SignatureFormSms";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, ReactNode, useEffect } from "react";
import {
  BiometricsSignatureType,
  resetBiometricSignature,
} from "State/Biometrics/BiometricsActions";
import { resetSignWithSmsState } from "State/Contracts/Create/SignatureSms/SignWithSmsState";

type Props = {
  isLoading: boolean;
  isBiometricsInProcess?: boolean;
  contractID: number | null | undefined;
  signatureHash: string | null | undefined;
  productColor?: string;
  productContrastColor?: string;
  sms: {
    phone: string;
  };
  biometrics: {
    signatureType: BiometricsSignatureType;
  };
  translations: {
    isLoading: string;
    sms: {
      title: string;
      codeSent: string;
      codeDetermination: string;
      verificationError: string;
      button: string;
    };
    biometrics: {
      title: string;
      signatureError: string;
      signatureInProcessError: string;
    };
    signatureHashNotFoundError: string;
  };
  components?: {
    sms?: {
      title?: ReactNode;
    };
    biometrics?: {
      title?: ReactNode;
    };
  };
  error?: Error | null;
  errorMessages?: string[] | null;
  onSuccess: () => void;
};

export const SignatureForm: FunctionComponent<Props> = props => {
  const {
    isLoading,
    isBiometricsInProcess = false,
    contractID,
    signatureHash,
    productColor,
    productContrastColor,
    sms,
    biometrics,
    translations,
    components,
    error,
    errorMessages,
    onSuccess,
  } = props;

  const dispatch = useAppDispatch();

  const { isInProgress: isBiometricsInProgress, type: biometricsType } =
    useAppSelector(s => s.biometrics.signature);

  const isBiometrics = biometricsType === biometrics.signatureType;

  useEffect(() => {
    return () => {
      dispatch(resetBiometricSignature());
      dispatch(resetSignWithSmsState());
    };
  }, [dispatch]);

  return isBiometrics || isBiometricsInProgress || isBiometricsInProcess ? (
    <SignatureFormBiometrics
      isBiometrics={isBiometrics}
      isBiometricsInProgress={isBiometricsInProgress || isBiometricsInProcess}
      translations={{
        title: translations.biometrics.title,
        isLoading: translations.isLoading,
        signatureError: translations.biometrics.signatureError,
        signatureInProcessError:
          translations.biometrics.signatureInProcessError,
      }}
      components={{
        title: components?.biometrics?.title,
      }}
    />
  ) : (
    <SignatureFormSms
      isLoading={isLoading}
      contractID={contractID}
      phone={sms.phone}
      signatureHash={signatureHash}
      productColor={productColor}
      productContrastColor={productContrastColor}
      translations={{
        title: translations.sms.title,
        isLoading: translations.isLoading,
        codeSent: translations.sms.codeSent,
        codeDetermination: translations.sms.codeDetermination,
        verificationError: translations.sms.verificationError,
        signatureHashNotFoundError: translations.signatureHashNotFoundError,
        button: translations.sms.button,
      }}
      components={{
        title: components?.sms?.title,
      }}
      error={error}
      errorMessages={errorMessages}
      onSuccess={onSuccess}
    />
  );
};
