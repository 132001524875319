import ProgressCircle from "Components/Shared/ProgressCircle";
import {
  type FunctionComponent,
  type MouseEventHandler,
  type PropsWithChildren,
} from "react";
import styled from "styled-components";

type Props = PropsWithChildren<{
  size: number;
  color?: "primary" | "secondary";
  asButton?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  transparent?: boolean;
  highlighted?: boolean;
  backgroundColor?: string;
  progressPercent?: number;
}>;

const StyledWrapper = styled.div<
  Pick<Props, "size" | "color" | "progressPercent"> & {
    $transparent?: boolean;
    $asButton?: boolean;
    $highlighted?: boolean;
    $backgroundColor?: string;
  }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  min-height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  border-radius: 50%;
  cursor: pointer;
  ${props =>
    !props.$transparent &&
    `background-color: ${props.theme.palette.secondary.main}${
      props.$highlighted ? 47 : 27
    }`};

  ${props =>
    props.$asButton &&
    `
     cursor: pointer;
  `}

  svg {
    color: ${props => props.color && props.theme.palette[props.color].main};
  }

  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    z-index: -1;
    background: ${props => props.$backgroundColor || "transparent"};
  }
`;

export const IconCircleWrapper: FunctionComponent<Props> = ({
  size,
  children,
  color = "secondary",
  asButton,
  onClick,
  transparent,
  highlighted,
  backgroundColor,
  progressPercent = 0,
}) => {
  return (
    <StyledWrapper
      size={size}
      color={color}
      role={asButton || !!onClick ? "button" : undefined}
      onClick={onClick}
      $transparent={transparent}
      $highlighted={highlighted}
      $backgroundColor={backgroundColor}
    >
      <ProgressCircle size={size} progressPercent={progressPercent} />

      {children}
    </StyledWrapper>
  );
};
