import { Box, Typography } from "@mui/material";
import { Chip } from "Components/Shared/Chip";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useModelingTitleValues } from "Hooks/Contract/useModelingTitleValues";
import { useDesktop } from "Hooks/useDesktop";
import { FunctionComponent } from "react";

export const InvestmentHeaderTitle: FunctionComponent = () => {
  const { category, modelationName } = useModelingTitleValues();
  const { isDesktop } = useDesktop();

  if (!modelationName || isDesktop) {
    return null;
  }

  return (
    <StyledFlex
      $gap={3}
      $justifyContent="space-between"
      $overflow="hidden"
      $alignItems="center"
    >
      <Typography variant="inherit" noWrap>
        {modelationName}
      </Typography>
      <Box flexShrink={0}>
        {category && (
          <Chip icon={category.icon} label={category.Name} $active $passive />
        )}
      </Box>
    </StyledFlex>
  );
};
