import { SignatureForm } from "Components/ContractBankConnections/Shared/SignatureForm";
import { InvestmentDetailTabs } from "Hooks/ContractDetail/useMenuTabs";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

type Props = {
  contractID: number;
};

const PageResources = Resources.Contract.BankConnections.Create;
const ComponentResources = Resources.Contract.BankConnections.Create.Signature;

export const BankConnectionSignature: FunctionComponent<Props> = ({
  contractID,
}) => {
  const { t } = useResource();
  const { isLoading, phone, signatureHash, error } = useAppSelector(
    s => s.contracts.contract.bankConnections.create,
  );

  const navigate = useNavigate();

  return (
    <SignatureForm
      isLoading={isLoading}
      contractID={contractID}
      signatureHash={signatureHash}
      sms={{
        phone: phone ?? "",
      }}
      biometrics={{
        signatureType: BiometricsSignatureType.CreateBankConnection,
      }}
      translations={{
        isLoading: t(ComponentResources.WorkingText),
        sms: {
          title: t(PageResources.Title),
          codeSent: t(Resources.Signature.SmsSent),
          codeDetermination: t(ComponentResources.CodeDetermination),
          verificationError: t(ComponentResources.VerificationError),
          button: t(ComponentResources.SubmitButton),
        },
        biometrics: {
          title: t(ComponentResources.Biometrics),
          signatureError: t(ComponentResources.BiometricsError),
          signatureInProcessError: t(
            ComponentResources.BiometricsInProcessError,
          ),
        },
        signatureHashNotFoundError: t(
          ComponentResources.SignatureHashNotFoundError,
        ),
      }}
      error={error}
      onSuccess={() =>
        navigate(
          getPath(
            AppRouting.ContractDetail,
            contractID,
            InvestmentDetailTabs.Accounts,
          ),
        )
      }
    />
  );
};
