import { Box, Typography } from "@mui/material";
import { Loader } from "Components/Layout/Loader";
import { FunctionComponent } from "react";

export type BankIDCallbackLoadingWrapperProps = {
  isLoading: boolean;
  isError: boolean;
  translations: {
    description: string;
    error: string;
  };
};

export const BankIDCallbackLoadingWrapper: FunctionComponent<
  BankIDCallbackLoadingWrapperProps
> = ({ isLoading, isError, translations }) => {
  return (
    <Box width="stretch">
      {isLoading && !isError && (
        <>
          <Typography marginBottom={20}>{translations.description}</Typography>
          <Loader />
        </>
      )}
      {isError && <Typography color="error">{translations.error}</Typography>}
    </Box>
  );
};
