import { ProductFeeRateCalculationType } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  FeeRateType,
  clearFeeRate,
  singleInvestmentFeeRateRequest,
  periodicalInvestmentFeeRateRequest,
  ProductFeeRateCalculationRequest,
} from "State/Products/FeeRate/GetFeeRateState";
import { useEffect } from "react";

export type FeeRateProps = {
  investmentLengthYears?: number | null;
} & ProductFeeRateCalculationRequest;

export const useFeeRate = (
  {
    sourceContractID,
    sourceContractTypeCode,
    sourceIsin,
    targetContractID,
    targetContractTypeCode,
    targetIsin,
    amountOrPieces,
    isPieces,
    investmentLengthYears,
    calculationType = ProductFeeRateCalculationType.Default,
  }: FeeRateProps,
  feeRate: FeeRateType,
) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(s => s.products.feeRate[feeRate]);

  useEffect(() => {
    if (
      !amountOrPieces ||
      Number.isNaN(amountOrPieces) ||
      !Number.isFinite(amountOrPieces) ||
      !sourceContractTypeCode ||
      !sourceIsin
    ) {
      return;
    }

    if (
      calculationType === ProductFeeRateCalculationType.ExchangeAssets &&
      (!targetContractTypeCode || !targetIsin)
    ) {
      return;
    }

    const payload: ProductFeeRateCalculationRequest & {
      horizont?: number | null;
    } = {
      sourceContractID,
      sourceContractTypeCode,
      sourceIsin,
      targetContractID,
      targetContractTypeCode,
      targetIsin,
      amountOrPieces,
      isPieces,
      horizont: investmentLengthYears,
      calculationType,
    };

    if (feeRate === "periodical") {
      dispatch(periodicalInvestmentFeeRateRequest(payload));
    } else {
      dispatch(singleInvestmentFeeRateRequest(payload));
    }
  }, [
    amountOrPieces,
    calculationType,
    dispatch,
    feeRate,
    investmentLengthYears,
    isPieces,
    sourceContractID,
    sourceContractTypeCode,
    sourceIsin,
    targetContractID,
    targetContractTypeCode,
    targetIsin,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearFeeRate(feeRate));
    };
  }, [dispatch, feeRate]);

  return state;
};
