import { PersonalDocumentType } from "Api/Api";
import {
  SetBankIDProfileActionType,
  clearBankIDProfileError,
  setBankIDProfile,
  setBankIDProfileSaga,
} from "State/Contracts/Create/BankIDProfile/SetBankIDProfile";
import {
  CreateContractActionType,
  createContractAsync,
  createContractSaga,
  createContractState,
  CreateContractStateType,
} from "State/Contracts/Create/CreateContract/CreateContractState";

import {
  AMLQuestionsFormModel,
  DocumentsFormModel,
  FormModelType,
  FormModels,
  NewContractStep,
  PersonalDataFormModel,
  VerificationMode,
} from "State/Contracts/Create/Models";
import {
  PostPreviewActionType,
  PostPreviewStateType,
  postPreviewSaga,
  postPreviewAsync,
  postPreviewState,
} from "State/Contracts/Create/PostPreview/PostPreviewState";
import {
  GetProcessInformationActionType,
  GetProcessInformationStateType,
  getProcessInformationAsync,
  getProcessInformationSaga,
  getProcessInformationState,
} from "State/Contracts/Create/ProcessInformation/getProcessInformation";
import {
  SetContractStepActionType,
  setContractStep,
} from "State/Contracts/Create/Shared/ContractStep";
import {
  InitializeSmsSignatureActionType,
  InitializeSmsSignatureStateType,
  initializeSmsSignatureAsync,
  InitializeSmsSignatureSaga,
  initializeSmsSignatureState,
} from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import {
  PutSignatureSmsActionType,
  SignWithSmsStateType,
  signWithSmsSmsAsync,
  signWithSmsSaga,
  signWithSmsState,
  resetSignWithSmsError,
  resetSignWithSmsState,
} from "State/Contracts/Create/SignatureSms/SignWithSmsState";
import { resetNewContract } from "State/Contracts/Shared/Actions";
import {
  deleteDocument,
  postDocumentsAsync,
  rehydrateContractDraft,
} from "State/Contracts/Shared/Draft";
import {
  SetClientExistenceValidationResultActionType,
  setClientExistenceValidationResult,
} from "State/Shared/ClientExistenceValidationResult";
import {
  UserContactVerifiedActionType,
  userContactVerified,
} from "State/Shared/UserContactVerified";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
} from "State/Utils";
import { produce } from "immer";
import { all } from "typed-redux-saga";
import { createReducer, ActionType, createAction } from "typesafe-actions";
import {
  TerminateContractActionType,
  TerminateContractStateType,
  terminateContractAsync,
  terminateContractSaga,
  terminateContractState,
} from "./TerminateContract/TerminateContractState";
import { getFetchStateDefaultValue } from "State/Models";
import { PathSelectionFormModel } from "./Models";

export type CreateContractState = {
  isContractReset: boolean;
  isBankIDVerified: boolean;
  bankIdVerificationError?: Error;
  isIdentityNumberVerified: boolean;
  isPhoneVerified: boolean;
  isDuplicityVerified: boolean;
  isBiometrySignatureInProcess: boolean;
  actualStep: NewContractStep;
  formData: FormModelType;
  preview: PostPreviewStateType;
  createContract: CreateContractStateType;
  signatureSms: InitializeSmsSignatureStateType;
  verificationSignatureSms: SignWithSmsStateType;
  processInformation: GetProcessInformationStateType;
  terminate: TerminateContractStateType;
  // we use this flag to prevent overwriting of bankId data when draft is rehydrated
  isBankIDLoaded: boolean;
};

export const CONTRACT_STEPS: NewContractStep[] = [
  NewContractStep.PathSelection,
  NewContractStep.PersonalData,
  NewContractStep.ContantVerification,
  NewContractStep.ContactInformation,
  NewContractStep.Documents,
  NewContractStep.BankConnection,
  NewContractStep.AMLQuestions,
  NewContractStep.LastInformation,
  NewContractStep.ContractPreview,
  NewContractStep.ContractSignature,
  NewContractStep.FinalPage,
  NewContractStep.BankId,
];

const getNextStep = (
  actualStep: NewContractStep,
  formModel: FormModels | undefined,
  steps: NewContractStep[] = CONTRACT_STEPS,
) => {
  if (
    actualStep === NewContractStep.PathSelection &&
    !!formModel &&
    (formModel as PathSelectionFormModel).verificationMode ===
      VerificationMode.BankID
  ) {
    return NewContractStep.BankId;
  }

  if (steps.length - 1 === steps.indexOf(actualStep)) {
    throw new Error("Next step not found");
  }

  return steps[steps.indexOf(actualStep) + 1];
};

const getPreviousStep = (
  actualStep: NewContractStep,
  steps: NewContractStep[] = CONTRACT_STEPS,
) => {
  const actualStepIndex = steps.indexOf(actualStep);

  if (actualStepIndex === 0) {
    throw new Error("Previous step not found");
  }

  if (actualStep === NewContractStep.BankId) {
    return steps[0];
  }

  return steps[actualStepIndex - 1];
};

export const setNextStep = createAction("@contract/SET_NEXT_STEP")<{
  formData?: FormModels;
  steps: NewContractStep[];
}>();

export const setPreviousStep = createAction("@contract/SET_PREVIOUS_STEP")<{
  steps: NewContractStep[];
}>();

export const setIdentityNumberVerified = createAction(
  "@contract/SET_IDENTITY_NUMBER_VERIFIED",
)<boolean>();

export const setDuplicityVerified = createAction(
  "@contract/SET_DUPLICITY_VERIFIED",
)<boolean>();

export const setIsBiometrySignatureInProcess = createAction(
  "@contract/SET_IS_BIOMETRY_SIGNATURE_IN_PROCESS",
)<boolean>();

export type ContractAction =
  | ActionType<typeof setNextStep>
  | ActionType<typeof setPreviousStep>
  | ActionType<typeof resetNewContract>
  | ActionType<typeof setIdentityNumberVerified>
  | ActionType<typeof setDuplicityVerified>
  | ActionType<typeof setIsBiometrySignatureInProcess>
  | ActionType<typeof rehydrateContractDraft>
  | ActionType<typeof postDocumentsAsync>
  | ActionType<typeof deleteDocument>
  | ActionType<typeof resetSignWithSmsState>
  | ActionType<typeof resetSignWithSmsError>
  | SetBankIDProfileActionType
  | PostPreviewActionType
  | CreateContractActionType
  | InitializeSmsSignatureActionType
  | PutSignatureSmsActionType
  | GetProcessInformationActionType
  | UserContactVerifiedActionType
  | SetContractStepActionType
  | SetClientExistenceValidationResultActionType
  | TerminateContractActionType;

export const getCreateContractState = (
  isContractReset = false,
): CreateContractState => ({
  isContractReset,
  isBankIDVerified: false,
  isIdentityNumberVerified: false,
  isDuplicityVerified: false,
  isBiometrySignatureInProcess: false,
  actualStep: CONTRACT_STEPS[0],
  formData: {},
  preview: postPreviewState(),
  isPhoneVerified: false,
  createContract: createContractState(),
  signatureSms: initializeSmsSignatureState(),
  verificationSignatureSms: signWithSmsState(),
  processInformation: getProcessInformationState(),
  terminate: terminateContractState(),
  isBankIDLoaded: false,
});

export function* watchCreateContractsSaga() {
  yield all([
    setBankIDProfileSaga(),
    postPreviewSaga(),
    InitializeSmsSignatureSaga(),
    createContractSaga(),
    signWithSmsSaga(),
    getProcessInformationSaga(),
    terminateContractSaga(),
  ]);
}

export const createContractReducer = createReducer<
  CreateContractState,
  ContractAction
>(getCreateContractState())
  .handleAction(setNextStep, (state, { payload: { formData, steps } }) =>
    produce(state, draft => {
      const { actualStep, formData: stateFormData } = draft;

      // ignore if we are at the last step
      if (actualStep === NewContractStep.FinalPage) {
        return draft;
      }

      // for czech nationality we only allows as first document identity card, so we can preselect it
      if (
        actualStep === NewContractStep.PersonalData &&
        (formData as PersonalDataFormModel)?.nationalities?.some(
          ({ value }) => value === "CZ",
        ) &&
        !stateFormData?.[NewContractStep.Documents]?.firstDocument?.type
      ) {
        if (!draft.formData[NewContractStep.Documents]) {
          draft.formData[NewContractStep.Documents] = {
            firstDocument: {
              type: PersonalDocumentType.IdentityCard,
            },
          } as DocumentsFormModel; // force type to allow asign without filled other required fields
        } else {
          if (draft.formData[NewContractStep.Documents].firstDocument) {
            draft.formData[NewContractStep.Documents].firstDocument.type =
              PersonalDocumentType.IdentityCard;
          }
        }
      }

      // in this action handle we only set isBankIDVerified to false if the condition is not true
      // but true is set only in setBankIDProfile action
      if (
        actualStep === NewContractStep.PathSelection &&
        formData &&
        "verificationMode" in formData &&
        formData.verificationMode !== VerificationMode.BankID
      ) {
        draft.isBankIDVerified = false;
      }

      draft.actualStep = getNextStep(actualStep, formData, steps);

      if (draft.actualStep === NewContractStep.FinalPage) {
        var step = draft.formData[NewContractStep.LastInformation]!;

        step.acceptPersonalDataMarketing = false;
        step.clientAgreeTakeoverData = false;
        step.declareContractingDataValidity = false;
        step.voluntarilyDecision = false;
      }

      if (formData) {
        draft.formData = {
          ...draft.formData,
          [actualStep]: formData,
        };
      }

      return draft;
    }),
  )
  .handleAction(setPreviousStep, (state, action) => ({
    ...state,
    bankIdVerificationError: undefined,
    actualStep: getPreviousStep(state.actualStep, action.payload.steps),
  }))
  .handleAction(resetNewContract, () => getCreateContractState(true))
  .handleAction(
    setBankIDProfile.success,
    (
      state,
      {
        payload: [
          personalData,
          addresses,
          bankConnection,
          IdentityCardDocument,
          aml,
        ],
      },
    ) => {
      return produce(state, draft => {
        draft.formData[NewContractStep.PathSelection] = {
          verificationMode: VerificationMode.BankID,
        };

        draft.formData[NewContractStep.PersonalData] = personalData;
        draft.formData[NewContractStep.ContactInformation] = addresses;
        draft.formData[NewContractStep.BankConnection] = bankConnection;

        if (IdentityCardDocument) {
          draft.formData[NewContractStep.Documents] = {
            firstDocument: IdentityCardDocument,
          };
        }

        draft.formData[NewContractStep.AMLQuestions] =
          aml as AMLQuestionsFormModel;

        draft.actualStep = NewContractStep.PersonalData;

        draft.isBankIDVerified = true;
        draft.isIdentityNumberVerified = false;

        draft.bankIdVerificationError = undefined;
        draft.isBankIDLoaded = true;

        return draft;
      });
    },
  )
  .handleAction(setBankIDProfile.failure, (state, { payload, meta }) => {
    return produce(state, draft => {
      draft.isBankIDLoaded = true;
      draft.isBankIDVerified = false;
      draft.isIdentityNumberVerified = false;
      draft.bankIdVerificationError = payload;
      draft.actualStep = NewContractStep.PersonalData;

      if (draft.formData[NewContractStep.PathSelection]) {
        draft.formData[NewContractStep.PathSelection] = {
          ...draft.formData[NewContractStep.PathSelection],
          verificationMode: VerificationMode.Manual,
        };
      } else {
        draft.formData[NewContractStep.PathSelection] = {
          verificationMode: VerificationMode.Manual,
        };
      }

      if (meta) {
        const [
          personalData,
          addresses,
          bankConnection,
          IdentityCardDocument,
          aml,
        ] = meta;

        draft.formData[NewContractStep.PersonalData] = personalData;
        draft.formData[NewContractStep.ContactInformation] = addresses;
        draft.formData[NewContractStep.BankConnection] = bankConnection;

        if (IdentityCardDocument) {
          draft.formData[NewContractStep.Documents] = {
            firstDocument: IdentityCardDocument,
          };
        }

        draft.formData[NewContractStep.AMLQuestions] =
          aml as AMLQuestionsFormModel;
      }
    });
  })
  .handleAction(clearBankIDProfileError, state =>
    produce(state, draft => {
      draft.bankIdVerificationError = undefined;
      return draft;
    }),
  )
  .handleAction(setIdentityNumberVerified, (state, { payload }) =>
    produce(state, draft => {
      draft.isIdentityNumberVerified = payload;

      return draft;
    }),
  )
  .handleAction(postPreviewAsync.request, state => ({
    ...state,
    preview: handleActionRequest(state.preview),
  }))
  .handleAction(postPreviewAsync.failure, (state, action) => ({
    ...state,
    preview: handleActionFailure(state.preview, action),
  }))
  .handleAction(postPreviewAsync.success, (state, action) => ({
    ...state,
    preview: handleActionSuccess(state.preview, action),
  }))
  .handleAction(createContractAsync.request, state => ({
    ...state,
    createContract: handleActionRequest(state.createContract),
  }))
  .handleAction(createContractAsync.failure, (state, action) => ({
    ...state,
    createContract: handleActionFailure(state.createContract, action),
  }))
  .handleAction(createContractAsync.success, (state, action) =>
    produce(state, draft => {
      draft.createContract = handleActionSuccess(state.createContract, action);
      if (!draft.formData[NewContractStep.ContractSignature]) {
        draft.formData[NewContractStep.ContractSignature] = {};
      }

      draft.formData[NewContractStep.ContractSignature].contractID =
        action.payload.contract?.contractID ?? undefined;
      draft.formData[NewContractStep.ContractSignature].signatureHashList =
        action.payload.signatureHashList ?? undefined;
    }),
  )
  .handleAction(initializeSmsSignatureAsync.request, state => ({
    ...state,
    signatureSms: {
      isLoading: true,
      data: null,
      error: null,
    },
  }))
  .handleAction(initializeSmsSignatureAsync.failure, (state, action) => ({
    ...state,
    signatureSms: handleActionFailure(state.signatureSms, action),
  }))
  .handleAction(initializeSmsSignatureAsync.success, (state, action) => ({
    ...state,
    signatureSms: handleActionSuccess(state.signatureSms, action),
  }))
  .handleAction(signWithSmsSmsAsync.request, state => ({
    ...state,
    verificationSignatureSms: handleActionRequest(
      state.verificationSignatureSms,
    ),
  }))
  .handleAction(signWithSmsSmsAsync.failure, (state, action) => ({
    ...state,
    verificationSignatureSms: handleActionFailure(
      state.verificationSignatureSms,
      action,
    ),
  }))
  .handleAction(signWithSmsSmsAsync.success, (state, action) => ({
    ...state,
    verificationSignatureSms: handleActionSuccess(
      state.verificationSignatureSms,
      action,
    ),
  }))
  .handleAction(getProcessInformationAsync.request, state => ({
    ...state,
    processInformation: handleActionRequest(state.processInformation),
  }))
  .handleAction(getProcessInformationAsync.failure, (state, action) => ({
    ...state,
    processInformation: handleActionFailure(state.processInformation, action),
  }))
  .handleAction(getProcessInformationAsync.success, (state, action) => ({
    ...state,
    processInformation: handleActionSuccess(state.processInformation, action),
    isBankIDLoaded: false,
    actualStep:
      action.payload.steps.find(step => step === state.actualStep) ??
      NewContractStep.PersonalData,
  }))
  .handleAction(userContactVerified, state =>
    produce(state, draft => {
      draft.isPhoneVerified = true;
      return draft;
    }),
  )
  .handleAction(setDuplicityVerified, (state, action) =>
    produce(state, draft => {
      draft.isDuplicityVerified = action.payload;
      return draft;
    }),
  )
  .handleAction(setIsBiometrySignatureInProcess, (state, action) =>
    produce(state, draft => {
      draft.isBiometrySignatureInProcess = action.payload;
      return draft;
    }),
  )
  .handleAction(setContractStep, (state, { payload }) =>
    produce(state, draft => {
      draft.actualStep = payload.step;
      return draft;
    }),
  )
  .handleAction(setClientExistenceValidationResult, (state, { payload }) =>
    produce(state, draft => {
      draft.isDuplicityVerified = payload;
      return draft;
    }),
  )
  .handleAction(
    rehydrateContractDraft,
    (state, action) =>
      produce(state, draft => {
        if (!action.payload) {
          return draft;
        }

        draft.isBankIDLoaded = false;

        // prevent overwriting of bankId data when draft is rehydrated
        if (state.isBankIDLoaded) {
          return draft;
        }

        const {
          draft: { create },
          isContractCreated,
          isContractSigned,
        } = action.payload;

        if (create.actualStep === NewContractStep.ContractSignature) {
          draft.actualStep = create.actualStep;
        } else {
          draft.actualStep = NewContractStep.PathSelection;
        }

        const formData = create.formData;

        if (
          create.actualStep !== NewContractStep.ContractPreview &&
          !isContractSigned &&
          !isContractCreated
        ) {
          delete formData[NewContractStep.LastInformation];
        }

        if (isContractSigned) {
          delete formData[NewContractStep.ContractPreview];
          delete formData[NewContractStep.ContractSignature];

          if (formData[NewContractStep.Documents]) {
            const {
              backScan: firstBackScan,
              frontScan: firstFrontScan,
              ...firstDocument
            } = formData[NewContractStep.Documents].firstDocument ?? {};
            const {
              backScan: secondBackScan,
              frontScan: secondFrontScan,
              ...secondDocument
            } = formData[NewContractStep.Documents].secondDocument ?? {};

            formData[NewContractStep.Documents] = {
              firstDocument,
              secondDocument,
            };
          }
        } else {
          draft.isIdentityNumberVerified = create.isIdentityNumberVerified;
          draft.isPhoneVerified = create.isPhoneVerified;
          draft.isDuplicityVerified = create.isDuplicityVerified;
          draft.isBiometrySignatureInProcess =
            create.isBiometrySignatureInProcess;
        }

        draft.formData = {
          ...draft.formData,
          ...formData,
        };

        if (
          create.isPhoneVerified &&
          create.actualStep === NewContractStep.ContantVerification
        ) {
          draft.actualStep = NewContractStep.ContactInformation;
        }

        // after we reset contract, the user should go to the first step
        if (
          action.payload.isContractCreated &&
          !action.payload.isContractSigned
        ) {
          draft.isContractReset = false;
        } else if (draft.isContractReset) {
          draft.actualStep = NewContractStep.PathSelection;
          draft.isContractReset = false;
        }

        return draft;
      }),
    // add document to form data if successfully uploaded to the server
  )
  .handleAction(postDocumentsAsync.request, (state, action) =>
    produce(state, draft => {
      const { side, document } = action.payload;

      if (document === "BankAccount") {
        if (!draft.formData[NewContractStep.BankConnection]) {
          draft.formData[NewContractStep.BankConnection] = {
            bankConnectionType: "bankNumber",
          };
        }

        return draft;
      }

      if (draft.formData?.[NewContractStep.Documents]?.[document]?.[side]) {
        draft.formData[NewContractStep.Documents][document]![side] = undefined;
      }

      return draft;
    }),
  )
  .handleAction(postDocumentsAsync.success, (state, action) =>
    produce(state, draft => {
      const { side, file, document, guid } = action.meta;

      if (document === "BankAccount") {
        return draft;
      }

      if (!draft.formData?.[NewContractStep.Documents]) {
        draft.formData[NewContractStep.Documents] = {};
      }

      if (!draft.formData?.[NewContractStep.Documents]?.[document]) {
        draft.formData[NewContractStep.Documents][document] = {};
      }

      draft.formData[NewContractStep.Documents][document]![side] = {
        guid,
        fileName: file.name,
        mimeType: file.type,
      };

      return draft;
    }),
  )
  .handleAction(deleteDocument, (state, action) =>
    produce(state, draft => {
      if (action.payload.document !== "BankAccount") {
        delete draft.formData?.[NewContractStep.Documents]?.[
          action.payload.document
        ]?.[action.payload.side];
      }

      return draft;
    }),
  )
  .handleAction(resetSignWithSmsState, state =>
    produce(state, draft => {
      draft.verificationSignatureSms.isLoading = false;
      draft.verificationSignatureSms.error = undefined;
      draft.verificationSignatureSms.data = undefined;
      return draft;
    }),
  )
  .handleAction(resetSignWithSmsError, state =>
    produce(state, draft => {
      draft.verificationSignatureSms.error = undefined;
      return draft;
    }),
  )
  .handleAction(terminateContractAsync.request, state => ({
    ...state,
    createContract: createContractState(),
    terminate: handleActionRequest(state.terminate),
  }))
  .handleAction(terminateContractAsync.failure, (state, action) => ({
    ...state,
    terminate: handleActionFailure(state.terminate, action),
    signatureSms: getFetchStateDefaultValue(),
  }))
  .handleAction(terminateContractAsync.success, (state, action) => ({
    ...state,
    terminate: handleActionSuccess(state.terminate, action),
    signatureSms: getFetchStateDefaultValue(),
  }));
