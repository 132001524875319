import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { throttle } from "typed-redux-saga";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import {
  ClientSendContactConfirmationDto,
  ContactConfirmationDto,
  postClientContactConfirmation,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";

export type PostContactConfirmationStateType =
  FetchStateType<ContactConfirmationDto> & {
    requested: boolean;
    requestedNumber?: string;
  };

export const postContactConfirmationState =
  (): PostContactConfirmationStateType => ({
    ...getFetchStateDefaultValue(),
    requested: false,
    requestedNumber: undefined,
  });

export type PostContactConfirmationActionType = ActionType<
  typeof postContactConfirmationAsync
>;

export const postContactConfirmationAsync = createAsyncAction(
  "@client/POST_CONTACT_CONFIRMATION_REQUEST",
  "@client/POST_CONTACT_CONFIRMATION_SUCCESS",
  "@client/POST_CONTACT_CONFIRMATION_FAILURE",
)<
  ClientSendContactConfirmationDto & { onSuccess?: () => void },
  ContactConfirmationDto,
  Error
>();

function* postContactConfirmation(
  action: ActionType<typeof postContactConfirmationAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postClientContactConfirmation,
      action.payload,
    );

    if (error) {
      yield put(postContactConfirmationAsync.failure(error));
      return;
    }

    if (!response.contactConfirmation) {
      yield put(
        postContactConfirmationAsync.failure(
          new ApplicationError(
            "Failed to update contact information and initialize verification process",
            ErrorLevel.Error,
          ),
        ),
      );
      return;
    }

    yield put(
      postContactConfirmationAsync.success(response.contactConfirmation),
    );

    action.payload.onSuccess?.();
  } catch (err) {
    yield put(postContactConfirmationAsync.failure(err as Error));
  }
}

export function* postContactConfirmationSaga() {
  yield throttle(
    5_000,
    getType(postContactConfirmationAsync.request),
    postContactConfirmation,
  );
}

export const postContactConfirmationReducer = createReducer<
  PostContactConfirmationStateType,
  PostContactConfirmationActionType
>(postContactConfirmationState())
  .handleAction(postContactConfirmationAsync.request, (state, action) =>
    produce(state, draft => {
      draft = handleActionRequest(state);
      draft.requested = true;
      draft.requestedNumber = action.payload.mobilePhoneNumber ?? undefined;

      return draft;
    }),
  )
  .handleAction(postContactConfirmationAsync.failure, handleActionFailure)
  .handleAction(postContactConfirmationAsync.success, handleActionSuccess);
