import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { createContractAsync } from "State/Contracts/Create/CreateContract/CreateContractState";
import { useCallback, useEffect } from "react";
import { useSaveContractDraft } from "./useSaveContractDraft";
import { useFormData } from "./useFormData";
import { NewContractStep } from "State/Contracts/Create/Models";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";

export const useCreateContract = () => {
  const dispatch = useAppDispatch();

  const modelingData = useAppSelector(
    s => s.contracts.modeling.modelingState.formData,
  );
  const contractData = useAppSelector(s => s.contracts.create.formData);
  const isBankIDVerified = useIsBankIDVerified();
  const isDip = useIsDipSelected();

  const { postDraft } = useSaveContractDraft();

  const { isLoading, data, error } = useAppSelector(
    s => s.contracts.create.createContract,
  );

  const { defaultValues } = useFormData<NewContractStep.ContractSignature>({
    step: NewContractStep.ContractSignature,
  });

  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();

  const createContract = useCallback(() => {
    dispatch(
      createContractAsync.request({
        contractData,
        isBankIDVerified,
        isDip,
        modelingData,
        canUseBiometrics: canUseBiometricsForSigning,
      }),
    );
  }, [
    dispatch,
    contractData,
    isBankIDVerified,
    isDip,
    modelingData,
    canUseBiometricsForSigning,
  ]);

  useEffect(() => {
    if (!data?.contract?.contractID) {
      return;
    }

    // We need to save a draft after contractId is created
    postDraft({ isContractCreated: true, isContractSigned: false });
  }, [data?.contract?.contractID, postDraft]);

  return {
    createContract,
    creatingContract: isLoading,
    error,
    signatureHash: defaultValues?.signatureHashList?.[0],
    contractID: defaultValues?.contractID,
    contractCreated: !!defaultValues?.signatureHashList,
  };
};
