import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { all, takeEvery, takeLeading } from "typed-redux-saga";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import {
  ContractTypeCode,
  ProductFeeRateCalculationQueryResult,
  ProductFeeRateCalculationType,
  getProductFeeRateCalculation,
} from "Api/Api";
import { produce } from "immer";

export type ProductFeeRateCalculationRequest = {
  sourceContractID?: number;
  targetContractID?: number;
  sourceIsin?: string;
  targetIsin?: string;
  sourceContractTypeCode?: ContractTypeCode;
  targetContractTypeCode?: ContractTypeCode;
  amountOrPieces?: number;
  isPieces?: boolean;
  calculationType?: ProductFeeRateCalculationType;
};

export type FeeRateType = "periodical" | "single";

export type GetFeeRateStateType = {
  [key in FeeRateType]: FetchStateType<ProductFeeRateCalculationQueryResult>;
};

export const getFeeRateState = (): GetFeeRateStateType => ({
  periodical: getFetchStateDefaultValue(),
  single: getFetchStateDefaultValue(),
});

export type GetFeeRateActionType =
  | ActionType<typeof getFeeRateAsync>
  | ActionType<typeof clearFeeRate>;

type MetaType = {
  _feeRateType: FeeRateType;
};

export const periodicalInvestmentFeeRateRequest = createAction(
  "@products/GET_PERIODICAL_INVESTMENT_FEE_RATE_REQUEST",
)<
  ProductFeeRateCalculationRequest & {
    horizont?: number | null;
  }
>();

export const singleInvestmentFeeRateRequest = createAction(
  "@products/GET_SINGLE_INVESTMENT_FEE_RATE_REQUEST",
)<
  ProductFeeRateCalculationRequest & {
    horizont?: number | null;
  }
>();

/**
 * Used only internally
 */
const getFeeRateAsync = createAsyncAction(
  "@products/GET_FEE_RATE_REQUEST",
  "@products/GET_FEE_RATE_SUCCESS",
  "@products/GET_FEE_RATE_FAILURE",
)<
  [ProductFeeRateCalculationRequest, MetaType],
  [ProductFeeRateCalculationQueryResult, MetaType],
  [Error, MetaType]
>();

export const clearFeeRate = createAction(
  "@products/CLEAR_FEE_RATE",
)<FeeRateType>();

function* getFeeRate(
  action: ReturnType<typeof getFeeRateAsync.request>,
): Generator {
  const {
    sourceContractID,
    targetContractID,
    sourceIsin,
    targetIsin,
    sourceContractTypeCode,
    targetContractTypeCode,
    amountOrPieces,
    isPieces = false,
    calculationType = ProductFeeRateCalculationType.Default,
  } = action.payload ?? {};

  try {
    const { response, error } = yield* safeApiCall(
      getProductFeeRateCalculation,
      sourceContractID,
      targetContractID,
      sourceContractTypeCode,
      targetContractTypeCode,
      sourceIsin,
      targetIsin,
      amountOrPieces,
      isPieces,
      calculationType,
    );
    if (error) {
      yield put(
        getFeeRateAsync.failure(error, {
          _feeRateType: action.meta._feeRateType,
        }),
      );
      return;
    }

    yield put(
      getFeeRateAsync.success(response, {
        _feeRateType: action.meta._feeRateType,
      }),
    );
  } catch (err) {
    yield put(
      getFeeRateAsync.failure(err as Error, {
        _feeRateType: action.meta._feeRateType,
      }),
    );
  }
}

function* getPeriodicalInvestmentFeeRate(
  action: ReturnType<typeof getFeeRateAsync.request>,
): Generator {
  yield put(
    getFeeRateAsync.request(action.payload, {
      _feeRateType: "periodical",
    }),
  );
}
function* getSingleInvestmentFeeRate(
  action: ReturnType<typeof getFeeRateAsync.request>,
): Generator {
  yield put(
    getFeeRateAsync.request(action.payload, {
      _feeRateType: "single",
    }),
  );
}

export function* getFeeRateSaga() {
  yield all([
    takeLeading(
      getType(periodicalInvestmentFeeRateRequest),
      getPeriodicalInvestmentFeeRate,
    ),
    takeLeading(
      getType(singleInvestmentFeeRateRequest),
      getSingleInvestmentFeeRate,
    ),
    takeEvery(getType(getFeeRateAsync.request), getFeeRate),
  ]);
}

export const getFeeRateReducer = createReducer<
  GetFeeRateStateType,
  GetFeeRateActionType
>(getFeeRateState())
  .handleAction(getFeeRateAsync.request, (state, action) =>
    produce(state, draft => {
      draft[action.meta._feeRateType] = handleActionRequest(
        state[action.meta._feeRateType],
      );
    }),
  )
  .handleAction(getFeeRateAsync.failure, (state, action) =>
    produce(state, draft => {
      draft[action.meta._feeRateType] = handleActionFailure(
        state[action.meta._feeRateType],
        action,
      );
    }),
  )
  .handleAction(getFeeRateAsync.success, (state, action) =>
    produce(state, draft => {
      draft[action.meta._feeRateType] = handleActionSuccess(
        state[action.meta._feeRateType],
        action,
      );
    }),
  )
  .handleAction(clearFeeRate, () => getFeeRateState());
