import { put } from "redux-saga/effects";
import { delay, fork, select } from "typed-redux-saga";
import { RootStateType } from "State/Store";
import { checkIsAuthenticated } from "Utils/AuthUtils";
import { resetUser, setIsAuthenticated } from "State/Auth/AuthReducer";

function* checkTokenExpiration(): Generator {
  const { tokenExpiration, isAuthenticated } = yield* select(
    (state: RootStateType) => state.auth,
  );

  if (!isAuthenticated) {
    return;
  }

  const isUserAuthenticated = checkIsAuthenticated(tokenExpiration);

  if (!isUserAuthenticated) {
    yield put(setIsAuthenticated(false));
    yield put(
      resetUser({
        wasSignedOutManually: false,
      }),
    );
  }
}
export function* checkTokenExpirationSaga() {
  while (true) {
    yield fork(checkTokenExpiration);
    yield delay(3000);
  }
}
