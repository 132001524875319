import { captureException } from "@sentry/react";
import { SENTRY_CONFIG } from "Utils/SentryUtils";

const logError = (
  error?: Error,
  additionalInfo?: any,
  isUserDialogShown = true,
) => {
  captureException(error, {
    extra: {
      additionalInfo: additionalInfo,
      doNotShowDialog: !(
        isUserDialogShown && SENTRY_CONFIG.IS_FEEDBACK_ENABLED
      ),
    },
  });
};

export { logError };
