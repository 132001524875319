import { type FieldErrorProps, useFieldError } from "Hooks/useFieldError";
import {
  CheckboxInput,
  type CheckboxInputProps,
} from "Components/Shared/Inputs/BlCheckboxField/CheckboxInput";
import { CheckboxErrorWrapper } from "Components/Shared/Inputs/BlCheckboxField/CheckboxErrorWrapper";

type Props = FieldErrorProps & CheckboxInputProps;

export const BlCheckboxField: React.FunctionComponent<Props> = props => {
  const { hasError, errorMessage, originalProps } = useFieldError(props);

  return (
    <CheckboxErrorWrapper hasError={hasError} errorMessage={errorMessage}>
      <CheckboxInput
        id={originalProps.name}
        {...originalProps}
        hasError={hasError}
      />
    </CheckboxErrorWrapper>
  );
};
