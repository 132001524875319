import { Typography } from "@mui/material";
import { Step } from "Components/ContractCreate/Shared/Step";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { useFormData } from "Hooks/Contract/useFormData";
import {
  BankConnectionFormModel,
  BankConnectionsType,
  NewContractStep,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { useEffect, type FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PageTitle } from "./Shared/PageTitle";
import { useYupFormSchema } from "Hooks/Contract/BankConnection/useYupFormSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiddenSubmitButton } from "Components/Shared/Buttons/HiddenSubmitButton";
import { useBanksList } from "Hooks/codeLists/useBanksList";
import { useAppSelector } from "Hooks/useAppSelector";
import { getBankUniqueCode } from "Utils/BankUtils";
import { useBankCurrenciesList } from "Hooks/codeLists/useBankCurrenciesList";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const PageResources = Resources.Contract.NewContract.BankConnection;

export const BankConnection: FunctionComponent = () => {
  const { t } = useResource();
  const schema = useYupFormSchema();
  const banksList = useBanksList();
  const bankCurrenciesList = useBankCurrenciesList();
  const { color, contrastColor } = useModelingColor();
  const { data: banks } = useAppSelector(s => s.codeList.banks);
  const { nextStep } = useCreateContractStep();

  const { defaultValues } = useFormData<NewContractStep.BankConnection>({
    step: NewContractStep.BankConnection,
  });

  const form = useForm<BankConnectionFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      bankConnectionType: "bankNumber",
      ...defaultValues,
    },
    mode: "onSubmit",
  });
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = form;

  const onSubmit = (formData: BankConnectionFormModel) => {
    nextStep({ formData });
  };

  const connType = watch("bankConnectionType");
  const selectedBank = watch("selectedBank");

  useEffect(() => {
    if (selectedBank) {
      const bank = banks?.bankList?.find(
        bank => getBankUniqueCode(bank) === selectedBank,
      );

      if (bank) {
        setValue("bankCode", bank.numericCode);
        setValue("swiftCode", bank.swiftCode);
        setValue("country", bank.country);
      }
    }
  }, [setValue, selectedBank, banks]);

  const onConnectionTypeChange = () => {
    setValue("bankNumber", "", { shouldValidate: false });
    setValue("iban", "", { shouldValidate: false });
    setValue("selectedBank", "", {
      shouldValidate: false,
    });
  };

  return (
    <>
      <div>
        <Step actualStep={3} />

        <PageTitle>{t(PageResources.Title)}</PageTitle>
        <Typography>{t(PageResources.Label)}</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...form}>
            <RadioButtons<BankConnectionFormModel, BankConnectionsType>
              control={control}
              errors={errors}
              name="bankConnectionType"
              defaultValue="bankNumber"
              codeList={[
                {
                  code: "bankNumber",
                  name: t(PageResources.BankNumber),
                },
                {
                  code: "iban",
                  name: t(PageResources.Iban),
                },
              ]}
              onChange={onConnectionTypeChange}
            />

            {connType === "bankNumber" ? (
              <>
                <BlInputFormField
                  control={control}
                  errors={errors}
                  name="bankNumber"
                  label={t(PageResources.BankNumber)}
                  mask={/^[0-9-]+$/}
                  // TODO: we should separate prefix, otherwise on ios we doesn't have dash key
                  // inputProps={{
                  // inputMode: "numeric",
                  // pattern: "[0-9\\-]*",
                  // }}
                />
                <DropdownInput
                  control={control}
                  errors={errors}
                  name="selectedBank"
                  options={banksList}
                  label={t(PageResources.BankCode)}
                />
              </>
            ) : (
              <BlInputFormField
                control={control}
                errors={errors}
                name="iban"
                label={t(PageResources.Iban)}
                mask={/^[A-Za-z0-9]+$/}
                valueTransformer={v => v?.toUpperCase()}
              />
            )}

            <DropdownInput
              control={control}
              errors={errors}
              name="bankCurrency"
              options={bankCurrenciesList}
              label={t(PageResources.BankCurrency)}
            />

            <HiddenSubmitButton />
          </FormProvider>
        </form>
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
