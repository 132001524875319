import { HealthStatus } from "Api/Api";
import { useAppSelector } from "Hooks/useAppSelector";
import { useMemo } from "react";
import { notNoU } from "Utils/ObjectUtils";
import { isWebView } from "Utils/WebViewUtils";

export const useBiometricsCanBeUsed = () => {
  const isWebviewEnabled = useMemo(() => isWebView(), []);
  const biometrics = useAppSelector(s => s.biometrics);
  const apiHealth = useAppSelector(s => s.health);

  const isApiOperational = apiHealth.apiHealthStatus === HealthStatus.Operational && notNoU(apiHealth.lastChecked);

  const { canBeUsed, canBeUsedForSigning, canBeUsedForSignIn } = useMemo(() => {
    return {
      canBeUsed: isApiOperational && isWebviewEnabled && biometrics.canBeUsed,
      canBeUsedForSigning:
        isApiOperational &&
        isWebviewEnabled &&
        biometrics.canBeUsed &&
        biometrics.signature.canBeUsed,
      canBeUsedForSignIn:
        isApiOperational &&
        isWebviewEnabled &&
        biometrics.canBeUsed &&
        biometrics.signIn.canBeUsed,
    };
  }, [
    isApiOperational,
    isWebviewEnabled,
    biometrics.canBeUsed,
    biometrics.signature.canBeUsed,
    biometrics.signIn.canBeUsed,
  ]);

  return {
    canUseBiometrics: canBeUsed,
    canUseBiometricsForSigning: canBeUsedForSigning,
    canUseBiometricsForSignIn: canBeUsedForSignIn,
  };
};
