export const MAX_SAFE_INTEGER_MASK = "0000000000000000";
export const VALIDATION_POSTAL_CODE_COUNTRIES = ["CZ", "SK"];
export const PASSWORD_PLACEHOLDER = "******";

export const DATE_INPUT_FORMAT = "yyyy-MM-dd";

export const AllowedFileMimeTypes = [
  "image/jpeg",
  "image/png",
  "image/heif",
  "image/heic",
];
