import { Typography } from "@mui/material";
import { StyledButtonsWrapper } from "Components/Auth/Styles";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { DocumentLink } from "Components/Shared/DocumentLink";
import { Resources, useResource } from "Translations/Resources";
import { TERMS_AND_CONDITIONS_URL } from "Utils/ExternalLinkUtils";

type Props = {
  onEnableBiometrics(): void;
  onIgnoreBiometrics(): void;
};

const PageResources = Resources.Biometrics;

export function Biometrics({ onEnableBiometrics, onIgnoreBiometrics }: Props) {
  const { t } = useResource();

  return (
    <>
      <div>
        <Typography component="h2" variant="h2">
          {t(PageResources.Title)}
        </Typography>
        <Typography paddingTop={2}>{t(PageResources.Description)}</Typography>
        <Typography paddingTop={4}>{t(PageResources.Description2)}</Typography>
      </div>
      <div>
        <Typography marginBottom={5}>
          {t(PageResources.MoreInformation)}{" "}
          <DocumentLink target="_blank" href={TERMS_AND_CONDITIONS_URL}>
            {t(PageResources.TermsAndConditions)}
          </DocumentLink>
        </Typography>
        <StyledButtonsWrapper>
          <PrimaryButton color="secondary" onClick={onEnableBiometrics}>
            {t(PageResources.Enable)}
          </PrimaryButton>
          <PrimaryButton color="secondary" onClick={onIgnoreBiometrics}>
            {t(PageResources.Skip)}
          </PrimaryButton>
        </StyledButtonsWrapper>
      </div>
    </>
  );
}
