import { useViewTracking } from "Hooks/useViewTracking";
import { TrackingPayload } from "Utils/TrackingUtils";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = { payload: TrackingPayload };

export const TrackingWrapper: FunctionComponent<
  PropsWithChildren<Props>
> = props => {
  useViewTracking(props.payload);

  return <>{props.children}</>;
};
