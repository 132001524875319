import {
  InitializeBiometricSignatureCommand,
  postSignatureBiometrics,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { WebViewMessageTypes } from "Models/WebViewModels";
import { postMessageFromWebView } from "Utils/WebViewUtils";
import { put } from "typed-redux-saga";
import { logError } from "ErrorService";

export const initializeBiometricSignatureAsync = createAsyncAction(
  "@contract/INITIALIZE_BIOMETRIC_SIGNATURE_REQUEST",
  "@contract/INITIALIZE_BIOMETRIC_SIGNATURE_SUCCESS",
  "@contract/INITIALIZE_BIOMETRIC_SIGNATURE_FAILURE",
)<
  InitializeBiometricSignatureCommand,
  {
    signatureHash: string;
    challenge: string;
  },
  Error
>();

function* initializeBiometricSignature(
  action: ReturnType<typeof initializeBiometricSignatureAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postSignatureBiometrics,
      action.payload,
    );

    if (error) {
      yield put(initializeBiometricSignatureAsync.failure(error));
      logError(error, { payload: action.payload }, false);
      return;
    }

    if (!response.challenge) {
      const error = new Error("No challenge was returned.");
      yield put(initializeBiometricSignatureAsync.failure(error));
      logError(error, { payload: action.payload }, false);

      return;
    }

    postMessageFromWebView({
      type: WebViewMessageTypes.BIOMETRIC_SIGNATURE_REQUEST,
      payload: {
        challenge: response.challenge,
      },
    });

    yield put(
      initializeBiometricSignatureAsync.success({
        signatureHash: action.payload.signatureHash,
        challenge: response.challenge,
      }),
    );
  } catch (err) {
    yield put(initializeBiometricSignatureAsync.failure(err as Error));
    logError(err as Error, { payload: action.payload }, false);
  }
}
export function* initializeBiometricSignatureSaga() {
  yield takeLatest(
    getType(initializeBiometricSignatureAsync.request),
    initializeBiometricSignature,
  );
}
