import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

export type Props = PropsWithChildren<{
  isHidden?: boolean;
  initialHidden?: boolean;
  delay?: number;
}>;

export const ComponentVisibilityWrapper: FunctionComponent<Props> = ({
  isHidden,
  initialHidden = false,
  delay = 400,
  children,
}) => {
  const [hidden, setHidden] = useState(initialHidden);

  useEffect(() => {
    const shouldHide = isHidden !== undefined ? isHidden : initialHidden;

    if (!shouldHide) {
      const timeout = setTimeout(() => {
        setHidden(false);
      }, delay);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      setHidden(true);
    }
  }, [isHidden, initialHidden, delay]);

  return <div hidden={hidden}>{children}</div>;
};
