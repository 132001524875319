import { PhoneVerificationFormModel } from "State/Contracts/Create/Models";

export enum TransferDipStep {
  TransferInformation,
  TransferPreview,
  TransferSignature,
  FinalPage,
}

export type TransferDipInfoFormModel = {
  transferAccept: boolean;
};

export type TransferDipSignatureFormModel = {
  contractID?: number;
  signatureHashList?: string[];
} & PhoneVerificationFormModel;
