import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { postMessageFromWebView } from "Utils/WebViewUtils";
import { safeApiCall } from "State/Utils";
import {
  SignUpBiometricsChallengeCommandResult,
  postAuthSignUpBiometricsChallenge,
} from "Api/Api";
import { WebViewMessageTypes } from "Models/WebViewModels";
import { setSettingBiometricsInProgress } from "State/Biometrics/BiometricsActions";
import { logError } from "ErrorService";

export const getBiometricSignUpChallengeAsync = createAsyncAction(
  "@auth/GET_BIOMETRIC_SIGN_UP_CHALLENGE_REQUEST",
  "@auth/GET_BIOMETRIC_SIGN_UP_CHALLENGE_SUCCESS",
  "@auth/GET_BIOMETRIC_SIGN_UP_CHALLENGE_FAILURE",
)<
  {
    login: string;
    redirectUrl: string | null;
  },
  SignUpBiometricsChallengeCommandResult,
  Error
>();

function* getBiometricSignUpChallenge(
  action: ReturnType<typeof getBiometricSignUpChallengeAsync.request>,
): Generator {
  try {
    yield put(setSettingBiometricsInProgress(true));

    const { response, error } = yield* safeApiCall(
      postAuthSignUpBiometricsChallenge,
    );

    if (!!error || !!response.error) {
      var err = (error ?? response.error) as Error;

      yield put(setSettingBiometricsInProgress(false));
      yield put(getBiometricSignUpChallengeAsync.failure(err));
      console.error("biometricSignUpGetChallenge error", err);
      logError(err, { payload: action.payload, err }, false);
      return;
    }

    postMessageFromWebView({
      type: WebViewMessageTypes.BIOMETRIC_SIGN_UP_REQUEST,
      payload: {
        challenge: response.challenge!,
        login: action.payload.login,
      },
    });

    yield put(getBiometricSignUpChallengeAsync.success(response));
  } catch (err) {
    yield put(setSettingBiometricsInProgress(false));
    yield put(getBiometricSignUpChallengeAsync.failure(err as Error));
  }
}
export function* biometricSignUpChallengeSaga() {
  yield takeLatest(
    getType(getBiometricSignUpChallengeAsync.request),
    getBiometricSignUpChallenge,
  );
}
