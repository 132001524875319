import { FetchResponse } from "Api/Api";
import { ApiCallError, toAPICallError } from "Models/Errors/ApiCallError";
import { call } from "typed-redux-saga";
import { type PayloadAction } from "typesafe-actions";

type DefaultStateCb<TState> = () => TState;

export function handleActionRequest<TState extends object>(state: TState) {
  return {
    ...state,
    isLoading: true,
  };
}

export function handleActionFailure<
  TState extends object,
  TAction extends PayloadAction<string, unknown>,
>(state: TState, action: TAction) {
  return {
    ...state,
    isLoading: false,
    error: action.payload,
  };
}

export function handleActionSuccess<
  TState extends object,
  TAction extends PayloadAction<string, unknown>,
>(state: TState, action: TAction) {
  return {
    ...state,
    isLoading: false,
    data: action.payload,
    error: null,
  };
}

export function handleCleanupAction<TState extends object>(
  defaultStateCb: DefaultStateCb<TState>,
) {
  return () => {
    return defaultStateCb();
  };
}

/**
 * Use this function to safely call an API.
 * If the call succeeds, it returns the response.
 * If the call fails, it returns an APICallError.
 *
 * @param fn - API call function
 * @param args - API call arguments
 *
 * @returns {Generator}
 */
export function* safeApiCall<T, Args extends any[]>(
  fn: (...args: Args) => Promise<FetchResponse<T>>,
  ...args: Args
): Generator<
  any,
  | { response: T; error: null; status: number }
  | { response: null; error: ApiCallError<T>; status: number },
  any
> {
  let status = 0;
  try {
    try {
      const fetchResponse = (yield* call(fn, ...args)) as FetchResponse<T>;
      status = fetchResponse.status;

      if (fetchResponse.json != null && fetchResponse.error == null) {
        return { response: fetchResponse.json, error: null, status };
      }
      throw new ApiCallError(fetchResponse);
    } catch (error) {
      if (error instanceof ApiCallError) {
        throw error;
      }
      throw toAPICallError(error);
    }
  } catch (error) {
    return {
      response: null,
      error: error as ApiCallError<T>,
      status: status,
    };
  }
}
