import { Typography } from "@mui/material";
import { type FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";
import { SelectableCardBox } from "Components/Shared/SelectableCardBox";
import { ProductItemDto } from "Api/Api";
import { formatCurrency, getLocalizedCurrency } from "Utils/CurrencyUtils";
import { useFormattedProductClass } from "Hooks/Products/useFormattedProductClass";
import { useProductDescription } from "Hooks/Products/useProductDescription";

export type OnSelectProduct = (product: ProductItemDto) => void;

type Props = {
  product: ProductItemDto;
  isChecked: boolean;
  onCheck: (product: ProductItemDto) => void;
};

const PageResources = Resources.Investments.FundSelection;

export const Product: FunctionComponent<Props> = ({
  product,
  isChecked,
  onCheck,
}) => {
  const { t } = useResource();

  const productClass = useFormattedProductClass(product.isin);
  const productDescription = useProductDescription(product.isin);

  return (
    <SelectableCardBox
      isChecked={isChecked}
      onCheck={() => onCheck(product)}
      infoPopper={productDescription}
    >
      <Typography fontWeight={700} color="secondary">
        {productClass}
      </Typography>
      <Typography>
        <Typography component="span" color="secondary" display="inline-block">
          {t(PageResources.ExpectedAppreciation.Label)}
        </Typography>
        &nbsp;
        <Typography component="span" color="secondary" display="inline-block">
          {t(PageResources.ExpectedAppreciation.Format, {
            min: product.minPerformance,
            max: product.maxPerformance,
          })}
        </Typography>
      </Typography>
      <Typography color="secondary">
        {t(PageResources.AllowedInvestment, {
          value: formatCurrency(product.singleMinInvestment, product.currency, {
            isCurrencySymbolShown: false,
          }),
          currency: getLocalizedCurrency(product.currency, t),
        })}
      </Typography>
    </SelectableCardBox>
  );
};
