import { Box, Typography } from "@mui/material";
import { ProductItemDto } from "Api/Api";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useProductDescription } from "Hooks/Products/useProductDescription";
import { useResource } from "Translations/Resources";
import { getFundDescription } from "Utils/Products";
import { type FunctionComponent } from "react";

type Props = {
  descriptions?: string[];
  isin?: string | null;
} & Pick<ProductItemDto, "maxPerformance" | "minPerformance">;

export const FundDescription: FunctionComponent<Props> = ({
  minPerformance,
  maxPerformance,
  descriptions,
  isin,
}) => {
  const { t } = useResource();
  const productDescription = useProductDescription(isin);

  return (
    <Box paddingY={2}>
      {productDescription && (
        <Typography color="secondary" marginBottom={2} marginRight={1}>
          {productDescription}
        </Typography>
      )}

      {!!descriptions?.length &&
        descriptions.map(description => (
          <Typography color="secondary" key={description}>
            {description}
          </Typography>
        ))}

      <StyledFlex>
        <Typography color="secondary" marginRight={1}>
          {getFundDescription(isin, t, minPerformance, maxPerformance)}
        </Typography>
      </StyledFlex>
    </Box>
  );
};
