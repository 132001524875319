import * as React from "react";
export const EmailIcon = (
  props: {
    size?: number;
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 2.8V17.2H20V2.8H0ZM0.8 3.6H19.2V4.6C19.0438 4.73594 12.0297 10.8547 11.375 11.425L11.3375 11.4625C10.8188 11.9141 10.2875 12 10 12C9.71406 12 9.18125 11.9141 8.6625 11.4625C8.51406 11.3328 7.44531 10.4094 6.675 9.7375C4.38906 7.74375 0.917188 4.70313 0.8 4.6V3.6ZM0.8 5.6625C1.49375 6.26719 3.95469 8.40938 6.05 10.2375L0.8 14.3875V5.6625ZM19.2 5.6625V14.3875L13.95 10.2375C16.0453 8.40938 18.5063 6.26719 19.2 5.6625ZM6.6625 10.775C7.14844 11.1984 8.02656 11.9656 8.1375 12.0625L8.15 12.075C8.82656 12.6563 9.54531 12.8 10 12.8C10.4578 12.8 11.1828 12.6547 11.8625 12.0625C11.975 11.9656 12.8594 11.2031 13.35 10.775L19.2 15.4V16.4H0.8V15.4L6.6625 10.775Z"
        fill="white"
      />
    </svg>
  );
};
