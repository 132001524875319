import { IconButton } from "@mui/material";
import { TrashIcon } from "Components/Shared/Icons";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { track } from "Utils/TrackingUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";

type Props = {
  contractID: number;
  bankAccountID: number;
};

export const DeactivateBankConnectionButton: FunctionComponent<Props> = ({
  contractID,
  bankAccountID,
}) => {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => {
        track({
          category: "Button",
          event: "Click",
          tag: "ContractDeactivateBankConnection",
        });

        navigate(
          getPath(
            AppRouting.ContractDeactivateBankConnection,
            contractID,
            bankAccountID,
          ),
        );
      }}
    >
      <TrashIcon />
    </IconButton>
  );
};
