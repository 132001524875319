import {
  AttachmentDescriptor,
  EditPersonalDataVerificationType,
  PersonalDocumentType,
} from "Api/Api";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { FunctionComponent, FocusEvent, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  PrimaryDocumentFormModel,
  EditPersonalDataFormModel,
} from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { Resources, useResource } from "Translations/Resources";
import { isValid as isDateValid } from "date-fns";
import { parseDateToDocumentsDate } from "Utils/Contracts/NewContract/DateUtils";
import { allowedDocumentTypesFunc } from "Hooks/More/PersonalData/EditPersonalData/Modelling/useYupFormSchema";
import { useDocumentsList } from "Hooks/Inputs/useDocumentsList";
import { CZECHIA_COUNTRY_CODE } from "Constants/Countries";
import { PrimaryDocumentInput } from "Components/More/PersonalData/EditPersonalData/Modelling/components/PrimaryDocumentInput";

type Props = { nationalities: string[] } & Pick<
  EditPersonalDataFormModel,
  "verificationType"
>;

const ComponentResources =
  Resources.More.PersonalData.EditPersonalData.Modelling.PrimaryDocument;

export const DocumentsForm: FunctionComponent<Props> = ({
  nationalities,
  verificationType,
}) => {
  const { t } = useResource();

  const countries = useCountriesList();
  const documents = useDocumentsList();

  const allowedDocuments = useMemo(() => {
    const allowedDocumentTypes = allowedDocumentTypesFunc(
      verificationType,
      nationalities,
    );

    if (
      verificationType === EditPersonalDataVerificationType.Manual &&
      nationalities.some(x => x === CZECHIA_COUNTRY_CODE)
    ) {
      const identityCard = documents.find(
        x => x.Value === PersonalDocumentType.IdentityCard,
      );

      return identityCard ? [identityCard] : [];
    }

    return documents.filter(x => allowedDocumentTypes.some(y => y === x.Value));
  }, [documents, verificationType, nationalities]);

  const {
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext<PrimaryDocumentFormModel>();

  const handleIssueDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    const issueDate = new Date(event.target.value);
    const issueDateLowerBoundary = new Date(1900, 11, 30);
    const issueDateUpperBoundary = new Date();

    if (isDateValid(issueDate) && issueDate > issueDateUpperBoundary) {
      setValue(
        "primaryDocument.issueDate",
        parseDateToDocumentsDate(issueDateUpperBoundary),
        {
          shouldValidate: true,
        },
      );
    } else if (isDateValid(issueDate) && issueDate < issueDateLowerBoundary) {
      setValue(
        "primaryDocument.issueDate",
        parseDateToDocumentsDate(issueDateLowerBoundary),
        {
          shouldValidate: true,
        },
      );
    } else {
      trigger("primaryDocument.issueDate");
    }
  };

  const handleExpiryDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    const expiryDate = new Date(event.target.value);
    const expiryDateLowerBoundary = new Date();
    const expiryDateUpperBoundary = new Date(2999, 11, 30);

    if (isDateValid(expiryDate) && expiryDate > expiryDateUpperBoundary) {
      setValue(
        "primaryDocument.expiryDate",
        parseDateToDocumentsDate(expiryDateUpperBoundary),
        {
          shouldValidate: true,
        },
      );
    } else if (
      isDateValid(expiryDate) &&
      expiryDate < expiryDateLowerBoundary
    ) {
      setValue(
        "primaryDocument.expiryDate",
        parseDateToDocumentsDate(expiryDateLowerBoundary),
        {
          shouldValidate: true,
        },
      );
    } else {
      trigger("primaryDocument.expiryDate");
    }
  };

  return (
    <InputHeaderWrapper header={t(ComponentResources.Header)}>
      <DropdownInput
        control={control}
        errors={errors}
        name="primaryDocument.type"
        options={allowedDocuments}
        label={t(ComponentResources.Type)}
        disabled={
          allowedDocuments.length === 1 ||
          verificationType === EditPersonalDataVerificationType.BankID
        }
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name="primaryDocument.number"
        label={t(ComponentResources.Number)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
        mask={/^.+$/}
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name="primaryDocument.issueDate"
        type="date"
        label={t(ComponentResources.IssueDate)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
        onBlur={handleIssueDateBlur}
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name="primaryDocument.expiryDate"
        type="date"
        label={t(ComponentResources.ExpiryDate)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
        onBlur={handleExpiryDateBlur}
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name="primaryDocument.issuingAuthority"
        label={t(ComponentResources.IssuingAuthority)}
        placeholder={t(ComponentResources.IssuingAuthorityPlaceholder)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
      />

      <DropdownInput
        control={control}
        errors={errors}
        name="primaryDocument.issueCountry"
        options={countries}
        label={t(ComponentResources.IssueCountry)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
      />

      {verificationType === EditPersonalDataVerificationType.Manual && (
        <>
          <PrimaryDocumentInput
            fieldPath="primaryDocument.frontScan.guid"
            label={t(ComponentResources.FrontScan)}
            descriptor={
              AttachmentDescriptor.PRIMARY_IDENTITY_DOCUMENT_FRONT_SCAN
            }
          />

          <PrimaryDocumentInput
            fieldPath="primaryDocument.backScan.guid"
            label={t(ComponentResources.BackScan)}
            descriptor={
              AttachmentDescriptor.PRIMARY_IDENTITY_DOCUMENT_BACK_SCAN
            }
          />
        </>
      )}
    </InputHeaderWrapper>
  );
};
