import { useAppSelector } from "Hooks/useAppSelector";
import { useMemo } from "react";
import { EditPersonalDataSteps } from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { Resources, useResource } from "Translations/Resources";

const HookResources = Resources.More.PersonalData.EditPersonalData;

export const usePageTitle = () => {
  const { t } = useResource();
  const { actualStep } = useAppSelector(s => s.client.personalData.edit);

  const pageTitle = useMemo(() => {
    switch (actualStep) {
      case EditPersonalDataSteps.Modelling:
      case EditPersonalDataSteps.BankIDCallback:
      case EditPersonalDataSteps.Signature:
      case EditPersonalDataSteps.BankIDRedirect:
        return t(HookResources.Title);
    }

    return t(HookResources.Title);
  }, [actualStep, t]);

  return pageTitle;
};
