import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  ContractDocumentDto,
  ContractPaymentMessageDto,
  ContractTypeCode,
  DefaultBankAccountDto,
  PaymentInstructionDto,
} from "Api/Api";
import { SelectButtons } from "Components/Shared/Inputs/SelectButtons";
import {
  PaymentInformation,
  Props as PaymentInformationProps,
} from "Components/Shared/PaymentInformations/PaymentInformation";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { WarningText } from "Components/Shared/WarningText";
import { CZECHIA_COUNTRY_CODE } from "Constants/Countries";
import { useSettings } from "Hooks/App/Settings/useSettings";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { Resources, useResource } from "Translations/Resources";
import {
  RS_INVCZSELF_PAYMENT_INSTRUCTIONS_DIP_EMPLOYER_URL,
  RS_INVEURSELF_PAYMENT_INSTRUCTIONS_DIP_EMPLOYER_URL,
} from "Utils/ExternalLinkUtils";
import { useState, type FunctionComponent } from "react";
import { Document } from "Components/Shared/Document";
import { getProductColor } from "Utils/Products";

export enum PaymentInformationTab {
  Client,
  Employer,
}

type Props = {
  contractID: number;
  bankAccountRegion?: string | null | undefined;
  paymentInstructions?: PaymentInstructionDto[];
  isNoticeToFirstPaymentVisible?: boolean;
  contractPaymentMessage?: ContractPaymentMessageDto;
  defaultBankAccount?: DefaultBankAccountDto;
  bankCountry?: string;
  isinPaymentsInfo?: {
    [key: string]: {
      singleInvestment?: number;
      periodicalInvestment?: number;
      expiryDate?: Date;
    };
  };
  showSaveButton?: boolean;
  showSingleInvestment?: boolean;
  showSingleInvestmentProductLogo?: boolean;
  showSingleInvestmentTitle?: boolean;
  showPeriodicalInvestment?: boolean;
  showPeriodicalInvestmentProductLogo?: boolean;
  showPeriodicalInvestmentTitle?: boolean;
  showDivider?: boolean;
  contractTypeCode?: ContractTypeCode;
  documents?: ContractDocumentDto[];
  isDip?: boolean;
  isDocumentsReferenceVisible?: boolean;
  useProductColors?: boolean;
};

const PageResources = Resources.Components.PaymentInformation;

export const PaymentInformations: FunctionComponent<Props> = ({
  contractID,
  bankAccountRegion,
  contractPaymentMessage,
  isNoticeToFirstPaymentVisible,
  paymentInstructions = [],
  isinPaymentsInfo,
  showSaveButton,
  defaultBankAccount,
  showSingleInvestment = true,
  showSingleInvestmentProductLogo = true,
  showSingleInvestmentTitle = true,
  showPeriodicalInvestment = true,
  showPeriodicalInvestmentProductLogo = true,
  showPeriodicalInvestmentTitle = true,
  showDivider = true,
  contractTypeCode,
  documents,
  isDip = false,
  isDocumentsReferenceVisible = false,
  useProductColors = false,
}) => {
  const { t } = useResource();
  const isBankIdVerified = useIsBankIDVerified();
  const { settings } = useSettings();
  const { color, contrastColor } = getProductColor(
    paymentInstructions?.[0]?.isin,
  );
  const [selectedTab, setSelectedTab] = useState(PaymentInformationTab.Client);

  const contractNo = paymentInstructions?.[0]?.vs;
  const isSelfNegotiatedContract =
    contractTypeCode === ContractTypeCode.RS_INVCZKSELF ||
    contractTypeCode === ContractTypeCode.RS_INVEURSELF;

  return (
    <div>
      {isDip && (
        <>
          <Typography marginTop={4}>
            {t(PageResources.Dip.Description)}
          </Typography>

          <Box marginTop={4}>
            <SelectButtons
              buttons={[
                {
                  label: t(PageResources.Tabs.Client.ButtonText),
                  value: PaymentInformationTab.Client,
                },
                {
                  label: t(PageResources.Tabs.Employer.ButtonText),
                  value: PaymentInformationTab.Employer,
                },
              ]}
              active={selectedTab}
              onChange={value => setSelectedTab(value)}
              color={useProductColors ? contrastColor : undefined}
              backgroundColor={useProductColors ? color : undefined}
            />
          </Box>

          {selectedTab === PaymentInformationTab.Employer && contractID && (
            <Stack spacing={4}>
              <Typography>
                {t(PageResources.Tabs.Employer.Description)}
              </Typography>

              {isDocumentsReferenceVisible && (
                <Typography>
                  {t(PageResources.Tabs.Employer.DocumentsReference)}
                </Typography>
              )}

              {isSelfNegotiatedContract && (
                <Document
                  name={t(
                    contractTypeCode === ContractTypeCode.RS_INVCZKSELF
                      ? Resources.Common.PaymentInstructions.RS_INVCZKSELF_DIP
                      : Resources.Common.PaymentInstructions.RS_INVEURSELF_DIP,
                  )}
                  href={
                    contractTypeCode === ContractTypeCode.RS_INVCZKSELF
                      ? RS_INVCZSELF_PAYMENT_INSTRUCTIONS_DIP_EMPLOYER_URL
                      : RS_INVEURSELF_PAYMENT_INSTRUCTIONS_DIP_EMPLOYER_URL
                  }
                />
              )}

              {!isSelfNegotiatedContract &&
                documents?.map(({ fileName }) => (
                  <Document
                    key={fileName}
                    name={fileName}
                    contractID={contractID}
                  />
                ))}

              {!isSelfNegotiatedContract && !documents?.length && (
                <Typography>{t(PageResources.DocumentsNotFound)}</Typography>
              )}

              {contractNo && (
                <WarningText>
                  <Typography component="span">
                    {t(PageResources.Tabs.Employer.VariableSymbol, {
                      vs: contractNo,
                    })}
                  </Typography>
                </WarningText>
              )}
            </Stack>
          )}
        </>
      )}

      {selectedTab === PaymentInformationTab.Client &&
        contractID &&
        paymentInstructions
          .filter(
            i =>
              (i.isSingleInvestment && showSingleInvestment) ||
              (!i.isSingleInvestment && showPeriodicalInvestment),
          )
          .map(
            (
              { iban, isin, ss, vs, bankAccount, currency, isSingleInvestment },
              _,
              instructions,
            ) => {
              const investment = isin
                ? isSingleInvestment
                  ? isinPaymentsInfo?.[isin]?.singleInvestment
                  : isinPaymentsInfo?.[isin]?.periodicalInvestment
                : null;

              const multipleIsinInstructions =
                instructions.filter(i => i.isin === isin).length > 1;

              const showProductLogo =
                isin &&
                ((isSingleInvestment && showSingleInvestmentProductLogo) ||
                  (!isSingleInvestment && showPeriodicalInvestmentProductLogo));

              const paymentInformationProps: PaymentInformationProps = {
                contractID,
                title: isSingleInvestment
                  ? t(PageResources.SingleInvestment)
                  : t(PageResources.PeriodicalInvestment),
                iban: iban || undefined,
                accountNumber: bankAccount || undefined,
                variableCode: vs ?? "",
                specificSymbol: ss ?? "",
                constantSymbol: "",
                amount: investment ?? 0,
                expiryDate: isin
                  ? isinPaymentsInfo?.[isin]?.expiryDate
                  : undefined,
                currency,
                contractNo: contractPaymentMessage?.contractNo ?? "",
                firstName: contractPaymentMessage?.firstName ?? "",
                lastName: contractPaymentMessage?.lastName ?? "",
                isBankIdVerified,
                isNoticeToFirstPaymentVisible: !!isNoticeToFirstPaymentVisible,
                isSelfNegotiatedContract,
                showSaveButton,
                isBySquareQRCodeEnabled:
                  settings?.isBySquareQRCodeEnabled ?? false,
                isSingleInvestment,
                invoiceID: "",
                defaultBankAccount,
              };

              return (
                <div key={isSingleInvestment ? `S${isin}` : `P${isin}`}>
                  {(isSingleInvestment && multipleIsinInstructions) ||
                  !multipleIsinInstructions ? (
                    <Box marginY={2}>
                      {showProductLogo && (
                        <ProductLogo isin={isin} marginBottom={3} />
                      )}
                      <Typography>{t(PageResources.Instructions)}</Typography>
                    </Box>
                  ) : (
                    <Box marginY={5}>{showDivider && <Divider />}</Box>
                  )}

                  <PaymentInformation
                    {...paymentInformationProps}
                    showTitle={
                      (isSingleInvestment && showSingleInvestmentTitle) ||
                      (!isSingleInvestment && showPeriodicalInvestmentTitle)
                    }
                    bankAccountRegion={
                      bankAccountRegion ?? CZECHIA_COUNTRY_CODE
                    }
                  />
                </div>
              );
            },
          )}
    </div>
  );
};
