import { Link, Typography } from "@mui/material";
import { PageTitle } from "Components/Shared/PageTitle";
import { useAppSelector } from "Hooks/useAppSelector";
import { Resources, useResource } from "Translations/Resources";
import { VITE_APPLICATION_VERSION, VITE_BUILD_NUMBER } from "Utils/BuildUtils";
import { FunctionComponent } from "react";

type Props = {
  pageTitle: string;
};

const PageResources = Resources.More.About;

export const About: FunctionComponent<Props> = ({ pageTitle }) => {
  const { t } = useResource();
  const nativeVersion = useAppSelector(s => s.metadata.mobile.app.version);

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{pageTitle}</PageTitle>
        <Typography>{t(PageResources.Description)}</Typography>
        <Typography paddingTop={2}>{t(PageResources.Constraints)}</Typography>
      </div>

      <Link href="investika://callback" target="_blank">
        <Typography variant="caption" color="gray" component="span">
          {!!nativeVersion
            ? t(PageResources.Version.Mobile, {
                applicationVersion: VITE_APPLICATION_VERSION,
                buildNumber: VITE_BUILD_NUMBER,
                nativeVersion: nativeVersion,
              })
            : t(PageResources.Version.Web, {
                applicationVersion: VITE_APPLICATION_VERSION,
                buildNumber: VITE_BUILD_NUMBER,
              })}
        </Typography>
      </Link>
    </>
  );
};
