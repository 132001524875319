import { Typography } from "@mui/material";
import { CurrencyCode } from "Api/Api";
import { CurrencyRenderer } from "Components/Shared/CurrencyRenderer";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { StyledDarkCard, StyledFlex } from "Components/Shared/StyledComponents";
import { useFormattedProductClass } from "Hooks/Products/useFormattedProductClass";
import { FunctionComponent } from "react";
import styled from "styled-components";

const PopperContent = styled(StyledDarkCard)`
  height: 100px;
  min-width: 200px;
  padding: ${props => props.theme.spacing(3, 3)};
  border: 0;
`;

type Props = {
  isin: string;
  amount: number;
  amountPercentage: string;
  currency: CurrencyCode;
};

export const InvestmentCompositionPieChartTooltipContent: FunctionComponent<
  Props
> = ({ isin, amount, amountPercentage, currency }) => {
  const productClass = useFormattedProductClass(isin);

  return (
    <PopperContent>
      <StyledFlex $gap={5} $justifyContent="space-between" $alignItems="center">
        <ProductLogo
          isin={isin}
          name={productClass}
          imageHeight={30}
          nameNoWrap
        />
        <Typography
          fontSize={16}
          fontWeight={600}
          variant="body1"
          color="primary"
          noWrap
        >
          {amountPercentage}
        </Typography>
      </StyledFlex>
      <StyledFlex $justifyContent="flex-end">
        <CurrencyRenderer
          value={amount}
          decimalPlaces={2}
          currency={currency}
          fontSize={16}
          fontWeight={600}
        />
      </StyledFlex>
    </PopperContent>
  );
};
