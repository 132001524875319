import { Box, BoxProps, useTheme } from "@mui/material";
import { WarningIcon } from "Components/Shared/Icons";
import { PropsWithChildren, type FunctionComponent } from "react";

type Props = PropsWithChildren<Omit<BoxProps, "display">>;

export const WarningText: FunctionComponent<Props> = ({
  children,
  ...props
}) => {
  const { palette } = useTheme();

  return (
    <Box {...props} display="flex">
      <Box marginRight={2}>
        <WarningIcon height={20} width={20} color={palette.primary.main} />
      </Box>
      <div>{children}</div>
    </Box>
  );
};
