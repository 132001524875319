import { PageTitle } from "Components/Shared/PageTitle";
import { useDesktop } from "Hooks/useDesktop";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  title: string;
  titleComponent?: ReactNode;
};

export const SignatureFormTitle: FunctionComponent<Props> = ({
  title,
  titleComponent,
}) => {
  const { isDesktop } = useDesktop();

  if (!!titleComponent) {
    return <>{titleComponent}</>;
  }

  return <PageTitle showOnMobile={isDesktop}>{title}</PageTitle>;
};
