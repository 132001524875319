import {
  MAXIMUM_PERIODICAL_DEPOSIT,
  MAXIMUM_SINGLE_DEPOSIT,
} from "Components/ContractModeling/FundConfiguration/FundConfiguration";
import { ProductItemDto } from "Api/Api";
import { FundConfigurationFormModel } from "State/Contracts/Modeling/Models";
import { Resources, useResource } from "Translations/Resources";
import { convertNanToNull } from "Utils/NumberUtils";
import { number, object, ObjectSchema, string } from "yup";
import { nameof } from "Utils/ObjectUtils";

export const useYupFormSchema = (
  props: Partial<ProductItemDto> = {},
): ObjectSchema<FundConfigurationFormModel> => {
  const { t } = useResource();

  const formSchema = object<FundConfigurationFormModel>().shape({
    type: string().oneOf(["Target", "Unlimited"]).required(),
    singleInvestment: number()
      .transform(v => convertNanToNull(parseInt(v)))
      .nullable()
      .max(MAXIMUM_SINGLE_DEPOSIT)
      .integer()
      .test(
        "min",
        t(Resources.Validation.Number.Min, {
          count: props.singleMinInvestment ?? 0,
        }),
        function (value) {
          return !(!!value && value < (props.singleMinInvestment ?? 0));
        },
      ),
    periodicalInvestment: number()
      .transform(v => convertNanToNull(parseInt(v)))
      .nullable()
      .max(MAXIMUM_PERIODICAL_DEPOSIT)
      .integer()
      .test(
        "deposit",
        t(Resources.Forms.Investments.SingleInvestmentRequired),
        function (value) {
          return !(
            value === 0 &&
            (convertNanToNull(Number.parseInt(this.parent.singleInvestment)) ??
              0) === 0
          );
        },
      )
      .test(
        "min",
        t(Resources.Validation.Number.Min, {
          count: props.periodicalMinInvestment ?? 0,
        }),
        function (value) {
          return !(!!value && value < (props.periodicalMinInvestment ?? 0));
        },
      ),
    investmentLengthYears: number()
      .transform(v => convertNanToNull(parseInt(v)))
      .integer()
      .min(1)
      .required(),
    targetAmount: number().when(nameof<FundConfigurationFormModel>("type"), {
      is: "Target",
      then: schema => schema.required().integer(),
      otherwise: schema => schema.nullable().notRequired(),
    }),
    totalAmount: number().nullable().notRequired(),
    dealerID: number().nullable().notRequired(),
    dealerCode: string().nullable().notRequired(),
  });

  return formSchema;
};
