import { BlCheckboxField } from "Components/Shared/Inputs/BlCheckboxField/BlCheckboxField";
import { CheckboxProps } from "@mui/material";
import { get } from "lodash-es";
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";

type Props<FormType extends FieldValues> = Omit<CheckboxProps, "onChange"> & {
  name: FieldPath<FormType>;
  label?: string | JSX.Element;
  control: Control<FormType, object>;
  errors: FieldErrors<FormType>;
  formInfo?: { [key: string]: string };
  isLabelFirst?: boolean;
  onChange?: (value: boolean) => void;
};

export const BlCheckboxFormField = <T extends object>(props: Props<T>) => {
  const {
    control,
    errors,
    formInfo,
    name,
    isLabelFirst,
    onChange: propsOnChange,
    ...rest
  } = props;
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <BlCheckboxField
            onChange={v => {
              onChange(v);
              propsOnChange && propsOnChange(v.target.checked);
            }}
            value={value}
            checked={value === true}
            name={name}
            fieldError={get(errors, name as string)}
            isLabelFirst={isLabelFirst}
            {...rest}
          />
        )}
      ></Controller>
    </>
  );
};
