import {
  BankIDProcessStatus,
  getBankIdAuthProcessesProcessID,
  GetBankIDProcessQueryResultStatus,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { NavigateFunction } from "react-router";
import { getTrackingSessionID } from "Utils/TrackingUtils";
import { setBankIDProfile } from "State/Contracts/Create/BankIDProfile/SetBankIDProfile";
import { safeApiCall } from "State/Utils";
import { put, select, takeLatest } from "typed-redux-saga";
import { RootStateType } from "State/Store";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { resolveBankIDCallbackRedirectRoute } from "Utils/BankIDUtils";

export const getBankIDVerificationProcessAsync = createAsyncAction(
  "@bankID/GET_BANK_ID_VERIFICATION_PROCESS_REQUEST",
  "@bankID/GET_BANK_ID_VERIFICATION_PROCESS_SUCCESS",
  "@bankID/GET_BANK_ID_VERIFICATION_PROCESS_FAILURE",
)<
  {
    navigate: NavigateFunction;
    t: (resourcePath: string, options?: any) => string;
  },
  BankIDProcessStatus,
  Error
>();

function* getBankIDVerificationProcess(
  action: ReturnType<typeof getBankIDVerificationProcessAsync.request>,
): Generator {
  try {
    const { navigate, t } = action.payload;
    const { process } = yield* select((s: RootStateType) => s.bankID);
    if (!process) {
      yield put(
        getBankIDVerificationProcessAsync.failure(
          new ApplicationError("Bank iD verification process not found."),
        ),
      );
      return;
    }

    const { response, error } = yield* safeApiCall(
      getBankIdAuthProcessesProcessID,
      process.id,
      process.audience,
      getTrackingSessionID(),
    );

    if (error) {
      yield put(getBankIDVerificationProcessAsync.failure(error));
      return;
    }

    if (
      response.status === GetBankIDProcessQueryResultStatus.Success &&
      response.verificationProcess?.profile
    ) {
      yield put(
        setBankIDProfile.request({
          processAudience: process.audience,
          profile: response.verificationProcess.profile,
          t,
        }),
      );

      yield put(
        getBankIDVerificationProcessAsync.success(
          response.verificationProcess.processStatus,
        ),
      );

      navigate(resolveBankIDCallbackRedirectRoute(process.audience));
    } else {
      yield put(
        getBankIDVerificationProcessAsync.failure(new Error(response.status)),
      );
    }
  } catch (err) {
    yield put(getBankIDVerificationProcessAsync.failure(err as Error));
  }
}

export function* watchGetBankIDVerificationProcessSaga() {
  yield takeLatest(
    getType(getBankIDVerificationProcessAsync.request),
    getBankIDVerificationProcess,
  );
}
