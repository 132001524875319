import { CardContent, Typography } from "@mui/material";
import { StyledDarkCard, StyledFlex } from "Components/Shared/StyledComponents";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

export const ProductSelectorWidth = 140;
export const ProductSelectorHeight = 130;

export const StyledCard = styled(StyledDarkCard)`
  height: ${ProductSelectorHeight}px;
  width: ${ProductSelectorWidth}px;
  background-color: #888888;
  border-style: dashed;
  border-width: 1px solid;

  .MuiCardContent-root {
    height: 100%;
    width: 100%;
  }
`;

type Props = {
  onClick?: () => void;
};

const ComponentResources =
  Resources.Contract.ExchangeAssets.Modelling.FundSelection;

export const ProductSelectorPlaceholder: FunctionComponent<Props> = ({
  onClick,
}) => {
  const { t } = useResource();
  return (
    <StyledCard onClick={onClick}>
      <CardContent>
        <StyledFlex $alignItems="center" $justifyContent="center" $fullHeight>
          <Typography color="primary">
            {t(ComponentResources.Select)}
          </Typography>
        </StyledFlex>
      </CardContent>
    </StyledCard>
  );
};
