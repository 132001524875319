import { useAppSelector } from "Hooks/useAppSelector";
import { type FormModelType } from "State/Contracts/Modeling/Models";

type Props<T extends keyof FormModelType> = {
  step: T;
};

export const useFormData: <T extends keyof FormModelType>(
  props: Props<T>,
) => { defaultValues: FormModelType[T] } = ({ step }) => {
  const defaultValues = useAppSelector(
    e => e.contracts.modeling.modelingState.formData[step],
  );

  return {
    defaultValues,
  };
};
