import { Box, Dialog, DialogContent, Grid, Stack } from "@mui/material";
import { FundDescription } from "Components/ContractModeling/FundSelector/Components/FundDescription";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { CloseIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useEffect, type FunctionComponent, useMemo, useState } from "react";
import styled from "styled-components";
import { TrackingWrapper } from "Components/Tracking/TrackingWrapper";

import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import {
  ProductDetailSections,
  useProductDetail,
} from "Hooks/Products/useProductDetail";
import { useProductTilesItems } from "Hooks/Products/useProductTilesItems";
import { ProductsIsinDetail } from "Constants/Products";
import { getProductDetail, isKnownIsin } from "Utils/Products";
import { SelectButtons } from "Components/Shared/Inputs/SelectButtons";
import { Document } from "Components/Shared/Document";
import { isNoU } from "Utils/ObjectUtils";

import coins from "Assets/Images/ProductProperties/coins.png";
import stocks from "Assets/Images/ProductProperties/stocks.png";
import structural from "Assets/Images/ProductProperties/structural.png";
import { DESKTOP_BREAKPOINT } from "Constants/Layout";
import { useDesktop } from "Hooks/useDesktop";

const images = {
  coins,
  stocks,
  structural,
};

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    @media (max-width: ${props =>
        props.theme.breakpoints.values[DESKTOP_BREAKPOINT]}px) {
      height: calc(100vh - 90px);
      margin-top: 90px;
      width: 90%;
    }

    @media (min-width: ${props =>
        props.theme.breakpoints.values[DESKTOP_BREAKPOINT]}px) {
      max-height: 80vh;
      max-width: calc(
        ${props => props.theme.breakpoints.values[DESKTOP_BREAKPOINT]}px * 0.8
      );
    }

    border-radius: 16px;
    border: 1px solid ${props => props.theme.colors.border}60;
  }

  .MuiDialog-paper {
    box-shadow: none;
    background-color: ${props => props.theme.colors.gray};
  }
`;

const StyledDialogHeader = styled(DialogContent)<{ $backgroundImage?: string }>`
  margin-bottom: 0;
  overflow: hidden;
  background-image: url(${props => props.$backgroundImage});
  background-position: center top;
  background-size: cover;
`;

const StyledIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.background.default}25;
  border: 1px solid ${props => props.theme.colors.border}40;

  img {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const StyledName = styled.div`
  max-width: 135px;
  text-align: center;
`;

const StyledParagraph = styled.div<{
  $hexColor?: string | null | undefined;
}>`
  div {
    border-left: 3px solid
      ${props =>
        isNoU(props.$hexColor)
          ? props.theme.palette.primary.main
          : props.$hexColor};
    padding-left: ${props => props.theme.spacing(2)};
    margin: ${props => props.theme.spacing(3, 0)};
  }
`;

const StyledCloseIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: ${props => props.theme.palette.secondary.main}25;
  border-radius: 50%;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 21px;
  height: 21px;
  color: ${props => props.theme.palette.text.primary};
`;

type Props = {
  isin?: string | null | undefined;
  productCode?: string;
  onClose: () => void;
  isDip: boolean;
};

export const ProductDetailDialog: FunctionComponent<Props> = ({
  isin,
  productCode,
  onClose,
  isDip,
}) => {
  const [selectedIsin, setSelectedIsin] = useState<string | null | undefined>(
    isin,
  );
  const { isDesktop } = useDesktop();
  const { color, contrastColor, headerImage } =
    getProductDetail(selectedIsin) ?? {};

  const calculatedCode =
    productCode ??
    (isKnownIsin(isin) ? ProductsIsinDetail[isin]?.code : undefined);

  const { productDetail, isLoading: isLoadingDetail } =
    useProductDetail(selectedIsin);
  const { items, isLoading: isLoadingTiles } =
    useProductTilesItems(calculatedCode);

  const isLoading = isLoadingDetail || isLoadingTiles;
  const isOpen =
    typeof isin !== "undefined" || typeof productCode !== "undefined";

  const selectedItemDetail = useMemo(
    () => items?.find(({ isin }) => isin === selectedIsin),
    [items, selectedIsin],
  );

  useEffect(() => {
    if (!isin) {
      setSelectedIsin(items?.[0]?.isin);
    } else {
      setSelectedIsin(isin);
    }
  }, [items, isin]);

  return (
    <StyledDialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      hideBackdrop={!isDesktop}
    >
      <StyledDialogHeader $backgroundImage={headerImage}>
        <Box>
          <StyledFlex $justifyContent="space-between">
            {selectedIsin && (
              <ProductLogo isin={selectedIsin} imageHeight={35} />
            )}
            <Box>
              <StyledCloseIconWrapper onClick={onClose}>
                <StyledCloseIcon />
              </StyledCloseIconWrapper>
            </Box>
          </StyledFlex>
        </Box>
      </StyledDialogHeader>

      <DialogContent sx={{ zIndex: 1 }}>
        <TrackingWrapper
          payload={{
            category: "ModalView",
            event: "FundSelectorDescriptionDialog",
            value: isin,
            action: isOpen ? "Open" : "Close",
          }}
        >
          <LoadingWrapper isLoading={isLoadingTiles}>
            <SelectButtons
              buttons={items
                .filter(
                  ({ classCode, isin }) =>
                    classCode !== null &&
                    classCode !== undefined &&
                    isin !== null &&
                    isin !== undefined,
                )
                .filter(({ isDipAllowed }) => !isDip || isDipAllowed === isDip)
                .map(({ classCode, isin }) => ({
                  label: classCode as string,
                  value: isin as string,
                  trackingTag: `currency;${classCode}`,
                  trackingValue: isin as string,
                }))}
              active={selectedIsin as string}
              color={contrastColor}
              backgroundColor={color}
              onChange={v => setSelectedIsin(v)}
            />
          </LoadingWrapper>

          <LoadingWrapper
            isLoading={isLoading}
            skeleton={
              <Stack gap={3}>
                <PrimarySkeleton fullWidth height={60} />
                <PrimarySkeleton fullWidth height={250} />
                <PrimarySkeleton fullWidth height={30} />
                <PrimarySkeleton fullWidth height={100} />
                <PrimarySkeleton fullWidth height={80} />
                <PrimarySkeleton fullWidth height={80} />
              </Stack>
            }
          >
            <FundDescription
              descriptions={productDetail?.[ProductDetailSections.Headers]?.map(
                ({ value }) => value,
              )}
              maxPerformance={selectedItemDetail?.maxPerformance ?? 0}
              minPerformance={selectedItemDetail?.minPerformance ?? 0}
              isin={selectedIsin}
            />

            <Grid
              container
              marginTop={
                !!productDetail?.[ProductDetailSections.Properties]?.length
                  ? 4
                  : 0
              }
            >
              {productDetail?.[ProductDetailSections.Properties]?.map(
                ({ imageName, value, productSectionItemID }) => (
                  <Grid
                    key={productSectionItemID}
                    xs={6}
                    item
                    container
                    justifyContent="center"
                    alignItems="top"
                    marginBottom={4}
                  >
                    <div>
                      <StyledFlex $justifyContent="center">
                        <StyledIconBox>
                          <img
                            src={
                              imageName in images
                                ? images[imageName as keyof typeof images]
                                : stocks
                            }
                            width={30}
                            height={30}
                            alt={imageName}
                          />
                        </StyledIconBox>
                      </StyledFlex>
                      <StyledName>{value}</StyledName>
                    </div>
                  </Grid>
                ),
              )}
            </Grid>

            <Box marginY={1} display="grid" gap={1}>
              {productDetail?.[ProductDetailSections.Descriptions]?.map(
                ({ value, productSectionItemID }) => (
                  <span
                    dangerouslySetInnerHTML={{ __html: value }}
                    key={productSectionItemID}
                  />
                ),
              )}
            </Box>

            <Box marginBottom={5}>
              {productDetail?.[ProductDetailSections.Paragraphs]?.map(
                ({ value, productSectionItemID }) => (
                  <StyledParagraph key={productSectionItemID} $hexColor={color}>
                    <span dangerouslySetInnerHTML={{ __html: value }} />
                  </StyledParagraph>
                ),
              )}
            </Box>

            {productDetail?.[ProductDetailSections.Documents]?.map(
              ({ value, productSectionItemID, documentUrl }) => (
                <Document
                  key={productSectionItemID}
                  name={value}
                  href={documentUrl}
                />
              ),
            )}
          </LoadingWrapper>
        </TrackingWrapper>
      </DialogContent>
    </StyledDialog>
  );
};
