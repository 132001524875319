import { ButtonProps } from "@mui/material/Button";
import { TrackedButton } from "Components/Shared/Buttons/TrackedButton";
import { FunctionComponent } from "react";
import styled from "styled-components";

export type TextButtonProps = Omit<ButtonProps, "variant"> & {
  trackingTag?: string;
  trackingValue?: string;
};

const StyledButton = styled(TrackedButton)`
  border: none;
  padding: 0;

  ${props =>
    props.color === "secondary" &&
    `
      color: ${props.theme.palette.secondary.main};
    `}

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  &.Mui-disabled {
    color: unset;
    opacity: 0.5;
    text-decoration: none;
  }

  text-decoration: underline;
  text-underline-offset: 2px;
  font-weight: 500;
  text-transform: none;

  line-height: unset;
  font-size: 14px;
`;

export const TextButton: FunctionComponent<TextButtonProps> = props => {
  return (
    <StyledButton {...props}>
      <>{props.children}</>
    </StyledButton>
  );
};
