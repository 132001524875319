import * as React from "react";
export const MenuIcon = (
  props: {
    size?: number;
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={4}
        y={5}
        width={16}
        height={5}
        rx={1}
        stroke={props.color || "#FFFFFF"}
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <rect
        x={4}
        y={14}
        width={16}
        height={5}
        rx={1}
        stroke={props.color || "#FFFFFF"}
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </svg>
  );
};
