import { track } from "Utils/TrackingUtils";

export type AppWindow = Window & {
  canUpdateVersion?: boolean;
  isTrackingEnabled?: boolean;
  isHotjarIdentified?: boolean;
  postMessageToWebView?: (msg: any) => void;
  Android?: {
    postMessage: (msg: string) => void;
  };
  webkit?: {
    messageHandlers?: {
      postMessageListener?: {
        postMessage: (msg: string) => void;
      };
    };
  };
  track: typeof track;
  customDefaultUrlPostfix: string | null | undefined;
};

export const appWindow = window as unknown as AppWindow;
