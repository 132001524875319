import { BankIDProcessAudience } from "Api/Api";
import {
  BankIDCallbackLoadingWrapper,
  BankIDCallbackLoadingWrapperProps,
} from "Components/BankID/BankIDCallbackLoadingWrapper";
import { useBankIDCallbackParams } from "Hooks/BankID/useBankIDCallbackParams";
import { useAppSelector } from "Hooks/useAppSelector";
import { processBankIDVerificationAsync } from "State/BankID/ProcessBankIDVerificationState";
import { useResource } from "Translations/Resources";
import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { v4 as uuid } from "uuid";

export type BankIDCallbackWebProps = Pick<
  BankIDCallbackLoadingWrapperProps,
  "translations"
> & {
  processAudience: BankIDProcessAudience;
  onError: () => void;
};

export const BankIDCallbackWeb: FunctionComponent<
  BankIDCallbackWebProps
> = props => {
  const { t } = useResource();
  const { processAudience, onError } = props;
  const { isLoading, error: bankIDError } = useAppSelector(s => s.bankID);
  const { accessToken, state: processID, error } = useBankIDCallbackParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      dispatch(
        processBankIDVerificationAsync.request({
          processID: processID || uuid(),
          processAudience,
          accessToken,
          navigate,
          t,
        }),
      );
    }
  }, [processID, accessToken, processAudience, dispatch, navigate, t]);

  useEffect(() => {
    if (error === "access_denied") {
      onError();
    }
  }, [error, onError]);

  const isError = !!error || !!bankIDError;

  return (
    <BankIDCallbackLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      {...props}
    />
  );
};
