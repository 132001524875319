import { Box } from "@mui/material";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { useDesktop } from "Hooks/useDesktop";
import { FunctionComponent, PropsWithChildren } from "react";

type ContentWrapperProps = PropsWithChildren<{
  disableContentPadding?: boolean;
}>;

export const ContentWrapper: FunctionComponent<ContentWrapperProps> = ({
  children,
  disableContentPadding,
}) => {
  const { isDesktop } = useDesktop();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={isDesktop ? "start" : "space-between"}
      flex={1}
      gap={10}
      paddingX={disableContentPadding ? undefined : LAYOUT_PADDING_X}
      paddingBottom={LAYOUT_PADDING_X}
      overflow="hidden"
    >
      {children}
    </Box>
  );
};
