import { Box, Typography } from "@mui/material";
import { PageTitle } from "./Shared/PageTitle";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent, useEffect } from "react";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { useAppSelector } from "Hooks/useAppSelector";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { ContractPreviewDownloadLink } from "./Shared/ContractPreviewDownloadLink";
import { useCreateContract } from "Hooks/Contract/useCreateContract";
import { useAppDispatch } from "Hooks/useAppDispatch";
import Store from "State/Store";
import { postPreviewAsync } from "State/Contracts/Create/PostPreview/PostPreviewState";
import { ContractModelingStep } from "State/Contracts/Modeling/Models";
import { Document } from "Components/Shared/Document";
import { getKidTariffDocumentLink } from "Utils/DocumentUtils";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useModelingColor } from "Hooks/useModelingColor";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";

const PageResources = Resources.Contract.NewContract.ContractPreview;

export const ContractPreview: FunctionComponent = () => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const { color, contrastColor } = useModelingColor();
  const { isLoading, error } = useAppSelector(s => s.contracts.create.preview);
  const { nextStep } = useCreateContractStep();
  const fundData = useAppSelector(
    s => s.contracts.modeling.modelingState.formData[ContractModelingStep.Fund],
  );

  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();

  const { createContract } = useCreateContract();
  const isDip = useIsDipSelected();

  const onSubmit = () => {
    createContract();
    nextStep({ saveDraft: false });
  };

  useEffect(() => {
    const state = Store.store.getState();
    const contractData = state.contracts.create.formData;
    const modelingData = state.contracts.modeling.modelingState.formData;
    const isBankIDVerified = state.contracts.create.isBankIDVerified;
    const isDip = state.contracts.modeling.modelingState.isDip;

    dispatch(
      postPreviewAsync.request({
        contractData,
        modelingData,
        isBankIDVerified,
        isDip,
        canUseBiometrics: canUseBiometricsForSigning,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const kidTariffLink = getKidTariffDocumentLink(fundData?.contractTypeCode);
  const { data: preview, isLoading: isLoadingPreview } = useAppSelector(
    s => s.contracts.create.preview,
  );

  return (
    <>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <PageTitle>{t(PageResources.Title)}</PageTitle>
        {isDip ? (
          <Typography>
            {t(PageResources.DIP.LabelParts.First)}
            <Typography component="span" fontWeight={700}>
              {t(PageResources.DIP.LabelParts.Second)}
            </Typography>
            {t(PageResources.DIP.LabelParts.Third)}
            <Typography component="span" fontWeight={700}>
              {t(PageResources.DIP.LabelParts.Fourth)}
            </Typography>
            {t(PageResources.DIP.LabelParts.Fifth)}
          </Typography>
        ) : (
          <Typography>{t(PageResources.Label)}</Typography>
        )}

        <Box marginTop={1}>
          <ContractPreviewDownloadLink
            base64String={preview?.data}
            isLoading={isLoadingPreview}
            fileName={t(PageResources.FileName)}
          />
        </Box>

        <Box display="flex" flexGrow={1} flexDirection="column">
          {kidTariffLink && (
            <Box>
              <Typography marginTop={2} marginBottom={1}>
                {t(PageResources.KidTariffDocumentLabel)}
              </Typography>
              <Document
                name={t(Resources.Common.KidTariffDocumentLink)}
                href={kidTariffLink}
              />
            </Box>
          )}
        </Box>
      </Box>

      <BlSlideButton
        minSlideVelocity={0.95}
        minSlideWidth={0.95}
        onSlideDone={onSubmit}
        disabled={isLoading || !!error}
        isLoading={isLoading}
        trackingTag={t(PageResources.SignContract)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(PageResources.SignContract)}
      </BlSlideButton>
    </>
  );
};
