import * as React from "react";
export const ExpandArrowIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44.5}
      height={24.344}
      viewBox="0 0 44.5 24.344"
      {...props}
    >
      <path
        d="M4.844,12.906,2.75,15,25,37.25,47.25,15l-2.094-2.094L25,33.063Z"
        transform="translate(-2.75 -12.906)"
        fill="currentColor"
      />
    </svg>
  );
};
