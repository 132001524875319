import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { CreateBankConnectionButton } from "Components/ContractDetail/BankAccounts/components/CreateBankConnectionButton";
import { DeactivateBankConnectionButton } from "Components/ContractDetail/BankAccounts/components/DeactivateBankConnectionButton";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { CZECHIA_COUNTRY_CODE } from "Constants/Countries";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { useBankConnections } from "Hooks/Contract/BankConnections/useBankConnections";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { TabProps } from "Pages/Contracts/DetailPage";
import { getBankAccountsAsync } from "State/InvestmentsDetail/BankAccounts/BankAccountsState";
import { isNoU } from "Utils/ObjectUtils";
import { useEffect, type FunctionComponent } from "react";
import styled from "styled-components";

const StyledList = styled(List)`
  padding: 0;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const StyledListItem = styled(ListItem)`
  padding: ${props => props.theme.spacing(2, LAYOUT_PADDING_X)};
  border-bottom: 1px solid ${props => props.theme.palette.secondary.main}27;
`;

const StyledListItemText = styled(ListItemText)`
  margin-left: ${props => props.theme.spacing(1)};

  .MuiListItemText-primary {
    font-size: ${props => props.theme.typography.fontSize}px;
  }

  .MuiListItemText-secondary {
    color: ${props => props.theme.palette.text.primary};
  }
`;

export type AccountType = {
  currency: string;
  iban?: string;
  bankNumber?: string;
};

type Props = TabProps & { whitelistedIDs?: number[] };

export const BankAccounts: FunctionComponent<Props> = ({
  contractID,
  whitelistedIDs,
}) => {
  const dispatch = useAppDispatch();
  const {
    canCreate: createButtonVisible,
    canDeactivate: deactivateButtonVisible,
  } = useBankConnections();

  const { isLoading: isLoadingBankAccounts, data: bankAccounts } =
    useAppSelector(s => s.investmentsDetail.bankAccounts);

  useEffect(() => {
    dispatch(getBankAccountsAsync.request(contractID));
  }, [dispatch, contractID]);

  return (
    <LoadingWrapper
      isLoading={isLoadingBankAccounts}
      skeleton={
        <Box paddingX={4} gap={3} display="flex" flexDirection="column">
          <PrimarySkeleton variant="rectangular" width="100%" height={60} />
          <PrimarySkeleton variant="rectangular" width="100%" height={60} />
          <PrimarySkeleton variant="rectangular" width="50%" height={60} />
        </Box>
      }
    >
      <StyledList>
        {bankAccounts
          ?.filter(
            x =>
              isNoU(whitelistedIDs) ||
              whitelistedIDs.some(y => x.bankAccountID === y),
          )
          .map(({ bankAccountID, accountNumber, bankCode, iban }, index) => (
            <StyledListItem key={index}>
              <ListItemAvatar>
                <IconCircleWrapper size={45}>
                  <Typography fontWeight={700}>
                    {iban?.slice(0, 2) ?? CZECHIA_COUNTRY_CODE}
                  </Typography>
                </IconCircleWrapper>
              </ListItemAvatar>
              <StyledListItemText
                primary={accountNumber ? iban : undefined}
                secondary={
                  accountNumber ? `${accountNumber}/${bankCode}` : iban
                }
                secondaryTypographyProps={{ variant: "body1", fontWeight: 700 }}
              />
              {deactivateButtonVisible && !!bankAccountID && (
                <DeactivateBankConnectionButton
                  contractID={contractID}
                  bankAccountID={bankAccountID}
                />
              )}
            </StyledListItem>
          ))}
      </StyledList>
      {createButtonVisible && (
        <CreateBankConnectionButton contractID={contractID} />
      )}
    </LoadingWrapper>
  );
};
