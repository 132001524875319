import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put, takeLeading } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import {
  ContractDashboardDto,
  ContractDashboardQueryResultStatus,
  getContractDashboard,
} from "Api/Api";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { track } from "Utils/TrackingUtils";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { logError } from "ErrorService";

export type GetContractsStateType = FetchStateType<ContractDashboardDto>;

export const getContractsState = (): GetContractsStateType =>
  getFetchStateDefaultValue();

export type GetContractsActionType =
  | ActionType<typeof getContractsAsync>
  | ActionType<typeof resetDashboardContracts>;

export const getContractsAsync = createAsyncAction(
  "@investments-detail/GET_CONTRACTS_REQUEST",
  "@investments-detail/GET_CONTRACTS_SUCCESS",
  "@investments-detail/GET_CONTRACTS_FAILURE",
)<void, ContractDashboardDto, Error>();

export const resetDashboardContracts = createAction(
  "@investments-detail/RESET_CONTRACTS",
)();

function* getContracts(): Generator {
  try {
    const { status, response, error } =
      yield* safeApiCall(getContractDashboard);

    if (status === 401) {
      yield put(resetDashboardContracts());
      return;
    }

    if (error) {
      yield put(getContractsAsync.failure(error));
      logError(
        error,
        {
          status,
          error,
        },
        false,
      );

      return;
    }

    if (
      response?.status !== ContractDashboardQueryResultStatus.Success ||
      !response?.contractDashboard
    ) {
      yield put(
        getContractsAsync.failure(
          new ApplicationError(
            `Get contracts request ended with HTTP status code ${status}, request result status ${response?.status}`,
            ErrorLevel.Critical,
            {
              status,
              response: {
                status: response.status,
                error: response.error,
              },
              error,
            },
          ),
        ),
      );

      return;
    }

    if (response.contractDashboard.isAccessRestricted) {
      track({
        category: "RestrictedAccess",
        action: "Start",
        tag: "UnsupportedDistribution",
        event: "Distribution",
        url: getPath(AppRouting.Dashboard),
      });
    }

    yield put(getContractsAsync.success(response.contractDashboard));
  } catch (err) {
    yield put(getContractsAsync.failure(err as Error));
  }
}

export function* getContractsSaga() {
  yield takeLeading(getType(getContractsAsync.request), getContracts);
}

export const getContractsReducer = createReducer<
  GetContractsStateType,
  GetContractsActionType
>(getContractsState())
  .handleAction(getContractsAsync.request, handleActionRequest)
  .handleAction(getContractsAsync.failure, handleActionFailure)
  .handleAction(getContractsAsync.success, handleActionSuccess)
  .handleAction(resetDashboardContracts, () => getContractsState());
