import {
  JobType,
  NetIncomeType,
  SourceOfIncomeType,
  SourceOfResourcesType,
} from "Api/Api";
import { EUR_CURRENCY_VALUE } from "Constants/Investika";
import { useBusinessSectorList } from "Hooks/codeLists/useBusinessSectorList";
import { type CodeListItem } from "Models/CodeListItem";
import { TaxResidencyEnum } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { int2readableString } from "Utils/NumberUtils";

const PageResources = Resources.Contract.NewContract.AMLQuestions;

export const useFormOptions = () => {
  const { t } = useResource();
  const businessSectorOptions = useBusinessSectorList();

  const annualIncomeOptions: CodeListItem<NetIncomeType>[] = [
    {
      name: t(PageResources.AnnualIncomeOptions.NetIncomeTypeA, {
        value1: int2readableString(500_000),
        value2: int2readableString(500_000 / EUR_CURRENCY_VALUE),
      }),
      code: NetIncomeType.A,
    },
    {
      name: t(PageResources.AnnualIncomeOptions.NetIncomeTypeB, {
        value1: int2readableString(500_000),
        value2: int2readableString(1_000_000),
        value3: int2readableString(500_000 / EUR_CURRENCY_VALUE),
        value4: int2readableString(1_000_000 / EUR_CURRENCY_VALUE),
      }),
      code: NetIncomeType.B,
    },
    {
      name: t(PageResources.AnnualIncomeOptions.NetIncomeTypeC, {
        value1: int2readableString(1_000_000),
        value2: int2readableString(3_000_000),
        value3: int2readableString(1_000_000 / EUR_CURRENCY_VALUE),
        value4: int2readableString(3_000_000 / EUR_CURRENCY_VALUE),
      }),
      code: NetIncomeType.C,
    },
    {
      name: t(PageResources.AnnualIncomeOptions.NetIncomeTypeD, {
        value1: int2readableString(3_000_000),
        value2: int2readableString(3_000_000 / EUR_CURRENCY_VALUE),
      }),
      code: NetIncomeType.D,
    },
  ];

  const sourceOfIncomeOptions: CodeListItem<SourceOfIncomeType>[] = [
    {
      name: t(PageResources.SourceOfIncomeOptions.Salary),
      code: SourceOfIncomeType.Salary,
    },
    {
      name: t(PageResources.SourceOfIncomeOptions.Business),
      code: SourceOfIncomeType.Business,
    },
    {
      name: t(PageResources.SourceOfIncomeOptions.Rent),
      code: SourceOfIncomeType.Rent,
    },
    {
      name: t(PageResources.SourceOfIncomeOptions.Dividend),
      code: SourceOfIncomeType.Dividend,
    },
    {
      name: t(PageResources.SourceOfIncomeOptions.Other),
      code: SourceOfIncomeType.Other,
    },
  ];

  const jobPositionOptions: CodeListItem<JobType>[] = [
    {
      name: t(PageResources.JobPositionOptions.ManagerialPosition),
      code: JobType.ManagerialPosition,
    },
    {
      name: t(PageResources.JobPositionOptions.NonManagerialPosition),
      code: JobType.NonManagerialPosition,
    },
  ];

  const sourceOfFundsOptions: CodeListItem<SourceOfResourcesType>[] = [
    {
      name: t(PageResources.SourceOfFundsOptions.Savings),
      code: SourceOfResourcesType.Savings,
    },
    {
      name: t(PageResources.SourceOfFundsOptions.SaleOfProperty),
      code: SourceOfResourcesType.SaleOfProperty,
    },
    {
      name: t(PageResources.SourceOfFundsOptions.Heritage),
      code: SourceOfResourcesType.Heritage,
    },
    {
      name: t(PageResources.SourceOfFundsOptions.Other),
      code: SourceOfResourcesType.Other,
    },
  ];

  const taxResidencyOptions: CodeListItem<TaxResidencyEnum>[] = [
    {
      name: t(PageResources.TaxResidencyOptions.Czechia),
      code: TaxResidencyEnum.CzechRepublic,
    },
    {
      name: t(PageResources.TaxResidencyOptions.Slovakia),
      code: TaxResidencyEnum.Slovakia,
    },
    {
      name: t(PageResources.TaxResidencyOptions.USA),
      code: TaxResidencyEnum.USA,
      disabled: true,
      popper: t(PageResources.TaxResidencyUSAPopper),
    },
    {
      name: t(PageResources.TaxResidencyOptions.Other),
      code: TaxResidencyEnum.Other,
    },
  ];

  return {
    annualIncomeOptions,
    sourceOfIncomeOptions,
    jobPositionOptions,
    sourceOfFundsOptions,
    taxResidencyOptions,
    businessSectorOptions,
  };
};
