import * as React from "react";
export const PlusIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 1C6.1585 1 1 6.1585 1 12.5C1 18.8415 6.1585 24 12.5 24C18.8415 24 24 18.8415 24 12.5C24 6.1585 18.8415 1 12.5 1ZM16 13H13V16C13 16.2765 12.7765 16.5 12.5 16.5C12.2235 16.5 12 16.2765 12 16V13H9C8.7235 13 8.5 12.7765 8.5 12.5C8.5 12.2235 8.7235 12 9 12H12V9C12 8.7235 12.2235 8.5 12.5 8.5C12.7765 8.5 13 8.7235 13 9V12H16C16.2765 12 16.5 12.2235 16.5 12.5C16.5 12.7765 16.2765 13 16 13Z"
        fill="currentColor"
      />
    </svg>
  );
};
