import { DealerDto, getClientDealers } from "Api/Api";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";

export type GetDealersStateType = FetchStateType<DealerDto[]>;

export const getDealersState = (): GetDealersStateType =>
  getFetchStateDefaultValue();

export type GetDealersActionType =
  | ActionType<typeof getDealersAsync>
  | ActionType<typeof clearDealers>;

export const getDealersAsync = createAsyncAction(
  "@client/GET_DEALERS_REQUEST",
  "@client/GET_DEALERS_SUCCESS",
  "@client/GET_DEALERS_FAILURE",
)<void, DealerDto[], Error>();

export const clearDealers = createAction("@client/CLEAR_DEALERS")();

function* getDealers(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getClientDealers);

    if (error) {
      yield put(getDealersAsync.failure(error));
      return;
    }

    yield put(getDealersAsync.success(response));
  } catch (err) {
    yield put(getDealersAsync.failure(err as Error));
  }
}

export function* getDealersSaga() {
  yield takeLatest(getType(getDealersAsync.request), getDealers);
}

export const getDealersReducer = createReducer<
  GetDealersStateType,
  GetDealersActionType
>(getDealersState())
  .handleAction(getDealersAsync.request, handleActionRequest)
  .handleAction(getDealersAsync.failure, handleActionFailure)
  .handleAction(getDealersAsync.success, handleActionSuccess)
  .handleAction(clearDealers, getDealersState);
