import { Badge, Box, IconButton, Typography } from "@mui/material";
import { LAYOUT_PADDING_X, LAYOUT_PADDING_Y } from "Constants/Layout";
import styled from "styled-components";
import { DashboardLogo } from "../DashboardLogo";
import { StyledBackIcon, StyledFlex } from "Components/Shared/StyledComponents";
import { UserIcon } from "Components/Shared/Icons";
import { FunctionComponent, ReactNode } from "react";
import { Colors } from "../Themes/Colors";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useLocation, useNavigate } from "react-router";
import { useDesktop } from "Hooks/useDesktop";
import { track } from "Utils/TrackingUtils";
import { StyledLayoutWrapper } from "../AuthenticatedLayout";
import { useProductDesign } from "Hooks/useProductDesign";
import { ProductLogo } from "Components/Shared/ProductLogo";

const StyledHeaderWrapper = styled.div<{
  $customBorderColor?: string;
  $backgroundImage?: string;
}>`
  position: relative;
  padding: ${props =>
    props.theme.spacing(
      LAYOUT_PADDING_Y,
      LAYOUT_PADDING_X,
      2,
      LAYOUT_PADDING_X,
    )};

  box-shadow: ${props => props.theme.shadow.primary};

  border-bottom: ${({ $customBorderColor }) =>
    $customBorderColor
      ? `2px solid ${$customBorderColor}`
      : `1px solid rgba(136, 136, 136, 0.1)`};

  ${({ $backgroundImage }) =>
    $backgroundImage &&
    `
      background-image: url(${$backgroundImage});
      background-position: center top;
      background-size: cover;
  `}
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${props => props.theme.palette.secondary.main}15;
  height: 35px;
  width: 35px;

  &:hover {
    background-color: ${props => props.theme.palette.secondary.main}30;
  }

  * svg {
    width: 21px;
  }

  .MuiBadge-badge {
    width: 10px;
    height: 10px;
  }
`;

export type AppHeaderProps = {
  title: ReactNode;
  isLogoShown?: boolean;
  onBackClick?: () => void;
  isTitleShown?: boolean;
  children?: ReactNode;
  productIsin?: string;
  allowProductBackground?: boolean;
  isControlsShown?: boolean;
};

export const AppHeader: FunctionComponent<AppHeaderProps> = ({
  title,
  isLogoShown,
  isTitleShown,
  onBackClick,
  children,
  productIsin,
  allowProductBackground,
  isControlsShown,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isDesktop } = useDesktop();
  const { color, backgroundImage } = useProductDesign(productIsin);

  const showProductLogo = !isDesktop && productIsin && !isTitleShown;

  const handleProfileSettingsNavigation = () => {
    const destination = getPath(AppRouting.ProfileSettings);
    if (pathname !== destination) {
      navigate(getPath(AppRouting.ProfileSettings));
    }
  };

  const logo = <DashboardLogo height={35} width={125} />;

  const backItem = isDesktop ? (
    logo
  ) : (
    <StyledFlex>
      <StyledBackIcon
        role="button"
        onClick={() => {
          track({
            action: "Click",
            event: "GoBack",
            category: "AppState",
          });
          onBackClick?.();
        }}
      />
      {showProductLogo && (
        <ProductLogo
          isin={productIsin ?? ""}
          imageHeight={30}
          position="relative"
          top={4}
          marginBottom={1}
        />
      )}
    </StyledFlex>
  );

  return (
    <Box marginBottom={4}>
      <StyledHeaderWrapper
        $backgroundImage={
          showProductLogo && allowProductBackground
            ? backgroundImage
            : undefined
        }
        $customBorderColor={color}
      >
        <StyledLayoutWrapper maxWidth="md">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              flexGrow={1}
              overflow="hidden"
            >
              {!!onBackClick && backItem}

              {isLogoShown ? (
                logo
              ) : (
                <>
                  {isTitleShown && !showProductLogo && (
                    <Typography
                      variant="h2"
                      component="h2"
                      marginBottom={0}
                      flex="1"
                      noWrap
                    >
                      {title}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <Box display="flex" gap={1}>
              {/* // TODO: temporary disabled - will be implemented in the future
                  <StyledIconButton onClick={toggleBanner}>
                  <Badge variant="dot" color="error">
                    <NotificationIcon color={Colors.PrimaryText} />
                  </Badge>
                    </StyledIconButton>*/}
              {isControlsShown && (
                <StyledIconButton onClick={handleProfileSettingsNavigation}>
                  <Badge variant="dot">
                    <UserIcon color={Colors.PrimaryText} />
                  </Badge>
                </StyledIconButton>
              )}
            </Box>
          </Box>
        </StyledLayoutWrapper>
      </StyledHeaderWrapper>
      {!!children && <Box paddingX={LAYOUT_PADDING_X}>{children}</Box>}
    </Box>
  );
};
