import { useAppSelector } from "Hooks/useAppSelector";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { clientVerificationTypeAsync } from "State/Client/VerificationType/ClientVerificationTypeState";

export const useIsAnyContractBankIDVerified = (
  reFetchContracts: boolean = false,
) => {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector(s => s.client.verificationType);

  const isAnyContractBankIDVerified = useMemo(
    () => !!data?.isBankIDVerified,
    [data?.isBankIDVerified],
  );

  useEffect(() => {
    if (reFetchContracts) {
      dispatch(clientVerificationTypeAsync.request());
    }
  }, [reFetchContracts, dispatch]);

  return isAnyContractBankIDVerified;
};
