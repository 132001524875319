import {
  BiometricSignatureRequest,
  SignWithBiometricsCommandResult,
  putSignatureBiometrics,
  SignWithBiometricsCommandResultStatus,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { WebViewMessage, WebViewMessageTypes } from "Models/WebViewModels";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { call, select } from "typed-redux-saga";
import { RootStateType } from "State/Store";
import {
  setIsBiometrySignatureInProcess,
  setNextStep,
} from "State/Contracts/Create/CreateState";
import { logError } from "ErrorService";
import { NewContractStep } from "State/Contracts/Create/Models";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import {
  InvestUninvestedDepositsStep,
  setInvestUninvestedDepositsStep,
} from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";
import {
  RedemptionStep,
  setRedemptionStep,
} from "State/Contracts/Redemption/RedemptionState";
import { redeemAsync } from "State/Contracts/Redemption/Redeem/RedeemState";
import { investUninvestedDepositsAsync } from "State/Contracts/UninvestedDeposits/Invest/InvestUninvestedDepositsState";
import { setTransferDipStep } from "State/Contracts/TransferDip/TransferDipReducer";
import { TransferDipStep } from "State/Contracts/TransferDip/TransferDipModels";
import { transferDipInitializeSignatureAsync } from "State/Contracts/TransferDip/TransferDipInitializeSignatureState";
import { contractCreateBankConnectionAsync } from "State/Contracts/Contract/BankConnections/ContractCreateBankConnectionState";
import { contractDeactivateBankConnectionAsync } from "State/Contracts/Contract/BankConnections/ContractDeactivateBankConnectionState";
import { NavigateFunction } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { InvestmentDetailTabs } from "Hooks/ContractDetail/useMenuTabs";
import { editAMLAsync } from "State/More/PersonalData/EditAML/EditAMLReducer";
import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";
import {
  exchangeAssetsAsync,
  ExchangeAssetsStep,
  setExchangeAssetsStep,
} from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { editPersonalDataAsync } from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";

export const signWithBiometryAsync = createAsyncAction(
  "@contract/SIGN_WITH_BIOMETRY_REQUEST",
  "@contract/SIGN_WITH_BIOMETRY_SUCCESS",
  "@contract/SIGN_WITH_BIOMETRY_FAILURE",
)<
  Extract<
    WebViewMessage,
    {
      type: WebViewMessageTypes.BIOMETRIC_SIGNATURE_RESPONSE;
    }
  >["payload"] & {
    navigate: NavigateFunction;
  },
  SignWithBiometricsCommandResult,
  Error
>();

function* signWithBiometry(
  action: ReturnType<typeof signWithBiometryAsync.request>,
): Generator {
  try {
    const { type, signatureHash } = yield* select(
      (e: RootStateType) => e.biometrics.signature,
    );

    if (action.payload.isSuccess === false) {
      yield put(setIsBiometrySignatureInProcess(false));
      yield put(
        signWithBiometryAsync.failure(
          new ApplicationError(
            "Biometrics sign contract failed. Probably because face id or touch id was not successful.",
          ),
        ),
      );

      if (!signatureHash) {
        return;
      }

      if (type === BiometricsSignatureType.UninvestedDeposits) {
        const { lastRequest } = yield* select(
          (e: RootStateType) => e.contracts.uninvestedDeposits,
        );
        if (!!lastRequest) {
          yield put(
            investUninvestedDepositsAsync.request({
              ...lastRequest,
              ...{ isBiometry: false },
            }),
          );
        }
      }

      if (type === BiometricsSignatureType.Redemption) {
        const { lastRequest } = yield* select(
          (e: RootStateType) => e.contracts.redemption,
        );

        if (!!lastRequest) {
          yield put(
            redeemAsync.request({ ...lastRequest, ...{ isBiometry: false } }),
          );
        }
      }

      if (type === BiometricsSignatureType.TransferDip) {
        const { lastRequest } = yield* select(
          (e: RootStateType) => e.contracts.transferDip,
        );

        if (lastRequest) {
          yield put(
            transferDipInitializeSignatureAsync.request({
              ...lastRequest,
              ...{ isBiometry: false },
            }),
          );
        }
      }

      if (type === BiometricsSignatureType.CreateBankConnection) {
        const createBankConnection = yield* select(
          (e: RootStateType) => e.contracts.contract.bankConnections.create,
        );

        if (!!createBankConnection.contractID) {
          yield put(
            contractCreateBankConnectionAsync.request({
              ...createBankConnection.formData,
              contractID: createBankConnection.contractID,
              isBiometry: false,
            }),
          );
        }
      }

      if (type === BiometricsSignatureType.DeactivateBankConnection) {
        const deactivateBankConnection = yield* select(
          (e: RootStateType) => e.contracts.contract.bankConnections.deactivate,
        );

        if (
          !!deactivateBankConnection.contractID &&
          !!deactivateBankConnection.bankAccountID
        ) {
          yield put(
            contractDeactivateBankConnectionAsync.request({
              contractID: deactivateBankConnection.contractID,
              bankAccountID: deactivateBankConnection.bankAccountID,
              isBiometry: false,
            }),
          );
        }
      }

      if (type === BiometricsSignatureType.EditAML) {
        const { lastRequest } = yield* select(
          (e: RootStateType) => e.client.personalData.editAML,
        );

        if (!!lastRequest) {
          yield put(
            editAMLAsync.request({
              ...lastRequest,
              ...{ isBiometry: false },
            }),
          );
        }
      }

      if (type === BiometricsSignatureType.EditPersonalData) {
        const { lastRequest } = yield* select(
          (e: RootStateType) => e.client.personalData.edit,
        );

        if (!!lastRequest) {
          yield put(
            editPersonalDataAsync.request({
              ...lastRequest,
              ...{ isBiometry: false },
            }),
          );
        }
      }

      if (type === BiometricsSignatureType.ExchangeAssets) {
        const exchangeAssets = yield* select(
          (e: RootStateType) => e.contracts.exchangeAssets,
        );

        if (!!exchangeAssets.contractID) {
          yield put(
            exchangeAssetsAsync.request({
              ...exchangeAssets.formData,
              contractID: exchangeAssets.contractID,
              isBiometry: false,
            }),
          );
        }
      }

      return;
    }

    if (!signatureHash) {
      const error = new ApplicationError(
        "Biometrics sign contract failed. signatureHash is null or undefined.",
        ErrorLevel.Critical,
      );
      yield put(setIsBiometrySignatureInProcess(false));
      yield put(signWithBiometryAsync.failure(error));
      logError(error);

      return;
    }

    const formData = yield* select(
      (e: RootStateType) =>
        e.contracts.create.formData[NewContractStep.ContractSignature],
    );

    const contractID =
      type === BiometricsSignatureType.Contract ? formData?.contractID : null;

    if (!contractID && type === BiometricsSignatureType.Contract) {
      yield put(setIsBiometrySignatureInProcess(false));
      yield put(
        signWithBiometryAsync.failure(
          new ApplicationError(
            "Biometrics sign contract failed.",
            ErrorLevel.Critical,
          ),
        ),
      );
      return;
    }

    const request: BiometricSignatureRequest = {
      contractID: contractID,
      challenge: action.payload.challenge,
      login: action.payload.login,
      publicKey: action.payload.publicKey,
      signedChallenge: action.payload.signedChallenge,
      signatureHash: signatureHash,
    };

    const { response, error } = yield* safeApiCall(
      putSignatureBiometrics,
      request,
    );

    if (error) {
      yield put(setIsBiometrySignatureInProcess(false));
      yield put(signWithBiometryAsync.failure(error));
      console.error("signContractWithBiometricSignature error", error);
      logError(
        error,
        {
          response,
          error,
          actionPayload: action.payload,
          request,
        },
        false,
      );

      return;
    }

    if (response.status !== SignWithBiometricsCommandResultStatus.Success) {
      const error = new ApplicationError(
        `Biometrics sign contract failed: ${response.status}`,
        ErrorLevel.Critical,
        {
          request: {
            payload: action.payload,
          },
          response: {
            status: response.status,
            signInResult: {
              status: response.signInResult?.status,
              error: response.signInResult?.error,
              errorMessage: response.signInResult?.errorMessage,
            },
            error: response.error,
          },
        },
      );

      yield put(setIsBiometrySignatureInProcess(false));
      yield put(signWithBiometryAsync.failure(error));

      console.error("signContractWithBiometricSignature error", error);
      logError(
        error,
        {
          request: {
            payload: action.payload,
          },
          response: {
            status: response.status,
            signInResult: {
              status: response.signInResult?.status,
              error: response.signInResult?.error,
              errorMessage: response.signInResult?.errorMessage,
            },
            error: response.error,
          },
          error,
        },
        false,
      );

      return;
    }

    console.log("Contract signed successfully");

    if (type === BiometricsSignatureType.Contract) {
      const { data } = yield* select(
        (s: RootStateType) => s.contracts.create.processInformation,
      );

      if (!!data) {
        yield put(setNextStep({ steps: data!.steps }));
      } else {
        logError(
          new Error(
            "Biometrics sign contract: data is null and cannot continue to next step.",
          ),
        );
      }
    }

    if (type === BiometricsSignatureType.UninvestedDeposits) {
      yield put(
        setInvestUninvestedDepositsStep(InvestUninvestedDepositsStep.Success),
      );
    }

    if (type === BiometricsSignatureType.Redemption) {
      yield put(setRedemptionStep(RedemptionStep.Success));
    }

    if (type === BiometricsSignatureType.TransferDip) {
      yield put(setTransferDipStep(TransferDipStep.FinalPage));
    }

    if (type === BiometricsSignatureType.CreateBankConnection) {
      const createBankConnectionContractID = yield* select(
        (s: RootStateType) =>
          s.contracts.contract.bankConnections.create.contractID,
      );

      if (!!createBankConnectionContractID) {
        yield* call(() =>
          action.payload.navigate(
            getPath(
              AppRouting.ContractDetail,
              createBankConnectionContractID,
              InvestmentDetailTabs.Accounts,
            ),
          ),
        );
      }
    }

    if (type === BiometricsSignatureType.DeactivateBankConnection) {
      const deactivateBankConnectionContractID = yield* select(
        (s: RootStateType) =>
          s.contracts.contract.bankConnections.deactivate.contractID,
      );

      if (!!deactivateBankConnectionContractID) {
        yield* call(() =>
          action.payload.navigate(
            getPath(
              AppRouting.ContractDetail,
              deactivateBankConnectionContractID,
              InvestmentDetailTabs.Accounts,
            ),
          ),
        );
      }
    }

    if (type === BiometricsSignatureType.EditAML) {
      yield* call(() =>
        action.payload.navigate(
          getPath(AppRouting.PersonalData, PersonalDataTabs.AML),
        ),
      );
    }

    if (type === BiometricsSignatureType.EditPersonalData) {
      yield* call(() =>
        action.payload.navigate(
          getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData),
        ),
      );
    }

    if (type === BiometricsSignatureType.ExchangeAssets) {
      yield put(setExchangeAssetsStep(ExchangeAssetsStep.Success));
    }

    yield put(signWithBiometryAsync.success(response));
  } catch (err) {
    yield put(setIsBiometrySignatureInProcess(false));
    yield put(signWithBiometryAsync.failure(err as Error));
  }
}
export function* signWithBiometrySaga() {
  yield takeLatest(getType(signWithBiometryAsync.request), signWithBiometry);
}
