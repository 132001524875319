import { Typography, type TypographyProps } from "@mui/material";
import { type Variant } from "@mui/material/styles/createTypography";
import { Colors } from "Components/Layout/Themes/Colors";
import { formatCurrency } from "Utils/CurrencyUtils";
import { roundNumberToDecimalPlaces } from "Utils/NumberUtils";
import { type FunctionComponent, useEffect, useState } from "react";

type Props = {
  value?: number | null;
  variant?: Variant;
  decimalPlaces?: number;
  fixedDecimalPlaces?: boolean;
  currency?: string;
  displaySign?: boolean;
} & Pick<
  TypographyProps,
  "fontWeight" | "fontSize" | "noWrap" | "display" | "color"
>;

export const CurrencyRenderer: FunctionComponent<Props> = ({
  value,
  variant = "h1",
  decimalPlaces = 2,
  fixedDecimalPlaces,
  currency,
  displaySign = false,
  ...props
}) => {
  const [currencyValue, setCurrencyValue] = useState("");

  useEffect(() => {
    setCurrencyValue(
      formatCurrency(value, currency, {
        decimalPlaces: decimalPlaces,
        fixedDecimalPlaces,
        displaySign,
      }),
    );
  }, [value, decimalPlaces, fixedDecimalPlaces, currency, displaySign]);

  const valueRounded = roundNumberToDecimalPlaces(value, decimalPlaces);
  const color =
    displaySign && valueRounded !== 0
      ? valueRounded > 0
        ? Colors.Green
        : Colors.Red
      : Colors.White;

  return (
    <Typography component="div" variant={variant} color={color} {...props}>
      {currencyValue}
    </Typography>
  );
};
