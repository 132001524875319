import {
  TextButton,
  TextButtonProps,
} from "Components/Shared/Buttons/TextButton";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

const StyledIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: ${props => props.theme.spacing(1)};
  background-color: ${props => props.theme.palette.primary.main}26;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 12px;
    width: 12px;
    color: ${props => props.theme.palette.primary.main};
  }
`;

type Props = PropsWithChildren<
  {
    icon: ReactNode;
    onClick?(): void;
  } & Pick<TextButtonProps, "disabled">
>;

export const BlIconTextButton: FunctionComponent<Props> = ({
  icon,
  children,
  onClick,
  ...props
}) => {
  return (
    <TextButton color="secondary" onClick={onClick} {...props}>
      <StyledIconWrapper>{icon}</StyledIconWrapper>
      {children}
    </TextButton>
  );
};
