import { Typography } from "@mui/material";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";

type Props = {
  name: string;
  quantity: number | null | undefined;
};

export const SecurityPaper: FunctionComponent<Props> = ({ name, quantity }) => {
  const { t } = useResource();

  return (
    <StyledFlex $gap={4} $justifyContent="space-between">
      <Typography fontWeight={700} variant="body2">
        {name}
      </Typography>
      <Typography fontWeight={700} variant="body2" noWrap overflow="visible">
        {t(Resources.Common.PiecesShort, { count: quantity })}
      </Typography>
    </StyledFlex>
  );
};
