import { TransactionsFiltersFormModel } from "State/InvestmentsDetail/Transactions/TransactionsState";
import { boolean, object, ObjectSchema, string } from "yup";

export const useYupFormSchema =
  (): ObjectSchema<TransactionsFiltersFormModel> => {
    return object<TransactionsFiltersFormModel>().shape({
      includePurchases: boolean().optional(),
      includeRedemptions: boolean().optional(),
      includeAssetsExchanges: boolean().optional(),
      includeAssetsTransfers: boolean().optional(),
      dateFrom: string().optional(),
      dateTo: string().optional(),
    });
  };
