import * as React from "react";
export const FilterOffIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 19.8335L3.33333 19.8335"
        stroke="#F2F2F2"
        strokeLinecap="round"
      />
      <path
        d="M16.6667 8.1665L14.1667 8.1665"
        stroke="#F2F2F2"
        strokeLinecap="round"
      />
      <path
        d="M16.6667 19.8335L13.3334 19.8335"
        stroke="#F2F2F2"
        strokeLinecap="round"
      />
      <path
        d="M10.8333 8.1665L3.33325 8.1665"
        stroke="#F2F2F2"
        strokeLinecap="round"
      />
      <path d="M5.83325 14L3.33325 14" stroke="#F2F2F2" strokeLinecap="round" />
      <path d="M16.6667 14L9.16675 14" stroke="#F2F2F2" strokeLinecap="round" />
      <circle
        cx={11.6667}
        cy={19.8333}
        r={1.66667}
        transform="rotate(-90 11.6667 19.8333)"
        stroke="#F2F2F2"
        strokeLinecap="round"
      />
      <circle
        cx={7.49992}
        cy={13.9998}
        r={1.66667}
        transform="rotate(-90 7.49992 13.9998)"
        stroke="#F2F2F2"
        strokeLinecap="round"
      />
      <circle
        cx={12.4999}
        cy={8.16683}
        r={1.66667}
        transform="rotate(-90 12.4999 8.16683)"
        stroke="#F2F2F2"
        strokeLinecap="round"
      />
    </svg>
  );
};
