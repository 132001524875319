import { Box } from "@mui/material";
import { BankIDCallbackMobile } from "Components/BankID/BankIDCallbackMobile";
import {
  BankIDCallbackWeb,
  BankIDCallbackWebProps,
} from "Components/BankID/BankIDCallbackWeb";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, useEffect, useMemo } from "react";
import { isWebView } from "Utils/WebViewUtils";

type Props = BankIDCallbackWebProps & {
  productColor?: string | null;
  productContrastColor?: string | null;
  translations: {
    title: string;
    backButton: string;
  };
  onBackClick: () => void;
};

export const BankIDCallback: FunctionComponent<Props> = props => {
  const { error } = useAppSelector(s => s.bankID);
  const {
    productColor,
    productContrastColor,
    translations,
    onError,
    onBackClick,
  } = props;

  const useMobileFlow = useMemo(() => isWebView(), []);

  useEffect(() => {
    document.title = translations.title;
  }, [translations.title]);

  useEffect(() => {
    if (error) {
      onError();
    }
  }, [error, onError]);

  return (
    <UnauthenticatedLayout title={translations.title}>
      <Box marginBottom="auto">
        <PageTitle>{translations.title}</PageTitle>
        {useMobileFlow && <BankIDCallbackMobile {...props} />}
        {!useMobileFlow && <BankIDCallbackWeb {...props} />}
      </Box>

      <PrimaryButton
        hexColor={productContrastColor}
        hexBackgroundColor={productColor}
        fullWidth
        onClick={() => onBackClick()}
      >
        {translations.backButton}
      </PrimaryButton>
    </UnauthenticatedLayout>
  );
};
