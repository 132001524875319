import { Box, Typography } from "@mui/material";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useBankIDCallbackParams } from "Hooks/BankID/useBankIDCallbackParams";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { FunctionComponent, useCallback, useEffect } from "react";
import { processBankIDNativeCallbackAsync } from "State/BankID/ProcessBankIDNativeCallbackState";
import { Resources, useResource } from "Translations/Resources";

const MOBILE_APP_REDIRECT_URL = "investika://callback";

const PageResources = Resources.BankID.NativeCallback;

export const BankIDNativeCallbackPage: FunctionComponent = () => {
  const { t } = useResource();
  const { accessToken, state: processID, error } = useBankIDCallbackParams();

  const dispatch = useAppDispatch();

  const redirectFromWebToMobile = useCallback(() => {
    window.location.href = MOBILE_APP_REDIRECT_URL;
  }, []);

  useEffect(() => {
    if (error) {
      redirectFromWebToMobile();
      return;
    }

    if (!accessToken || !processID) {
      redirectFromWebToMobile();
      return;
    }

    dispatch(
      processBankIDNativeCallbackAsync.request({
        processID,
        accessToken,
        error,
        redirectFromWebToMobile,
      }),
    );
  }, [processID, accessToken, error, dispatch, redirectFromWebToMobile]);

  return (
    <UnauthenticatedLayout title={t(PageResources.Title)}>
      <Box marginBottom="auto">
        <PageTitle>{t(PageResources.Title)}</PageTitle>
        <Typography>{t(PageResources.Description)}</Typography>
      </Box>
      <PrimaryButton fullWidth onClick={() => redirectFromWebToMobile()}>
        {t(PageResources.RedirectFromWebToMobile)}
      </PrimaryButton>
    </UnauthenticatedLayout>
  );
};
