import * as React from "react";
export const TransferDipIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M9.9999 0.399902C7.34566 0.399902 4.94918 1.52662 3.27021 3.32725C3.23295 3.36539 3.20369 3.4106 3.18415 3.46022C3.16461 3.50984 3.15519 3.56286 3.15645 3.61617C3.1577 3.66949 3.16959 3.72201 3.19144 3.77066C3.21328 3.8193 3.24463 3.86309 3.28364 3.89945C3.32266 3.9358 3.36854 3.96399 3.41861 3.98236C3.46867 4.00072 3.5219 4.00889 3.57517 4.00639C3.62844 4.00388 3.68067 3.99075 3.72878 3.96777C3.7769 3.94479 3.81994 3.91242 3.85537 3.87256C5.3892 2.22759 7.57174 1.1999 9.9999 1.1999C12.677 1.1999 15.054 2.45219 16.5921 4.3999H13.9999C13.9469 4.39915 13.8943 4.40895 13.8451 4.42871C13.7959 4.44848 13.7511 4.47782 13.7134 4.51504C13.6756 4.55226 13.6457 4.5966 13.6252 4.64551C13.6047 4.69441 13.5942 4.74689 13.5942 4.7999C13.5942 4.85291 13.6047 4.90539 13.6252 4.9543C13.6457 5.0032 13.6756 5.04755 13.7134 5.08477C13.7511 5.12198 13.7959 5.15133 13.8451 5.17109C13.8943 5.19086 13.9469 5.20065 13.9999 5.1999H17.3796H17.9999V1.1999C18.0006 1.14639 17.9906 1.09327 17.9705 1.04369C17.9503 0.994116 17.9204 0.94909 17.8825 0.911282C17.8446 0.873473 17.7995 0.84365 17.7499 0.82358C17.7003 0.803509 17.6472 0.793598 17.5937 0.794434C17.4877 0.79609 17.3867 0.839714 17.3129 0.915731C17.2391 0.991749 17.1985 1.09395 17.1999 1.1999V3.87959C15.514 1.76074 12.915 0.399902 9.9999 0.399902ZM9.9999 4.7999C5.80727 4.7999 2.3999 8.20728 2.3999 12.3999C2.3999 16.5925 5.80727 19.9999 9.9999 19.9999C14.1925 19.9999 17.5999 16.5925 17.5999 12.3999C17.5999 8.20728 14.1925 4.7999 9.9999 4.7999ZM9.9999 5.5999C13.7602 5.5999 16.7999 8.63963 16.7999 12.3999C16.7999 16.1602 13.7602 19.1999 9.9999 19.1999C6.23963 19.1999 3.1999 16.1602 3.1999 12.3999C3.1999 8.63963 6.23963 5.5999 9.9999 5.5999Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
