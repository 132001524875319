import { ChartTooltip } from "Components/Charts/ChartTooltip";
import {
  LegendContent,
  LegendContentProps,
} from "Components/Charts/LegentContent";
import { Colors } from "Components/Layout/Themes/Colors";
import { formatDate } from "Utils/DateUtils";
import { nameof } from "Utils/ObjectUtils";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  ReferenceLine,
  Legend,
  Rectangle,
  Area,
  Tooltip,
} from "recharts";

const Y_AXIS_WIDTH = 80;

const X_AXIS_BAR_WIDTH = 13;
const X_AXIS_BAR_RADIUS = 10;
const X_AXIS_BAR_MAX_GAP = 1;
const X_AXIS_BAR_MIN_GAP = 0;
const X_AXIS_BAR_GAP_DEC = 5;

const barGradientId = "UlUE7WiPFB";
const yRangeGradientId = "gradientUnderLine";

export type ChartDataProps = {
  deposit: number;
  expectedYieldMax: number;
  expectedYieldMin: number;
  period: number;
};

const CustomBar = (props: { payload: ChartDataProps }) => {
  return <Rectangle {...props} fill={`url(#${barGradientId}`} />;
};

const formatNumber = (number: number) => {
  const parts = String(number).split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1]
    ? String(Math.round(Number("0." + parts[1]) * 10))
    : "0";
  return Number(`${integerPart}.${decimalPart}`);
};

const tickFormatter = (value: any) => {
  const digits = Math.floor(Math.log10(Math.round(value)));

  if (digits < 3) {
    return String(Math.round(value));
  }

  if (digits > 8) {
    return `${formatNumber(value / 1_000_000_000).toLocaleString()} mld`;
  }

  if (digits > 5) {
    return `${formatNumber(value / 1_000_000).toLocaleString()} mil`;
  }

  return `${formatNumber(value / 1_000).toLocaleString()} tis`;
};

type Props = {
  data: ChartDataProps[];
  isYieldLineDashed: boolean;
  currency?: string;
  startDate?: Date;
  endDate?: Date;
  isExpectedYield?: boolean;
  isAlignedLeft?: boolean;
  color?: string;
} & LegendContentProps;

export type ChartData = ChartDataProps & {
  yRange: [number, number];
};

export const Chart: FunctionComponent<Props> = ({
  data,
  lineLabel,
  barLabel,
  isYieldLineDashed,
  currency,
  startDate,
  endDate,
  isExpectedYield,
  isAlignedLeft = false,
  color,
}) => {
  const wrapperElementRef = useRef<HTMLDivElement>(null);
  const [isWrapperElementInit, setIsWrapperElementInit] = useState(false);

  const targetValue = useMemo(() => {
    return data[data.length - 1]?.expectedYieldMax;
  }, [data]);

  const CustomLineDot = (props: { payload?: ChartDataProps } = {}) => {
    if (props?.payload?.expectedYieldMax !== targetValue) {
      return null;
    }

    return null;
  };

  const depositReduction = isExpectedYield ? 0.2 : 0;

  const transformedData: ChartData[] = data.map(item => ({
    ...item,
    yRange: [
      item.deposit - item.deposit * depositReduction,
      item.expectedYieldMax,
    ],
  }));

  const calculateXAxisRightPadding = (
    gridWidth: number,
    dataLength: number,
  ) => {
    if (dataLength === 1) {
      return (
        gridWidth -
        X_AXIS_BAR_WIDTH -
        X_AXIS_BAR_RADIUS * 2 -
        X_AXIS_BAR_MAX_GAP / 2
      );
    }

    const totalBarWidth =
      dataLength * X_AXIS_BAR_WIDTH + dataLength * X_AXIS_BAR_RADIUS * 2;

    for (
      let barGap = X_AXIS_BAR_MAX_GAP;
      barGap > X_AXIS_BAR_MIN_GAP;
      barGap = barGap - X_AXIS_BAR_GAP_DEC
    ) {
      const totalGapWidth = (dataLength - 1) * barGap;
      const availableSpace = gridWidth - totalBarWidth - totalGapWidth;
      if (availableSpace >= 0) {
        return availableSpace;
      }
    }

    return 0;
  };

  const handleViewportResize = useCallback(() => {
    if (isAlignedLeft && wrapperElementRef.current) {
      const gridWidth =
        wrapperElementRef.current.getBoundingClientRect().width - Y_AXIS_WIDTH;

      return calculateXAxisRightPadding(gridWidth, data.length);
    }

    return 0;
  }, [data.length, isAlignedLeft]);

  useEffect(() => {
    if (wrapperElementRef.current) {
      setIsWrapperElementInit(true);
    }
  }, [wrapperElementRef]);

  return (
    <div ref={wrapperElementRef}>
      {isWrapperElementInit && (
        <ResponsiveContainer height={275} width="100%">
          <ComposedChart data={transformedData} margin={{ top: 25 }}>
            <defs>
              <linearGradient id={barGradientId} gradientTransform="rotate(90)">
                <stop offset="20%" stopColor="#FFFFFF" />
                <stop offset="100%" stopColor="rgba(255,255,255, 0.11)" />
              </linearGradient>

              <linearGradient
                id={yRangeGradientId}
                gradientTransform="rotate(90)"
              >
                <stop
                  offset="5%"
                  stopColor={(color ?? Colors.PrimaryMain) + "99"}
                />
                <stop
                  offset="40%"
                  stopColor={(color ?? Colors.PrimaryMain) + "30"}
                />
                <stop
                  offset="90%"
                  stopColor={(color ?? Colors.PrimaryMain) + "00"}
                />
              </linearGradient>
            </defs>

            <YAxis
              width={Y_AXIS_WIDTH}
              axisLine={false}
              tickMargin={10}
              tickSize={0}
              tick={{ dy: -10 }}
              type="number"
              tickFormatter={tickFormatter}
            />
            <XAxis
              axisLine={false}
              tickMargin={10}
              tickSize={0}
              dataKey="period"
              padding={{ right: handleViewportResize() }}
            />

            <ReferenceLine
              stroke={Colors.PrimaryMain}
              y={targetValue}
              strokeDasharray="10 10"
              opacity={0.5}
              label={{
                value: targetValue,
                position: "left",
                dy: 10,
                dx: -15,
                fill: Colors.PrimaryMain,
                formatter: tickFormatter,
              }}
            />

            <CartesianGrid
              vertical={false}
              strokeDasharray="10 10"
              strokeLinecap="round"
              opacity="0.1"
              stroke={Colors.SecondaryMain}
            />

            <Bar
              dataKey="deposit"
              barSize={X_AXIS_BAR_WIDTH}
              fill={`url(#${barGradientId}`}
              radius={X_AXIS_BAR_RADIUS}
              shape={CustomBar}
            />

            <Line
              type="monotone"
              dataKey={nameof<ChartDataProps>("expectedYieldMax")}
              stroke={color ?? Colors.PrimaryMain}
              strokeDasharray={isYieldLineDashed ? "5 2" : undefined}
              strokeWidth={2}
              dot={<CustomLineDot />}
            />

            {isExpectedYield && (
              <Area
                type="monotone"
                dataKey={nameof<ChartData>("yRange")}
                fill={`url(#${yRangeGradientId})`}
                stroke="none"
              />
            )}

            <Area
              type="monotone"
              dataKey={nameof<ChartDataProps>("expectedYieldMin")}
              stroke="transparent"
              fillOpacity={0}
            />

            {!!currency && (
              <Tooltip
                content={data => (
                  <ChartTooltip currency={currency} data={data} />
                )}
              />
            )}
            <Legend
              margin={{ top: 1000 }}
              wrapperStyle={{
                paddingTop: "20px",
              }}
              content={
                <LegendContent
                  lineLabel={lineLabel}
                  barLabel={barLabel}
                  color={color}
                />
              }
            />
            {startDate && (
              <text
                x={10}
                y={275}
                dy={-30}
                fontSize={12}
                fill={Colors.SecondaryMain}
              >
                {formatDate(startDate)}
              </text>
            )}
            {endDate && (
              <text
                x="100%"
                y={275}
                dy={-30}
                textAnchor="end"
                fontSize={12}
                fill={Colors.SecondaryMain}
              >
                {formatDate(endDate)}
              </text>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
