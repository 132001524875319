import { FunctionComponent, PropsWithChildren } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { Box, Typography } from "@mui/material";

type DigTagWrapperProps = PropsWithChildren<{
  showPlain?: boolean;
  inverse?: boolean;
  moveLeft?: number;
}>;

const StyledDipTagWrapper = styled.div<{
  $inverse?: boolean;
  $moveLeft?: number;
}>`
  height: 20px;
  width: fit-content;
  max-width: 100%;
  border-radius: ${({ $inverse }) =>
    $inverse ? "0 0 16px 16px" : "16px 16px 0 0"};
  padding: 0 24px;
  background-color: ${({ theme }) => theme.palette.dip.main};
  overflow: hidden;
  margin-right: 24px;
  text-overflow: ellipsis;
  position: relative;
  left: ${({ $moveLeft, theme }) => theme.spacing($moveLeft ?? 0)};
`;

const ChildrenWrapper = styled.div<{ $inverse?: boolean }>`
  // disable border radius for the first child as we stick the DIP label to it
  & > * {
    ${({ $inverse }) =>
      $inverse
        ? "border-bottom-left-radius: 0 !important;"
        : "border-top-left-radius: 0 !important;"}
  }
`;

export const DipTagWrapper: FunctionComponent<DigTagWrapperProps> = ({
  children,
  showPlain,
  inverse,
  moveLeft,
}) => {
  const { t } = useResource();

  if (showPlain) {
    return <>{children}</>;
  }

  return (
    <div>
      {/* this padding is preventing the DIP label from extend to the right edge of the children 
      (it look better) */}
      <Box paddingRight={4}>
        <StyledDipTagWrapper $inverse={inverse} $moveLeft={moveLeft}>
          <Typography
            fontWeight={500}
            variant="body1"
            component="span"
            noWrap
            maxWidth=""
          >
            {t(Resources.Common.DipName)}
          </Typography>
        </StyledDipTagWrapper>
      </Box>
      <ChildrenWrapper $inverse={inverse}>{children}</ChildrenWrapper>
    </div>
  );
};
