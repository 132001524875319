import { yupResolver } from "@hookform/resolvers/yup";
import { AddressType, EditPersonalDataVerificationType } from "Api/Api";
import { AddressesForm } from "Components/More/PersonalData/EditPersonalData/Modelling/components/AddressesForm";
import { ContactInformationForm } from "Components/More/PersonalData/EditPersonalData/Modelling/components/ContactInfoForm";
import { DocumentsForm } from "Components/More/PersonalData/EditPersonalData/Modelling/components/DocumentsForm";
import { PersonalDataForm } from "Components/More/PersonalData/EditPersonalData/Modelling/components/PersonalDataForm";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useYupFormSchema } from "Hooks/More/PersonalData/EditPersonalData/Modelling/useYupFormSchema";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  editPersonalDataAsync,
  EditPersonalDataFormModel,
  EditPersonalDataSteps,
  setEditPersonalDataActualStep,
  setEditPersonalDataFormData,
} from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { Resources, useResource } from "Translations/Resources";

const PageResource = Resources.More.PersonalData.EditPersonalData;
const ComponentResources = PageResource.Modelling;

export const ModellingStep: FunctionComponent = _ => {
  const { t } = useResource();
  const { lastRequest: defaultValues } = useAppSelector(
    s => s.client.personalData.edit,
  );

  const { data: client } = useAppSelector(s => s.client.client);
  const { contactInfo } = useAppSelector(s => s.profile.contactInfo);
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();

  const dispatch = useAppDispatch();

  const [verificationType] = useState<EditPersonalDataVerificationType>(
    defaultValues?.verificationType ?? EditPersonalDataVerificationType.Manual,
  );

  const [phone, email] = useMemo(
    () => [contactInfo?.client?.phone, contactInfo?.client?.email],
    [contactInfo],
  );

  const nationalities = useMemo(() => {
    if (client?.citizenship && client?.otherCitizenships) {
      return Array.from(
        new Set([client.citizenship, ...client.otherCitizenships]),
      );
    }

    return client?.citizenship ? [client.citizenship] : [];
  }, [client?.citizenship, client?.otherCitizenships]);

  const permanentAddress = useMemo(
    () => client?.addresses.find(x => x.type === AddressType.Domicile),
    [client?.addresses],
  );

  const pageTitle = usePageTitle();
  const schema = useYupFormSchema(nationalities, phone, email);
  const form = useForm<EditPersonalDataFormModel>({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      ...defaultValues,
      personalData: {
        lastName:
          defaultValues.personalData.lastName || client?.name.lastName || "",
      },
      contactInfo: {
        ...defaultValues.contactInfo,
        phone: defaultValues.contactInfo.phone || phone || "",
        email: defaultValues.contactInfo.email || email || "",
      },
      permanentAddress: {
        ...defaultValues.permanentAddress,
        streetName:
          defaultValues.permanentAddress.streetName ||
          permanentAddress?.streetName ||
          "",
        streetNumber:
          defaultValues.permanentAddress.streetNumber ||
          permanentAddress?.streetNumber ||
          "",
        streetConscriptionNumber:
          defaultValues.permanentAddress.streetConscriptionNumber ||
          permanentAddress?.streetConscriptionNumber ||
          "",
        postalCode:
          defaultValues.permanentAddress.postalCode ||
          permanentAddress?.zip ||
          "",
        city:
          defaultValues.permanentAddress.city || permanentAddress?.city || "",
        country:
          defaultValues.permanentAddress.country ||
          permanentAddress?.country ||
          "",
      },
    },
  });

  const {
    handleSubmit,
    formState: { isValid, isValidating },
  } = form;

  // const phoneValue = watch("contactInfo.phone");
  // const emailValue = watch("contactInfo.email");

  // const isPhoneDirty = useMemo(
  //   () => phoneValue.replace(/\D/g, "") !== phone?.replace(/\D/g, ""),
  //   [phone, phoneValue],
  // );

  // const isEmailDirty = emailValue !== email;

  const onSubmit = useCallback(
    (formData: EditPersonalDataFormModel) => {
      dispatch(
        setEditPersonalDataFormData({
          ...formData,
          isBiometry: canUseBiometricsForSigning,
        }),
      );

      // if (isPhoneDirty || isEmailDirty) {
      //   dispatch(
      //     setEditPersonalDataActualStep(
      //       EditPersonalDataSteps.ContactInfoVerification,
      //     ),
      //   );

      //   return;
      // }

      dispatch(setEditPersonalDataActualStep(EditPersonalDataSteps.Signature));
      dispatch(
        editPersonalDataAsync.request({
          ...formData,
          isBiometry: canUseBiometricsForSigning,
        }),
      );
    },
    [canUseBiometricsForSigning, dispatch],
  );

  return (
    <>
      <div>
        <PageTitle showOnDesktop showOnMobile={false}>
          {pageTitle}
        </PageTitle>

        <FormProvider {...form}>
          <PersonalDataForm verificationType={verificationType} />
          <ContactInformationForm />
          <AddressesForm verificationType={verificationType} />
          <DocumentsForm
            nationalities={nationalities}
            verificationType={verificationType}
          />
        </FormProvider>
      </div>

      <BlSlideButton
        minSlideVelocity={0.95}
        minSlideWidth={0.95}
        onSlideDone={handleSubmit(onSubmit)}
        trackingTag={t(ComponentResources.Submit)}
        disabled={!isValid}
        isLoading={isValidating}
      >
        {t(ComponentResources.Submit)}
      </BlSlideButton>
    </>
  );
};
