import { Box } from "@mui/material";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { useSexCodeList } from "Hooks/Inputs/useSexCodeList";
import { PersonalDataFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";

export const SexRadioButtons: FunctionComponent = _ => {
  const { t } = useResource();
  const {
    control,
    formState: { errors },
  } = useFormContext<PersonalDataFormModel>();
  const isBankIDVerified = useIsBankIDVerified();

  const sexList = useSexCodeList();

  return (
    <Box marginTop={3}>
      <RadioButtons
        name="sex"
        label={t(Resources.Forms.PersonalData.Gender)}
        codeList={sexList}
        control={control}
        errors={errors}
        disabled={isBankIDVerified}
      />
    </Box>
  );
};
