import { useAppSelector } from "Hooks/useAppSelector";
import { LookupItem } from "Models/LookupItem";
import { useMemo } from "react";

type UseBanksType = () => LookupItem[];

export const useBankCurrenciesList: UseBanksType = () => {
  const { data } = useAppSelector(s => s.codeList.currencies);

  return useMemo<LookupItem<string>[]>(() => {
    if (!data) {
      return [];
    }

    return data.map(({ code, name }) => ({
      Name: name,
      Value: code,
    }));
  }, [data]);
};
