import { SignatureForm } from "Components/ContractBankConnections/Shared/SignatureForm";
import { usePageTitle } from "Hooks/ContractExchangeAssets/usePageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import {
  ExchangeAssetsStep,
  setExchangeAssetsStep,
} from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  contractID: number;
};

const ComponentResources = Resources.Contract.ExchangeAssets.Signature;

export const SignatureStep: FunctionComponent<Props> = ({ contractID }) => {
  const { t } = useResource();
  const { isLoading, phone, signatureHash, error } = useAppSelector(
    s => s.contracts.exchangeAssets,
  );

  const title = usePageTitle();
  const dispatch = useAppDispatch();

  return (
    <SignatureForm
      isLoading={isLoading}
      contractID={contractID}
      signatureHash={signatureHash || undefined}
      sms={{
        phone: phone ?? "",
      }}
      biometrics={{
        signatureType: BiometricsSignatureType.ExchangeAssets,
      }}
      translations={{
        isLoading: t(ComponentResources.Working),
        sms: {
          title: title,
          codeSent: t(Resources.Signature.SmsSent),
          codeDetermination: t(ComponentResources.CodeDetermination),
          verificationError: t(ComponentResources.Errors.Verification),
          button: t(ComponentResources.Submit),
        },
        biometrics: {
          title: title,
          signatureError: t(ComponentResources.Errors.Biometrics),
          signatureInProcessError: t(
            ComponentResources.Errors.BiometricsInProcess,
          ),
        },
        signatureHashNotFoundError: t(
          ComponentResources.Errors.SignatureHashNotFound,
        ),
      }}
      error={error || undefined}
      onSuccess={() =>
        dispatch(setExchangeAssetsStep(ExchangeAssetsStep.Success))
      }
    />
  );
};
