import { useSearchParams } from "react-router-dom";
import { InvestmentDetailTabs } from "./useMenuTabs";

const DEFAULT_TAB = InvestmentDetailTabs.Overview;

export const useActualTab = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: InvestmentDetailTabs.Overview,
  });
  const actualTab = (searchParams.get("tab") ??
    DEFAULT_TAB) as InvestmentDetailTabs;

  const setTab = (
    tab: InvestmentDetailTabs,
    params: Record<string, string> = {},
  ) => {
    setSearchParams({ tab, ...params });
  };

  return [actualTab, setTab] as const;
};
