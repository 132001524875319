import { TextFieldProps } from "@mui/material";
import { StyledFormControl } from "Components/Shared/FormStyles";
import { TextInput } from "Components/Shared/Inputs/TextInput";
import { get } from "lodash-es";
import { ReactNode } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { type IMask } from "react-imask";

type InputProps<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  label?: ReactNode;
  control: Control<FormType, object>;
  errors: FieldErrors<FormType>;
  formInfo?: { [key: string]: string };
  maxLength?: number;
  disabled?: boolean;
  mask?: undefined;
  type?: string;
  max?: number;
  min?: number;
  scale?: number;
  isNumber?: boolean;
  formControlClass?: "long" | "medium" | "tiny";
  unit?: string;
  inputEndAdornment?: JSX.Element;
  inputStartAdornment?: JSX.Element;
  valueTransformer?: (value: string) => unknown;
  inputValueTransformer?: (value: string) => string;
  allowEmptyNumber?: boolean;
  pattern?: string;
  hasTracking?: boolean;
  tagTrackingEnhancer?: (value: string | null | undefined) => string;
  isImaskNumber?: boolean;
  defaultValue?: any;
  onChange?: (value: any) => void;
};

export type Props<FormType extends FieldValues> =
  | (TextFieldProps & InputProps<FormType>)
  | ({
      mask:
        | string
        | NumberConstructor
        | RegExp
        | Date
        | DateConstructor
        | IMask.AnyMaskedOptions
        | undefined;
      min?: number | Date;
      max?: number | Date;
      blocks?: object;
      autofix?: boolean;
      overwrite?: boolean;
      unmask?: boolean;
      isMaskLazy?: boolean;
      maskPlaceholder?: string;
      imaskPattern?: string;
      defaultValue?: any;
      parse?: (str: string) => any;
      format?: (value: unknown) => string;
      dispatch?: IMask.AllMaskedOptions["dispatch"];
    } & Omit<TextFieldProps, "onChange"> &
      Omit<InputProps<FormType>, "min" | "max" | "mask">);

export const BlInputFormField = <T extends object>(props: Props<T>) => {
  const {
    name,
    control,
    errors,
    formInfo,
    maxLength,
    label,
    disabled,
    mask,
    type,
    max,
    min,
    scale,
    isNumber,
    formControlClass,
    unit,
    valueTransformer,
    inputValueTransformer,
    allowEmptyNumber = true,
    hasTracking,
    tagTrackingEnhancer,
    isImaskNumber,
    defaultValue,
    onChange: propsOnChange,
    ...rest
  } = props;

  const transform =
    isNumber || type === "number"
      ? {
          input: (value: number) =>
            allowEmptyNumber && (isNaN(value) || !value)
              ? ""
              : (value || 0).toString(),
          output: (e: { target: { value: string } }) => {
            const output = Number(e.target.value);
            return isNaN(output) ? 0 : output;
          },
        }
      : {
          input: (value: any) =>
            inputValueTransformer ? inputValueTransformer(value) : value ?? "",
          output: (e: { target: { value: string } }) => {
            return valueTransformer
              ? valueTransformer(e.target.value)
              : e.target.value;
          },
        };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, onBlur } }) => {
          return (
            <StyledFormControl
              className={`${formControlClass} ${!label ? "no-label" : ""}`}
              $fullWidth={rest.fullWidth}
            >
              <TextInput
                value={transform.input(value as any)}
                name={name.toString()}
                label={label}
                fieldError={get(errors, name as string)}
                maxLength={maxLength}
                disabled={disabled}
                onBlur={onBlur}
                mask={mask}
                type={type ?? "text"}
                max={max}
                min={min}
                scale={scale}
                unit={unit}
                onInputChange={event => {
                  const value = transform.output(event);

                  onChange(value);
                  propsOnChange && propsOnChange(value);
                }}
                onMaskInputChange={v => {
                  const value = isNumber
                    ? Number(v)
                    : transform.output({ target: { value: v } });

                  onChange(value);
                  propsOnChange && propsOnChange(value);
                }}
                hasTracking={hasTracking}
                tagTrackingEnhancer={tagTrackingEnhancer}
                {...rest}
              />
            </StyledFormControl>
          );
        }}
      />
    </>
  );
};
