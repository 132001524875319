import { CardContent, Grid, Link, Typography } from "@mui/material";
import {
  StyledDipCard,
  StyledSwitch,
} from "Components/Shared/StyledComponents";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { setIsDip } from "State/Contracts/Modeling/ModelingState/ModelingStateState";
import { Resources, useResource } from "Translations/Resources";
import { track } from "Utils/TrackingUtils";
import { useState, type FunctionComponent, useEffect } from "react";

const ComponentResources = Resources.Investments.FundSelection.DIP.Card;

type Props = { onDetail: () => void };

export const DipCard: FunctionComponent<Props> = ({ onDetail }) => {
  const { t } = useResource();
  const isDipSelected = useIsDipSelected();
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState(isDipSelected);

  useEffect(() => {
    dispatch(setIsDip({ isDip: isChecked }));
  }, [isChecked, dispatch]);

  const handleDetail = () => onDetail();

  return (
    <StyledDipCard>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item xs={9} md={10} display="flex" flexDirection="column">
            <Typography
              fontSize={16}
              fontWeight={700}
              color="secondary"
              marginBottom={1}
            >
              {t(ComponentResources.Title)}
            </Typography>
            <Typography marginBottom={2} color="secondary">
              {t(ComponentResources.Description)}
            </Typography>
            <Link onClick={handleDetail}>{t(ComponentResources.Link)}</Link>
          </Grid>
          <Grid
            item
            xs={3}
            md={2}
            display="flex"
            flexDirection="column"
            alignItems="end"
            justifyContent="center"
          >
            <StyledSwitch
              checked={isChecked}
              onChange={() => {
                track({
                  category: "Switch",
                  event: "DIP",
                  action: "Click",
                  value: String(!isChecked),
                });

                setIsChecked(!isChecked);
              }}
              color="dip"
            />
          </Grid>
        </Grid>
      </CardContent>
    </StyledDipCard>
  );
};
