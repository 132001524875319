import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PhoneInput } from "Components/Shared/Inputs/SpecificInputs/PhoneInput";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PageTitle } from "Components/Shared/PageTitle";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useYupFormSchema } from "Hooks/Profile/TwoFactorVerification/Modelling/useYupFormSchema";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import useHandleServerError from "Hooks/useHandleServerError";
import { FunctionComponent, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { postContactConfirmationAsync } from "State/Client/ContactConfirmation/PostContactConfirmation";
import {
  setTwoFactorVerificationActualStep,
  TwoFactorVerificationFormModel,
  TwoFactorVerificationSteps,
} from "State/Profile/TwoFactorVerification/TwoFactorVerificationReducer";
import { Resources, useResource } from "Translations/Resources";
import { formatPhoneNumber } from "Utils/PhoneUtils";

const PageResources = Resources.Profile.Settings.TwoFactorVerification;
const ComponentResources = PageResources.Modelling;

export const ModellingStep: FunctionComponent = () => {
  const { t } = useResource();
  const { isLoading: isInitializing, error: serverError } = useAppSelector(
    s => s.client.postContactConfirmation,
  );

  const {
    isLoading: isLoadingContactInfo,
    contactInfo,
    error,
  } = useAppSelector(s => s.profile.contactInfo);

  const dispatch = useAppDispatch();

  const schema = useYupFormSchema();
  const form = useForm<TwoFactorVerificationFormModel>({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isValidating, errors },
  } = form;

  const isLoading = isLoadingContactInfo && !contactInfo;
  const phone = contactInfo?.user.phone || "";

  const onSuccess = useCallback(() => {
    dispatch(
      setTwoFactorVerificationActualStep(
        TwoFactorVerificationSteps.Verification,
      ),
    );
  }, [dispatch]);

  const onSubmit = useCallback(
    (formData: TwoFactorVerificationFormModel) => {
      if (formData.phone) {
        dispatch(
          postContactConfirmationAsync.request({
            mobilePhoneNumber: formData.phone,
            onSuccess,
          }),
        );
      }
    },
    [onSuccess, dispatch],
  );

  useHandleServerError({
    form,
    serverError,
    resource: Resources.Validation.ServerError,
    key: "phone",
  });

  return (
    <LoadingWrapper
      isLoading={isLoading}
      error={error}
      skeleton={
        <>
          <Stack gap={2}>
            <PrimarySkeleton height={75} fullWidth fullHeight={false} />
            <PrimarySkeleton height={75} fullWidth fullHeight={false} />
          </Stack>
          <PrimarySkeleton height={75} fullWidth fullHeight={false} />
        </>
      }
    >
      <Stack gap={2}>
        <PageTitle showOnDesktop showOnMobile={false}>
          {t(PageResources.Title)}
        </PageTitle>

        <Typography>{t(ComponentResources.Description)}</Typography>

        <Typography>
          <Typography component="span">
            {t(ComponentResources.SignInNotice[1])}
          </Typography>
          <Typography component="span" fontWeight={700}>
            {formatPhoneNumber(phone)}
          </Typography>
          <Typography component="span">
            {t(ComponentResources.SignInNotice[2])}
          </Typography>
        </Typography>

        <FormProvider {...form}>
          <PhoneInput
            control={control}
            name={"phone"}
            label={t(ComponentResources.PhoneLabel)}
            errors={errors}
          />
        </FormProvider>
      </Stack>

      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoading || isValidating || isInitializing}
        disabled={!isValid}
        trackingTag={t(ComponentResources.Submit)}
      >
        {t(ComponentResources.Submit)}
      </PrimaryButton>
    </LoadingWrapper>
  );
};
