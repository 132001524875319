import { InputAdornment, Stack, Typography } from "@mui/material";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { ComponentTitleWrapper } from "Components/Shared/ComponentTitleWrapper";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { PageTitle } from "Components/Shared/PageTitle";
import { useDesktop } from "Hooks/useDesktop";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { getLocalizedCurrencyCode } from "Utils/CurrencyUtils";
import { WarningText } from "Components/Shared/WarningText";
import { ProductSelectors } from "Components/ContractExchangeAssets/components/ProductSelectors";
import {
  ContractExchangeAssetsMethod,
  ContractExchangeAssetsRequest,
  ContractExchangeAssetsType,
  ContractOverviewPortfolioItemDto,
  ContractTypeCode,
  CurrencyCode,
  ProductFeeRateCalculationType,
} from "Api/Api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useAppSelector } from "Hooks/useAppSelector";
import { RateLabel } from "Components/Contract/RateLabel";
import { usePageTitle } from "Hooks/ContractExchangeAssets/usePageTitle";
import { useFormData } from "Hooks/ContractExchangeAssets/useFormData";
import {
  MIN_EXCHANGE_ASSETS_AMOUNT_CZK,
  MIN_EXCHANGE_ASSETS_AMOUNT_EUR,
  useYupFormSchema,
} from "Hooks/ContractExchangeAssets/useYupFormSchema";
import {
  exchangeAssetsAsync,
  initialExchangeAssetsState,
} from "State/Contracts/ExchangeAssets/ExchangeAssetsState";

type Props = {
  isin: string;
  contractID: number;
  contractTypeCode: ContractTypeCode;
  productAllowedTransfers: string[];
  portfolioItem: ContractOverviewPortfolioItemDto;
  isDip: boolean;
};

const AmountDecimalPlaces = 0;

const ComponentResources = Resources.Contract.ExchangeAssets.Modelling;

export const ModellingStep: FunctionComponent<Props> = ({
  isin,
  contractID,
  contractTypeCode,
  productAllowedTransfers,
  portfolioItem,
  isDip,
}) => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();
  const { isLoading } = useAppSelector(s => s.contracts.exchangeAssets);

  const title = usePageTitle();
  const dispatch = useAppDispatch();
  const defaultValues = useFormData();
  const schema = useYupFormSchema(portfolioItem);

  const {
    control,
    watch,
    setValue,
    trigger,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<ContractExchangeAssetsRequest>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const targetIsin = watch("targetIsin");
  const method = watch("method");
  const amountOrPieces = watch("amountOrPieces");
  const isExchangeAll = watch("isExchangeAll");

  const decimalPlaces = useMemo(
    () =>
      method === ContractExchangeAssetsMethod.Pieces ? AmountDecimalPlaces : 0,
    [method],
  );

  const onSubmit = (formData: ContractExchangeAssetsRequest) => {
    dispatch(
      exchangeAssetsAsync.request({
        ...formData,
        contractID,
        isBiometry: canUseBiometricsForSigning,
      }),
    );
  };

  const defaultTargetIsin = useMemo(() => {
    if (productAllowedTransfers.length === 1) {
      const defaultTargetIsin = productAllowedTransfers[0];

      setValue("targetIsin", defaultTargetIsin);
      trigger("targetIsin");

      return defaultTargetIsin;
    }

    return initialExchangeAssetsState.formData.targetIsin;
  }, [productAllowedTransfers, setValue, trigger]);

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [isValid, clearErrors]);

  const handleMethodChange = (value: ContractExchangeAssetsMethod) => {
    if (value === ContractExchangeAssetsMethod.Pieces) {
      setValue("amountOrPieces", 1);
    }
    if (value === ContractExchangeAssetsMethod.Amount) {
      setValue(
        "amountOrPieces",
        portfolioItem.currency === CurrencyCode.CZK
          ? MIN_EXCHANGE_ASSETS_AMOUNT_CZK
          : MIN_EXCHANGE_ASSETS_AMOUNT_EUR,
      );
    }

    handleIsExchangeAllChange(isExchangeAll, value);
  };

  const handleIsExchangeAllChange = (
    isExchangeAll: boolean,
    method: ContractExchangeAssetsMethod,
  ) => {
    if (isExchangeAll) {
      if (
        method === ContractExchangeAssetsMethod.Pieces &&
        !!portfolioItem.quantity
      ) {
        setValue("amountOrPieces", portfolioItem.quantity);
      }

      if (
        method === ContractExchangeAssetsMethod.Amount &&
        !!portfolioItem.amount
      ) {
        setValue("amountOrPieces", portfolioItem.amount);
      }
    }
  };

  return (
    <>
      <div>
        <PageTitle showOnMobile={isDesktop}>{title}</PageTitle>
        <ComponentTitleWrapper
          title={t(ComponentResources.FundSelection.Title)}
          marginTop={0}
        >
          <Typography marginBottom={5}>
            {t(ComponentResources.FundSelection.Description)}
          </Typography>
          <ProductSelectors
            isDip={isDip}
            defaultIsin={targetIsin || defaultTargetIsin}
            portfolioItem={portfolioItem}
            allowedTransfers={productAllowedTransfers}
            onSelect={x => {
              setValue("targetIsin", x);
              trigger("targetIsin");
            }}
          />
        </ComponentTitleWrapper>
        <ComponentTitleWrapper title={t(ComponentResources.Parameters.Title)}>
          <Stack>
            <RadioButtons
              label={t(ComponentResources.Parameters.Type.Title)}
              control={control}
              errors={errors}
              name="type"
              codeList={[
                {
                  code: ContractExchangeAssetsType.Single,
                  name: t(ComponentResources.Parameters.Type.Single),
                },
                // {
                //   code: ContractExchangeAssetsType.Periodical,
                //   name: t(ComponentResources.Parameters.Type.Periodical),
                //   disabled: true,
                // },
              ]}
            />

            <RadioButtons
              label={t(ComponentResources.Parameters.Method.Title)}
              control={control}
              errors={errors}
              name="method"
              codeList={[
                {
                  code: ContractExchangeAssetsMethod.Pieces,
                  name: t(ComponentResources.Parameters.Method.Pieces),
                },
                {
                  code: ContractExchangeAssetsMethod.Amount,
                  name: t(ComponentResources.Parameters.Method.Amount),
                },
              ]}
              onChange={handleMethodChange}
            />

            <BlInputFormField
              label={
                method === ContractExchangeAssetsMethod.Amount
                  ? t(ComponentResources.Parameters.Amount.Label)
                  : t(ComponentResources.Parameters.Pieces.Label)
              }
              control={control}
              name="amountOrPieces"
              errors={errors}
              mask={Number}
              scale={decimalPlaces}
              isMaskLazy={false}
              inputProps={{
                inputMode: decimalPlaces ? "decimal" : "numeric",
                pattern: decimalPlaces
                  ? `[0-9]*[.,]?[0-9]{0,${decimalPlaces}}`
                  : "[0-9]*",
              }}
              maskPlaceholder=" "
              max={Number.MAX_SAFE_INTEGER}
              min={0}
              isNumber
              inputEndAdornment={
                <InputAdornment position="end">
                  {method === ContractExchangeAssetsMethod.Amount
                    ? getLocalizedCurrencyCode(portfolioItem.currency)
                    : t(Resources.Common.QuantityUnit)}
                </InputAdornment>
              }
              disabled={isExchangeAll}
              hasTracking
            />

            <RateLabel
              feeRate="single"
              sourceIsin={isin}
              targetIsin={targetIsin}
              sourceContractID={contractID}
              targetContractID={contractID}
              sourceContractTypeCode={contractTypeCode}
              targetContractTypeCode={contractTypeCode}
              amountOrPieces={amountOrPieces}
              isPieces={method === ContractExchangeAssetsMethod.Pieces}
              investmentLengthYears={null}
              calculationType={ProductFeeRateCalculationType.ExchangeAssets}
              translations={{
                single: {
                  label: t(ComponentResources.Parameters.FeeRate.Single.Label),
                },
              }}
            />

            <BlCheckboxFormField
              control={control}
              name="isExchangeAll"
              errors={errors}
              label={t(ComponentResources.Parameters.ExchangeAll)}
              onChange={x => handleIsExchangeAllChange(x, method)}
            />
          </Stack>
        </ComponentTitleWrapper>
      </div>
      <div>
        <WarningText alignItems="center" marginBottom={5}>
          <Typography component="span">
            {t(ComponentResources.Warning)}
          </Typography>
        </WarningText>
        <BlSlideButton
          minSlideVelocity={0.95}
          minSlideWidth={0.95}
          onSlideDone={handleSubmit(onSubmit)}
          trackingTag={t(ComponentResources.Submit)}
          disabled={!isValid}
          isLoading={isLoading}
        >
          {t(ComponentResources.Submit)}
        </BlSlideButton>
      </div>
    </>
  );
};
