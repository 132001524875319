import * as React from "react";
import { ButtonProps } from "@mui/material/Button";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { isNoU, notNoU } from "Utils/ObjectUtils";
import { TrackedButton } from "Components/Shared/Buttons/TrackedButton";
import { getSafeHoverBackground } from "Utils/CssUtils";

export type PrimaryButtonProps = ButtonProps & {
  paddingLeftRight?: number;
  isLoading?: boolean;
  fetchProgress?: number | null;
  small?: boolean;
  trackingTag?: string | null | undefined;
  trackingValue?: string | null | undefined;
  hexColor?: string | null | undefined;
  hexBackgroundColor?: string | null | undefined;
};

const StyledButton = styled(
  ({
    paddingLeftRight,
    fetchProgress,
    ...rest
  }: PrimaryButtonProps & {
    $small?: boolean;
    $hexColor?: string | null | undefined;
    $hexBackgroundColor?: string | null | undefined;
  }) => <TrackedButton {...rest} />,
)`
  padding: ${props =>
    props.theme.spacing(props.$small ? 0.5 : 3, props.paddingLeftRight ?? 2)};
  //width: 220px;
  min-width: 100px;
  border-radius: 100px;
  font-weight: 600;
  border: 2px solid;
  text-transform: none;

  box-shadow: ${props => props.theme.shadow};
  ${props =>
    (isNoU(props.$hexColor) || isNoU(props.$hexBackgroundColor)) &&
    props.color === "primary" &&
    `
  border-color: ${props.theme.palette.primary.main};
  color: ${props.theme.palette.common.black};
  `}

  ${props =>
    (isNoU(props.$hexColor) || isNoU(props.$hexBackgroundColor)) &&
    props.color === "secondary" &&
    `
    color: ${props.theme.palette.text.primary};
    background-color: rgba(0, 0, 0, 0);
    border-color: ${props.theme.palette.text.primary};

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }
  `}

  ${props =>
    notNoU(props.$hexColor) &&
    notNoU(props.$hexBackgroundColor) &&
    `
    color: ${props.$hexColor};
    background-color: ${props.$hexBackgroundColor};
    border-color: ${props.$hexBackgroundColor};

    ${getSafeHoverBackground(props.$hexBackgroundColor)};
  `}

  .MuiButton-label {
    z-index: 1;
  }

  &.Mui-disabled {
    opacity: ${props => (props.fetchProgress ? 1 : 0.5)};

    ${props =>
      (isNoU(props.$hexColor) || isNoU(props.$hexBackgroundColor)) &&
      props.color === "primary" &&
      `
        background-color: ${props.theme.palette.primary.main};
        color: ${props.theme.palette.common.black};
    `}

    ${props =>
      notNoU(props.$hexColor) &&
      notNoU(props.$hexBackgroundColor) &&
      `
        background-color: ${props.$hexBackgroundColor};
        color: ${props.$hexColor};
    `}

    ${props =>
      (isNoU(props.$hexColor) || isNoU(props.$hexBackgroundColor)) &&
      props.color === "secondary" &&
      `
        color: ${props.theme.palette.text.primary};
      `}

    ${props =>
      notNoU(props.$hexColor) &&
      notNoU(props.$hexBackgroundColor) &&
      `
        color: ${props.$hexColor};
      `}
  }
`;

export const StyledLoadingIconWrapper = styled.div<{
  $hexColor?: string | null | undefined;
  $hexBackgroundColor?: string | null | undefined;
}>`
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 100px;

  color: ${props => props.theme.palette.common.black};
  background-color: ${props => props.theme.palette.primary.main};

  ${props =>
    (isNoU(props.$hexColor) || isNoU(props.$hexBackgroundColor)) &&
    props.color === "secondary" &&
    `
      color: ${props.theme.palette.text.primary};
      background-color: ${props.theme.palette.background.default};
    `}

  ${props =>
    notNoU(props.$hexColor) &&
    notNoU(props.$hexBackgroundColor) &&
    `
      color: ${props.$hexColor};
      background-color: ${props.$hexBackgroundColor};
    `}
`;

const StyledProgress = styled(PrimarySkeleton)`
  &.MuiSkeleton-root {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: ${props => props.theme.palette.primary.main};
    z-index: -1;
  }
`;

export const PrimaryButton: React.FunctionComponent<
  PrimaryButtonProps
> = props => {
  const {
    isLoading,
    fetchProgress,
    children,
    color = "secondary",
    paddingLeftRight,
    small,
    disabled,
    hexColor,
    hexBackgroundColor,
    ...rest
  } = props;

  return (
    <StyledButton
      paddingLeftRight={paddingLeftRight}
      fetchProgress={fetchProgress}
      color={color}
      variant={"contained"}
      disabled={disabled || isLoading}
      $small={small}
      $hexColor={hexColor}
      $hexBackgroundColor={hexBackgroundColor}
      {...rest}
    >
      {isLoading && (
        <StyledLoadingIconWrapper
          color={color}
          $hexColor={hexColor}
          $hexBackgroundColor={hexBackgroundColor}
        >
          <CircularProgress size={18} color="inherit" />
        </StyledLoadingIconWrapper>
      )}
      <span>{children}</span>
      {!isNoU(fetchProgress) && <StyledProgress width={`${fetchProgress}%`} />}
    </StyledButton>
  );
};
