import {
  ContractExchangeAssetsMethod,
  ContractExchangeAssetsType,
  ContractOverviewPortfolioItemDto,
  CurrencyCode,
} from "Api/Api";
import { ExchangeAssetsFormData } from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { Resources, useResource } from "Translations/Resources";
import { getLocalizedCurrencyCode } from "Utils/CurrencyUtils";
import { convertNanToNull, formatNumber } from "Utils/NumberUtils";
import { nameof } from "Utils/ObjectUtils";
import { boolean, mixed, number, object, ObjectSchema, string } from "yup";

export const MIN_EXCHANGE_ASSETS_AMOUNT_CZK = 100;
export const MIN_EXCHANGE_ASSETS_AMOUNT_EUR = 5;

const ValidationResources = Resources.Contract.ExchangeAssets.Modelling;

export const useYupFormSchema = (
  portfolioItem: ContractOverviewPortfolioItemDto,
): ObjectSchema<ExchangeAssetsFormData> => {
  const { t } = useResource();

  const schema: ObjectSchema<ExchangeAssetsFormData> = object().shape({
    sourceIsin: string().required(),
    targetIsin: string().required(),
    type: mixed<ContractExchangeAssetsType>()
      .oneOf(Object.values(ContractExchangeAssetsType))
      .required(),
    method: mixed<ContractExchangeAssetsMethod>()
      .oneOf(Object.values(ContractExchangeAssetsMethod))
      .required(),
    amountOrPieces: number()
      .required()
      .when(nameof<ExchangeAssetsFormData>("method"), {
        is: ContractExchangeAssetsMethod.Pieces,
        then: schema =>
          schema
            .transform(x => convertNanToNull(Number.parseFloat(x)))
            .min(1, t(ValidationResources.Parameters.Pieces.Validation.Min))
            .max(
              portfolioItem.quantity ?? Number.MAX_SAFE_INTEGER,
              t(ValidationResources.Parameters.Pieces.Validation.Max, {
                value: formatNumber(
                  portfolioItem.quantity ?? Number.MAX_SAFE_INTEGER,
                ),
              }),
            )
            .required(),
        otherwise: schema =>
          schema
            .transform(x => convertNanToNull(Number.parseInt(x)))
            .min(
              portfolioItem.currency === CurrencyCode.CZK
                ? MIN_EXCHANGE_ASSETS_AMOUNT_CZK
                : MIN_EXCHANGE_ASSETS_AMOUNT_EUR,
              t(ValidationResources.Parameters.Amount.Validation.Min, {
                value:
                  portfolioItem.currency === CurrencyCode.CZK
                    ? MIN_EXCHANGE_ASSETS_AMOUNT_CZK
                    : MIN_EXCHANGE_ASSETS_AMOUNT_EUR,
                currency: getLocalizedCurrencyCode(portfolioItem.currency),
              }),
            )
            .max(
              portfolioItem.amount ?? Number.MAX_SAFE_INTEGER,
              t(ValidationResources.Parameters.Amount.Validation.Max, {
                value: formatNumber(
                  portfolioItem.amount ?? Number.MAX_SAFE_INTEGER,
                ),
                currency: getLocalizedCurrencyCode(portfolioItem.currency),
              }),
            )
            .required(),
      }),
    isExchangeAll: boolean().required(),
  });

  return schema;
};
