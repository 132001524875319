import { StyledDialog } from "Components/Shared/StyledComponents";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { DialogContent, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { FunctionComponent } from "react";
import WifiOffIcon from "@mui/icons-material/WifiOff";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 40px;
`;

const StyledIcon = styled(WifiOffIcon)`
  margin: 20px;
  margin-top: 0px;
  font-size: 120px;
`;

const PageResources = Resources.Errors.NetworkErrorFallback;

export const NetworkErrorFallbackDialog: FunctionComponent<Props> = props => {
  const { isOpen, onClose } = props;
  const { t } = useResource();

  return (
    <StyledDialog open={isOpen} fullWidth maxWidth="md" onClose={onClose}>
      <DialogContent>
        <CenteredContent>
          <div>
            <StyledIcon />
            <PageTitle>{t(PageResources.Title)}</PageTitle>
            <Typography>{t(PageResources.Description)}</Typography>
          </div>
          <div>
            <PrimaryButton
              onClick={() => window.location.reload}
              color="primary"
              paddingLeftRight={16}
            >
              {t(PageResources.RefreshButton)}
            </PrimaryButton>
          </div>
        </CenteredContent>
      </DialogContent>
    </StyledDialog>
  );
};
