import {
  type FormModels,
  ContractModelingStep,
  type FormModelType,
  FundData,
} from "State/Contracts/Modeling/Models";
import { resetNewContract } from "State/Contracts/Shared/Actions";
import { rehydrateContractDraft } from "State/Contracts/Shared/Draft";
import { produce } from "immer";
import { createReducer, ActionType, createAction } from "typesafe-actions";

export type ModelingStateState = {
  isContractReset: boolean;
  isDip: boolean;
  actualStep: ContractModelingStep;
  modelingCompleted: boolean;
  formData: FormModelType;
};

export const CONTRACT_MODELING_STEPS: ContractModelingStep[] = [
  ContractModelingStep.Information,
  ContractModelingStep.Fund,
  ContractModelingStep.FundConfiguration,
];

const getNextStep = (actualStep: ContractModelingStep) => {
  if (
    CONTRACT_MODELING_STEPS.length - 1 ===
    CONTRACT_MODELING_STEPS.indexOf(actualStep)
  ) {
    throw new Error("Next step not found");
  }

  return CONTRACT_MODELING_STEPS[
    CONTRACT_MODELING_STEPS.indexOf(actualStep) + 1
  ];
};

const getPreviousStep = (actualStep: ContractModelingStep) => {
  return CONTRACT_MODELING_STEPS[
    CONTRACT_MODELING_STEPS.indexOf(actualStep) - 1
  ];
};

export const setNextStep = createAction("@investment/SET_NEXT_STEP")<{
  formData: FormModels;
  onlySave?: boolean;
  modelingCompleted?: boolean;
}>();

export const setIsDip = createAction("@investment/SET_IS_DIP")<{
  isDip?: boolean;
}>();

// TODO: we should handle reset step in next step handler
export const resetNewInvestment = createAction("@investment/RESET")<void>();

export const setPreviousStep = createAction(
  "@investment/SET_PREVIOUS_STEP",
)<void>();

export const rehydrateFormData = createAction(
  "@investment/SET_REHYDRATE_FORM_DATA",
)<ModelingStateState["formData"]>();

export type ModelingStateActionType =
  | ActionType<typeof setNextStep>
  | ActionType<typeof setIsDip>
  | ActionType<typeof setPreviousStep>
  | ActionType<typeof resetNewInvestment>
  | ActionType<typeof resetNewContract>
  | ActionType<typeof rehydrateFormData>
  | ActionType<typeof rehydrateContractDraft>;

export const getModelingStateState = (
  isContractReset = false,
): ModelingStateState => ({
  isContractReset,
  isDip: false,
  actualStep: CONTRACT_MODELING_STEPS[0],
  modelingCompleted: false,
  formData: {},
});

export const modelingStateReducer = createReducer<
  ModelingStateState,
  ModelingStateActionType
>(getModelingStateState())
  .handleAction(
    setNextStep,
    (state, { payload: { formData, onlySave, modelingCompleted = false } }) => {
      return produce(state, draft => {
        const { actualStep } = draft;

        draft.actualStep = onlySave ? actualStep : getNextStep(actualStep);

        if (!draft.formData) {
          draft.formData = {};
        }

        const isDifferentFund =
          actualStep === ContractModelingStep.Fund &&
          draft.formData[ContractModelingStep.Fund]?.isin !==
            (formData as unknown as FundData)?.isin;

        draft.formData[actualStep] = formData as any;

        if (isDifferentFund) {
          const fundConfiguration =
            draft.formData[ContractModelingStep.FundConfiguration];

          if (!!fundConfiguration) {
            fundConfiguration.singleInvestment = undefined;
            fundConfiguration.periodicalInvestment = undefined;
          }
        }

        draft.modelingCompleted = modelingCompleted;
      });
    },
  )
  .handleAction(setIsDip, (state, action) => ({
    ...state,
    isDip: action.payload.isDip ?? false,
  }))
  .handleAction(setPreviousStep, state => ({
    ...state,
    actualStep: getPreviousStep(state.actualStep),
  }))
  .handleAction(resetNewInvestment, _ => {
    return getModelingStateState();
  })
  .handleAction(resetNewContract, _ => {
    return getModelingStateState(true);
  })
  .handleAction(rehydrateFormData, (state, action) =>
    produce(state, draft => {
      draft.formData = action.payload;
      return draft;
    }),
  )
  .handleAction(rehydrateContractDraft, (state, action) =>
    produce(state, draft => {
      if (!action.payload) {
        return draft;
      }

      // if contract is reset, we don't want to rehydrate modeling state
      if (
        action.payload.isContractCreated &&
        !action.payload.isContractSigned
      ) {
        draft.isContractReset = false;
      } else if (draft.isContractReset) {
        draft.isContractReset = false;
        return draft;
      }

      const {
        draft: {
          modeling: { modelingState },
        },
        isContractSigned,
      } = action.payload;

      // If user completed creating contract, modeling state is not rehydrated
      if (isContractSigned) {
        return draft;
      }

      draft.actualStep = modelingState.actualStep;
      draft.formData = modelingState.formData;
      draft.modelingCompleted = modelingState.modelingCompleted;
      draft.isDip = modelingState.isDip;

      return draft;
    }),
  );
