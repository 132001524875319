import { BankItemDto } from "Api/Api";
import { CZECHIA_COUNTRY_CODE } from "Constants/Countries";
import { isNoU } from "Utils/ObjectUtils";

export const isValidCzechBankNumber = (bankNumber?: string): boolean => {
  if (!bankNumber) {
    return false;
  }

  const [prefix, number] = bankNumber.includes("-")
    ? bankNumber.split("-")
    : ["", bankNumber];

  // Fill the prefix to 6 digits and the number to 10 digits with leading zeros
  const normalizedPrefix = prefix.padStart(6, "0");
  const normalizedNumber = number.padStart(10, "0");

  // Concatenate prefix and number
  const normalizedAccount = normalizedPrefix + normalizedNumber;

  // Calculate the checksum
  let checksum = 0;
  for (let i = 0; i < normalizedAccount.length; i++) {
    checksum +=
      +normalizedAccount[normalizedAccount.length - 1 - i] * (2 ** i % 11);
  }

  // The checksum modulo 11 should be 0
  return checksum % 11 === 0;
};

export const getBankUniqueCode = (bank: BankItemDto): string =>
  `${bank.country}-${bank.numericCode}`;

export const convertCzechIbanToBankNumber = (
  iban: string,
): { bankCode?: string; bankNumber?: string } => {
  const bankCode = iban.substring(4, 8);
  let bankNumber = iban.substring(8);

  let prefix = bankNumber.substring(0, bankNumber.length - 10);
  let base = String(parseInt(bankNumber.substring(bankNumber.length - 10), 10));

  if (parseInt(prefix, 10)) {
    bankNumber = parseInt(prefix, 10) + "-" + base;
  } else {
    bankNumber = base;
  }

  return { bankCode: bankCode ? String(bankCode) : undefined, bankNumber };
};

export const generateCzechIban = ({
  accountNumber,
  bankCode,
  prefix = "",
}: {
  bankCode: string;
  prefix?: string;
  accountNumber: string;
}) => {
  // Combine prefix and account number, then pad to 16 digits
  const fullAccountNumber =
    bankCode + prefix.padStart(6, "0") + accountNumber.padStart(10, "0");

  const controlNumber = (98n - (BigInt(`${fullAccountNumber}123500`) % 97n))
    .toString()
    .padStart(2, "0");

  // Step 3: Construct the IBAN
  return CZECHIA_COUNTRY_CODE + controlNumber + fullAccountNumber;
};

export const getBankCountry = (iban?: string | null | undefined): string => {
  if (isNoU(iban)) {
    return CZECHIA_COUNTRY_CODE;
  }
  return iban.slice(0, 2);
};
