import { FundConfiguration } from "Components/ContractModeling/FundConfiguration/FundConfiguration";
import { FundSelector } from "Components/ContractModeling/FundSelector/FundSelector";
import { NewInvestmentForm } from "Components/ContractModeling/NewInvestmentForm/NewInvestmentForm";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";

import { type FunctionComponent, useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import {
  getDefaultTrackingUrlBase,
  setCustomDefaultUrlPostfix,
} from "Utils/TrackingUtils";
import { useViewTracking } from "Hooks/useViewTracking";
import { ContractModelingStep } from "State/Contracts/Modeling/Models";
import {
  CONTRACT_MODELING_STEPS,
  rehydrateFormData,
  setPreviousStep,
} from "State/Contracts/Modeling/ModelingState/ModelingStateState";
import { MODELLING_PERSIST_SESSION_STORAGE_KEY } from "Constants/Contracts/Modeling";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { useRehydrateContractDraft } from "Hooks/Contract/useRehydrateContractDraft";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { useDesktop } from "Hooks/useDesktop";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { useHeaderIsin } from "Hooks/Investment/NewInvestment/useHeaderIsin";
import { InvestmentHeaderTitle } from "Components/ContractModeling/shared/InvestmentHeaderTitle";
import { Resources, useResource } from "Translations/Resources";

const PageResources = Resources.Contract.NewContract;

export type InvestmentTypes = "Unlimited" | "Target";

const Components: { [key in ContractModelingStep]: FunctionComponent } = {
  [ContractModelingStep.Information]: NewInvestmentForm,
  [ContractModelingStep.Fund]: FundSelector,
  [ContractModelingStep.FundConfiguration]: FundConfiguration,
};

export const CreatePage: FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const actualStep = useAppSelector(
    e => e.contracts.modeling.modelingState.actualStep,
  );
  const { isLoading: isLoadingDashboard, data: dashboard } = useAppSelector(
    s => s.dashboard.contracts,
  );
  const { isRehydrating, rehydrate } = useRehydrateContractDraft();
  const isDipSelected = useIsDipSelected();
  const { isDesktop } = useDesktop();
  const headerIsin = useHeaderIsin(actualStep);

  const showHeaderDip =
    isDipSelected && actualStep === ContractModelingStep.FundConfiguration;
  const isFundConfigurationStep =
    actualStep === ContractModelingStep.FundConfiguration;

  useEffect(() => {
    rehydrate();
  }, [rehydrate]);

  useEffect(() => {
    const sessionData = sessionStorage.getItem(
      MODELLING_PERSIST_SESSION_STORAGE_KEY,
    );
    if (sessionData) {
      const parsedData = JSON.parse(sessionData);
      if (parsedData && typeof parsedData === "object") {
        dispatch(rehydrateFormData(parsedData));
      }
      sessionStorage.removeItem(MODELLING_PERSIST_SESSION_STORAGE_KEY);
    }
  }, [dispatch]);

  const onBackClick = () => {
    if (actualStep === CONTRACT_MODELING_STEPS[0]) {
      navigate(getPath(AppRouting.Dashboard));
    } else {
      dispatch(setPreviousStep());
    }
  };

  const actualContractModelingStep =
    ContractModelingStep[actualStep] ?? ContractModelingStep.Information;

  useViewTracking({
    category: "PageView",
    event: "Open",
    action: "NextStep",
    value: actualContractModelingStep,
    url: `${getDefaultTrackingUrlBase()}#${actualContractModelingStep}`,
  });

  useEffect(() => {
    setCustomDefaultUrlPostfix(`#${actualContractModelingStep}`);

    return () => {
      setCustomDefaultUrlPostfix(null);
    };
  }, [actualContractModelingStep]);

  if (dashboard?.isAccessRestricted) {
    return <Navigate to={getPath(AppRouting.ContractDealer)} />;
  }

  const Component = Components[actualStep];

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      onBackClick={onBackClick}
      headerChildren={
        showHeaderDip && !isDesktop ? <DipTagWrapper inverse /> : undefined
      }
      productIsin={headerIsin}
      allowProductBackground={isFundConfigurationStep}
      titleComponent={<InvestmentHeaderTitle />}
      isTitleShown={!isFundConfigurationStep}
    >
      <LoadingWrapper
        isLoading={isRehydrating || (isLoadingDashboard && !dashboard)}
        skeleton={<LoadingSkeleton />}
      >
        <Component />
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const LoadingSkeleton = () => (
  <div>
    <PrimarySkeleton height={50} marginBottom={30} />
    <PrimarySkeleton height={150} marginBottom={20} />
    <PrimarySkeleton height={150} marginBottom={20} />
    <PrimarySkeleton height={150} marginBottom={20} />
  </div>
);

export default CreatePage;
