import {
  BankIDProcessAudience,
  EditPersonalDataCommandResult,
  EditPersonalDataRequest,
  EditPersonalDataVerificationType,
  getUserContact,
  PersonalDocumentType,
  postClientPersonalDataEdit,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { setBankIDProfile } from "State/Contracts/Create/BankIDProfile/SetBankIDProfile";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { safeApiCall } from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type PathSelectionFormModel = Pick<
  EditPersonalDataRequest,
  "verificationType"
>;

export type PersonalDataFormModel = Pick<
  EditPersonalDataRequest,
  "personalData"
>;

export type ContactInfoFormModel = Pick<EditPersonalDataRequest, "contactInfo">;

export type PrimaryDocumentFormModel = Pick<
  EditPersonalDataRequest,
  "primaryDocument"
>;

export type PermanentAddressFormModel = Pick<
  EditPersonalDataRequest,
  "permanentAddress"
>;

export type EditPersonalDataFormModel = PathSelectionFormModel &
  PersonalDataFormModel &
  ContactInfoFormModel &
  PrimaryDocumentFormModel &
  PermanentAddressFormModel;

export enum EditPersonalDataSteps {
  PathSelection,
  BankIDRedirect,
  BankIDCallback,
  Modelling,
  Signature,
}

export type EditPersonalDataState = {
  isLoading: boolean;
  isResetAllowed: boolean;
  bankID: {
    profile: {
      PIN?: string | null;
    };
  };
  actualStep: EditPersonalDataSteps;
  lastRequest: EditPersonalDataRequest;
  signatureHash?: string | null;
  requestHash?: string | null;
  error?: Error | null;
};

function getDefaultState(): EditPersonalDataState {
  return {
    isLoading: false,
    isResetAllowed: true,
    bankID: {
      profile: {},
    },
    actualStep: EditPersonalDataSteps.PathSelection,
    lastRequest: {
      verificationType: EditPersonalDataVerificationType.Manual,
      contactInfo: {
        phone: "",
        email: "",
      },
      personalData: {
        lastName: "",
      },
      permanentAddress: {
        streetName: "",
        streetNumber: "",
        streetConscriptionNumber: "",
        postalCode: "",
        city: "",
        country: "",
      },
      primaryDocument: {
        type: PersonalDocumentType.IdentityCard,
        number: "",
        issuingAuthority: "",
        issueCountry: "",
        issueDate: "",
        expiryDate: "",
      },
      isBiometry: false,
    },
    signatureHash: null,
    requestHash: null,
    error: null,
  };
}

export const resetEditPersonalDataState = createAction(
  "@personal-data/RESET_PERSONAL_DATA_EDIT_STATE",
)<void>();

export const setEditPersonalDataFormData = createAction(
  "@personal-data/SET_PERSONAL_DATA_EDIT_FORM_DATA",
)<EditPersonalDataRequest>();

export const setEditPersonalDataActualStep = createAction(
  "@personal-data/SET_PERSONAL_DATA_EDIT_ACTUAL_STEP",
)<EditPersonalDataSteps>();

export const setEditPersonalDataIsBankIDVerified = createAction(
  "@personal-data/SET_PERSONAL_DATA_EDIT_IS_BANK_ID_VERIFIED",
)<boolean>();

export const editPersonalDataAsync = createAsyncAction(
  "@personal-data/POST_PERSONAL_DATA_EDIT_REQUEST",
  "@personal-data/POST_PERSONAL_DATA_EDIT_SUCCESS",
  "@personal-data/POST_PERSONAL_DATA_EDIT_FAILURE",
)<
  EditPersonalDataRequest,
  EditPersonalDataCommandResult & { phone?: string | null },
  Error
>();

export type EditPersonalDataActions =
  | ActionType<typeof editPersonalDataAsync>
  | ActionType<typeof resetEditPersonalDataState>
  | ActionType<typeof setEditPersonalDataFormData>
  | ActionType<typeof setEditPersonalDataActualStep>
  | ActionType<typeof setEditPersonalDataIsBankIDVerified>
  | ActionType<typeof setBankIDProfile>;

function* editPersonalData(
  action: ReturnType<typeof editPersonalDataAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postClientPersonalDataEdit,
      action.payload,
    );

    if (!!error) {
      yield put(editPersonalDataAsync.failure(error));
      return;
    }

    if (!response.signatureHash) {
      yield put(
        editPersonalDataAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );
      return;
    }

    if (action.payload.isBiometry) {
      yield put(
        setBiometricsSignatureType(BiometricsSignatureType.EditPersonalData),
      );

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: response.signatureHash,
        }),
      );

      yield put(editPersonalDataAsync.success(response));

      return;
    }

    const { response: contactResponse, error: contactError } =
      yield* safeApiCall(getUserContact);

    if (!!contactError) {
      yield put(editPersonalDataAsync.failure(contactError));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash: response.signatureHash,
      }),
    );

    yield put(
      editPersonalDataAsync.success({
        ...response,
        phone:
          contactResponse.contactInfo?.client?.phone ||
          contactResponse.contactInfo?.user.phone,
      }),
    );
  } catch (err) {
    yield put(editPersonalDataAsync.failure(err as Error));
  }
}

export function* watchEditPersonalDataSaga() {
  yield takeLeading(getType(editPersonalDataAsync.request), editPersonalData);
}

export const editPersonalDataReducer = createReducer<
  EditPersonalDataState,
  EditPersonalDataActions
>(getDefaultState())
  .handleAction(editPersonalDataAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.lastRequest = action.payload;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(editPersonalDataAsync.success, (state, action) =>
    produce(state, draft => {
      const { requestHash, signatureHash } = action.payload;

      draft.isLoading = false;
      draft.actualStep = EditPersonalDataSteps.Signature;
      draft.requestHash = requestHash;
      draft.signatureHash = signatureHash;
      draft.error = null;

      return draft;
    }),
  )
  .handleAction(editPersonalDataAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(setEditPersonalDataActualStep, (state, action) =>
    produce(state, draft => {
      draft.actualStep = action.payload;
      return draft;
    }),
  )
  .handleAction(setEditPersonalDataFormData, (state, action) =>
    produce(state, draft => {
      draft.lastRequest = action.payload;
      return draft;
    }),
  )
  .handleAction(resetEditPersonalDataState, (state, action) =>
    produce(state, draft => {
      if (!state.isResetAllowed) {
        draft.isResetAllowed = true;
        return draft;
      }

      return getDefaultState();
    }),
  )
  .handleAction(setBankIDProfile.request, (state, action) =>
    produce(state, draft => {
      const { processAudience } = action.payload;
      if (processAudience !== BankIDProcessAudience.EditPersonalData) {
        return state;
      }

      draft = getDefaultState();
      draft.actualStep = EditPersonalDataSteps.BankIDCallback;
      draft.isResetAllowed = false;
      draft.lastRequest.verificationType =
        EditPersonalDataVerificationType.BankID;

      return draft;
    }),
  )
  .handleAction(
    setBankIDProfile.success,
    (state, { payload: [personalData, addresses, , document] }) =>
      produce(state, draft => {
        const {
          lastRequest: { verificationType },
        } = state;

        if (verificationType !== EditPersonalDataVerificationType.BankID) {
          return state;
        }

        draft.lastRequest.verificationType =
          EditPersonalDataVerificationType.BankID;

        draft.bankID.profile = {
          PIN: personalData.personalIdentificationNumber,
        };

        draft.lastRequest.personalData = {
          lastName: personalData.lastName,
        };

        draft.lastRequest.permanentAddress = {
          streetName: addresses.addresses.permanentAddress.street,
          streetNumber: addresses.addresses.permanentAddress.streetNumber,
          streetConscriptionNumber:
            addresses.addresses.permanentAddress.streetConscriptionNumber || "",
          postalCode: addresses.addresses.permanentAddress.postalCode,
          city: addresses.addresses.permanentAddress.city,
          country: addresses.addresses.permanentAddress.country,
        };

        if (document) {
          draft.lastRequest.primaryDocument = {
            type: document.type,
            number: document.number,
            issueDate: document.dateValidFrom,
            expiryDate: document.dateExpiry || "",
            issuingAuthority: document.issuingAuthority,
            issueCountry: document.countryIssue,
          };
        }

        return draft;
      }),
  )
  .handleAction(setBankIDProfile.failure, (state, action) =>
    produce(state, draft => {
      const {
        lastRequest: { verificationType },
      } = state;

      if (verificationType !== EditPersonalDataVerificationType.BankID) {
        return state;
      }

      draft.error = action.payload;
      draft.lastRequest.verificationType =
        EditPersonalDataVerificationType.Manual;

      return draft;
    }),
  )
  .handleAction(setEditPersonalDataIsBankIDVerified, (state, action) =>
    produce(state, draft => {
      const isVerified = action.payload;
      if (isVerified) {
        draft.lastRequest.verificationType =
          EditPersonalDataVerificationType.BankID;
      }

      if (!isVerified) {
        const { lastRequest } = getDefaultState();
        draft.lastRequest = {
          ...lastRequest,
          verificationType: EditPersonalDataVerificationType.Manual,
        };
      }

      draft.actualStep = EditPersonalDataSteps.Modelling;
      return draft;
    }),
  );
