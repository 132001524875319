import { useNotification } from "Hooks/Notifications/useNotification";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { WebViewMessageTypes } from "Models/WebViewModels";
import { useCallback } from "react";
import { fcmTokenStatusAsync } from "State/Notifications/Api/FcmTokenStatusState";
import { registerFcmTokenAsync } from "State/Notifications/Api/RegisterFcmTokenState";
import { resetNotificationsState } from "State/Notifications/NotificationsReducer";
import { isNoU } from "Utils/ObjectUtils";
import { postMessageFromWebView } from "Utils/WebViewUtils";

export const useNotificationSynchronization = () => {
  const dispatch = useAppDispatch();
  const { isEnabled, isPermissionGranted, fcmToken, canBeUsed } =
    useNotification();

  const synchronizeFcmToken = useCallback(() => {
    if (!canBeUsed) {
      dispatch(resetNotificationsState());
      return;
    }

    if (isNoU(fcmToken)) {
      postMessageFromWebView({
        type: WebViewMessageTypes.NOTIFICATION_TOKEN_REQUEST,
        payload: {},
      });
      return;
    }

    if (isNoU(isEnabled)) {
      dispatch(
        fcmTokenStatusAsync.request({
          token: fcmToken,
        }),
      );
      return;
    }

    dispatch(
      registerFcmTokenAsync.request({
        token: fcmToken,
        isNotificationEnabled: isEnabled,
        isNotificationPermissionGranted: isPermissionGranted,
      }),
    );
  }, [isEnabled, isPermissionGranted, fcmToken, canBeUsed, dispatch]);

  const synchronizePermission = useCallback(() => {
    if (!isPermissionGranted && canBeUsed) {
      postMessageFromWebView({
        type: WebViewMessageTypes.ENABLE_NOTIFICATIONS_REQUEST,
        payload: {},
      });
    }
  }, [isPermissionGranted, canBeUsed]);

  return {
    synchronizeFcmToken,
    synchronizePermission,
  };
};
