import {
  GetClientStateType,
  getClientReducer,
  getClientSaga,
} from "State/Client/Client/GetClient";
import {
  PostContactConfirmationStateType,
  postContactConfirmationReducer,
  postContactConfirmationSaga,
} from "State/Client/ContactConfirmation/PostContactConfirmation";
import {
  PutContactConfirmationStateType,
  putContactConfirmationReducer,
  putContactConfirmationSaga,
} from "State/Client/ContactConfirmation/PutContactConfirmation";
import {
  GetDealersStateType,
  getDealersReducer,
  getDealersSaga,
} from "State/Client/GetDealers/GetDealersState";
import {
  GetExistenceStateType,
  getExistenceReducer,
  getExistenceSaga,
} from "State/Client/GetExistence/GetExistenceState";
import {
  clientVerificationTypeReducer,
  GetClientVerificationTypeStateType,
  watchClientVerificationTypeSaga,
} from "State/Client/VerificationType/ClientVerificationTypeState";
import {
  personalDataReducer,
  PersonalDataState,
  watchPersonalDataSaga,
} from "State/More/PersonalData/PersonalDataReducer";

import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type ClientState = {
  client: GetClientStateType;
  dealers: GetDealersStateType;
  existence: GetExistenceStateType;
  postContactConfirmation: PostContactConfirmationStateType;
  putContactConfirmation: PutContactConfirmationStateType;
  personalData: PersonalDataState;
  verificationType: GetClientVerificationTypeStateType;
};

export function* watchClientSaga() {
  yield all([
    getClientSaga(),
    getDealersSaga(),
    getExistenceSaga(),
    postContactConfirmationSaga(),
    putContactConfirmationSaga(),
    watchPersonalDataSaga(),
    watchClientVerificationTypeSaga(),
  ]);
}

export const clientReducer = combineReducers<ClientState>({
  client: getClientReducer,
  dealers: getDealersReducer,
  existence: getExistenceReducer,
  postContactConfirmation: postContactConfirmationReducer,
  putContactConfirmation: putContactConfirmationReducer,
  personalData: personalDataReducer,
  verificationType: clientVerificationTypeReducer,
});
