import { PersonalDocumentType } from "Api/Api";
import { SLOVAKIA_COUNTRY_CODE } from "Constants/Countries";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { useAppSelector } from "Hooks/useAppSelector";
import { DocumentModel } from "Models/Forms";
import {
  NewContractStep,
  type DocumentProps,
  DocumentsFormModel,
  ScanType,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { formatDocumentsDateToDate } from "Utils/Contracts/NewContract/DateUtils";
import { nameof } from "Utils/ObjectUtils";
import { addDays, isAfter, isValid } from "date-fns";
import { useMemo } from "react";
import { object, string, ObjectSchema, boolean } from "yup";

const DocumentResources = Resources.Contract.NewContract.Documents;

export const useYupFormSchema = () => {
  const { t } = useResource();
  const formData = useAppSelector(s => s.contracts.create.formData);
  const personalData = formData[NewContractStep.PersonalData];
  const isBankIDVerified = useIsBankIDVerified();

  const isCzechNationality = useMemo(() => {
    return personalData?.nationalities?.some(({ value }) => value === "CZ");
  }, [personalData]);

  if (isBankIDVerified) {
    return object();
  }

  const documentScanTypeSchema = (): ObjectSchema<ScanType> =>
    object<ScanType>().shape({
      guid: string().required(),
      fileName: string().required(),
      mimeType: string().required(),
    });

  const documentPropsSchema = (
    isFirstDocument = false,
  ): ObjectSchema<DocumentProps> =>
    object<DocumentProps>().shape({
      frontScan: documentScanTypeSchema().required(),
      backScan: isFirstDocument
        ? documentScanTypeSchema().required()
        : documentScanTypeSchema().default(undefined).nullable().optional(),
      type: string()
        .required()
        .oneOf(Object.values(PersonalDocumentType))
        .test(
          "identity-card-required",
          t(DocumentResources.MustBeIdentityCard),
          function (value) {
            return !(
              isFirstDocument &&
              isCzechNationality &&
              value !== PersonalDocumentType.IdentityCard
            );
          },
        )
        .test(
          "identity-card-abadoned",
          t(DocumentResources.CannotBeIdentityCard),
          function (value) {
            return !(
              !isFirstDocument &&
              isCzechNationality &&
              value === PersonalDocumentType.IdentityCard
            );
          },
        ),
      number: string().required(),
      countryIssue: string().required(),
      issuingAuthority: string().required(),
      dateValidFrom: string()
        .transform((value: string | null | undefined) =>
          value && isValid(formatDocumentsDateToDate(value))
            ? value
            : undefined,
        )
        .required(),
      dateExpiry: string()
        .transform((value: string | null | undefined) =>
          value && isValid(formatDocumentsDateToDate(value))
            ? value
            : undefined,
        )
        .when(nameof<DocumentModel>("isExpiryDateIndefinite"), {
          is: true,
          then: schema => schema.default(undefined).optional(),
          otherwise: schema =>
            schema
              .required()
              .test(
                "is-after",
                t(DocumentResources.ExpiryDateBelowValidity),
                function (value: string) {
                  const { dateValidFrom: dateValidFromString } = this
                    .parent as DocumentProps;

                  if (!dateValidFromString) {
                    return true;
                  }

                  const dateValidFrom =
                    formatDocumentsDateToDate(dateValidFromString);

                  const dateExpiry = formatDocumentsDateToDate(value);
                  if (!isValid(dateValidFrom) || !isValid(dateExpiry)) {
                    return true;
                  }

                  return isAfter(dateExpiry, dateValidFrom);
                },
              )
              .test(
                "is-expired",
                t(DocumentResources.DocumentExpired),
                function (value: string) {
                  const dateExpiry = formatDocumentsDateToDate(value);
                  return isAfter(dateExpiry, addDays(new Date(), -1));
                },
              ),
        }),
      isExpiryDateIndefinite: boolean()
        .default(undefined)
        .optional()
        .test(
          "indefinite-expiry-date-allowed",
          t(DocumentResources.DocumentIndefiniteExpiryDateNotAllowed),
          function (isChecked: boolean | undefined) {
            const {
              type,
              countryIssue,
              dateValidFrom: dateValidFromString,
            } = this.parent as DocumentProps;

            if (!isChecked || !type || !countryIssue || !dateValidFromString) {
              return true;
            }

            const dateValidFrom =
              formatDocumentsDateToDate(dateValidFromString);

            return (
              type === PersonalDocumentType.DrivingLicense &&
              countryIssue === SLOVAKIA_COUNTRY_CODE &&
              dateValidFrom <= new Date(2013, 0, 18)
            );
          },
        ),
    });

  const documentsFormModelSchema = object<DocumentsFormModel>().shape({
    firstDocument: documentPropsSchema(true),
    secondDocument: documentPropsSchema(),
  });

  return documentsFormModelSchema;
};
