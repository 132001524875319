import { Box, CardContent } from "@mui/material";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { InfoIcon } from "Components/Shared/Icons";
import { RadioButton } from "Components/Shared/Inputs/RadioButton";
import { StyledDarkCard, StyledFlex } from "Components/Shared/StyledComponents";
import {
  ChangeEvent,
  PropsWithChildren,
  type FunctionComponent,
  ReactNode,
} from "react";
import styled from "styled-components";
import { BlInfoPopper } from "./BlInfoPopper";

const StyledProductCard = styled(StyledDarkCard)`
  .MuiCardContent-root {
    padding-left: 0;
  }
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type Props = PropsWithChildren<{
  isChecked: boolean;
  onCheck: () => void;
  onInfoClick?: () => void;
  infoPopper?: ReactNode;
}>;

export const SelectableCardBox: FunctionComponent<Props> = ({
  isChecked,
  onCheck,
  onInfoClick,
  infoPopper,
  children,
}) => {
  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onCheck();
    }
  };

  return (
    <StyledProductCard>
      <CardContent>
        <StyledFlex $gap={3}>
          <RadioButton checked={isChecked} onChange={handleCheck} />
          <Box flexGrow={1} onClick={onCheck}>
            {children}
          </Box>
          {(!!onInfoClick || !!infoPopper) && (
            <StyledInfoWrapper>
              {infoPopper ? (
                <BlInfoPopper size={18} icon="info" calcWidth={false}>
                  {infoPopper}
                </BlInfoPopper>
              ) : (
                <IconCircleWrapper
                  size={18}
                  transparent
                  onClick={!infoPopper ? onInfoClick : undefined}
                >
                  <InfoIcon />
                </IconCircleWrapper>
              )}
            </StyledInfoWrapper>
          )}
        </StyledFlex>
      </CardContent>
    </StyledProductCard>
  );
};
