import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { TextButton } from "Components/Shared/Buttons/TextButton";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { PASSWORD_PLACEHOLDER } from "Constants/Inputs";
import { useSetPasswordSchema } from "Hooks/Auth/useSetPasswordSchema";
import useHandleServerError from "Hooks/useHandleServerError";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

type Props = {
  serverError: Error | null;
  email: string;
  isLoading: boolean;
  onSubmit(model: { code: string; password: string }): void;
  onResend(): void;
};

const PageResources = Resources.ForgottenPassword;

const StyledButton = styled(TextButton)`
  padding-left: ${props => props.theme.spacing(1)};
`;

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(4)};
`;

export type FormModel = {
  code: string;
  newPassword: string;
  confirmPassword: string;
};

export const PasswordSetForm: FunctionComponent<Props> = ({
  email,
  serverError,
  isLoading,
  onSubmit,
  onResend,
}) => {
  const { t } = useResource();

  const validationSchema = useSetPasswordSchema();

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  useHandleServerError({
    form,
    key: "code",
    resource: PageResources.Error,
    serverError,
    keyMap: {
      newPassword: "password",
    },
  });

  return (
    <>
      <div>
        <Typography variant="h2" component="h2">
          {t(PageResources.Title)}
        </Typography>
        <Box display="flex" paddingTop={2}>
          <Typography>
            {t(PageResources.Subtitle.FirstPart)}
            {email}
            {t(PageResources.Subtitle.SecondPart)}
          </Typography>
        </Box>
        <StyledForm autoComplete="off">
          <BlInputFormField
            autoFocus
            control={control}
            name="code"
            errors={errors}
            label={t(PageResources.Code.Label)}
            mask="***-***"
            maskPlaceholder="_"
            isMaskLazy={false}
            autoComplete="one-time-code"
          />

          <Box display="flex" paddingTop={2}>
            <Typography>
              {t(PageResources.Code.ResendCode.FirstPart)}
            </Typography>
            <StyledButton color="secondary" onClick={onResend}>
              {t(PageResources.Code.ResendCode.SecondPart)}
            </StyledButton>
          </Box>

          <BlInputFormField
            control={control}
            errors={errors}
            name="newPassword"
            type="password"
            placeholder={PASSWORD_PLACEHOLDER}
            label={t(PageResources.NewPassword.Label)}
            autoComplete="new-password"
          />
          <BlInputFormField
            control={control}
            errors={errors}
            name="confirmPassword"
            type="password"
            placeholder={PASSWORD_PLACEHOLDER}
            label={t(PageResources.ConfirmPassword.Label)}
            autoComplete="new-password"
          />
        </StyledForm>
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(data =>
          onSubmit({ code: data.code, password: data.newPassword }),
        )}
        isLoading={isLoading}
        trackingTag={t(Resources.Common.Continue)}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
