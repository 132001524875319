import { Colors } from "Components/Layout/Themes/Colors";
import styled from "styled-components";

type ProgressCircleProps = {
  size: number;
  progressPercent: number;
};

const ProgressCircleStyled = styled.svg<Pick<ProgressCircleProps, "size">>`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  z-index: 0;
`;

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  size,
  progressPercent,
}) => {
  const strokeWidth = 2;
  const radius = size / 2 - strokeWidth;
  const circumference = 2 * Math.PI * radius;
  const offset = (1 - Math.min(progressPercent, 100) / 100) * circumference;

  return (
    <ProgressCircleStyled size={size}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={Colors.Green}
        strokeWidth={strokeWidth}
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
    </ProgressCircleStyled>
  );
};

export default ProgressCircle;
