import { DeleteBiometricsCommandResult, deleteAuthBiometricsId } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { WebViewMessage, WebViewMessageTypes } from "Models/WebViewModels";
import {
  setBiometrics,
  setSettingBiometricsInProgress,
} from "State/Biometrics/BiometricsActions";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";

export const deleteBiometricsAsync = createAsyncAction(
  "@biometrics/DELETE_REQUEST",
  "@biometrics/DELETE_SUCCESS",
  "@biometrics/DELETE_FAILURE",
)<
  Extract<
    WebViewMessage,
    { type: WebViewMessageTypes.DISABLE_BIOMETRICS_RESPONSE }
  >["payload"],
  DeleteBiometricsCommandResult,
  Error
>();

function* deleteBiometrics(
  action: ReturnType<typeof deleteBiometricsAsync.request>,
): Generator {
  try {
    yield put(setSettingBiometricsInProgress(false));
    if (action.payload.isBiometricsEnabled) {
      yield put(
        deleteBiometricsAsync.failure(
          new ApplicationError(
            "Native device didn't disable biometrics.",
            ErrorLevel.Error,
            {
              payload: action.payload,
            },
            undefined,
            true,
          ),
        ),
      );
      return;
    }

    yield put(setBiometrics(false));

    const { response, error } = yield* safeApiCall(
      deleteAuthBiometricsId,
      action.payload.id,
    );

    if (error) {
      yield put(deleteBiometricsAsync.failure(error));
      return;
    }

    yield put(deleteBiometricsAsync.success(response));
  } catch (err) {
    yield put(deleteBiometricsAsync.failure(err as Error));
  }
}
export function* deleteBiometricsSaga() {
  yield takeLatest(getType(deleteBiometricsAsync.request), deleteBiometrics);
}
