import { useIsDipSelected } from "./useIsDipSelected";
import { useSettings } from "Hooks/App/Settings/useSettings";

export const useIsDipEnabled = () => {
  const isDipSelected = useIsDipSelected();
  const { settings } = useSettings();

  return {
    // user has selected DIP switch and DIP is enabled
    isDipEnabled: (isDipSelected && settings?.isDIPEnabled) ?? false,
    isDipSelected,
  };
};
