import {
  EditAMLCommandResult,
  EditAMLRequest,
  getUserContact,
  postClientAmlEdit,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { safeApiCall } from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export enum EditAMLSteps {
  Modelling,
  Signature,
}

export type EditAMLState = {
  isLoading: boolean;
  actualStep: EditAMLSteps;
  formData?: AMLQuestionsFormModel | null;
  lastRequest?: EditAMLRequest | null;
  phone?: string | null;
  requestHash?: string | null;
  signatureHash?: string | null;
  error?: Error | null;
};

const initialEditAMLState: EditAMLState = {
  isLoading: false,
  actualStep: EditAMLSteps.Modelling,
  error: null,
};

export const resetEditAMLState = createAction(
  "@personal-data/RESET_AML_EDIT_STATE",
)<void>();

export const setEditAMLFormData = createAction(
  "@personal-data/SET_AML_EDIT_FORM_DATA",
)<AMLQuestionsFormModel>();

export const setEditAMLActualStep = createAction(
  "@personal-data/SET_AML_EDIT_ACTUAL_STEP",
)<EditAMLSteps>();

export const editAMLAsync = createAsyncAction(
  "@personal-data/POST_AML_EDIT_REQUEST",
  "@personal-data/POST_AML_EDIT_SUCCESS",
  "@personal-data/POST_AML_EDIT_FAILURE",
)<EditAMLRequest, EditAMLCommandResult & { phone?: string | null }, Error>();

export type EditAMLActions =
  | ActionType<typeof editAMLAsync>
  | ActionType<typeof setEditAMLActualStep>
  | ActionType<typeof setEditAMLFormData>
  | ActionType<typeof resetEditAMLState>;

function* editAML(action: ReturnType<typeof editAMLAsync.request>): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postClientAmlEdit,
      action.payload,
    );

    if (!!error) {
      yield put(editAMLAsync.failure(error));
      return;
    }

    if (!response.signatureHash) {
      yield put(
        editAMLAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );
      return;
    }

    if (action.payload.isBiometry) {
      yield put(setBiometricsSignatureType(BiometricsSignatureType.EditAML));

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: response.signatureHash,
        }),
      );

      yield put(editAMLAsync.success(response));

      return;
    }

    const { response: contactResponse, error: contactError } =
      yield* safeApiCall(getUserContact);

    if (!!contactError) {
      yield put(editAMLAsync.failure(contactError));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash: response.signatureHash,
      }),
    );

    yield put(
      editAMLAsync.success({
        ...response,
        phone:
          contactResponse.contactInfo?.client?.phone ||
          contactResponse.contactInfo?.user.phone,
      }),
    );
  } catch (err) {
    yield put(editAMLAsync.failure(err as Error));
  }
}

export function* watchEditAMLSaga() {
  yield takeLeading(getType(editAMLAsync.request), editAML);
}

export const editAMLReducer = createReducer<EditAMLState, EditAMLActions>(
  initialEditAMLState,
)
  .handleAction(editAMLAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.lastRequest = action.payload;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(editAMLAsync.success, (state, action) =>
    produce(state, draft => {
      const { phone, requestHash, signatureHash } = action.payload;
      draft.isLoading = false;
      draft.actualStep = EditAMLSteps.Signature;
      draft.phone = phone;
      draft.requestHash = requestHash;
      draft.signatureHash = signatureHash;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(editAMLAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(setEditAMLActualStep, (state, action) =>
    produce(state, draft => {
      draft.actualStep = action.payload;
      return draft;
    }),
  )
  .handleAction(setEditAMLFormData, (state, action) =>
    produce(state, draft => {
      draft.formData = action.payload;
      return draft;
    }),
  )
  .handleAction(resetEditAMLState, (state, action) =>
    produce(state, draft => {
      return initialEditAMLState;
    }),
  );
