import { CheckboxInput } from "Components/Shared/Inputs/BlCheckboxField/CheckboxInput";
import { MenuItem } from "Components/Shared/MenuItem";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { updateClientAnalyticsStatusAsync } from "State/Auth/AppData/UpdateClientAnalyticsStatusState";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";

const ComponentResources = Resources.Profile.Settings;

export const AnalyticsItem: FunctionComponent = _ => {
  const { t } = useResource();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(s => s.auth);

  const isAnalyticsEnabled = user?.appData.isAnalyticsEnabled ?? false;

  const actionElement = (
    <CheckboxInput
      name="analytics"
      checked={isAnalyticsEnabled}
      onChange={(_, checked) =>
        dispatch(
          updateClientAnalyticsStatusAsync.request({
            currentValue: checked,
            previousValue: isAnalyticsEnabled,
          }),
        )
      }
    />
  );

  return (
    <MenuItem
      title={t(ComponentResources.Analytics.Title)}
      description={t(ComponentResources.Analytics.Description)}
      actionElement={actionElement}
    />
  );
};
