import {
  ProlongTokenCommandResult,
  ProlongTokenCommandResultStatus,
  postAuthTokenProlong,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, select, throttle } from "typed-redux-saga";
import { getType } from "typesafe-actions";
import { toAPICallError } from "Models/Errors/ApiCallError";
import { safeApiCall } from "State/Utils";
import { resetUser } from "State/Auth/AuthReducer";
import { history } from "Routes/AppHistory";
import { setToken } from "Utils/LocalStorageUtils";
import { getPathnameAndSearch } from "Utils/UrlUtils";
import { RootStateType } from "State/Store";

export const checkAndProlongTokenAsync = createAsyncAction(
  "@auth/CHECK_AND_PROLONG_REQUEST",
  "@auth/CHECK_AND_PROLONG_SUCCESS",
  "@auth/CHECK_AND_PROLONG_FAILURE",
)<void, ProlongTokenCommandResult, Error>();

function* checkAndProlongToken(
  action: ReturnType<typeof checkAndProlongTokenAsync.request>,
): Generator {
  try {
    const user = yield* select((state: RootStateType) => state.auth.user);

    if (!user) {
      return;
    }

    const { response, error } = yield* safeApiCall(postAuthTokenProlong);
    if (error) {
      yield put(checkAndProlongTokenAsync.failure(error));
      return;
    }
    if (response.status === ProlongTokenCommandResultStatus.Success) {
      setToken({ token: response.token });
      yield put(checkAndProlongTokenAsync.success(response));
    } else {
      yield put(
        resetUser({
          authRedirectUrl: getPathnameAndSearch(history.location),
          wasSignedOutManually: false,
        }),
      );
    }
  } catch (err) {
    yield put(checkAndProlongTokenAsync.failure(toAPICallError(err)));
  }
}
export function* checkAndProlongTokenSaga() {
  yield throttle(
    6000,
    getType(checkAndProlongTokenAsync.request),
    checkAndProlongToken,
  );
}
