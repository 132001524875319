import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getBiometricSignInChallengeAsync } from "State/Auth/Biometrics/BiometricSignInChallengeState";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";

const PageResources = Resources.SignIn;

export const BiometricSignInButton: FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();

  const { canUseBiometricsForSignIn } = useBiometricsCanBeUsed();
  const { isBiometricsEnabled, signIn } = useAppSelector(x => x.biometrics);
  const { login, isInProgress } = signIn;

  const isEnabled =
    canUseBiometricsForSignIn &&
    isBiometricsEnabled &&
    !isInProgress &&
    !!login;

  const onClick = () => {
    if (!isEnabled) {
      return;
    }

    dispatch(
      getBiometricSignInChallengeAsync.request({
        login,
      }),
    );
  };

  if (!canUseBiometricsForSignIn || !isBiometricsEnabled || !login) {
    return null;
  }

  return (
    <>
      <PrimaryButton onClick={onClick} isLoading={isInProgress}>
        {t(PageResources.SignInBiometrics)}
      </PrimaryButton>
    </>
  );
};
