import { Link } from "@mui/material";
import {
  IGNORE_DOCUMENT_CACHE,
  formatDocumentLink,
} from "Utils/ExternalLinkUtils";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  children: string | ReactNode | undefined;
  href: string;
  ignoreCache?: boolean;
  target?: "_self" | "_blank" | undefined;
  color?: "primary" | "secondary" | undefined;
  fontWeight?: number;
};

export const DocumentLink: FunctionComponent<Props> = ({
  children,
  href,
  target,
  ignoreCache = IGNORE_DOCUMENT_CACHE,
  color = "secondary",
  fontWeight = 600,
}) => {
  return (
    <Link
      href={formatDocumentLink(href, ignoreCache)}
      target={target}
      color={color}
      fontWeight={fontWeight}
    >
      {children}
    </Link>
  );
};
