import { Box, CircularProgress } from "@mui/material";
import { FunctionComponent, useEffect, useRef } from "react";

type Props = {
  isLoading: boolean;
  onElementVisible: () => void;
};

export const MenuItemLoader: FunctionComponent<Props> = ({
  isLoading,
  onElementVisible,
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isLoading || !elementRef.current) {
      return;
    }

    const currentElement = elementRef.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          onElementVisible();
        }
      },
      { threshold: 0.1 },
    );

    observer.observe(currentElement);

    return () => observer.unobserve(currentElement);
  }, [isLoading, onElementVisible]);

  return (
    <Box display="flex" justifyContent="center" marginTop={5} ref={elementRef}>
      {isLoading && <CircularProgress />}
    </Box>
  );
};
