import { Box, DialogContent, Link, Typography } from "@mui/material";
import { StyledDialog } from "Components/Shared/StyledComponents";
import styled from "styled-components";
import { TrackingWrapper } from "Components/Tracking/TrackingWrapper";
import { Link as RLink } from "react-router-dom";
import {
  RS_IRF_CZK_EUR_REDEMPTION_SINGLE_INVESTMENT_URL,
  RS_IRF_CZK_EUR_REDEMPTION_SINGLE_INVESTMENT_DIP_URL,
  TERMS_AND_CONDITIONS_URL,
} from "Utils/ExternalLinkUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { AppDocumentType } from "Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { DocumentLink } from "Components/Shared/DocumentLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const StyledDialogWithOffset = styled(StyledDialog)`
  margin-top: 90px;
  height: calc(100vh - 90px);
`;

type Props = {
  isDip: boolean;
  isOpened: boolean;
  onClose: () => void;
};

const PageResources = Resources.Contract.Detail.Redemption.NotAllowedFund;

export const RedemptionNotAllowedDialog: FunctionComponent<Props> = ({
  isDip,
  isOpened,
  onClose,
}) => {
  const { t } = useResource();

  return (
    <StyledDialogWithOffset
      open={isOpened}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <TrackingWrapper
          payload={{
            category: "ModalView",
            event: "FundNotAllowedDialog",
            action: isOpened ? "Open" : "Close",
          }}
        >
          <Box marginBottom={5}>
            <Typography>
              {t(PageResources.Disclaimer.Part1)}{" "}
              <DocumentLink target="_blank" href={TERMS_AND_CONDITIONS_URL}>
                {t(PageResources.Disclaimer.TosLinkText)}
              </DocumentLink>{" "}
              {t(PageResources.Disclaimer.Part2)}{" "}
              <DocumentLink
                target="_blank"
                href={
                  isDip
                    ? RS_IRF_CZK_EUR_REDEMPTION_SINGLE_INVESTMENT_DIP_URL
                    : RS_IRF_CZK_EUR_REDEMPTION_SINGLE_INVESTMENT_URL
                }
              >
                {t(PageResources.Disclaimer.FormLinkText)}
              </DocumentLink>{" "}
              {t(PageResources.Disclaimer.Part3)}{" "}
              <Link
                component={RLink}
                to={getPath(
                  AppRouting.MoreDocuments,
                  AppDocumentType.FundStatutes,
                )}
                fontWeight={600}
                color="secondary"
              >
                {t(PageResources.Disclaimer.StatusLinkText)}
              </Link>{" "}
              {t(PageResources.Disclaimer.Part4)}
            </Typography>
          </Box>
          <PrimaryButton small color="primary" onClick={onClose} fullWidth>
            {t(PageResources.Close)}
          </PrimaryButton>
        </TrackingWrapper>
      </DialogContent>
    </StyledDialogWithOffset>
  );
};
