import { FetchResponse } from "Api/Api";

export class ApiCallError<T> extends Error {
  public statusCode: number;
  public response: T;

  constructor(response: FetchResponse<T>) {
    super(`API call failed with status ${response.status}`);
    this.name = "APICallError";
    this.cause = response.error ?? response;
    this.statusCode = response.status;
    this.response = response.error;
  }
}

export function toAPICallError<T>(error: unknown): ApiCallError<T> {
  return new ApiCallError({ json: null as T, status: 0, args: null, error });
}

export function getCause(
  error: unknown | ApiCallError<unknown>,
): string | null {
  if (error instanceof ApiCallError) {
    const cause = error.cause as any;
    if (!!cause?.Detail) {
      return cause.Detail.toString();
    }
    return error.cause?.toString() ?? null;
  }
  return null;
}
