import {
  CreateContractCommandResultStatus,
  CreateContractCommandResult,
} from "Api/Api";
import { PageTitle } from "./Shared/PageTitle";
import { useCreateContract } from "Hooks/Contract/useCreateContract";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { useFormData } from "Hooks/Contract/useFormData";
import { useServerErrorMessage } from "Hooks/useHandleServerError";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import { NewContractStep } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, useMemo } from "react";
import { useModelingColor } from "Hooks/useModelingColor";
import { isNoU } from "Utils/ObjectUtils";
import { SignatureForm } from "Components/ContractBankConnections/Shared/SignatureForm";
import { useIsBiometrySignatureInProcess } from "Hooks/Contract/useIsBiometrySignatureInProcess";

const ComponentResources = Resources.Contract.NewContract.ContractSignature;

export const ContractSignature: FunctionComponent = () => {
  const { t } = useResource();
  const { nextStep } = useCreateContractStep();
  const { color, contrastColor } = useModelingColor();
  const { defaultValues } = useFormData<NewContractStep.PersonalData>({
    step: NewContractStep.PersonalData,
  });

  const {
    creatingContract: isCreatingContract,
    signatureHash,
    contractID,
    error,
  } = useCreateContract();

  const isBiometrySignatureInProcess = useIsBiometrySignatureInProcess();

  const isError = !!error;
  const validationErrorMessage = useServerErrorMessage(
    error,
    Resources.Validation.ServerError,
  );

  const errorMessages = useMemo((): string[] | null => {
    if (error) {
      const commandResult = error.cause as
        | CreateContractCommandResult
        | undefined;

      if (commandResult) {
        if (
          commandResult.status ===
          CreateContractCommandResultStatus.PersonalIdentificationNumberMismatch
        ) {
          return [t(ComponentResources.PersonalIdentificationNumberMismatch)];
        }

        if (
          commandResult.status ===
          CreateContractCommandResultStatus.DistributionNotAllowed
        ) {
          return [t(ComponentResources.DistributionNotAllowed)];
        }

        if (
          commandResult.status ===
          CreateContractCommandResultStatus.DipUnavailable
        ) {
          return [t(ComponentResources.DipUnavailable)];
        }
      }
    }

    if (isError) {
      return validationErrorMessage;
    }

    if (isNoU(signatureHash)) {
      return [t(ComponentResources.SignatureHashNotFoundError)];
    }

    return null;
  }, [error, isError, signatureHash, t, validationErrorMessage]);

  return (
    <SignatureForm
      isLoading={isCreatingContract}
      isBiometricsInProcess={isBiometrySignatureInProcess}
      contractID={contractID}
      signatureHash={signatureHash}
      productColor={color}
      productContrastColor={contrastColor}
      sms={{
        phone: defaultValues?.phone ?? "",
      }}
      biometrics={{
        signatureType: BiometricsSignatureType.Contract,
      }}
      translations={{
        isLoading: t(ComponentResources.WorkingText),
        sms: {
          title: t(ComponentResources.Title),
          codeSent: t(Resources.Signature.SmsSent),
          codeDetermination: t(ComponentResources.CodeDetermination),
          verificationError: t(ComponentResources.VerificationError),
          button: t(ComponentResources.SignContract),
        },
        biometrics: {
          title: t(ComponentResources.Biometrics),
          signatureError: t(ComponentResources.BiometricsError),
          signatureInProcessError: t(
            ComponentResources.BiometricsInProcessError,
          ),
        },
        signatureHashNotFoundError: t(
          ComponentResources.SignatureHashNotFoundError,
        ),
      }}
      components={{
        sms: {
          title: <PageTitle>{t(ComponentResources.Title)}</PageTitle>,
        },
        biometrics: {
          title: <PageTitle>{t(ComponentResources.Biometrics)}</PageTitle>,
        },
      }}
      error={error}
      errorMessages={errorMessages}
      onSuccess={() => {
        nextStep({
          isContractCreated: true,
          isContractSigned: true,
        });
      }}
    />
  );
};
