import { useNotificationSynchronization } from "Hooks/Notifications/useNotificationSynchronization";
import {
  useEffect,
  type FunctionComponent,
  type PropsWithChildren,
} from "react";

export const NotificationsProvider: FunctionComponent<
  PropsWithChildren<{}>
> = ({ children }) => {
  const { synchronizeFcmToken } = useNotificationSynchronization();

  useEffect(() => {
    synchronizeFcmToken();
  }, [synchronizeFcmToken]);

  return <>{children}</>;
};
