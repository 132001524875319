import * as React from "react";
import styled from "styled-components";

type Props = {
  height?: number;
  width?: number;
  circleWidth?: number;
  delay?: boolean;
  absolute?: boolean;
  white?: boolean;
};

const StyledLoader = styled.span<Props>`
  ${props => (props.width ? `width: ${props.width}px;` : "")}
  display: block;

  ${props =>
    props.absolute
      ? `
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        min-height: ${props.height ? `${props.height}px` : `100%`};
    `
      : `
        position: relative;
        height: ${props.height ? `${props.height}px` : `100%`};
    `}

  .circle {
    width: ${props => props.circleWidth ?? 25}px;
    height: ${props => props.circleWidth ?? 25}px;
    position: absolute;
    background: ${props =>
      props.white ? "#fff" : props.theme.palette.primary.main};
    border-radius: 50%;
    margin: -${props => (props.circleWidth ?? 25) / 2}px;
    animation: mesh 3s ease-in-out infinite -1.5s;
  }

  & > div .circle:last-child {
    animation-delay: 0s;
  }

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  & > div:last-child {
    transform: rotate(90deg);
  }

  @keyframes mesh {
    0% {
      transform-origin: 50% -100%;
      transform: rotate(0);
    }
    50% {
      transform-origin: 50% -100%;
      transform: rotate(360deg);
    }
    50.00001% {
      transform-origin: 50% 200%;
      transform: rotate(0deg);
    }
    100% {
      transform-origin: 50% 200%;
      transform: rotate(360deg);
    }
  }
`;

export const Loader: React.FunctionComponent<Props> = props => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (props.delay) {
      setVisible(false);
      const timeout = setTimeout(() => {
        setVisible(true);
      }, 300);
      return () => clearTimeout(timeout);
    } else {
      setVisible(true);
    }
  }, [props.delay]);

  return (
    <>
      {visible ? (
        <StyledLoader {...props}>
          <div className="set-one">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <div className="set-two">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </StyledLoader>
      ) : (
        <></>
      )}
    </>
  );
};
