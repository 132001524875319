import {
  DeleteAttachmentCommandResult,
  DeleteAttachmentCommandResultStatus,
  deleteAttachmentsAttachmentGuid,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";

export const deleteAttachmentAsync = createAsyncAction(
  "@attachments/DELETE_ATTACHMENT_REQUEST",
  "@attachments/DELETE_ATTACHMENT_SUCCESS",
  "@attachments/DELETE_ATTACHMENT_FAILURE",
)<{ guid: string }, [DeleteAttachmentCommandResult, { guid: string }], Error>();

function* deleteAttachment(
  action: ReturnType<typeof deleteAttachmentAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      deleteAttachmentsAttachmentGuid,
      action.payload.guid,
    );

    if (error) {
      if (
        error.response.status ===
        DeleteAttachmentCommandResultStatus.AttachmentNotFound
      ) {
        yield put(
          deleteAttachmentAsync.success(error.response, {
            guid: action.payload.guid,
          }),
        );
        return;
      }

      yield put(deleteAttachmentAsync.failure(error));
      return;
    }

    yield put(
      deleteAttachmentAsync.success(response, {
        guid: action.payload.guid,
      }),
    );
  } catch (err) {
    yield put(deleteAttachmentAsync.failure(err as Error));
  }
}

export function* watchDeleteAttachmentSaga() {
  yield takeLatest(getType(deleteAttachmentAsync.request), deleteAttachment);
}
