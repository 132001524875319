import { ColorsType } from "Components/Layout/Themes/Themes";

export const Colors: ColorsType = {
  PrimaryMain: "#FFFFFF",
  PrimaryDark: "#F0D40D",
  PrimaryText: "#F2F2F2",
  SecondaryMain: "#F2F2F2",
  SecondaryDark: "#F2F2F2",
  SecondaryText: "rgba(242,242,242,0.5)",
  Body: "#252525",
  Red: "#FF0000",
  RedDark: "#BF0000",
  Warning: "#F7B500",
  Green: "#77D276",
  Text: "#F2F2F2",
  TextLight: "rgba(242,242,242,0.5)",
  TextDisabled: "rgba(242,242,242,0.35)",
  Black: "#252525",
  White: "#ffffff",
  Gray: "#404040",
  DarkGray: "#303030",
  Border: "#DDDDDD",
  Blue: "#009FE3",
  MenuBar: "#404040",
  Divider: "#888888",
  Dip: "#94C0B0",
};
