import { PasswordResetForm } from "Components/Auth/Passwords/PasswordResetForm";
import { PasswordSetForm } from "Components/Auth/Passwords/PasswordSetForm";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { useAppSelector } from "Hooks/useAppSelector";
import { useEffect, type FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { resetError } from "State/Auth/AuthReducer";
import { ResetPasswordStep } from "State/Auth/Models/AuthStateModels";
import {
  resetPasswordAsync,
  setPasswordResetStep,
} from "State/Auth/Passwords/ResetPasswordState";
import { setPasswordAsync } from "State/Auth/Passwords/SetPasswordState";
import { getPath, AppRouting } from "Utils/UrlUtils";

export const ForgottenPasswordPage: FunctionComponent = _ => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { resetPasswordStep, forgottenPassword, isLoading } = useAppSelector(
    e => e.auth,
  );

  useEffect(() => {
    dispatch(resetError());

    return () => {
      dispatch(setPasswordResetStep(ResetPasswordStep.Email));
      dispatch(resetError());
    };
  }, [dispatch]);

  const onEmailSubmit = (login: string) => {
    dispatch(resetError());
    dispatch(resetPasswordAsync.request({ login }));
  };

  const onPasswordSet = (code: string, password: string) => {
    if (!!forgottenPassword.tokenID) {
      dispatch(resetError());
      dispatch(
        setPasswordAsync.request({
          navigate,
          model: {
            code,
            password,
            tokenID: forgottenPassword.tokenID,
          },
        }),
      );
    }
  };

  const email = forgottenPassword?.email;

  return (
    <UnauthenticatedLayout
      title={"Zapomenuté heslo"}
      onBackClick={() => navigate(getPath(AppRouting.SignIn))}
    >
      {resetPasswordStep === ResetPasswordStep.Email && (
        <PasswordResetForm
          serverError={forgottenPassword.error}
          onSubmit={onEmailSubmit}
          isLoading={isLoading}
        />
      )}

      {resetPasswordStep === ResetPasswordStep.SetPassword && !!email && (
        <PasswordSetForm
          serverError={forgottenPassword.error}
          email={email}
          onSubmit={data => onPasswordSet(data.code, data.password)}
          onResend={() => onEmailSubmit(email)}
          isLoading={isLoading}
        />
      )}
    </UnauthenticatedLayout>
  );
};

export default ForgottenPasswordPage;
