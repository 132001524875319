import { rehydrateContractDraft } from "State/Contracts/Shared/Draft";
import { ActionType, createReducer } from "typesafe-actions";
import { resetNewContract } from "../Shared/Actions";

const getRehydrateContractState = () => ({
  isRehydrated: false,
});

type RehydrateContractState = {
  isRehydrated: boolean;
};

type RehydrateContractAction =
  | ActionType<typeof rehydrateContractDraft>
  | ActionType<typeof resetNewContract>;

export const rehydrateContractReducer = createReducer<
  RehydrateContractState,
  RehydrateContractAction
>(getRehydrateContractState())
  .handleAction(rehydrateContractDraft, state => ({
    ...state,
    isRehydrated: true,
  }))
  .handleAction(resetNewContract, _ => getRehydrateContractState());
