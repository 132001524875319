import { PurchaseFormDataWrapper } from "Components/ContractPurchase/Purchase/PurchaseFormDataWrapper";
import { usePurchaseTitle } from "Hooks/Contract/usePurchaseTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import {
  PurchaseStep,
  setPurchaseConfiguration,
  setPurchaseStep,
} from "State/Contracts/Purchase/PurchaseState";
import { type FunctionComponent } from "react";

type Props = {
  contractID: number;
  defaultIsin: string;
  onSelectedProductChange: (isin: string | null) => void;
};

export const ConfigurationStep: FunctionComponent<Props> = props => {
  const dispatch = useAppDispatch();
  const pageTitle = usePurchaseTitle();

  return (
    <PurchaseFormDataWrapper
      pageTitle={pageTitle}
      contractID={props.contractID}
      defaultIsin={props.defaultIsin}
      investmentType="Unlimited"
      onSubmit={({
        fundISIN,
        currency,
        singleInvestment,
        periodicalInvestment,
      }) => {
        dispatch(
          setPurchaseConfiguration({
            isin: fundISIN,
            singleInvestment: singleInvestment ?? 0,
            periodicalInvestment: periodicalInvestment ?? 0,
            currency,
          }),
        );

        dispatch(setPurchaseStep(PurchaseStep.Success));
      }}
      onSelectedProductChange={props.onSelectedProductChange}
    />
  );
};
