import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { CategorySelector } from "Components/ContractModeling/NewInvestmentForm/components/CategorySelector";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledButtonBox } from "Components/Shared/StyledComponents";
import { useSaveContractDraft } from "Hooks/Contract/useSaveContractDraft";
import { useYupFormSchema } from "Hooks/Investment/NewInvestment/NewInvestmentForm/useYupFormSchema";
import { useFormData } from "Hooks/Investment/NewInvestment/useFormData";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { setNextStep } from "State/Contracts/Modeling/ModelingState/ModelingStateState";
import { InformationFormModel } from "State/Contracts/Modeling/Models";
import { ContractModelingStep } from "State/Contracts/Modeling/Models";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";
import { getFormattedNowDate } from "Utils/DateUtils";
import { FormProvider, useForm } from "react-hook-form";
import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";

const PageResources = Resources.Investments.NewInvestmentForm;

export const NewInvestmentForm: FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const { isLoading, postDraft } = useSaveContractDraft();

  const schema = useYupFormSchema();
  const { defaultValues } = useFormData<ContractModelingStep.Information>({
    step: ContractModelingStep.Information,
  });
  const form = useForm<InformationFormModel>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { control, formState, setValue, watch, handleSubmit } = form;

  const onSubmit = (formData: InformationFormModel) => {
    if (!formData.name) {
      // replaceAll is fix for the app if app is generating non-breaking spaces in date
      // which causes that name is saved with question marks
      const name = `${t(
        PageResources.DefaultName,
      )} ${getFormattedNowDate()}`.replaceAll(/\s/g, " ");

      formData = {
        ...formData,
        name,
      };
    }

    dispatch(setNextStep({ formData }));
    postDraft({ isContractCreated: false, isContractSigned: false });
  };

  return (
    <FormProvider {...form}>
      <div>
        <ContractGuideWrapper>
          <PageTitle>{t(PageResources.Title)}</PageTitle>
        </ContractGuideWrapper>

        <Typography>{t(PageResources.Label)}</Typography>

        <Box marginY={3}>
          <BlInputFormField
            label={t(PageResources.Name)}
            placeholder={t(PageResources.Placeholder)}
            name="name"
            control={control}
            errors={formState.errors}
            hasTracking
          />
        </Box>

        <CategorySelector
          label={t(PageResources.Categories)}
          watch={() => watch("category")}
          setValue={category => setValue("category", category || undefined)}
        />
      </div>
      <StyledButtonBox>
        <PrimaryButton
          color="primary"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          trackingTag={t(Resources.Common.Continue)}
        >
          {t(Resources.Common.Continue)}
        </PrimaryButton>
      </StyledButtonBox>
    </FormProvider>
  );
};
