import {
  StatusCommandResult,
  deleteContractContractIDInstruction,
} from "Api/Api";
import { logError } from "ErrorService";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import { safeApiCall } from "State/Utils";

import { put, takeLatest } from "typed-redux-saga";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";

export type TerminateContractStateType = FetchStateType<StatusCommandResult>;

export const terminateContractState = (): TerminateContractStateType =>
  getFetchStateDefaultValue();

export type TerminateContractActionType = ActionType<
  typeof terminateContractAsync
>;

export const terminateContractAsync = createAsyncAction(
  "@contract/TERMINATE_CONTRACT_REQUEST",
  "@contract/TERMINATE_CONTRACT_SUCCESS",
  "@contract/TERMINATE_CONTRACT_FAILURE",
)<number, StatusCommandResult, Error>();

function* terminateContract(
  action: ActionType<typeof terminateContractAsync.request>,
): Generator {
  try {
    const { status, response, error } = yield* safeApiCall(
      deleteContractContractIDInstruction,
      action.payload,
    );

    if (error) {
      yield put(terminateContractAsync.failure(error));
      logError(
        error,
        {
          request: {
            contractID: action.payload,
          },
          response: {
            status,
            error,
          },
        },
        false,
      );

      return;
    }

    yield put(terminateContractAsync.success(response));
  } catch (err) {
    yield put(terminateContractAsync.failure(err as Error));
  }
}

export function* terminateContractSaga() {
  yield takeLatest(getType(terminateContractAsync.request), terminateContract);
}
