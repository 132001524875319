import { ReactNode, type FunctionComponent } from "react";
import {
  Box,
  ListItemIcon,
  MenuItem as MuiMenuItem,
  Typography,
} from "@mui/material";
import { useFormatLocale } from "Hooks/DateFns/useFormatLocale";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { CurrencyRenderer } from "Components/Shared/CurrencyRenderer";
import { LAYOUT_PADDING_X } from "Constants/Layout";

import styled from "styled-components";
import { formatCurrency } from "Utils/CurrencyUtils";
import { Resources, useResource } from "Translations/Resources";
import { InstructionValueType, CurrencyCode } from "Api/Api";

const StyledMenuItem = styled(MuiMenuItem)`
  padding: ${props => props.theme.spacing(2, LAYOUT_PADDING_X)};
  display: flex;
  gap: ${props => props.theme.spacing(3)};
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.palette.secondary.main}27;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 25px;
    height: 25px;
  }
`;

const StyledDateTypography = styled(Typography)`
  font-size: ${props => props.theme.typography.fontSize}px;
`;

type Props = {
  amount?: number | null | undefined;
  date?: Date;
  numberOfItems?: number | null | undefined;
  pricePerItem?: number | null | undefined;
  name: ReactNode;
  icon: ReactNode | null | undefined;
  dateFormat?: string;
  instructionValueType: InstructionValueType;
  currency: CurrencyCode | string | null | undefined;
};

export const MenuItem: FunctionComponent<Props> = ({
  amount,
  date,
  name,
  pricePerItem,
  numberOfItems,
  icon,
  dateFormat = "P",
  currency,
  instructionValueType,
}) => {
  const { t } = useResource();
  const format = useFormatLocale();

  return (
    <StyledMenuItem as="div">
      <StyledFlex $gap={1} $fullHeight $alignItems="center">
        <ListItemIcon>
          <IconCircleWrapper size={45}>
            <StyledIconWrapper>{icon}</StyledIconWrapper>
          </IconCircleWrapper>
        </ListItemIcon>
        <div>
          {!!date && (
            <StyledDateTypography>
              {format(date, dateFormat)}
            </StyledDateTypography>
          )}
          <div>{name}</div>
        </div>
      </StyledFlex>
      <StyledFlex $gap={2}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          width="max-content"
        >
          {instructionValueType === InstructionValueType.Amount &&
            !!currency && (
              <CurrencyRenderer
                value={amount}
                currency={currency}
                decimalPlaces={2}
                variant="body1"
                fontWeight={700}
              />
            )}

          {instructionValueType === InstructionValueType.Pieces && (
            <Typography component="div" variant="body1" fontWeight={700}>
              {`${amount} ks`}
            </Typography>
          )}

          {instructionValueType === InstructionValueType.All && (
            <Typography component="div" variant="body1" fontWeight={700}>
              {amount}
            </Typography>
          )}

          {!!numberOfItems && (
            <Typography component="div">
              {t(Resources.Common.PiecesShort, { count: numberOfItems })}
            </Typography>
          )}
          {!!pricePerItem && (
            <Typography component="div">
              {formatCurrency(pricePerItem, currency, {
                decimalPlaces: 4,
                fixedDecimalPlaces: true,
              })}
              /{t(Resources.Common.PiecesSuffix)}
            </Typography>
          )}
        </Box>
      </StyledFlex>
    </StyledMenuItem>
  );
};
