import {
  PostGenerateQrCodeStateType,
  postGenerateQrCodeReducer,
  postGenerateQrCodeSaga,
  postGenerateQrCodeState,
} from "State/Payments/generateQrCode/GenerateQrCodeState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type PaymentsState = {
  generateQrCode: PostGenerateQrCodeStateType;
};

export const getInitialState = (): PaymentsState => ({
  generateQrCode: postGenerateQrCodeState(),
});

export function* watchPaymentsSaga() {
  yield all([postGenerateQrCodeSaga()]);
}

export const paymentsReducer = combineReducers<PaymentsState>({
  generateQrCode: postGenerateQrCodeReducer,
});
