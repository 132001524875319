import { BankIDProcessAudience } from "Api/Api";
import { BankIDRedirect } from "Components/BankID/BankIDRedirect";
import { ContractGuideWrapper } from "Components/Shared/ContractGuideWrapper";
import { useIsDipSelected } from "Hooks/Contract/useIsDipSelected";
import { PageStepProps } from "Pages/Contracts/CreatePage";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";

type Props = PageStepProps;

export const BankIDPage: FunctionComponent<Props> = ({ isActive }) => {
  const { t } = useResource();
  const isDip = useIsDipSelected();

  return (
    <ContractGuideWrapper isDipWrapped={isDip}>
      <BankIDRedirect
        isActive={isActive}
        processAudience={BankIDProcessAudience.ContractCreate}
        translations={{
          redirecting: t(
            Resources.Contract.NewContract.BankID.WaitingForBankID,
          ),
        }}
      />
    </ContractGuideWrapper>
  );
};
