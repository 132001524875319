import {
  ContractDraftDto,
  SaveContractDraftCommandResult,
  postContractDraft,
} from "Api/Api";
import { toast } from "react-toastify";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { Resources } from "Translations/Resources";

import { put, takeLatest } from "typed-redux-saga";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";

export type PostDraftStateType = FetchStateType<SaveContractDraftCommandResult>;

export const postDraftState = (): PostDraftStateType =>
  getFetchStateDefaultValue();

export type PostDraftActionType = ActionType<typeof postDraftAsync>;

export const postDraftAsync = createAsyncAction(
  "@contract/POST_DRAFT_REQUEST",
  "@contract/POST_DRAFT_SUCCESS",
  "@contract/POST_DRAFT_FAILURE",
)<
  {
    draft: ContractDraftDto;
    translate: (resourcePath: string, options?: any) => string;
  },
  SaveContractDraftCommandResult,
  Error
>();

function* postDraft(
  action: ActionType<typeof postDraftAsync.request>,
): Generator {
  try {
    const { status, response, error } = yield* safeApiCall(
      postContractDraft,
      action.payload.draft,
    );

    if (status === 422) {
      toast.error(
        action.payload.translate(Resources.Errors.Error422.ContractDraft),
      );
    }

    if (error) {
      yield put(postDraftAsync.failure(error));
      return;
    }

    yield put(postDraftAsync.success(response));
  } catch (err) {
    yield put(postDraftAsync.failure(err as Error));
  }
}

export function* postDraftSaga() {
  yield takeLatest(postDraftAsync.request, postDraft);
}

export const postDraftReducer = createReducer<
  PostDraftStateType,
  PostDraftActionType
>(postDraftState())
  .handleAction(postDraftAsync.request, handleActionRequest)
  .handleAction(postDraftAsync.failure, handleActionFailure)
  .handleAction(postDraftAsync.success, handleActionSuccess);
