import { ContractDraftDto, getContractDraft } from "Api/Api";
import { NewContractStep } from "State/Contracts/Create/Models";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { resetNewContract } from "State/Contracts/Shared/Actions";
import { rehydrateContractDraft } from "State/Contracts/Shared/Draft";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import { RootStateType } from "State/Store";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";

import { put, select, takeLatest } from "typed-redux-saga";
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type GetDraftStateType = FetchStateType<ContractDraftDto | null>;

export const getDraftState = (): GetDraftStateType =>
  getFetchStateDefaultValue();

export type GetDraftActionType =
  | ActionType<typeof getDraftAsync>
  | ActionType<typeof resetNewContract>;

export const getDraftAsync = createAsyncAction(
  "@contract/GET_DRAFT_REQUEST",
  "@contract/GET_DRAFT_SUCCESS",
  "@contract/GET_DRAFT_FAILURE",
)<void, ContractDraftDto | null, Error>();

function* getDraft(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getContractDraft);

    if (error) {
      yield put(getDraftAsync.failure(error));
      yield put(rehydrateContractDraft(null));
      return;
    }

    yield put(getDraftAsync.success(response));
    yield put(
      rehydrateContractDraft({
        draft: JSON.parse(response.jsonData),
        isContractCreated: response.isContractCreated,
        isContractSigned: response.isContractSigned,
      }),
    );

    if (response.isContractCreated && !response.isContractSigned) {
      const isBiometrySignatureInProcess = yield* select(
        (s: RootStateType) => s.contracts.create.isBiometrySignatureInProcess,
      );

      if (isBiometrySignatureInProcess) {
        return;
      }

      const formData = yield* select(
        (s: RootStateType) => s.contracts.create.formData,
      );

      const signatureHash =
        formData?.[NewContractStep.ContractSignature]?.signatureHashList?.[0];

      if (!!signatureHash) {
        yield put(initializeSmsSignatureAsync.request({ signatureHash }));
      }
    }
  } catch (err) {
    yield put(getDraftAsync.failure(err as Error));
    yield put(rehydrateContractDraft(null));
  }
}

export function* getDraftSaga() {
  yield takeLatest(getType(getDraftAsync.request), getDraft);
}

export const getDraftReducer = createReducer<
  GetDraftStateType,
  GetDraftActionType
>(getDraftState())
  .handleAction(getDraftAsync.request, handleActionRequest)
  .handleAction(getDraftAsync.failure, handleActionFailure)
  .handleAction(getDraftAsync.success, handleActionSuccess)
  .handleAction(resetNewContract, _ => getDraftState());
