import { Typography } from "@mui/material";
import { SignatureFormLoader } from "Components/ContractBankConnections/Shared/SignatureFormLoader";
import { SignatureFormTitle } from "Components/ContractBankConnections/Shared/SignatureFormTitle";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  isBiometrics: boolean;
  isBiometricsInProgress: boolean;
  translations: {
    title: string;
    isLoading: string;
    signatureError: string;
    signatureInProcessError: string;
  };
  components?: {
    title?: ReactNode;
  };
};

export const SignatureFormBiometrics: FunctionComponent<Props> = props => {
  const { isBiometrics, translations, components } = props;

  const biometricsError = useAppSelector(s => s.biometrics.signature.error);

  if (isBiometrics && !biometricsError) {
    return (
      <SignatureFormLoader
        title={translations.title}
        titleComponent={components?.title}
        working={translations.isLoading}
      />
    );
  }

  return (
    <div>
      <SignatureFormTitle
        title={translations.title}
        titleComponent={components?.title}
      />

      {isBiometrics && (
        <Typography color="error">{translations.signatureError}</Typography>
      )}

      {!isBiometrics && (
        <Typography color="error">
          {translations.signatureInProcessError}
        </Typography>
      )}
    </div>
  );
};
