import { CardContent, Divider, Typography } from "@mui/material";
import { ContractOverviewPortfolioItemDto } from "Api/Api";
import {
  ProductSelectorHeight,
  ProductSelectorPlaceholder,
  ProductSelectorWidth,
} from "Components/ContractExchangeAssets/components/ProductSelectorPlaceholder";
import { CurrencyRenderer } from "Components/Shared/CurrencyRenderer";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { StyledDarkCard, StyledFlex } from "Components/Shared/StyledComponents";
import { useFormattedProductClass } from "Hooks/Products/useFormattedProductClass";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

export const StyledProductSelectorCard = styled(StyledDarkCard)`
  height: ${ProductSelectorHeight}px;
  width: ${ProductSelectorWidth}px;

  .MuiCardContent-root {
    height: 100%;
    width: 100%;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: ${props => props.theme.spacing(1, 0)};
`;

type Props = {
  onClick?: () => void;
} & Partial<
  Pick<
    ContractOverviewPortfolioItemDto,
    "isin" | "quantity" | "amount" | "currency"
  >
>;

export const ProductSelector: FunctionComponent<Props> = ({
  isin,
  quantity: pieces,
  amount,
  currency,
  onClick,
}) => {
  const { t } = useResource();
  const productClass = useFormattedProductClass(isin);

  if (!!isin) {
    return (
      <StyledProductSelectorCard onClick={onClick}>
        <CardContent>
          {!!amount || !!pieces ? (
            <>
              <ProductLogo
                isin={isin}
                name={productClass}
                imageWidth={104}
                nameNoWrap
                nameTextOverflow="ellipsis"
                nameMaxWidth="12ch"
              />
              <StyledDivider />
              <Typography variant="body1" color="primary">
                {t(Resources.Common.PiecesShort, { count: pieces ?? 0 })}
              </Typography>
              <CurrencyRenderer
                value={amount ?? 0}
                currency={currency}
                decimalPlaces={2}
                variant="body1"
                color="primary"
              />
            </>
          ) : (
            <>
              <StyledFlex
                $justifyContent="center"
                $alignItems="center"
                $fullHeight
              >
                <ProductLogo isin={isin} name={productClass} imageWidth={104} />
              </StyledFlex>
            </>
          )}
        </CardContent>
      </StyledProductSelectorCard>
    );
  }

  return <ProductSelectorPlaceholder onClick={onClick} />;
};
