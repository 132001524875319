export enum BankIdScopes {
  ProfileName = "profile.name",
  ProfileIdCards = "profile.idcards",
  ProfileLegalStatus = "profile.legalstatus",
  ProfileEmail = "profile.email",
  ProfilePhoneNumber = "profile.phonenumber",
  ProfilePaymentAccounts = "profile.paymentAccounts",
  ProfileAddresses = "profile.addresses",
  ProfileBirthplaceNationality = "profile.birthplaceNationality",
  ProfileBirthNumber = "profile.birthnumber",
  ProfileBirthDate = "profile.birthdate",
  ProfileGender = "profile.gender",
  ProfileTitles = "profile.titles",
}

export enum BankIdClaims {
  GivenName = "given_name",
  FamilyName = "family_name",
  MiddleName = "middle_name",
  TitlePrefix = "title_prefix ",
  TitleSuffix = "title_suffix",
  Gender = "gender",
  BirthDate = "birthdate",
  BirthCountry = "birthcountry",
  Age = "age ",
  DateOfDeath = "date_of_death",
  BirthNumber = "birthnumber",
  BirthPlace = "birthplace",
  PrimaryNationality = "primary_nationality ",
  Nationalities = "nationalities",
  MaritalStatus = "maritalstatus",
  Type = "type",
  Street = "street",
  BuildingApartment = "buildingapartment",
  StreetNumber = "streetnumber",
  EvidenceNumber = "evidencenumber",
  City = "city",
  CityArea = "cityarea",
  ZipCode = "zipcode",
  Country = "country",
  RuianReference = "ruian_reference",
  Description = "description",
  PaymentAccounts = "paymentAccounts",
  PaymentAccountsDetails = "paymentAccountsDetails",
  Email = "email",
  PhoneNumber = "phone_number",
  UpdatedAt = "updated_at",
  Pep = "pep",
  LimitedLegalCapacity = "limited_legal_capacity",
  VerifiedClaims = "verified_claims",
  Verification = "verification",
  Locale = "locale",
  Number = "number",
  ValidTo = "valid_to",
  IssueDate = "issue_date",
  Issuer = "issuer ",
}

export const TrackableBankIdErrors = [
  `${BankIdScopes.ProfileName}:${BankIdClaims.GivenName}`,
  `${BankIdScopes.ProfileName}:${BankIdClaims.FamilyName}`,
  `${BankIdScopes.ProfileName}:${BankIdClaims.MiddleName}`,
  `${BankIdScopes.ProfileBirthDate}:${BankIdClaims.BirthDate}`,
  `${BankIdScopes.ProfileAddresses}:${BankIdClaims.Type}`,
  `${BankIdScopes.ProfileAddresses}:${BankIdClaims.Street}`,
  `${BankIdScopes.ProfileAddresses}:${BankIdClaims.BuildingApartment}`,
  `${BankIdScopes.ProfileAddresses}:${BankIdClaims.City}`,
  `${BankIdScopes.ProfileAddresses}:${BankIdClaims.ZipCode}`,
  `${BankIdScopes.ProfileAddresses}:${BankIdClaims.Country}`,
  `${BankIdScopes.ProfileGender}:${BankIdClaims.Gender}`,
  `${BankIdScopes.ProfileBirthNumber}:${BankIdClaims.BirthNumber}`,
  `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.BirthPlace}`,
  `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.BirthCountry}`,
  `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.PrimaryNationality}`,
  `${BankIdScopes.ProfileBirthplaceNationality}:${BankIdClaims.Nationalities}`,
  `${BankIdScopes.ProfileIdCards}:${BankIdClaims.Country}`,
  `${BankIdScopes.ProfileIdCards}:${BankIdClaims.Number}`,
  `${BankIdScopes.ProfileLegalStatus}:${BankIdClaims.Pep}`,
];

export type BankIdDocumentType =
  | "ID"
  | "P"
  | "DL"
  | "IR"
  | "VS"
  | "PS"
  | "IX"
  | "IE"
  | "OP"
  | "CA"
  | "UNKNOWN";

export const AllowedBankIdDocumentTypes: BankIdDocumentType[] = [
  "ID",
  "P",
  "IR",
  "PS",
  "IX",
  "IE",
  "OP",
  "CA",
];
