import { put, takeLatest } from "redux-saga/effects";
import {
  BankIDProcessAudience,
  BankIDProcessStatus,
  ProcessBankIDVerificationCommandResultStatus,
  getBankIdAuthVerify,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { NavigateFunction } from "react-router";
import { getTrackingSessionID } from "Utils/TrackingUtils";
import { setBankIDProfile } from "State/Contracts/Create/BankIDProfile/SetBankIDProfile";
import { safeApiCall } from "State/Utils";
import { resolveBankIDCallbackRedirectRoute } from "Utils/BankIDUtils";

export const processBankIDVerificationAsync = createAsyncAction(
  "@bankID/PROCESS_BANK_ID_VERIFICATION_REQUEST",
  "@bankID/PROCESS_BANK_ID_VERIFICATION_SUCCESS",
  "@bankID/PROCESS_BANK_ID_VERIFICATION_FAILURE",
)<
  {
    processID: string;
    processAudience: BankIDProcessAudience;
    accessToken: string;
    navigate: NavigateFunction;
    t: (resourcePath: string, options?: any) => string;
  },
  BankIDProcessStatus,
  Error
>();

function* processBankIDVerification(
  action: ReturnType<typeof processBankIDVerificationAsync.request>,
): Generator {
  try {
    const { processID, processAudience, accessToken, navigate, t } =
      action.payload;

    const { response, error } = yield* safeApiCall(
      getBankIdAuthVerify,
      processID,
      processAudience,
      accessToken,
      getTrackingSessionID(),
    );

    if (error) {
      yield put(processBankIDVerificationAsync.failure(error));
      return;
    }

    if (
      response.status ===
        ProcessBankIDVerificationCommandResultStatus.Success &&
      response.verificationProcess?.profile
    ) {
      yield put(
        setBankIDProfile.request({
          processAudience,
          profile: response.verificationProcess.profile,
          t,
        }),
      );

      yield put(
        processBankIDVerificationAsync.success(
          response.verificationProcess.processStatus,
        ),
      );

      navigate(resolveBankIDCallbackRedirectRoute(processAudience));
    } else {
      yield put(
        processBankIDVerificationAsync.failure(new Error(response.status)),
      );
    }
  } catch (err) {
    yield put(processBankIDVerificationAsync.failure(err as Error));
  }
}

export function* watchProcessBankIDVerificationSaga() {
  yield takeLatest(
    getType(processBankIDVerificationAsync.request),
    processBankIDVerification,
  );
}
