import { getUserContact, UserContactInfoQueryResult } from "Api/Api";
import { produce } from "immer";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { safeApiCall } from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type ContactInfoState = {
  isLoading: boolean;
  error?: Error | null;
} & Pick<UserContactInfoQueryResult, "contactInfo">;

const initialContactInfoState: ContactInfoState = {
  isLoading: false,
  contactInfo: null,
  error: null,
};

export const contactInfoAsync = createAsyncAction(
  "profile-settings/GET_CONTACT_INFO_REQUEST",
  "profile-settings/GET_CONTACT_INFO_SUCCESS",
  "profile-settings/GET_CONTACT_INFO_FAILURE",
)<void, UserContactInfoQueryResult, Error>();

export type ContactInfoActions = ActionType<typeof contactInfoAsync>;

function* contactInfo(
  action: ActionType<typeof contactInfoAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(getUserContact);

    if (error) {
      yield put(contactInfoAsync.failure(error));
      return;
    }

    if (!response.contactInfo) {
      yield put(
        contactInfoAsync.failure(
          new ApplicationError(
            "Failed to fetch user contact information.",
            ErrorLevel.Error,
          ),
        ),
      );
      return;
    }

    yield put(contactInfoAsync.success(response));
  } catch (err) {
    yield put(contactInfoAsync.failure(err as Error));
  }
}

export function* watchContactInfoSaga() {
  yield takeLeading(getType(contactInfoAsync.request), contactInfo);
}

export const contactInfoReducer = createReducer<
  ContactInfoState,
  ContactInfoActions
>(initialContactInfoState)
  .handleAction(contactInfoAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.contactInfo = null;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(contactInfoAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.contactInfo = action.payload.contactInfo;
      return draft;
    }),
  )
  .handleAction(contactInfoAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  );
