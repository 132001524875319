import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { usePageTitle } from "Hooks/ContractExchangeAssets/usePageTitle";
import { useDesktop } from "Hooks/useDesktop";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

type Props = {
  contractID: number;
};

const ComponentResources = Resources.Contract.ExchangeAssets.Success;

export const SuccessStep: FunctionComponent<Props> = ({ contractID }) => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();

  const title = usePageTitle();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <PageTitle showOnMobile={isDesktop}>{title}</PageTitle>
        <Typography>{t(ComponentResources.Description)}</Typography>
      </div>
      <PrimaryButton
        color="primary"
        onClick={() => navigate(getPath(AppRouting.ContractDetail, contractID))}
      >
        {t(ComponentResources.Submit)}
      </PrimaryButton>
    </>
  );
};
