import { Radio, type RadioProps } from "@mui/material";
import { type FunctionComponent } from "react";
import styled from "styled-components";

const StyledIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.palette.secondary.main}60;
  background-color: ${props => props.theme.palette.secondary.main}15;
`;

const StyledIconChecked = styled(StyledIcon)`
  &::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.palette.primary.light};
    border-radius: 50%;
  }
`;

type Props = Omit<RadioProps, "icon" | "checkedIcon">;

export const RadioButton: FunctionComponent<Props> = props => {
  return (
    <Radio
      icon={<StyledIcon />}
      checkedIcon={<StyledIconChecked />}
      {...props}
    />
  );
};
