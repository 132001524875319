import { CountryListQueryResult, getCodeListCountries } from "Api/Api";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";

export type GetCountriesStateType = FetchStateType<CountryListQueryResult>;

export const getCountriesState = (): GetCountriesStateType =>
  getFetchStateDefaultValue();

export type GetCountriesActionType = ActionType<typeof getCountriesAsync>;

export const getCountriesAsync = createAsyncAction(
  "@codeList/GET_COUNTRIES_REQUEST",
  "@codeList/GET_COUNTRIES_SUCCESS",
  "@codeList/GET_COUNTRIES_FAILURE",
)<void, CountryListQueryResult, Error>();

function* getCountries(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getCodeListCountries);
    if (error) {
      yield put(getCountriesAsync.failure(error));
      return;
    }

    yield put(getCountriesAsync.success(response));
  } catch (err) {
    yield put(getCountriesAsync.failure(err as Error));
  }
}

export function* getCountriesSaga() {
  yield takeLatest(getType(getCountriesAsync.request), getCountries);
}

export const getCountriesReducer = createReducer<
  GetCountriesStateType,
  GetCountriesActionType
>(getCountriesState())
  .handleAction(getCountriesAsync.request, handleActionRequest)
  .handleAction(getCountriesAsync.failure, handleActionFailure)
  .handleAction(getCountriesAsync.success, handleActionSuccess);
