import { Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledHtmlLink } from "Components/Routes/UnstyledLink";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledFlex } from "Components/Shared/StyledComponents";
import styled from "styled-components";

const Trivia = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  margin: ${props => props.theme.spacing(1, 0, 3, 0)};
`;

const StyledText = styled(StyledFlex)`
  flex-wrap: wrap;
`;

export const ContactPage: React.FunctionComponent = _ => {
  return (
    <AuthenticatedLayout title="Kontakt" isLogoShown>
      <PageTitle>Kontakt</PageTitle>

      <br />
      <StyledText $gap={1}>
        <Typography>E-mail:</Typography>
        <StyledHtmlLink href="mailto:info@Investika.io">
          info@Investika.io
        </StyledHtmlLink>
      </StyledText>
      <StyledText $gap={1}>
        <Typography>Telefon:</Typography>
        <StyledHtmlLink href="tel:+420739437184">
          +420 739 437 184
        </StyledHtmlLink>
      </StyledText>

      <Trivia fontSize={13}>
        Telefonicky nás můžete kontaktovat v pracovní dny od 9:00 do 16:00.
      </Trivia>

      <Typography fontWeight={600}>Provozovatel</Typography>
      <Typography>SmartFit s.r.o.</Typography>
      <Typography>IČ: 11833866</Typography>
      <br />

      <Typography fontWeight={600}>Bankovní spojení</Typography>
      <Typography>Fio banka: 2702109034/2010</Typography>
    </AuthenticatedLayout>
  );
};

export default ContactPage;
