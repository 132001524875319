import { TabProps } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { ModellingStep } from "Components/Profile/TwoFactorVerification/ModellingStep";
import { VerificationStep } from "Components/Profile/TwoFactorVerification/VerificationStep";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { useIsLead } from "Hooks/User/useIsLead";
import { FunctionComponent, useCallback, useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import { contactInfoAsync } from "State/Profile/ContactInfo/ClientContactReducer";
import {
  resetTwoFactorVerificationState,
  TwoFactorVerificationSteps,
} from "State/Profile/TwoFactorVerification/TwoFactorVerificationReducer";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

const Components: {
  [key in TwoFactorVerificationSteps]: FunctionComponent<TabProps>;
} = {
  [TwoFactorVerificationSteps.Modelling]: ModellingStep,
  [TwoFactorVerificationSteps.Verification]: VerificationStep,
};

const PageResources = Resources.Profile.Settings.TwoFactorVerification;

export const TwoFactorVerificationPage: FunctionComponent = () => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const { actualStep } = useAppSelector(s => s.profile.twoFactorVerification);

  const isLead = useIsLead();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const Component = Components[actualStep];

  useEffect(() => {
    dispatch(resetTwoFactorVerificationState());
    dispatch(contactInfoAsync.request());
  }, [dispatch]);

  const handleBackClick = useCallback(() => {
    switch (actualStep) {
      case TwoFactorVerificationSteps.Modelling:
      case TwoFactorVerificationSteps.Verification:
        navigate(getPath(AppRouting.ProfileSettings), {
          state: { from: getPath(AppRouting.TwoFactorVerification) },
        });
        return;
    }
  }, [actualStep, navigate]);

  if (isLead) {
    return <Navigate to={getPath(AppRouting.ProfileSettings)} />;
  }

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={handleBackClick}
    >
      {Component && <Component />}
    </AuthenticatedLayout>
  );
};
