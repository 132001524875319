import { CodeListItemDto, getCodeListCurrencies } from "Api/Api";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";

export type GetCurrenciesStateType = FetchStateType<CodeListItemDto[]>;

export const getCurrenciesState = (): GetCurrenciesStateType =>
  getFetchStateDefaultValue();

export type GetCurrenciesActionType = ActionType<typeof getCurrenciesAsync>;

export const getCurrenciesAsync = createAsyncAction(
  "@codeList/GET_CURRENCIES_REQUEST",
  "@codeList/GET_CURRENCIES_SUCCESS",
  "@codeList/GET_CURRENCIES_FAILURE",
)<void, CodeListItemDto[], Error>();

function* getCurrencies(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getCodeListCurrencies);

    if (error) {
      yield put(getCurrenciesAsync.failure(error));
      return;
    }

    yield put(getCurrenciesAsync.success(response));
  } catch (err) {
    yield put(getCurrenciesAsync.failure(err as Error));
  }
}

export function* getCurrenciesSaga() {
  yield takeLatest(getType(getCurrenciesAsync.request), getCurrencies);
}

export const getCurrenciesReducer = createReducer<
  GetCurrenciesStateType,
  GetCurrenciesActionType
>(getCurrenciesState())
  .handleAction(getCurrenciesAsync.request, handleActionRequest)
  .handleAction(getCurrenciesAsync.failure, handleActionFailure)
  .handleAction(getCurrenciesAsync.success, handleActionSuccess);
