import { SignInResult } from "Api/Api";
import { isAfter, parseJSON } from "date-fns";
import { setUnauthenticatedUrl } from "State/Auth/AuthReducer";
import { RootStateType } from "State/Store";
import { setToken } from "Utils/LocalStorageUtils";
import { AppRouting, getPath, resolveStartPage } from "Utils/UrlUtils";
import { put, select } from "typed-redux-saga";
import { NavigateFunction } from "react-router";
import {
  setTracking,
  track,
  trackUserIdentification,
} from "Utils/TrackingUtils";
import { captureException } from "@sentry/react";
import { isWebView, postMessageFromWebView } from "Utils/WebViewUtils";
import { WebViewMessageTypes } from "Models/WebViewModels";

export const JWT_KEY = "jwtToken";

export function checkIsAuthenticated(tokenExpiration: string | null) {
  if (tokenExpiration === null) {
    return false;
  }
  return isAfter(parseJSON(tokenExpiration), new Date());
}

export function* afterSignInSuccess(
  response: SignInResult,
  navigate?: NavigateFunction,
) {
  setTracking(response.appData?.isAnalyticsEnabled ?? false);

  setToken(response);
  trackUserIdentification();
  const { user, unauthenticatedUrl } = yield* select(
    (state: RootStateType) => state.auth,
  );
  yield* put(setUnauthenticatedUrl(null));

  track({
    category: "Auth",
    event: "SignIn",
  });

  if (isWebView()) {
    postMessageFromWebView({
      type: WebViewMessageTypes.SET_USER_REQUEST,
      payload: {
        login: response.login!,
      },
    });
  }

  if (!navigate) {
    return;
  }

  try {
    const startUrl = resolveStartPage(
      user,
      unauthenticatedUrl,
      response.defaultPage,
    );

    const url = new URL(
      `${window.location.protocol}//${window.location.host}${startUrl}`,
    );

    navigate(
      {
        pathname: url.pathname,
        search: url.search,
      },
      {
        replace: true,
        state: null,
        relative: "path",
        preventScrollReset: true,
      },
    );
  } catch (e) {
    captureException(e);
    navigate(
      {
        pathname: getPath(AppRouting.SignIn),
      },
      {
        replace: true,
        state: null,
        relative: "path",
        preventScrollReset: true,
      },
    );
  }
}
