import { ContractOverviewDto } from "Api/Api";
import { ContractCategories } from "Models/Contracts";
import { ContractRenameFormModel } from "State/Contracts/Edit/ContractEditState";
import { number, object, ObjectSchema, string } from "yup";

const MAX_NAME_LENGTH = 50;

type Props = Pick<ContractOverviewDto, "name">;

export const useYupFormSchema = ({
  name,
}: Props): ObjectSchema<ContractRenameFormModel> => {
  return object<ContractRenameFormModel>().shape({
    name: !!name
      ? string().max(MAX_NAME_LENGTH).required()
      : string().max(MAX_NAME_LENGTH).notRequired(),
    purposeCategory: number()
      .oneOf(Object.values(ContractCategories).map(x => Number(x)))
      .notRequired(),
  });
};
