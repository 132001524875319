import { Typography } from "@mui/material";
import { SexRadioButtons } from "Components/ContractCreate/PersonalData/components/SexRadioButtons";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { useIsBankIDVerified } from "Hooks/Contract/useIsBankIdVerified";
import { type PersonalDataFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { PersonalIdentification } from "Utils/PersonalIdentification";
import { format, isValid } from "date-fns";
import { useEffect, type FunctionComponent, FocusEvent } from "react";
import { useFormContext } from "react-hook-form";

const ComponentResources = Resources.Contract.NewContract.PersonalInformation;
const FormResources = Resources.Forms.PersonalData;

type Props = {
  validatedNationality: boolean;
  validateExistence(): void;
  isExistence: boolean;
  personalIdentificationMask: string | RegExp;
};

export const PersonalIdentificatonNumberPart: FunctionComponent<Props> = ({
  validatedNationality,
  validateExistence,
  isExistence,
  personalIdentificationMask,
}) => {
  const { t } = useResource();
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
    clearErrors,
    trigger,
  } = useFormContext<PersonalDataFormModel>();
  const isBankIDVerified = useIsBankIDVerified();

  const personalIdentificationNumber = watch("personalIdentificationNumber");

  useEffect(() => {
    if (
      !isBankIDVerified &&
      !errors?.personalIdentificationNumber?.message &&
      validatedNationality
    ) {
      const personalIdentificator = PersonalIdentification.getInfo(
        personalIdentificationNumber,
      );

      if (!personalIdentificator.IsValid) {
        return;
      }

      if (personalIdentificator.BirthDate) {
        setValue(
          "birthDate",
          format(personalIdentificator.BirthDate, "yyyy-MM-dd"),
          { shouldValidate: true },
        );
      }

      if (personalIdentificator.Gender) {
        setValue("sex", personalIdentificator.Gender);
        clearErrors("sex");
      }
    }
  }, [
    getValues,
    setValue,
    personalIdentificationNumber,
    errors?.personalIdentificationNumber?.message,
    validatedNationality,
    clearErrors,
    isBankIDVerified,
  ]);

  const onBirthDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    const maxDate = new Date(2999, 11, 30);
    if (isValid(date) && date > maxDate) {
      setValue("birthDate", format(maxDate, "yyyy-MM-dd"), {
        shouldValidate: true,
      });
    } else {
      trigger("birthDate");
    }
  };

  return (
    <>
      <BlInputFormField
        control={control}
        name="personalIdentificationNumber"
        isMaskLazy={false}
        errors={errors}
        label={t(ComponentResources.PersonalIdentificationNumber)}
        mask={personalIdentificationMask}
        disabled={isBankIDVerified}
        onBlur={validateExistence}
      />

      {isExistence && (
        <Typography color="error">
          {t(ComponentResources.DuplicityMessage)}
        </Typography>
      )}

      <BlInputFormField
        control={control}
        name="birthDate"
        type="date"
        errors={errors}
        label={t(FormResources.BirthDate)}
        disabled={isBankIDVerified}
        onBlur={onBirthDateBlur}
        onFocus={event => (event.currentTarget.defaultValue = "")}
      />

      <SexRadioButtons />
    </>
  );
};
