import { InputAdornment, Stack, Typography } from "@mui/material";
import { ComponentTitleWrapper } from "Components/Shared/ComponentTitleWrapper";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { Resources, useResource } from "Translations/Resources";
import {
  useState,
  type FunctionComponent,
  useEffect,
  useCallback,
} from "react";
import { useForm } from "react-hook-form";

import {
  ProductCard,
  ProductCardProduct,
} from "Components/ContractPurchase/Purchase/ProductCard";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorTypography, StyledError } from "Components/Shared/FormStyles";
import { ProductDetailDialog } from "Components/Shared/ProductDetailDialog";
import { useCurrencySymbol } from "Hooks/useCurrencySymbol";
import { convertApiCurrencyCodeFromString } from "Utils/CurrencyUtils";
import {
  MAX_INVESTMENT_VALUE,
  useYupFormSchema,
} from "Hooks/Contract/Purchase/useYupFormSchema";
import { InvestmentTypes } from "Pages/Contracts/ModelingPage";
import { ContractTypeCode } from "Api/Api";
import { RateLabel } from "Components/Contract/RateLabel";
import { track } from "Utils/TrackingUtils";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { getProductColor } from "Utils/Products";
import { useDesktop } from "Hooks/useDesktop";

type Props = {
  contractID: number;
  currency: string;
  products: ProductCardProduct[];
  isPeriodicalInvestmentVisible: boolean;
  maxPeriodicalInvestment?: number;
  pageTitle: string;
  investmentType: InvestmentTypes;
  contractTypeCode: ContractTypeCode;
  defaultISIN: string | null;
  decimalPlaces?: number;
  onSubmit: (data: FormModel) => void;
  productsNotFoundMessage?: string;
  showProductsNotFoundMessage: boolean;
  isDip: boolean;
  onSelectedProductChange?: (isin: string | null) => void;
};

export type FormModel = {
  singleInvestment?: number | null;
  periodicalInvestment?: number | null;
  fundISIN: string;
};

const PageResources = Resources.Contract.Detail.Purchase;

export const PurchaseForm: FunctionComponent<Props> = props => {
  const {
    contractID,
    products,
    currency,
    maxPeriodicalInvestment,
    isPeriodicalInvestmentVisible,
    pageTitle,
    investmentType,
    contractTypeCode,
    defaultISIN,
    decimalPlaces,
    onSubmit,
    productsNotFoundMessage,
    showProductsNotFoundMessage = false,
    isDip,
    onSelectedProductChange,
  } = props;

  const { t } = useResource();
  const { isDesktop } = useDesktop();

  const [productDetail, setProductDetail] = useState<
    string | null | undefined
  >();

  const currencySymbol = useCurrencySymbol(
    convertApiCurrencyCodeFromString(currency),
  );

  const [fundISIN, setFundISIN] = useState<string | null>(defaultISIN);

  const schema = useYupFormSchema({
    currency,
    fundISIN,
    products,
    maxPeriodicalInvestment: maxPeriodicalInvestment ?? null,
    decimalPlaces,
  });

  const defaultOneTimeDeposit = !!maxPeriodicalInvestment
    ? maxPeriodicalInvestment
    : currency === "CZK"
    ? 50_000
    : 2_000;

  const defaultPeriodicalDeposit =
    investmentType === "Unlimited" && isPeriodicalInvestmentVisible
      ? currency === "CZK"
        ? 5_000
        : 200
      : null;

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
  } = useForm<FormModel>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      singleInvestment: defaultOneTimeDeposit,
      periodicalInvestment: defaultPeriodicalDeposit,
    },
  });

  const handleFundChange = useCallback(
    (isin?: string | null | undefined) => {
      setValue("fundISIN", isin!);
      setFundISIN(isin!);
    },
    [setValue],
  );

  useEffect(() => {
    if (defaultISIN) {
      handleFundChange(defaultISIN);
    }
  }, [defaultISIN, handleFundChange]);

  useEffect(() => {
    onSelectedProductChange?.(fundISIN ?? defaultISIN);
  }, [defaultISIN, fundISIN, onSelectedProductChange]);

  const submit = (data: FormModel) => {
    onSubmit(data);
  };

  const singleInvestment = watch("singleInvestment");
  const periodicalInvestment = watch("periodicalInvestment");
  const selectedProductISIN = watch("fundISIN");

  useEffect(() => {
    if (selectedProductISIN) {
      trigger();
    }
  }, [trigger, selectedProductISIN]);

  useEffect(() => {
    trigger("periodicalInvestment");
  }, [trigger, singleInvestment]);

  useEffect(() => {
    trigger("singleInvestment");
  }, [trigger, periodicalInvestment]);

  const { color, contrastColor } = getProductColor(fundISIN);

  return (
    <>
      <div>
        <PageTitle showOnMobile={isDesktop}>{pageTitle}</PageTitle>
        <InputHeaderWrapper
          header={t(PageResources.Amount)}
          marginTop={isDesktop ? 6 : 0}
        >
          <BlInputFormField
            label={t(PageResources.SingleInvestment)}
            control={control}
            name="singleInvestment"
            errors={errors}
            mask={Number}
            scale={decimalPlaces}
            isMaskLazy={false}
            maskPlaceholder=" "
            max={MAX_INVESTMENT_VALUE}
            min={0}
            isNumber
            inputEndAdornment={
              <InputAdornment position="end">{currencySymbol}</InputAdornment>
            }
            inputProps={{
              inputMode: decimalPlaces ? "decimal" : "numeric",
              pattern: decimalPlaces
                ? `[0-9]*[.,]?[0-9]{0,${decimalPlaces}}`
                : "[0-9]*",
            }}
            hasTracking
          />
          <RateLabel
            feeRate="single"
            amountOrPieces={singleInvestment || undefined}
            sourceContractTypeCode={contractTypeCode || undefined}
            investmentLengthYears={null}
            sourceIsin={fundISIN || undefined}
            sourceContractID={contractID}
          />
          {isPeriodicalInvestmentVisible && (
            <>
              <BlInputFormField
                label={t(PageResources.PeriodicalInvestment)}
                control={control}
                name="periodicalInvestment"
                errors={errors}
                mask={Number}
                isMaskLazy={false}
                maskPlaceholder=" "
                max={MAX_INVESTMENT_VALUE}
                min={0}
                isNumber
                inputEndAdornment={
                  <InputAdornment position="end">
                    {currencySymbol}
                  </InputAdornment>
                }
                inputProps={{
                  inputMode: decimalPlaces ? "decimal" : "numeric",
                  pattern: decimalPlaces
                    ? `[0-9]*[.,]?[0-9]{0,${decimalPlaces}}`
                    : "[0-9]*",
                }}
                hasTracking
              />

              <RateLabel
                feeRate="periodical"
                amountOrPieces={periodicalInvestment || undefined}
                sourceContractTypeCode={contractTypeCode || undefined}
                investmentLengthYears={null}
                sourceIsin={fundISIN || undefined}
                sourceContractID={contractID}
              />
            </>
          )}
        </InputHeaderWrapper>
        <ComponentTitleWrapper title={t(PageResources.Product)}>
          <Stack gap={2} marginTop={2}>
            {!!products?.length && (
              <>
                {products.map(product => (
                  <DipTagWrapper showPlain={!isDip} key={product.isin}>
                    <ProductCard
                      key={product.isin}
                      onCheck={isin => {
                        track({
                          category: "RadioButtons",
                          event: "Change",
                          action: "Click",
                          tag: "fundISIN",
                          value: isin,
                        });

                        handleFundChange(isin);
                      }}
                      checked={fundISIN === product.isin}
                      onDetail={setProductDetail}
                      {...product}
                    />
                  </DipTagWrapper>
                ))}
              </>
            )}
            {!products?.length &&
              showProductsNotFoundMessage &&
              productsNotFoundMessage && (
                <Typography>{productsNotFoundMessage}</Typography>
              )}
          </Stack>
          {!!errors.fundISIN?.message && (
            <StyledError>
              <ErrorTypography>{errors.fundISIN?.message}</ErrorTypography>
            </StyledError>
          )}
        </ComponentTitleWrapper>

        <ProductDetailDialog
          isin={productDetail}
          // productColor={products.find(p => p.isin === productDetail)?.color}
          onClose={() => setProductDetail(undefined)}
          isDip={isDip}
        />
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(submit)}
        disabled={!fundISIN}
        trackingTag={t(PageResources.PurchaseButton)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(PageResources.PurchaseButton)}
      </PrimaryButton>
    </>
  );
};
