import { Box, Typography } from "@mui/material";
import { PageTitle } from "Components/Shared/PageTitle";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";

const ComponentResources = Resources.Contract.Dealer;

export const ContractDealer: FunctionComponent = _ => {
  const { t } = useResource();

  return (
    <Box>
      <PageTitle>{t(ComponentResources.Title)}</PageTitle>
      <Typography>{t(ComponentResources.Description)}</Typography>
    </Box>
  );
};
