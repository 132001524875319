import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { LookupItem } from "Models/LookupItem";
import { getCountriesAsync } from "State/CodeLists/Countries/GetCountriesState";
import { useMemo } from "react";
import { useEffectOnce } from "react-use";

type UseCountriesType = () => LookupItem[];

export const useCountriesList: UseCountriesType = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(s => s.codeList.countries);

  useEffectOnce(() => {
    if (!data?.countries?.length && !isLoading) {
      dispatch(getCountriesAsync.request());
    }
  });

  return useMemo<LookupItem<string>[]>(
    () =>
      data?.countries?.map<LookupItem<string>>(({ code, name }) => ({
        Name: name,
        Value: code,
      })) ?? [],
    [data],
  );
};
