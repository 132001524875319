import { Grid, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { GrowingMoneyIcon } from "Components/Shared/Icons";
import { useResource, Resources } from "Translations/Resources";

import { type FunctionComponent } from "react";

const PageResources = Resources.WelcomeDashboard;

type Props = {
  onStartClick(): void;
};

export const WelcomeDashboard: FunctionComponent<Props> = ({
  onStartClick,
}) => {
  const { t } = useResource();

  return (
    <Grid
      container
      gap={3}
      justifyContent="center"
      direction="column"
      alignItems="center"
      flexGrow={1}
    >
      <Grid item>
        <GrowingMoneyIcon height="100px" width="100px" />
      </Grid>
      <Grid item>
        <Typography variant="h2" textAlign="center">
          {t(PageResources.Title)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginY={1} textAlign="center">
          {t(PageResources.Description)}
        </Typography>
      </Grid>
      <Grid item>
        <PrimaryButton color="primary" onClick={onStartClick}>
          {t(PageResources.BeginInvestingButton)}
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};
