import { Typography } from "@mui/material";
import { useEnvironment } from "Hooks/useEnvironment";
import { Resources, useResource } from "Translations/Resources";
import styled from "styled-components";

const StyledTestVersionTagWrapper = styled.div<{
  $moveLeft?: number;
}>`
  height: 20px;
  width: fit-content;
  max-width: 100%;
  border-radius: 0 0 16px 16px;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.contrastText};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CenteredContainer = styled.div`
  position: absolute;
  right: 50%;
  transform: translate(50%, 0%);
  z-index: 1000;
`;

export const TestVersionTag = () => {
  const { t } = useResource();
  const { isProduction } = useEnvironment();

  return isProduction ? (
    <></>
  ) : (
    <CenteredContainer>
      <StyledTestVersionTagWrapper>
        <Typography textAlign="center">
          {t(Resources.Common.TestVersionTag)}
        </Typography>
      </StyledTestVersionTagWrapper>
    </CenteredContainer>
  );
};
