import {
  PersonalDocumentType,
  EditPersonalDataFormModel as ApiEditPersonalDataFormModel,
  EditPersonalDataContactInfoFormModel,
  EditPersonalDataDocumentScanFromModel,
  EditPersonalDataDocumentFormModel,
  EditPersonalDataAddressFormModel,
  EditPersonalDataVerificationType,
} from "Api/Api";
import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import { CZECHIA_COUNTRY_CODE, USA_COUNTRY_CODE } from "Constants/Countries";
import { VALIDATION_POSTAL_CODE_COUNTRIES } from "Constants/Inputs";
import { useAppSelector } from "Hooks/useAppSelector";
import { BankIdClaims, BankIdScopes } from "Models/BankID";
import { useState } from "react";
import { EditPersonalDataFormModel } from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { ResourceDictionary } from "Translations/ResourceDictionary";
import { Resources, useResource } from "Translations/Resources";
import { ObjPathProxy } from "ts-object-path";
import { convertNanToNull } from "Utils/NumberUtils";
import { isNoU } from "Utils/ObjectUtils";
import { mixed, object, ObjectSchema, string } from "yup";

export const useYupFormSchema = (
  nationalities: string[],
  originalPhone: string | null | undefined,
  originalEmail: string | null | undefined,
): ObjectSchema<EditPersonalDataFormModel> => {
  const { t } = useResource();
  const { lastRequest: defaultValues } = useAppSelector(
    s => s.client.personalData.edit,
  );

  const [verificationType] = useState(
    defaultValues.verificationType || EditPersonalDataVerificationType.Manual,
  );

  return object<EditPersonalDataFormModel>().shape({
    verificationType: mixed<EditPersonalDataVerificationType>()
      .oneOf(Object.values(EditPersonalDataVerificationType))
      .required(),
    personalData: personalDataValidator(),
    contactInfo: contactInformationValidator(
      originalPhone || "",
      originalEmail || "",
      t,
    ),
    permanentAddress: addressValidator(t),
    primaryDocument: documentValidator(
      verificationType,
      allowedDocumentTypesFunc(verificationType, nationalities),
    ),
  });
};

const addressValidator = (
  t: (
    resourcePath: ObjPathProxy<ResourceDictionary, string>,
    options?: any,
  ) => string,
): ObjectSchema<EditPersonalDataAddressFormModel> =>
  object<EditPersonalDataAddressFormModel>().shape({
    streetName: string()
      .required()
      .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.Street}`),
    city: string()
      .min(2)
      .required()
      .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.City}`),
    streetNumber: string()
      .optional()
      .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.StreetNumber}`),
    streetConscriptionNumber: string()
      .transform(x => x || "")
      .required()
      .label(
        `${BankIdScopes.ProfileAddresses}:${BankIdClaims.BuildingApartment}`,
      ),
    postalCode: string()
      .required()
      .when("country", {
        is: (country: string) =>
          VALIDATION_POSTAL_CODE_COUNTRIES.includes(country),
        then: schema =>
          schema
            .min(5, t(Resources.Validation.InvalidFormat))
            .max(5, t(Resources.Validation.InvalidFormat))
            .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.ZipCode}`),
        otherwise: schema =>
          schema
            .transform(val =>
              isNoU(convertNanToNull(parseInt(val))) ? null : val,
            )
            .nonNullable()
            .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.ZipCode}`),
      }),
    country: string()
      .required()
      .test(
        USA_NATIONALITY_FORBID_TYPE,
        t(Resources.Contract.NewContract.Shared.USACountryPermit),
        value => value !== USA_COUNTRY_CODE,
      )
      .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.Country}`),
  });

const documentValidator = (
  verificationType: EditPersonalDataVerificationType,
  allowedDocumentTypes: PersonalDocumentType[],
): ObjectSchema<EditPersonalDataDocumentFormModel> =>
  object<EditPersonalDataDocumentFormModel>().shape({
    type: mixed<PersonalDocumentType>()
      .oneOf(Object.values(PersonalDocumentType))
      .oneOf(allowedDocumentTypes)
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Type}`),
    issueCountry: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Country}`),
    expiryDate: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.ValidTo}`),
    issueDate: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.IssueDate}`),
    issuingAuthority: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Issuer}`),
    number: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Number}`),
    frontScan:
      verificationType === EditPersonalDataVerificationType.Manual
        ? documentScanValidator().required()
        : documentScanValidator().default(undefined).nullable().optional(),
    backScan:
      verificationType === EditPersonalDataVerificationType.Manual
        ? documentScanValidator().required()
        : documentScanValidator().default(undefined).nullable().optional(),
  });

const documentScanValidator =
  (): ObjectSchema<EditPersonalDataDocumentScanFromModel> =>
    object<EditPersonalDataDocumentScanFromModel>().shape({
      guid: string()
        .transform(x => x || undefined)
        .required(),
      fileName: string().nullable().optional(),
      mimeType: string().nullable().optional(),
    });

export function allowedDocumentTypesFunc(
  verificationType: EditPersonalDataVerificationType,
  nationalities: string[],
): PersonalDocumentType[] {
  if (nationalities.some(x => x === CZECHIA_COUNTRY_CODE)) {
    return [PersonalDocumentType.IdentityCard];
  }

  return Object.values(PersonalDocumentType);
}

const personalDataValidator = (): ObjectSchema<ApiEditPersonalDataFormModel> =>
  object<ApiEditPersonalDataFormModel>().shape({
    lastName: string().required(),
  });

const contactInformationValidator = (
  originalPhone: string,
  originalEmail: string,
  t: (
    resourcePath: ObjPathProxy<ResourceDictionary, string>,
    options?: any,
  ) => string,
): ObjectSchema<EditPersonalDataContactInfoFormModel> =>
  object<EditPersonalDataContactInfoFormModel>().shape({
    email: string().required(),
    phone: string()
      .required()
      .test(
        "email-and-phone-changed",
        t(
          Resources.More.PersonalData.EditPersonalData.Modelling.ContactInfo
            .EmailAndPhoneChanged,
        ),
        (_, context) => {
          const isPhoneMatch =
            originalPhone?.replace(/\D/g, "") ===
            String(context.parent.phone).replace(/\D/g, "");

          const isEmailMatch =
            originalEmail?.trim() === String(context.parent.email).trim();

          return !(!isPhoneMatch && !isEmailMatch);
        },
      ),
  });
