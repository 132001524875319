import { Typography } from "@mui/material";
import { BankIDProcessAudience } from "Api/Api";
import { isAfter, parseJSON } from "date-fns";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { checkAndProlongTokenAsync } from "State/Auth/Tokens/CheckAndProlongTokenState";
import { initBankIDVerificationProcess } from "State/BankID/BankIDReducer";
import { initBankIDVerificationProcessAsync } from "State/BankID/InitializeBankIDVerificationProcess";
import { addOrUpdateURLQuery } from "Utils/UrlUtils";
import { isWebView } from "Utils/WebViewUtils";
import { v4 as uuid } from "uuid";

const WEB_BANK_ID_URL = import.meta.env.VITE_WEB_BANK_ID_URL;
const NATIVE_BANK_ID_URL = import.meta.env.VITE_NATIVE_BANK_ID_URL;

type Props = {
  isActive?: boolean;
  processAudience: BankIDProcessAudience;
  translations: {
    redirecting: string;
  };
};

export const BankIDRedirect: FunctionComponent<Props> = ({
  isActive = true,
  processAudience,
  translations: { redirecting },
}) => {
  const useMobileFlow = useMemo(() => isWebView(), []);
  const dispatch = useAppDispatch();
  const tokenExpiration = useAppSelector(s => s.auth.tokenExpiration);
  const [originalTokenExpiration, setOriginalTokenExpiration] = useState<
    string | null
  >(null);

  const redirectUriBase: string = useMemo(
    () => (useMobileFlow ? NATIVE_BANK_ID_URL : WEB_BANK_ID_URL),
    [useMobileFlow],
  );

  useEffect(() => {
    if (!isActive || originalTokenExpiration) {
      return;
    }

    setOriginalTokenExpiration(tokenExpiration);
    dispatch(checkAndProlongTokenAsync.request());
  }, [
    isActive,
    dispatch,
    tokenExpiration,
    originalTokenExpiration,
    setOriginalTokenExpiration,
  ]);

  const [processID] = useState(uuid());
  const [redirectUri] = useState(
    addOrUpdateURLQuery(redirectUriBase, "state", processID),
  );

  const redirectToBankID = useCallback(() => {
    if (!redirectUri) {
      return;
    }

    if (useMobileFlow) {
      dispatch(
        initBankIDVerificationProcessAsync.request({
          processID,
          processAudience,
          redirectUri,
        }),
      );
      return;
    }

    dispatch(initBankIDVerificationProcess({ processID, processAudience }));
    window.location.href = redirectUri;
  }, [useMobileFlow, processID, processAudience, redirectUri, dispatch]);

  useEffect(() => {
    if (
      !tokenExpiration ||
      !originalTokenExpiration ||
      !isAfter(parseJSON(tokenExpiration), parseJSON(originalTokenExpiration))
    ) {
      return;
    }

    redirectToBankID();
  }, [tokenExpiration, originalTokenExpiration, redirectToBankID]);

  return <Typography>{redirecting}</Typography>;
};
