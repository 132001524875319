import { ContractOverviewDto } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { ProductsIsin } from "Models/Products";
import { useEffect, useMemo } from "react";
import { productAllowedTransfersAsync } from "State/Products/ProductAllowedTransfers/ProductAllowedTransfersState";
import { isKnownIsin } from "Utils/Products";

type Props = {
  isin: string | ProductsIsin | null | undefined;
  disabledFetch?: boolean;
} & Partial<Pick<ContractOverviewDto, "type" | "isDip">>;

export const useProductAllowedTransfers = ({
  isin,
  type: contractTypeCode,
  isDip,
  disabledFetch = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(s => s.products.allowedTransfers);

  useEffect(() => {
    if (
      isKnownIsin(isin) &&
      !state[isin].isLoading &&
      !state[isin].data?.productIsinList &&
      !state[isin].error &&
      !disabledFetch
    ) {
      dispatch(
        productAllowedTransfersAsync.request(
          { isin, contractTypeCode, isDip },
          { _isin: isin },
        ),
      );
    }
  }, [state, isin, contractTypeCode, isDip, disabledFetch, dispatch]);

  const [isLoading, data] = useMemo(() => {
    if (!isKnownIsin(isin)) {
      return [false, []];
    }

    return [state[isin].isLoading, state[isin].data?.productIsinList ?? []];
  }, [isin, state]);

  return {
    isLoading,
    data,
  };
};
