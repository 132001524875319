import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { PageTitle } from "Components/Shared/PageTitle";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useBankCurrenciesList } from "Hooks/codeLists/useBankCurrenciesList";
import { useBanksList } from "Hooks/codeLists/useBanksList";
import { useYupFormSchema } from "Hooks/Contract/BankConnections/Create/useYupFormSchema";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { FunctionComponent, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  contractCreateBankConnectionAsync,
  ContractCreateBankConnectionFormModel,
  BankConnectionType,
  initialState,
} from "State/Contracts/Contract/BankConnections/ContractCreateBankConnectionState";
import { Resources, useResource } from "Translations/Resources";
import { getBankUniqueCode } from "Utils/BankUtils";
import { nameof } from "Utils/ObjectUtils";

type Props = {
  contractID: number;
};

const PageResources = Resources.Contract.BankConnections.Create;
const ComponentResources = Resources.Contract.BankConnections.Create.Modelling;

export const BankConnectionModelling: FunctionComponent<Props> = ({
  contractID,
}) => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();
  const { isLoading } = useAppSelector(
    s => s.contracts.contract.bankConnections.create,
  );

  const banks = useBanksList();
  const bankCodes = useAppSelector(s => s.codeList.banks.data);
  const currencies = useBankCurrenciesList();
  const schema = useYupFormSchema();
  const dispatch = useAppDispatch();

  const form = useForm<ContractCreateBankConnectionFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialState.formData,
    },
    mode: "onSubmit",
  });

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    watch,
    setValue,
    trigger,
    clearErrors,
  } = form;

  const type = watch(nameof<ContractCreateBankConnectionFormModel>("type"));
  const selectedBank = watch(
    nameof<ContractCreateBankConnectionFormModel>("bank"),
  );

  useEffect(() => {
    if (!selectedBank) {
      return;
    }

    const bank = bankCodes?.bankList.find(
      x => getBankUniqueCode(x) === selectedBank,
    );

    if (bank) {
      setValue("bankCode", bank.numericCode, { shouldValidate: true });
    }
  }, [setValue, banks, selectedBank, bankCodes?.bankList]);

  const onSubmit = (formData: ContractCreateBankConnectionFormModel) => {
    dispatch(
      contractCreateBankConnectionAsync.request({
        ...formData,
        contractID,
        isBiometry: canUseBiometricsForSigning,
      }),
    );
  };

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [isValid, clearErrors]);

  return (
    <>
      <div>
        <PageTitle showOnMobile={isDesktop}>{t(PageResources.Title)}</PageTitle>
        <Typography>{t(ComponentResources.Description)}</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...form}>
            <RadioButtons<
              ContractCreateBankConnectionFormModel,
              BankConnectionType
            >
              control={control}
              errors={errors}
              name="type"
              defaultValue="bankNumber"
              codeList={[
                {
                  code: "bankNumber",
                  name: t(ComponentResources.BankNumberRadio),
                },
                {
                  code: "iban",
                  name: t(ComponentResources.IbanRadio),
                },
              ]}
              onChange={type => {
                setValue(
                  nameof<ContractCreateBankConnectionFormModel>("type"),
                  type,
                );

                setValue(
                  nameof<ContractCreateBankConnectionFormModel>("iban"),
                  "",
                );

                setValue(
                  nameof<ContractCreateBankConnectionFormModel>("bank"),
                  "",
                );

                setValue(
                  nameof<ContractCreateBankConnectionFormModel>("bankCode"),
                  "",
                );

                setValue(
                  nameof<ContractCreateBankConnectionFormModel>("bankNumber"),
                  "",
                );

                clearErrors();
              }}
            />

            {type === "bankNumber" && (
              <>
                <BlInputFormField
                  control={control}
                  errors={errors}
                  name="bankNumber"
                  label={t(ComponentResources.BankNumberLabel)}
                  mask={/^[0-9-]+$/}
                  onBlur={x =>
                    trigger(
                      nameof<ContractCreateBankConnectionFormModel>(
                        "bankNumber",
                      ),
                    )
                  }
                />
                <DropdownInput
                  control={control}
                  errors={errors}
                  name="bank"
                  options={banks}
                  label={t(ComponentResources.BankCodeLabel)}
                />
              </>
            )}

            {type === "iban" && (
              <BlInputFormField
                control={control}
                errors={errors}
                name="iban"
                label={t(ComponentResources.IbanLabel)}
                mask={/^[A-Za-z0-9]+$/}
                valueTransformer={x => x?.toUpperCase()}
                onBlur={_ =>
                  trigger(nameof<ContractCreateBankConnectionFormModel>("iban"))
                }
              />
            )}

            <DropdownInput
              control={control}
              errors={errors}
              name="currency"
              options={currencies}
              label={t(ComponentResources.CurrencyLabel)}
            />
          </FormProvider>
        </form>
      </div>
      <BlSlideButton
        minSlideVelocity={0.95}
        minSlideWidth={0.95}
        onSlideDone={handleSubmit(onSubmit)}
        trackingTag={t(ComponentResources.SwipeButtonText)}
        disabled={!isValid}
        isLoading={isLoading}
      >
        {t(ComponentResources.SwipeButtonText)}
      </BlSlideButton>
    </>
  );
};
