import {
  ContractExchangeAssetsCommandResult,
  ContractExchangeAssetsMethod,
  ContractExchangeAssetsRequest,
  ContractExchangeAssetsType,
  getUserContact,
  postContractContractIDExchangeAssets,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { safeApiCall } from "State/Utils";
import { all, put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type ExchangeAssetsFormData = Pick<
  ContractExchangeAssetsRequest,
  | "sourceIsin"
  | "targetIsin"
  | "type"
  | "method"
  | "amountOrPieces"
  | "isExchangeAll"
>;

export enum ExchangeAssetsStep {
  Modelling,
  Signature,
  Success,
}

export type ExchangeAssetsState = {
  isLoading: boolean;
  contractID: number;
  actualStep: ExchangeAssetsStep;
  formData: ExchangeAssetsFormData;
  phone?: string | null;
  signatureHash?: string | null;
  error?: Error | null;
};

export const initialExchangeAssetsState: ExchangeAssetsState = {
  isLoading: false,
  contractID: 0,
  actualStep: ExchangeAssetsStep.Modelling,
  formData: {
    sourceIsin: "",
    targetIsin: "",
    type: ContractExchangeAssetsType.Single,
    method: ContractExchangeAssetsMethod.Pieces,
    amountOrPieces: 1,
    isExchangeAll: false,
  },
  phone: null,
  signatureHash: null,
  error: null,
};

export const setExchangeAssetsDefaultValues = createAction(
  "@contract/SET_EXCHANGE_ASSETS_DEFAULT_VALUES",
)<{
  contractID: number;
  sourceIsin: string;
}>();

export const setExchangeAssetsStep = createAction(
  "@contract/SET_EXCHANGE_ASSETS_STEP",
)<ExchangeAssetsStep>();

export const resetExchangeAssetsState = createAction(
  "@contract/RESET_EXCHANGE_ASSETS_STATE",
)<void>();

export type ExchangeAssetsStateAction =
  | ActionType<typeof setExchangeAssetsDefaultValues>
  | ActionType<typeof setExchangeAssetsStep>
  | ActionType<typeof resetExchangeAssetsState>
  | ActionType<typeof exchangeAssetsAsync>;

export const exchangeAssetsAsync = createAsyncAction(
  "@contract/POST_CONTRACT_EXCHANGE_ASSETS_REQUEST",
  "@contract/POST_CONTRACT_EXCHANGE_ASSETS_SUCCESS",
  "@contract/POST_CONTRACT_EXCHANGE_ASSETS_FAILURE",
)<
  ContractExchangeAssetsRequest & {
    contractID: number;
  },
  ContractExchangeAssetsCommandResult & { phone?: string | null },
  Error
>();

function* exchangeAssets(
  action: ReturnType<typeof exchangeAssetsAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postContractContractIDExchangeAssets,
      action.payload,
      action.payload.contractID,
    );

    if (!!error) {
      yield put(exchangeAssetsAsync.failure(error));
      return;
    }

    if (!response.signatureHash) {
      yield put(
        exchangeAssetsAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );
      return;
    }

    if (action.payload.isBiometry) {
      yield put(
        setBiometricsSignatureType(BiometricsSignatureType.ExchangeAssets),
      );

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: response.signatureHash,
        }),
      );

      return;
    }

    const { response: contactResponse, error: contactError } =
      yield* safeApiCall(getUserContact);

    if (!!contactError) {
      yield put(exchangeAssetsAsync.failure(contactError));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash: response.signatureHash,
      }),
    );

    yield put(
      exchangeAssetsAsync.success({
        ...response,
        phone:
          contactResponse.contactInfo?.client?.phone ||
          contactResponse.contactInfo?.user.phone ||
          undefined,
      }),
    );
  } catch (err) {
    yield put(exchangeAssetsAsync.failure(err as Error));
  }
}

export function* exchangeAssetsSaga() {
  yield takeLeading(getType(exchangeAssetsAsync.request), exchangeAssets);
}

export function* watchExchangeAssetsSaga() {
  yield all([exchangeAssetsSaga()]);
}

export const exchangeAssetsReducer = createReducer<
  ExchangeAssetsState,
  ExchangeAssetsStateAction
>(initialExchangeAssetsState)
  .handleAction(setExchangeAssetsDefaultValues, (state, action) => {
    return produce(state, draft => {
      const { contractID, sourceIsin } = action.payload;

      draft.contractID = contractID;
      draft.formData.sourceIsin = sourceIsin;

      return draft;
    });
  })
  .handleAction(resetExchangeAssetsState, (state, _) => {
    return produce(state, _ => {
      return initialExchangeAssetsState;
    });
  })
  .handleAction(setExchangeAssetsStep, (state, action) => {
    return produce(state, draft => {
      draft.actualStep = action.payload;
      return draft;
    });
  })
  .handleAction(exchangeAssetsAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.actualStep = ExchangeAssetsStep.Signature;
      draft.formData = action.payload;
      return draft;
    });
  })
  .handleAction(exchangeAssetsAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.signatureHash = action.payload.signatureHash;
      draft.phone = action.payload.phone;
      return draft;
    });
  })
  .handleAction(exchangeAssetsAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  });
