import { Breakpoint } from "@mui/material";
import { useCurrentBreakpoint } from "Hooks/useCurrentBreakpoint";
import { FunctionComponent } from "react";
import styled from "styled-components";

const DarkCircle = styled.svg`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 117px;
  height: 107px;
  z-index: 0;

  ${props => props.theme.breakpoints.up("sm")} {
    width: 217px;
    height: 200px;
  }

  ${props => props.theme.breakpoints.up("lg")} {
    width: 317px;
    height: 200px;
  }
`;

const circleProps: Record<Breakpoint, { cx: string; cy: string; r: string }> = {
  xs: { cx: "71.5", cy: "59.5", r: "71.5" },
  sm: { cx: "120", cy: "100", r: "110" },
  md: { cx: "150", cy: "100", r: "140" },
  lg: { cx: "220", cy: "100", r: "200" },
  xl: { cx: "220", cy: "100", r: "200" },
};

export const CardCircle: FunctionComponent = () => {
  const currentBreakpoint = useCurrentBreakpoint();

  const { cx, cy, r } = circleProps[currentBreakpoint];

  return (
    <DarkCircle>
      <circle opacity="0.15" cx={cx} cy={cy} r={r} fill="#252525" />
    </DarkCircle>
  );
};
