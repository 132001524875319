import { MenuList, Stack, Typography } from "@mui/material";
import { MenuItem } from "Components/ContractDetail/Transactions/components/MenuItem";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  getCashMovementsAsync,
  initialCashMovementsState,
} from "State/InvestmentsDetail/CashMovements/CashMovementsState";
import { useEffect, useState, type FunctionComponent } from "react";
import { InstructionValueType } from "Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { LAYOUT_PADDING_X } from "Constants/Layout";
import { PurchaseIcon, RedeemIcon } from "Components/Shared/Icons";
import { MenuItemLoader } from "Components/ContractDetail/Transactions/components/MenuItemLoader";
import { FilterButton } from "Components/ContractDetail/Transactions/components/FilterButton";
import { FilterDialog } from "Components/ContractDetail/CashMovements/components/FilterDialog";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { isEqual } from "lodash-es";

const PageResources = Resources.Contract.Detail.CashMovements;

const Icons = {
  debet: <RedeemIcon />,
  credit: <PurchaseIcon />,
};

type CashMovementsProps = {
  contractID: number;
};

export const CashMovements: FunctionComponent<CashMovementsProps> = ({
  contractID,
}) => {
  const dispatch = useAppDispatch();

  const { t } = useResource();
  const {
    isLoading: isLoadingCashMovements,
    data: cashMovements,
    metadata: pagination,
    filters,
    error,
  } = useAppSelector(s => s.investmentsDetail.cashMovements);

  useEffect(() => {
    if (!cashMovements) {
      dispatch(
        getCashMovementsAsync.request({
          ...filters,
          contractID,
          pageSize: 100,
        }),
      );
    }
  }, [contractID, cashMovements, filters, dispatch]);

  const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);

  const isCashMovementsVisible = !!cashMovements?.length;
  const isLoading = isLoadingCashMovements && !cashMovements;
  const isDataFiltered = !isEqual(filters, initialCashMovementsState.filters);

  return (
    <LoadingWrapper
      isLoading={isLoading}
      error={error}
      skeleton={
        <Stack gap={2} paddingX={LAYOUT_PADDING_X}>
          <StyledFlex $justifyContent="flex-end">
            <PrimarySkeleton width={100} height={25} />
          </StyledFlex>
          <PrimarySkeleton width="100%" height={60} />
          <PrimarySkeleton width="100%" height={60} />
          <PrimarySkeleton width="100%" height={60} />
        </Stack>
      }
    >
      <FilterButton
        isDataFiltered={isDataFiltered}
        onClick={() => setIsFilterDialogVisible(true)}
      />

      <FilterDialog
        isOpened={isFilterDialogVisible}
        onClose={() => setIsFilterDialogVisible(false)}
      />

      <MenuList disablePadding>
        {isCashMovementsVisible &&
          cashMovements.map(
            (
              {
                amount,
                date,
                description,
                directionType,
                cashFlowID,
                currency,
              },
              index,
            ) => (
              <MenuItem
                key={cashFlowID ?? index}
                amount={
                  amount !== null && amount !== undefined
                    ? directionType === 0
                      ? -amount
                      : amount
                    : 0
                }
                icon={directionType === 0 ? Icons.debet : Icons.credit}
                date={new Date(date)}
                name={description}
                instructionValueType={InstructionValueType.Amount}
                currency={currency}
              />
            ),
          )}

        <MenuItemLoader
          isLoading={isLoadingCashMovements && !!cashMovements}
          onElementVisible={() => {
            if (pagination?.hasNext) {
              dispatch(
                getCashMovementsAsync.request({
                  ...filters,
                  contractID,
                  pageSize: pagination.pageSize,
                  pageNumber: pagination.currentPage + 1,
                }),
              );
            }
          }}
        />

        {!isCashMovementsVisible && (
          <Typography paddingX={LAYOUT_PADDING_X}>
            {t(PageResources.NoneTransactionsFoundPlaceholder)}
          </Typography>
        )}
      </MenuList>
    </LoadingWrapper>
  );
};
