import { useAppSelector } from "Hooks/useAppSelector";
import { useMemo } from "react";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import { ExchangeAssetsStep } from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { Resources, useResource } from "Translations/Resources";

const HookResources = Resources.Contract.ExchangeAssets;

export const usePageTitle = () => {
  const { t } = useResource();
  const { actualStep } = useAppSelector(s => s.contracts.exchangeAssets);
  const { isInProgress: isBiometricsInProgress, type: biometricsType } =
    useAppSelector(s => s.biometrics.signature);

  const isBiometrics =
    biometricsType === BiometricsSignatureType.ExchangeAssets ||
    isBiometricsInProgress;

  const title = useMemo(() => {
    switch (actualStep) {
      case ExchangeAssetsStep.Modelling:
        return t(HookResources.Title);
      case ExchangeAssetsStep.Signature:
        return isBiometrics
          ? t(HookResources.Signature.Biometrics)
          : t(HookResources.Title);
      case ExchangeAssetsStep.Success:
        return t(HookResources.Success.Title);
    }
  }, [isBiometrics, actualStep, t]);

  return title;
};
