import * as React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CzechResourcesDictionary } from "Translations/CzechResourcesDictionary";
import { Routes } from "Routes/Routes";
import { Layout } from "Components/Layout/Layout";
import { Provider } from "react-redux";
import appStore from "State/Store";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import { API_ROUTES, configureApiCalls } from "Api/Api";
import { BrowserRouter } from "react-router-dom";
import { ModuleLoadingFallback } from "Components/Layout/ModuleLoadingFallback";
import { JWT_KEY } from "Utils/AuthUtils";
import { trackUserIdentification } from "Utils/TrackingUtils";
import { useYupLocale } from "Hooks/useYupLocale";
import { checkAndProlongTokenAsync } from "State/Auth/Tokens/CheckAndProlongTokenState";
import { WebViewWrapper } from "Components/Layout/WebViewWrapper";
import { AppTrackingWrapper } from "Components/Tracking/AppTrackingWrapper";
import { NetworkStatusWrapper } from "Components/Layout/NetworkStatusWrapper";
import { NotificationsProvider } from "Components/Layout/NotificationsProvider";
import { NativeVersionDetector } from "Components/Versioning/NativeVersionDetector";

i18n.use(initReactI18next).init({
  resources: {
    cs: CzechResourcesDictionary,
  },
  lng: "cs",
  fallbackLng: "cs",

  interpolation: {
    escapeValue: false,
  },
});

function App() {
  useYupLocale();

  configureApiCalls({
    jwtKey: JWT_KEY,
    onResponse: r => {
      const url = r.args[0] as string | undefined;

      if (
        !!url &&
        !url?.includes(API_ROUTES.postAuthTokenProlong) &&
        !url?.includes(API_ROUTES.getHealthStatus)
      ) {
        appStore.store.dispatch(checkAndProlongTokenAsync.request());
      }

      trackUserIdentification();
    },
  });

  return (
    <Provider store={appStore.store}>
      <PersistGate loading={null} persistor={appStore.persistor}>
        <BrowserRouter>
          <WebViewWrapper>
            <NativeVersionDetector>
              <AppTrackingWrapper>
                <NotificationsProvider>
                  <Layout>
                    <NetworkStatusWrapper>
                      <React.Suspense fallback={<ModuleLoadingFallback />}>
                        <Routes />
                      </React.Suspense>
                    </NetworkStatusWrapper>
                  </Layout>
                </NotificationsProvider>
              </AppTrackingWrapper>
            </NativeVersionDetector>
          </WebViewWrapper>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
