import { createReducer, ActionType, createAction } from "typesafe-actions";
import { produce } from "immer";

import { all } from "typed-redux-saga";
import {
  investUninvestedDepositsAsync,
  investUninvestedDepositsSaga,
} from "State/Contracts/UninvestedDeposits/Invest/InvestUninvestedDepositsState";
import { Prettify } from "Models/TypeExtensions";
import { InvestUninvestedDepositsDto } from "Api/Api";

export enum InvestUninvestedDepositsStep {
  Configuration,
  Signature,
  Success,
}

export const setInvestUninvestedDepositsStep = createAction(
  "@contract/SET_INVEST_UNINVESTED_DEPOSITS_STEP",
)<InvestUninvestedDepositsStep>();

export const resetInvestUninvestedDepositsState = createAction(
  "@contract/RESET_INVEST_UNINVESTED_DEPOSITS",
)<void>();

type Result = Prettify<
  {
    signatureHash: string;
  } & (
    | {
        type: "SMS";
        phone?: string;
      }
    | {
        type: "BIOMETRICS";
      }
  )
>;

type InvestUninvestedDepositsState = {
  isLoading: boolean;
  step: InvestUninvestedDepositsStep;
  lastRequest: InvestUninvestedDepositsDto | null;
  result: Result | null;
};

const initialInvestUninvestedDepositsState = {
  isLoading: false,
  step: InvestUninvestedDepositsStep.Configuration,
  result: null,
  lastRequest: null,
};

export type UninvestedDepositsAction =
  | ActionType<typeof investUninvestedDepositsAsync>
  | ActionType<typeof setInvestUninvestedDepositsStep>
  | ActionType<typeof resetInvestUninvestedDepositsState>;

export function* watchUninvestedStateSagas() {
  yield all([investUninvestedDepositsSaga()]);
}

export const uninvestedDepositsReducer = createReducer<
  InvestUninvestedDepositsState,
  UninvestedDepositsAction
>(initialInvestUninvestedDepositsState)
  .handleAction(investUninvestedDepositsAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.lastRequest = action.payload;
      return draft;
    });
  })
  .handleAction(investUninvestedDepositsAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.result = action.payload;
      draft.step = InvestUninvestedDepositsStep.Signature;
      draft.isLoading = false;
      return draft;
    });
  })
  .handleAction(investUninvestedDepositsAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.step = InvestUninvestedDepositsStep.Configuration;
      return draft;
    });
  })
  .handleAction(setInvestUninvestedDepositsStep, (state, action) => {
    return produce(state, draft => {
      draft.step = action.payload;

      if (
        action.payload === InvestUninvestedDepositsStep.Success ||
        action.payload === InvestUninvestedDepositsStep.Configuration
      ) {
        draft.result = null;
        draft.isLoading = false;
      }
      return draft;
    });
  })
  .handleAction(resetInvestUninvestedDepositsState, (state, _) => {
    return produce(state, _ => {
      return initialInvestUninvestedDepositsState;
    });
  });
