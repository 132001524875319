import { FormHelperText } from "@mui/material";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useFeeRate } from "Hooks/ProductFeeRate/useFeeRate";
import {
  FeeRateType,
  ProductFeeRateCalculationRequest,
} from "State/Products/FeeRate/GetFeeRateState";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
  investmentLengthYears?: number | null;
  feeRate: FeeRateType;
  translations?: {
    single?: {
      label?: string;
      valueTemplate?: string;
    };
    periodical?: {
      label?: string;
      valueTemplate?: string;
    };
  };
} & ProductFeeRateCalculationRequest;

const Container = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const StyledFormHelperText = styled(FormHelperText)`
  font-size: 14px;
`;

const StyledSkeleton = styled(PrimarySkeleton)`
  margin-top: 7px;
  height: 17px;
  width: 40px;
`;

export const RateLabel: FunctionComponent<Props> = props => {
  const { investmentLengthYears, feeRate, translations } = props;
  const { t } = useResource();
  const { isLoading, data } = useFeeRate(
    {
      ...props,
      investmentLengthYears,
    },
    feeRate,
  );

  const isPeriodical = feeRate === "periodical";

  const label = isPeriodical
    ? translations?.periodical?.label ||
      t(Resources.Forms.Investments.PeriodicalInvestmentFeePart1)
    : translations?.single?.label ||
      t(Resources.Forms.Investments.SingleInvestmentFeePart1);

  const templatedValue = t(
    isPeriodical
      ? Resources.Forms.Investments.PeriodicalInvestmentFeePart2
      : Resources.Forms.Investments.SingleInvestmentFeePart2,
    {
      value: data?.feeRate ?? 0,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  );

  return (
    <Container>
      <StyledFlex>
        <StyledFormHelperText>{label}</StyledFormHelperText>
        &nbsp;
        {isLoading ? (
          <StyledSkeleton />
        ) : (
          <StyledFormHelperText>{templatedValue}</StyledFormHelperText>
        )}
      </StyledFlex>
    </Container>
  );
};
