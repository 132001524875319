import { Link, Typography } from "@mui/material";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { EmailIcon } from "Components/Shared/Icons";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, useRef } from "react";

type Props = {
  email: string;
};

const ComponentResources = Resources.Contract.Distribution;

export const ContractDistribution: FunctionComponent<Props> = ({ email }) => {
  const { t } = useResource();
  const emailRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <div>
      <PageTitle showOnMobile={false}>{t(ComponentResources.Title)}</PageTitle>
      <Typography marginBottom={5}>
        {t(ComponentResources.Description)}
      </Typography>
      <StyledFlex $justifyContent="space-around">
        <StyledFlex $alignItems="center" $gap={3} $overflow="hidden">
          <IconCircleWrapper
            size={40}
            onClick={() => emailRef.current?.click()}
          >
            <EmailIcon />
          </IconCircleWrapper>
          <Link
            target="_blank"
            href={`mailto:${email}`}
            color="secondary"
            ref={emailRef}
          >
            {email}
          </Link>
        </StyledFlex>
      </StyledFlex>
    </div>
  );
};
