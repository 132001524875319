import { Box } from "@mui/material";
import { FunctionComponent, PropsWithChildren } from "react";
import styled from "styled-components";
import { DipTagWrapper } from "./Dip/DipTagWrapper";
import { useDesktop } from "Hooks/useDesktop";

import { ProductLogo } from "./ProductLogo";
import { useProductDesign } from "Hooks/useProductDesign";

type ContractGuideWrapperProps = PropsWithChildren<{
  isDipWrapped?: boolean;
  productIsin?: string | null;
  allowProductBackground?: boolean;
}>;

const BorderBox = styled.div<{ $isBackgroundImage?: boolean }>`
  position: relative;

  &::before {
    opacity: 0.2;
    height: 55px;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 0px 16px 0 0;
    margin: ${props => props.theme.spacing(0, -2)};
    padding: 1px;
    ${({ $isBackgroundImage }) =>
      $isBackgroundImage &&
      `
      left: -1px;
      right: -1px;
      top: -1px;
      `}
    background: linear-gradient(#fff, rgba(255, 255, 255, 0));
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const ProductWrapper = styled.div<{ $backgroundImage: string }>`
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 45%,
      rgb(37, 37, 37) 100%
    ),
    url(${({ $backgroundImage }) => $backgroundImage});
  position: relative;
  margin: 0 -10px;
  border-radius: 0px 16px 0 0;
  padding: 0 10px;
  padding-top: 10px;
  background-position: center top;
  background-size: cover;
`;

const StyledBox = styled.div<{ $isProductLogo?: boolean }>`
  ${({ $isProductLogo }) =>
    $isProductLogo &&
    `
    position: relative;
    top: 1px;
  `}
`;

export const ContractGuideWrapper: FunctionComponent<
  ContractGuideWrapperProps
> = ({ children, isDipWrapped, productIsin, allowProductBackground }) => {
  const { isDesktop } = useDesktop();
  const { backgroundImage } = useProductDesign(productIsin ?? undefined);

  const isBackgroundImage = Boolean(
    allowProductBackground && isDesktop && backgroundImage,
  );

  if (!isDesktop) {
    return <>{children}</>;
  }

  const content = (
    <Box paddingTop={3} paddingBottom={2}>
      {children}
    </Box>
  );

  return (
    <DipTagWrapper moveLeft={-2} showPlain={!isDipWrapped}>
      <StyledBox $isProductLogo={isBackgroundImage}>
        <BorderBox $isBackgroundImage={isBackgroundImage}>
          {isBackgroundImage && productIsin ? (
            <ProductWrapper $backgroundImage={backgroundImage ?? ""}>
              <ProductLogo
                isin={productIsin}
                imageHeight={30}
                position="relative"
                top={4}
                marginBottom={1}
              />
              {content}
            </ProductWrapper>
          ) : (
            content
          )}
        </BorderBox>
      </StyledBox>
    </DipTagWrapper>
  );
};
