import * as React from "react";
export const PlusBoldIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 6.25L12.5 18.75"
        stroke="#F2F2F2"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M18.75 12.5L6.25 12.5"
        stroke="#F2F2F2"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
};
