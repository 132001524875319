import { Stack } from "@mui/material";
import { ContractDistribution } from "Components/ContractDistribution/ContractDistribution";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { Resources, useResource } from "Translations/Resources";
import { BROKER_CONSULTING_DISTRIBUTION_ID } from "Utils/DistributionUtils";
import { track } from "Utils/TrackingUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent } from "react";
import { Navigate, useNavigate } from "react-router";

const PageResources = Resources.Contract.Distribution;

export const DistributionPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const { isLoading: isLoadingDashboard, data: dashboard } = useAppSelector(
    s => s.dashboard.contracts,
  );

  const navigate = useNavigate();

  if (dashboard?.isAccessRestricted) {
    track({
      category: "PageView",
      event: "Open",
      url: getPath(AppRouting.ContractDistribution),
      value: dashboard.superiorAgentID
        ? `Distribution ID: ${dashboard.superiorAgentID};`
        : null,
    });
  }

  if (!dashboard?.isAccessRestricted) {
    return <Navigate to={getPath(AppRouting.ContractModeling)} />;
  }

  if (
    dashboard?.isAccessRestricted &&
    dashboard?.superiorAgentID !== Number(BROKER_CONSULTING_DISTRIBUTION_ID)
  ) {
    return <Navigate to={getPath(AppRouting.ContractDealer)} />;
  }

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.Dashboard))}
    >
      <LoadingWrapper
        isLoading={isLoadingDashboard && !dashboard}
        skeleton={
          <Stack gap={2}>
            <PrimarySkeleton fullWidth fullHeight={false} height={30} />
            <PrimarySkeleton fullWidth fullHeight={false} height={80} />
          </Stack>
        }
      >
        <ContractDistribution email="centrumpeceoklienty@bcas.cz" />
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};
