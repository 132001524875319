import { resetUser } from "State/Auth/AuthReducer";
import { getBiometricSignInChallengeAsync } from "State/Auth/Biometrics/BiometricSignInChallengeState";
import { signInBiometricsAsync } from "State/Auth/Biometrics/BiometricSignInState";
import { getBiometricSignUpChallengeAsync } from "State/Auth/Biometrics/BiometricSignUpChallengeState";
import { signUpBiometricsAsync } from "State/Auth/Biometrics/BiometricSignUpState";
import {
  setBiometrics,
  setSettingBiometricsInProgress,
  setLogin,
  setBiometricsSignInCanBeUsed,
  BiometricsSignatureType,
  setBiometricsSignatureType,
  resetBiometricSignatureError,
  setBiometricsCanBeUsed,
  resetBiometricSignature,
  setBiometricsSignInType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { signWithBiometryAsync } from "State/Contracts/Biometrics/SignWithBiometryState";
import { produce } from "immer";
import { createReducer, ActionType } from "typesafe-actions";

export enum BiometricsSignInType {
  MANUAL = 0,
  MANUAL_WITH_PROMPT = 1,
}

export type BiometricsState = {
  canBeUsed: boolean;
  isBiometricsEnabled: boolean;
  isSettingBiometricsInProgress: boolean;
  wasSignedOutManually: boolean | null;
  signature: {
    canBeUsed: boolean;
    isInProgress: boolean;
    type: BiometricsSignatureType | null;
    signatureHash: string | null;
    error: Error | null;
  };
  signIn: {
    type: BiometricsSignInType;
    canBeUsed: boolean;
    login: string | null;
    isInProgress: boolean;
  };
  signUp: {
    redirectUrl: string | null;
  };
};

export type BiometricsAction =
  | ActionType<typeof setBiometrics>
  | ActionType<typeof setBiometricsCanBeUsed>
  | ActionType<typeof setSettingBiometricsInProgress>
  | ActionType<typeof setLogin>
  | ActionType<typeof setBiometricsSignInCanBeUsed>
  | ActionType<typeof setBiometricsSignInType>
  | ActionType<typeof setBiometricsSignatureType>
  | ActionType<typeof resetBiometricSignatureError>
  | ActionType<typeof resetBiometricSignature>
  | ActionType<typeof resetUser>
  | ActionType<typeof initializeBiometricSignatureAsync>
  | ActionType<typeof signWithBiometryAsync>
  | ActionType<typeof getBiometricSignInChallengeAsync>
  | ActionType<typeof signInBiometricsAsync>
  | ActionType<typeof getBiometricSignUpChallengeAsync>
  | ActionType<typeof signUpBiometricsAsync>;

export const biometricsReducer = createReducer<
  BiometricsState,
  BiometricsAction
>({
  canBeUsed: false,
  isBiometricsEnabled: false,
  isSettingBiometricsInProgress: false,
  wasSignedOutManually: null,
  signature: {
    canBeUsed: false,
    isInProgress: false,
    type: null,
    signatureHash: null,
    error: null,
  },
  signIn: {
    type: BiometricsSignInType.MANUAL_WITH_PROMPT,
    login: null,
    isInProgress: false,
    canBeUsed: false,
  },
  signUp: {
    redirectUrl: null,
  },
})
  .handleAction(setBiometrics, (state, action) => {
    return produce(state, draft => {
      draft.isBiometricsEnabled = action.payload;
      draft.signature.canBeUsed = action.payload;
      draft.signIn.canBeUsed = action.payload;
    });
  })
  .handleAction(setBiometricsCanBeUsed, (state, action) => {
    return produce(state, draft => {
      draft.canBeUsed = action.payload;
    });
  })
  .handleAction(setSettingBiometricsInProgress, (state, action) => {
    return produce(state, draft => {
      draft.isSettingBiometricsInProgress = action.payload;
    });
  })
  .handleAction(signWithBiometryAsync.request, state => {
    return produce(state, draft => {
      draft.signature.isInProgress = true;
      draft.signature.error = null;
    });
  })
  .handleAction(signWithBiometryAsync.success, state => {
    return produce(state, draft => {
      draft.signature.isInProgress = false;
      draft.signature.signatureHash = null;
      draft.signature.type = null;
      draft.signature.error = null;
    });
  })
  .handleAction(signWithBiometryAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.signature.isInProgress = false;
      draft.signature.canBeUsed = false;
      draft.signature.signatureHash = null;
      draft.signature.error = action.payload;
    });
  })
  .handleAction(setLogin, (state, action) => {
    return produce(state, draft => {
      draft.signIn.login = action.payload;
    });
  })
  .handleAction(getBiometricSignInChallengeAsync.request, state => {
    return produce(state, draft => {
      draft.signIn.isInProgress = true;
    });
  })
  .handleAction(getBiometricSignInChallengeAsync.failure, state => {
    return produce(state, draft => {
      draft.signIn.isInProgress = false;
      draft.signIn.canBeUsed = false;
    });
  })
  .handleAction(signInBiometricsAsync.success, state => {
    return produce(state, draft => {
      draft.signIn.isInProgress = false;
      draft.wasSignedOutManually = null;
    });
  })
  .handleAction(signInBiometricsAsync.failure, state => {
    return produce(state, draft => {
      draft.signIn.isInProgress = false;
      draft.signIn.canBeUsed = false;
      draft.wasSignedOutManually = null;
    });
  })
  .handleAction(setBiometricsSignInCanBeUsed, (state, action) => {
    return produce(state, draft => {
      draft.signIn.canBeUsed = action.payload;
    });
  })
  .handleAction(setBiometricsSignInType, (state, action) => {
    return produce(state, draft => {
      draft.signIn.type = action.payload;
    });
  })
  .handleAction(getBiometricSignUpChallengeAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.signUp.redirectUrl = action.payload.redirectUrl;
    });
  })
  .handleAction(signUpBiometricsAsync.success, state => {
    return produce(state, draft => {
      draft.signUp.redirectUrl = null;
      draft.wasSignedOutManually = null;
    });
  })
  .handleAction(signUpBiometricsAsync.failure, state => {
    return produce(state, draft => {
      draft.signUp.redirectUrl = null;
      draft.wasSignedOutManually = null;
    });
  })
  .handleAction(setBiometricsSignatureType, (state, action) => {
    return produce(state, draft => {
      draft.signature.type = action.payload;
    });
  })
  .handleAction(initializeBiometricSignatureAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.signature.signatureHash = null;
    });
  })
  .handleAction(initializeBiometricSignatureAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.signature.signatureHash = action.payload.signatureHash;
    });
  })
  .handleAction(initializeBiometricSignatureAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.signature.signatureHash = null;
    });
  })
  .handleAction(resetBiometricSignatureError, (state, action) => {
    return produce(state, draft => {
      draft.signature.error = null;
    });
  })
  .handleAction(resetUser, (state, action) => {
    return produce(state, draft => {
      draft.wasSignedOutManually = action.payload.wasSignedOutManually;
    });
  })
  .handleAction(resetBiometricSignature, (state, _) => {
    return produce(state, draft => {
      draft.signature.type = null;
      draft.signature.signatureHash = null;
      draft.signature.error = null;
      draft.signature.isInProgress = false;
    });
  });
