import { useCallback } from "react";

import {
  ConfirmDialogProps,
  ConfirmValues,
  confirmDialog,
} from "Components/Shared/Dialogs/ConfirmDialog";
import { useDynamicRef } from "Hooks/useDynamicRef";
import { useTheme } from "@mui/material";
import { Resources, useResource } from "Translations/Resources";

export const useConfirmationDialog = (
  mainProps: {
    confirmation: ConfirmDialogProps["confirmation"];
  } & Partial<ConfirmDialogProps>,
) => {
  const mainPropsRef = useDynamicRef(mainProps);
  const theme = useTheme();
  const { t } = useResource();

  const confirm = useCallback(
    async (props: Partial<ConfirmDialogProps> = {}): Promise<ConfirmValues> => {
      try {
        const result = await confirmDialog({
          confirmText: t(Resources.Common.Yes),
          cancelText: t(Resources.Common.No),
          ...mainPropsRef.current,
          ...props,
          theme,
        } as ConfirmDialogProps);
        return Object.values<string>(ConfirmValues).includes(
          result as ConfirmValues,
        )
          ? (result as ConfirmValues)
          : ConfirmValues.Cancel;
      } catch {
        return ConfirmValues.Cancel;
      }
    },
    [mainPropsRef, t, theme],
  );

  return confirm;
};
