import { useAppSelector } from "Hooks/useAppSelector";

export const useBankConnections = () => {
  const bankAccounts = useAppSelector(
    s => s.investmentsDetail.bankAccounts.data,
  );

  const isBankIDVerified = !!useAppSelector(
    s => s.investmentsDetail.overview.data?.isBankIDVerified,
  );

  const hasPortfolioItem = !!useAppSelector(
    s => s.investmentsDetail.overview.data?.portfolioItems,
  )?.length;

  const hasBankAccounts = !!bankAccounts && bankAccounts.length > 1;

  return {
    canCreate: isBankIDVerified || hasPortfolioItem,
    canDeactivate:
      (isBankIDVerified && hasBankAccounts) ||
      (!isBankIDVerified && hasBankAccounts && hasPortfolioItem),
  };
};
