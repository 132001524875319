import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import { USA_COUNTRY_CODE } from "Constants/Countries";
import { VALIDATION_POSTAL_CODE_COUNTRIES } from "Constants/Inputs";
import { BankIdScopes, BankIdClaims } from "Models/BankID";
import {
  ContactInformationAddressType,
  ContactInformationAddresses,
  ContactInformationFormModel,
} from "Models/Forms";
import { convertNanToNull } from "Utils/NumberUtils";
import { isNoU } from "Utils/ObjectUtils";
import { ObjectSchema, boolean, lazy, mixed, object, string } from "yup";

type Props = {
  translates?: {
    USACountryPermit: string;
    InvalidFormat: string;
  };
};

export const getContactInformationSchema = ({
  translates,
}: Props = {}): ObjectSchema<ContactInformationFormModel> => {
  const ContactInformationAddressTypeSchema = (
    permanentAddress: boolean = false,
  ): ObjectSchema<ContactInformationAddressType> =>
    object<ContactInformationAddressType>().shape({
      street: string()
        .required()
        .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.Street}`),
      city: string()
        .min(2)
        .required()
        .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.City}`),
      streetNumber: string()
        .optional()
        .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.StreetNumber}`),
      streetConscriptionNumber: string()
        .transform(v => v || undefined)
        .required()
        .label(
          `${BankIdScopes.ProfileAddresses}:${BankIdClaims.BuildingApartment}`,
        ),
      postalCode: lazy((_, context) => {
        if (VALIDATION_POSTAL_CODE_COUNTRIES.includes(context.parent.country)) {
          return string()
            .min(5, translates?.InvalidFormat ?? "Invalid format")
            .max(5, translates?.InvalidFormat ?? "Invalid format")
            .required()
            .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.ZipCode}`);
        }

        return string()
          .transform(val =>
            isNoU(convertNanToNull(parseInt(val))) ? null : val,
          )
          .nonNullable()
          .required()
          .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.ZipCode}`);
      }),
      country: string()
        .required()
        .test(
          USA_NATIONALITY_FORBID_TYPE,
          translates?.USACountryPermit ?? "USA country permit",
          value => !(permanentAddress && value === USA_COUNTRY_CODE),
        )
        .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.Country}`),
    });

  const ContactInformationAddressesSchema = (
    mailingAddressAsPermanent: boolean,
  ): ObjectSchema<ContactInformationAddresses> =>
    object<ContactInformationAddresses>().shape({
      permanentAddress: ContactInformationAddressTypeSchema(true).required(),
      mailingAddress: lazy(_ => {
        if (mailingAddressAsPermanent) {
          return mixed<ContactInformationAddressType>().optional();
        }

        return ContactInformationAddressTypeSchema(true).required();
      }),
    });

  return object<ContactInformationFormModel>().shape({
    mailingAddressAsPermanent: boolean().required(),
    addresses: lazy((_, context) => {
      const { mailingAddressAsPermanent } = context.parent;
      if (mailingAddressAsPermanent) {
        return ContactInformationAddressesSchema(true);
      }

      return ContactInformationAddressesSchema(false);
    }),
  });
};
