import { type InputBaseComponentProps } from "@mui/material/InputBase";
import { forwardRef, useId } from "react";
import styled from "styled-components";

type Props = InputBaseComponentProps & {
  label?: string;
  isPlaceholder: boolean;
};

const Label = styled.label`
  width: 100%;
  position: relative;
  cursor: pointer;

  input {
    opacity: 0 !important;
  }

  span {
    position: absolute;
    color: ${({ theme }) => theme.palette.text.primary};
    opacity: 0.9;
    font-weight: 300;
    left: 15px;
    right: 0;
    top: 30%;
    bottom: 0;
    z-index: 2;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span.MuiFileInput-placeholder {
    color: gray;
  }
`;

export const Input = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { label, isPlaceholder, placeholder, ...restInputProps } = props;
    const id = useId();

    return (
      <Label htmlFor={id}>
        <input {...restInputProps} ref={ref} id={id} />
        {label ? (
          <span
            aria-placeholder={placeholder}
            className={isPlaceholder ? "MuiFileInput-placeholder" : ""}
          >
            {label}
          </span>
        ) : null}
      </Label>
    );
  },
);
