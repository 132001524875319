import { produce } from "immer";
import {
  userContactVerified,
  UserContactVerifiedActionType,
} from "State/Shared/UserContactVerified";
import { ActionType, createAction, createReducer } from "typesafe-actions";

export type TwoFactorVerificationFormModel = {
  phone?: string | null;
};

export enum TwoFactorVerificationSteps {
  Modelling,
  Verification,
}

export type TwoFactorVerificationState = {
  isLoading: boolean;
  isPhoneVerified: boolean;
  actualStep: TwoFactorVerificationSteps;
  formData?: TwoFactorVerificationFormModel;
  error?: Error | null;
};

const initialTwoFactorVerificationState: TwoFactorVerificationState = {
  isLoading: false,
  isPhoneVerified: false,
  actualStep: TwoFactorVerificationSteps.Modelling,
  error: null,
};

export const resetTwoFactorVerificationState = createAction(
  "@profile-settings/RESET_2FA_STATE",
)<void>();

export const setTwoFactorVerificationActualStep = createAction(
  "@profile-settings/SET_2FA_ACTUAL_STEP",
)<TwoFactorVerificationSteps>();

export type TwoFactorVerificationActions =
  | ActionType<typeof resetTwoFactorVerificationState>
  | ActionType<typeof setTwoFactorVerificationActualStep>
  | UserContactVerifiedActionType;

// export function* watchTwoFactorVerificationSaga() {
//   yield takeLeading(getType(editAMLAsync.request), editAML);
// }

export const twoFactorVerificationReducer = createReducer<
  TwoFactorVerificationState,
  TwoFactorVerificationActions
>(initialTwoFactorVerificationState)
  .handleAction(setTwoFactorVerificationActualStep, (state, action) =>
    produce(state, draft => {
      draft.actualStep = action.payload;
      return draft;
    }),
  )
  .handleAction(resetTwoFactorVerificationState, (state, action) =>
    produce(state, draft => {
      return initialTwoFactorVerificationState;
    }),
  )
  .handleAction(userContactVerified, (state, action) =>
    produce(state, draft => {
      draft.isPhoneVerified = true;
      return draft;
    }),
  );
