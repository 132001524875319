import * as React from "react";
export const UserIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9375 1.70312C11.0977 1.73633 9.75391 2.28516 8.9375 3.35938C7.9707 4.63281 7.79492 6.57227 8.40625 9.10938C8.18164 9.38477 8.01172 9.80273 8.07812 10.3594C8.20898 11.457 8.64844 11.9121 9 12.0938C9.16992 12.9609 9.64844 13.9316 10.1094 14.3906V14.625C10.1113 14.9551 10.1055 15.2676 10.0938 15.5938C10.3555 16.1406 11.1855 17 13 17C14.8281 17 15.6797 16.123 15.9219 15.5156C15.9121 15.2148 15.9199 14.9297 15.9219 14.625V14.3906C16.3691 13.9336 16.8301 12.9609 17 12.0938C17.3613 11.9141 17.7891 11.4609 17.9219 10.3594C17.9883 9.81445 17.8281 9.40039 17.6094 9.125C17.9004 8.13477 18.4941 5.55664 17.4688 3.90625C17.0391 3.21484 16.3887 2.7793 15.5312 2.60938C15.0586 2.01367 14.1523 1.70312 12.9375 1.70312ZM16.375 16.6719C15.7441 17.4004 14.6289 18 13 18C11.3418 18 10.2578 17.3906 9.64062 16.6875C9.16406 17.0879 8.40234 17.3887 7.5625 17.7188C5.60938 18.4863 3.18164 19.4355 3 22.4688L2.96875 23H23.0312L23 22.4688C22.8184 19.4355 20.4004 18.4863 18.4531 17.7188C17.6094 17.3848 16.8496 17.0781 16.375 16.6719Z"
        fill="currentColor"
      />
    </svg>
  );
};
