import { ErrorTypography, StyledError } from "Components/Shared/FormStyles";
import { type FunctionComponent, type PropsWithChildren } from "react";

export type Props = {
  hasError?: boolean;
  errorMessage?: string;
};

export const CheckboxErrorWrapper: FunctionComponent<
  PropsWithChildren<Props>
> = ({ errorMessage, hasError, children }) => {
  return (
    <div>
      {children}

      {hasError && (
        <StyledError>
          <ErrorTypography>{errorMessage}</ErrorTypography>
        </StyledError>
      )}
    </div>
  );
};
