import { Resources, useResource } from "Translations/Resources";

const MAX_MESSAGE_LENGTH = 60;
const EMPTY_MESSAGE = "";
const SELF_NEGOTIATED_CONTRACT_MESSAGE_SUFFIX = "MobApp";

export const usePaymentMessage = (
  contractNo: string,
  firstName: string,
  lastName: string,
  ss: string,
  vs: string,
  isVerifiedBankIdAccount: boolean,
  isNoticeToFirstPaymentVisible: boolean,
  isSelfNegotiatedContract: boolean,
  isCzechBankAccount: boolean,
  isDomesticPayment: boolean,
): string => {
  const messageFirstPart = usePaymentMessageFistPart(ss, vs);
  const messageSecondPart = usePaymentMessageSecondPart(
    contractNo,
    firstName,
    lastName,
    isCzechBankAccount,
  );

  const paymentMessage = isDomesticPayment
    ? getDomesticPaymentMessage(
        messageSecondPart,
        isVerifiedBankIdAccount,
        isNoticeToFirstPaymentVisible,
      )
    : getForeignPaymentMessage(
        messageFirstPart,
        messageSecondPart,
        isVerifiedBankIdAccount,
        isNoticeToFirstPaymentVisible,
      );

  if (!isSelfNegotiatedContract) {
    return `${paymentMessage} ${SELF_NEGOTIATED_CONTRACT_MESSAGE_SUFFIX}`;
  }

  return paymentMessage;
};

export const useQrCodePaymentMessage = (
  contractNo: string,
  firstName: string,
  lastName: string,
  ss: string,
  vs: string,
  isVerifiedBankIdAccount: boolean,
  isNoticeToFirstPaymentVisible: boolean,
  isSelfNegotiatedContract: boolean,
  isCzechBankAccount: boolean,
  isSlovakiaBankAccount: boolean,
  isDomesticPayment: boolean,
): string => {
  const messageFirstPart = usePaymentMessageFistPart(ss, vs);
  const messageSecondPart = usePaymentMessageSecondPart(
    contractNo,
    firstName,
    lastName,
    isCzechBankAccount,
  );

  const qrCodePaymentMessage = isDomesticPayment
    ? getDomesticQrCodePaymentMessage(
        messageSecondPart,
        isVerifiedBankIdAccount,
        isNoticeToFirstPaymentVisible,
      )
    : getForeignQrCodePaymentMessage(
        messageFirstPart,
        isVerifiedBankIdAccount,
        isNoticeToFirstPaymentVisible,
        isSlovakiaBankAccount,
      );

  if (!isSelfNegotiatedContract) {
    return `${qrCodePaymentMessage} ${SELF_NEGOTIATED_CONTRACT_MESSAGE_SUFFIX}`;
  }

  return qrCodePaymentMessage;
};

const usePaymentMessageSecondPart = (
  contractNo: string,
  firstName: string,
  lastName: string,
  isCzechBankAccount: boolean,
) => {
  const { t } = useResource();

  if (isCzechBankAccount) {
    return t(Resources.Components.PaymentInformation.FirstPaymentMessage, {
      contractNo,
      firstName: firstName.toLocaleUpperCase(),
      lastName: lastName.toLocaleUpperCase(),
    })
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
  }

  return t(
    Resources.Components.PaymentInformation
      .FirstPaymentMessageForeignBankAccountCountry,
    {
      contractNo,
      firstName: firstName.toLocaleUpperCase(),
      lastName: lastName.toLocaleUpperCase(),
    },
  )
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");
};

const usePaymentMessageFistPart = (ss: string, vs: string) => {
  return `/VS${vs}/SS${ss}`;
};

const getDomesticPaymentMessage = (
  messageSecondPart: string,
  isVerifiedBankIdAccount: boolean,
  isNoticeToFirstPaymentVisible: boolean,
) => {
  if (isVerifiedBankIdAccount) {
    return EMPTY_MESSAGE;
  }

  if (isNoticeToFirstPaymentVisible) {
    return messageSecondPart;
  }

  return EMPTY_MESSAGE;
};

const getForeignPaymentMessage = (
  messageFirstPart: string,
  messageSecondPart: string,
  isVerifiedBankIdAccount: boolean,
  isNoticeToFirstPaymentVisible: boolean,
) => {
  if (isVerifiedBankIdAccount) {
    return messageFirstPart;
  }

  if (isNoticeToFirstPaymentVisible) {
    return `${messageFirstPart} ${messageSecondPart}`;
  }

  return messageFirstPart;
};

const getDomesticQrCodePaymentMessage = (
  messageSecondPart: string,
  isVerifiedBankIdAccount: boolean,
  isNoticeToFirstPaymentVisible: boolean,
) => {
  if (isVerifiedBankIdAccount) {
    return EMPTY_MESSAGE;
  }

  if (isNoticeToFirstPaymentVisible) {
    return messageSecondPart.substring(0, MAX_MESSAGE_LENGTH);
  }

  return EMPTY_MESSAGE;
};

const getForeignQrCodePaymentMessage = (
  messageFirstPart: string,
  isVerifiedBankIdAccount: boolean,
  isNoticeToFirstPaymentVisible: boolean,
  isSlovakiaBankAccount: boolean,
) => {
  if (
    isSlovakiaBankAccount &&
    (isVerifiedBankIdAccount || !isNoticeToFirstPaymentVisible)
  ) {
    return messageFirstPart;
  }

  return EMPTY_MESSAGE;
};
