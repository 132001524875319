import { Box, DialogContent, Divider, Typography } from "@mui/material";
import { StyledDialog } from "Components/Shared/StyledComponents";
import styled from "styled-components";
import { TrackingWrapper } from "Components/Tracking/TrackingWrapper";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, FocusEvent, useCallback } from "react";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useAppSelector } from "Hooks/useAppSelector";
import { useYupFormSchema } from "Hooks/Contract/Detail/CashMovements/useYupFormSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValid as isValidDate } from "date-fns";
import {
  formatDocumentsDateToDate,
  parseDateToDocumentsDate,
} from "Utils/Contracts/NewContract/DateUtils";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { useAppDispatch } from "Hooks/useAppDispatch";
import {
  CashMovementsFiltersFormModel,
  initialCashMovementsState,
  setCashMovementsFilters,
} from "State/InvestmentsDetail/CashMovements/CashMovementsState";
import { isEqual } from "lodash-es";

const StyledDialogWithOffset = styled(StyledDialog)`
  margin-top: 90px;
  height: calc(100vh - 90px);
`;

const StyledDateInput = styled(BlInputFormField)`
  color-scheme: dark;
`;

type Props = {
  isOpened: boolean;
  onClose: () => void;
};

const ComponentResources =
  Resources.Contract.Detail.CashMovements.Filter.Dialog;

export const FilterDialog: FunctionComponent<Props> = ({
  isOpened,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const { t } = useResource();
  const { filters } = useAppSelector(s => s.investmentsDetail.cashMovements);

  const schema = useYupFormSchema();
  const {
    control,
    setValue,
    trigger,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CashMovementsFiltersFormModel>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: filters,
  });

  const onSubmit = (formData: CashMovementsFiltersFormModel) => {
    if (!isEqual(filters, formData)) {
      dispatch(setCashMovementsFilters(formData));
    }

    onClose();
  };

  const onReset = useCallback(() => {
    if (!isEqual(filters, initialCashMovementsState.filters)) {
      dispatch(setCashMovementsFilters(initialCashMovementsState.filters));
    }

    onClose();
  }, [filters, onClose, dispatch]);

  const dateFromString = watch("dateFrom");
  const dateToString = watch("dateTo");

  const handleDateBlur = useCallback(
    (
      event: FocusEvent<HTMLInputElement>,
      propertyName: "dateFrom" | "dateTo",
      dateFromString?: string,
      dateToString?: string,
    ) => {
      if (!event.target.value) {
        setValue(propertyName, "", {
          shouldValidate: true,
        });
        return;
      }

      const date = new Date(event.target.value);
      const minDate = new Date(1900, 0, 1, 0, 0, 0);
      const maxDate = new Date(2999, 11, 31, 23, 59, 59);

      if (dateFromString) {
        const dateFrom = formatDocumentsDateToDate(dateFromString);
        if (isValidDate(dateFrom) && dateFrom > date) {
          setValue(propertyName, dateFromString, {
            shouldValidate: true,
          });
          return;
        }
      }

      if (dateToString) {
        const dateTo = formatDocumentsDateToDate(dateToString);
        if (isValidDate(date) && date > dateTo) {
          setValue(propertyName, dateToString, {
            shouldValidate: true,
          });
          return;
        }
      }

      if (isValidDate(date) && date > maxDate) {
        setValue(propertyName, parseDateToDocumentsDate(maxDate), {
          shouldValidate: true,
        });
        return;
      }

      if (isValidDate(date) && date < minDate) {
        setValue(propertyName, parseDateToDocumentsDate(minDate), {
          shouldValidate: true,
        });
        return;
      }

      trigger(propertyName);
    },
    [setValue, trigger],
  );

  const handleDateFromBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      handleDateBlur(event, "dateFrom", undefined, dateToString);
    },
    [dateToString, handleDateBlur],
  );

  const handleDateToBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      handleDateBlur(event, "dateTo", dateFromString, undefined);
    },
    [dateFromString, handleDateBlur],
  );

  return (
    <StyledDialogWithOffset
      open={isOpened}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <TrackingWrapper
          payload={{
            category: "ModalView",
            event: "FundNotAllowedDialog",
            action: isOpened ? "Open" : "Close",
          }}
        >
          <Typography fontWeight={700} fontSize={22}>
            {t(ComponentResources.Title)}
          </Typography>
          <Box marginY={2}>
            <Divider />
          </Box>
          <Box marginBottom={3}>
            <Typography variant="subtitle1" marginBottom={1}>
              {t(ComponentResources.DateRange)}
            </Typography>

            <StyledDateInput
              control={control}
              errors={errors}
              name="dateFrom"
              type="date"
              label={t(ComponentResources.DateFrom)}
              onBlur={handleDateFromBlur}
            />

            <StyledDateInput
              control={control}
              errors={errors}
              name="dateTo"
              type="date"
              label={t(ComponentResources.DateTo)}
              onBlur={handleDateToBlur}
            />
          </Box>

          <Box marginBottom={5}>
            <Typography variant="subtitle1" marginBottom={1}>
              {t(ComponentResources.Include)}
            </Typography>

            <BlCheckboxFormField
              control={control}
              name="includeDeposits"
              label={t(ComponentResources.IncludeDeposits)}
              errors={errors}
            />

            <BlCheckboxFormField
              control={control}
              name="includeWithdrawals"
              label={t(ComponentResources.IncludeWithdrawals)}
              errors={errors}
            />

            <BlCheckboxFormField
              control={control}
              name="includeFees"
              label={t(ComponentResources.IncludeFees)}
              errors={errors}
            />
          </Box>

          <Box display="flex" justifyContent="space-around" gap={3}>
            <PrimaryButton
              trackingTag={t(ComponentResources.Apply)}
              small
              color="primary"
              onClick={handleSubmit(onSubmit)}
              fullWidth
              disabled={!isValid}
            >
              {t(ComponentResources.Apply)}
            </PrimaryButton>
            <PrimaryButton
              trackingTag={t(ComponentResources.Close)}
              small
              color="secondary"
              onClick={onReset}
              fullWidth
            >
              {t(ComponentResources.Close)}
            </PrimaryButton>
          </Box>
        </TrackingWrapper>
      </DialogContent>
    </StyledDialogWithOffset>
  );
};
