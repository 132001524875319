import { useAppSelector } from "Hooks/useAppSelector";
import { LookupItem } from "Models/LookupItem";
import { useMemo } from "react";

type UseBusinessSectorType = () => LookupItem[];

export const useBusinessSectorList: UseBusinessSectorType = () => {
  const { data } = useAppSelector(s => s.codeList.businessSector);

  return useMemo<LookupItem<string>[]>(
    () =>
      data?.businessSectors?.map<LookupItem<string>>(({ name, code }) => ({
        Name: name,
        Value: code,
      })) ?? [],
    [data],
  );
};
