import { logError } from "ErrorService";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  Resources,
  mapValidationErrorToPath,
  useResource,
  useServerError,
} from "Translations/Resources";
import i18next from "i18next";
import { useEffect, useCallback, useRef } from "react";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

type KeyMap<T extends FieldValues> = Partial<Record<FieldPath<T>, string>>;

type UseHandleErrorsProps<T extends FieldValues> = {
  form: UseFormReturn<T, any>;
  serverError: Error | null | undefined;
  resource: Resource;
  key: FieldPath<T>;
  keyMap?: KeyMap<T>;
};

type Resource = {
  General: string;
  [key: string]: string;
};

const useHandleServerError = <T extends FieldValues>({
  serverError,
  resource,
  key,
  form,
  keyMap,
}: UseHandleErrorsProps<T>) => {
  const errorKeyRef = useRef<(FieldPath<T> | null)[]>([]);
  const { translateError } = useServerError(resource, resource.General);
  const { t } = useResource();
  const { t: i18Translation } = useTranslation();

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = form;

  const handleErrors = useCallback(() => {
    if (serverError) {
      const errorMessages = decodeMessages<T>(
        translateError,
        t,
        i18Translation,
        serverError,
        resource,
        keyMap,
      );

      // First, clear all previously set server errors
      errorKeyRef.current.forEach(k => {
        if (k) clearErrors(k);
      });
      // Reset the error keys tracking
      errorKeyRef.current = [];

      // Then set new server errors
      if (errorMessages.length === 0) {
        setError(key, {
          type: "server",
          message: t(Resources.Validation.General),
        });
      } else {
        errorMessages.forEach(({ message, errorKey }) => {
          const k = errorKey ?? key;
          if (k) {
            const prevErrorMessage = errors[k]?.message as string;
            if (prevErrorMessage && prevErrorMessage !== message) {
              setError(k, {
                type: "server",
                message: `${prevErrorMessage} ${message}`,
              });
            } else {
              setError(k, {
                type: "server",
                message,
              });
            }
            // Track the keys for which server errors are set
            errorKeyRef.current.push(k);
          }
        });
      }
    } else {
      // If there's no server error, clear all previously set server errors
      errorKeyRef.current.forEach(k => {
        if (k) {
          clearErrors(k);
        }
      });
      // Reset the error keys tracking
      errorKeyRef.current = [];
    }
  }, [
    serverError,
    errors,
    keyMap,
    i18Translation,
    key,
    resource,
    setError,
    translateError,
    clearErrors,
    t,
  ]);
  const prevServerErrorRef = useRef(serverError);

  useEffect(() => {
    if (prevServerErrorRef.current !== serverError) {
      handleErrors();
      prevServerErrorRef.current = serverError;
    }
  }, [serverError, handleErrors]);
};

function getEncodedErrors(
  serverError: Error,
): Array<string | null | undefined> {
  if (serverError instanceof ApplicationError) {
    return [serverError.validationErrors ?? serverError.message];
  }

  const errorMessages = (serverError.cause as any)?.error
    ?.errorMessages as string[];

  if (errorMessages?.length > 0) {
    return errorMessages;
  }

  try {
    const json = JSON.parse(
      (serverError as any).response.error.userFriendlyMessage,
    );
    if (!!json) {
      return json.errors ?? [];
    }
    return [];
  } catch (error) {
    return [];
  }
}

export function useServerErrorMessage(
  error: Error | null | undefined,
  resource: Resource,
) {
  const { translateError } = useServerError(resource, resource.General);
  const { t } = useResource();
  const { t: i18Translation } = useTranslation();

  if (error) {
    const errorMessages = decodeMessages(
      translateError,
      t,
      i18Translation,
      error,
      resource,
    );

    if (errorMessages.length === 0) {
      return [t(Resources.Validation.General)];
    }

    return errorMessages.map(x => x.message);
  }

  return [];
}

function decodeMessages<T extends FieldValues>(
  translateError: ReturnType<typeof useServerError>["translateError"],
  t: ReturnType<typeof useResource>["t"],
  i18T: ReturnType<typeof useTranslation>["t"],
  serverError: Error,
  resource: Resource,
  keyMap?: KeyMap<T>,
): {
  message: string;
  errorKey: FieldPath<T> | null;
}[] {
  const encodedErrors = getEncodedErrors(serverError)
    .filter(x => !!x)
    .map(x => x!);

  return encodedErrors.map(encodedError => {
    const decodedError = encodedError?.split("|")[0];
    const decodedErrorKey = encodedError?.split("|")[1] as string;

    const errorKey = keyMap
      ? (Object.keys(keyMap)[
          Object.values(keyMap).indexOf(decodedErrorKey)
        ] as FieldPath<T>) ?? null
      : null;

    const message = translateMessage(
      encodedError!,
      decodedError,
      resource,
      translateError,
      i18T,
      t,
    );

    return {
      message,
      errorKey,
    };
  });
}

function translateMessage(
  encodedError: string,
  decodedError: string | undefined,
  resource: Resource,
  translateError: ReturnType<typeof useServerError>["translateError"],
  i18T: ReturnType<typeof useTranslation>["t"],
  t: ReturnType<typeof useResource>["t"],
) {
  const generalTranslation = t(resource.General);
  const translation = translateError(decodedError);

  if (!!translation && translation !== generalTranslation) {
    return translation;
  }

  if (decodedError) {
    const resourcePath = mapValidationErrorToPath(decodedError);

    if (resourcePath) {
      const translation = i18T(resourcePath);

      const isCodeTranslated = i18next.exists(resourcePath);

      if (!isCodeTranslated) {
        console.error(
          `Translation not found for ${resourcePath} for ${encodedError}.`,
        );
        logError(
          new Error(
            `Translation not found for ${resourcePath} for ${encodedError}.`,
          ),
        );
        return generalTranslation;
      }

      return translation;
    }
  }

  console.error(`Translation not found for ${decodedError}.`);
  logError(new Error(`Translation not found for ${decodedError}.`));

  return generalTranslation;
}

export default useHandleServerError;
