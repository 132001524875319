import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useInvestUninvestedDepositsTitle } from "Hooks/Contract/useInvestUninvestedDepositsTitle";
import { useAppSelector } from "Hooks/useAppSelector";
import { useProductColor } from "Hooks/useProductColor";

import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { type FunctionComponent } from "react";
import { useNavigate } from "react-router";

type Props = {
  contractID: number;
};

const PageResources = Resources.Contract.Detail.Purchase.Target.Success;

export const SuccessStep: FunctionComponent<Props> = ({ contractID }) => {
  const { t } = useResource();
  const { lastRequest } = useAppSelector(s => s.contracts.uninvestedDeposits);
  const { color, contrastColor } = useProductColor(lastRequest?.isin);
  const pageTitle = useInvestUninvestedDepositsTitle();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{pageTitle}</PageTitle>
        <Typography component="span">{t(PageResources.Text)}</Typography>
      </div>
      <PrimaryButton
        color="primary"
        onClick={() => navigate(getPath(AppRouting.ContractDetail, contractID))}
        trackingTag={t(PageResources.Button)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(PageResources.Button)}
      </PrimaryButton>
    </>
  );
};
