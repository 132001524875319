import { ContractCategories } from "Models/Contracts";
import { InformationFormModel } from "State/Contracts/Modeling/Models";
import { string, object, number, ObjectSchema } from "yup";

export const useYupFormSchema = (): ObjectSchema<InformationFormModel> => {
  return object<InformationFormModel>().shape({
    name: string().optional(),
    category: number()
      .oneOf(Object.values(ContractCategories).map(v => Number(v)))
      .optional(),
  });
};
