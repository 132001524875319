import { useAppSelector } from "Hooks/useAppSelector";
import { ExchangeAssetsFormData } from "State/Contracts/ExchangeAssets/ExchangeAssetsState";

export const useFormData = (): ExchangeAssetsFormData => {
  const defaultValues = useAppSelector(
    s => s.contracts.exchangeAssets.formData,
  );

  return defaultValues;
};
