import { Box, CardContent } from "@mui/material";
import { RadioButton } from "Components/Shared/Inputs/RadioButton";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { StyledDarkCard, StyledFlex } from "Components/Shared/StyledComponents";
import { useFormattedProductClass } from "Hooks/Products/useFormattedProductClass";
import { ChangeEvent, type FunctionComponent } from "react";
import styled from "styled-components";

const StyledProductCard = styled(StyledDarkCard)`
  .MuiCardContent-root {
    padding-left: 0;
  }
`;

type Props = {
  isin: string;
  isChecked: boolean;
  onCheck: (isin: string) => void;
};

export const ProductCard: FunctionComponent<Props> = ({
  isin,
  isChecked,
  onCheck,
}) => {
  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onCheck(isin);
    }
  };

  const productClass = useFormattedProductClass(isin);

  return (
    <StyledProductCard>
      <CardContent>
        <StyledFlex $gap={3}>
          <RadioButton checked={isChecked} onChange={handleCheck} />
          <Box flexGrow={1} onClick={() => onCheck(isin)}>
            <ProductLogo isin={isin} name={productClass} imageHeight={35} />
          </Box>
        </StyledFlex>
      </CardContent>
    </StyledProductCard>
  );
};
