import { put, takeLatest } from "redux-saga/effects";
import { BankIDProcessAudience, postBankIdAuthProcessesInit } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { getTrackingSessionID } from "Utils/TrackingUtils";
import { safeApiCall } from "State/Utils";
import { postMessageFromWebView } from "Utils/WebViewUtils";
import { WebViewMessageTypes } from "Models/WebViewModels";

export const initBankIDVerificationProcessAsync = createAsyncAction(
  "@bankID/INIT_VERIFICATION_PROCESS_FOR_MOBILE_REQUEST",
  "@bankID/INIT_VERIFICATION_PROCESS_FOR_MOBILE_SUCCESS",
  "@bankID/INIT_VERIFICATION_PROCESS_FOR_MOBILE_FAILURE",
)<
  {
    processID: string;
    processAudience: BankIDProcessAudience;
    redirectUri: string;
  },
  void,
  Error
>();

function* initBankIDVerificationProcess(
  action: ReturnType<typeof initBankIDVerificationProcessAsync.request>,
): Generator {
  try {
    const { processID, processAudience, redirectUri } = action.payload;
    const { error } = yield* safeApiCall(postBankIdAuthProcessesInit, {
      processID,
      processAudience,
      trackingSessionID: getTrackingSessionID(),
    });

    if (error) {
      yield put(initBankIDVerificationProcessAsync.failure(error));
      return;
    }

    yield put(initBankIDVerificationProcessAsync.success());

    postMessageFromWebView({
      type: WebViewMessageTypes.BANK_ID_REDIRECT,
      payload: {
        redirectUri,
      },
    });
  } catch (err) {
    yield put(initBankIDVerificationProcessAsync.failure(err as Error));
  }
}

export function* watchInitBankIDVerificationProcessSaga() {
  yield takeLatest(
    getType(initBankIDVerificationProcessAsync.request),
    initBankIDVerificationProcess,
  );
}
