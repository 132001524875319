import { Typography } from "@mui/material";
import { CloseIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { track } from "Utils/TrackingUtils";

type Props = {
  channelID: string;
  title: string;
  deepLink?: string | null | undefined;
  onClose: (channelID: string) => void;
};

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.background.default};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 12px;
  height: 12px;
  color: ${({ theme }) => theme.palette.background.default};
`;

const StyledIconBox = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default}20;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: -${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(4)};
`;

export const CampaignCardHeading: FunctionComponent<Props> = ({
  channelID,
  title,
  onClose,
  deepLink,
}) => {
  const close = () => {
    track({
      category: "Banner",
      event: "Click",
      action: "Close",
      url: deepLink,
      channelID: channelID,
    });

    onClose(channelID);
  };

  return (
    <StyledFlex $justifyContent="space-between">
      <StyledTypography fontWeight={600}>{title}</StyledTypography>
      <div>
        <StyledIconBox role="button" onClick={close}>
          <StyledCloseIcon />
        </StyledIconBox>
      </div>
    </StyledFlex>
  );
};
