import { PersonalDocumentType } from "Api/Api";
import { LookupItem } from "Models/LookupItem";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

type ListType = LookupItem<PersonalDocumentType>;
type Result = ListType[];

const DocumentsResources = Resources.Forms.Documents;

export const useDocumentsList = (): Result => {
  const { t } = useResource();

  return useMemo(
    () => [
      {
        Value: PersonalDocumentType.IdentityCard,
        Name: t(DocumentsResources.IdentityCard),
      },
      {
        Value: PersonalDocumentType.DrivingLicense,
        Name: t(DocumentsResources.DrivingLicense),
      },
      {
        Value: PersonalDocumentType.TemporaryResidencePermit,
        Name: t(DocumentsResources.ResidencePermit),
      },
      {
        Value: PersonalDocumentType.Passport,
        Name: t(DocumentsResources.Passport),
      },
      {
        Value: PersonalDocumentType.GunLicense,
        Name: t(DocumentsResources.GunLicense),
      },
    ],
    [t],
  );
};
