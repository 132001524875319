import { Card, CardContent } from "@mui/material";
import { CampaignCardContent } from "Components/Shared/Campaigns/CampaignCardContent";
import { CampaignCardHeading } from "Components/Shared/Campaigns/CampaignCardHeading";
import { FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
  channelID: string;
  title: string;
  body?: string | null | undefined;
  deepLink?: string | null | undefined;
  onOpen: (channelID: string, deepLink: string | null | undefined) => void;
  onClose: (channelID: string) => void;
};

const StyledCampaignCard = styled(Card)`
  width: 100%;
  opacity: 0.9;
  box-shadow: none;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledCampaignCardContent = styled(CardContent)`
  box-sizing: border-box;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(3, 4, 2, 4)};
`;

const StyledContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(1)};
`;

export const CampaignCard: FunctionComponent<Props> = props => {
  return (
    <StyledCampaignCard className="campaign-card">
      <StyledCampaignCardContent>
        <StyledContainer>
          <CampaignCardHeading {...props} />
          <CampaignCardContent {...props} />
        </StyledContainer>
      </StyledCampaignCardContent>
    </StyledCampaignCard>
  );
};
