import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import styled from "styled-components";
import { Box, Link, Typography } from "@mui/material";
import { PERSONAL_DATA_ANALYTICS_URL } from "Utils/ExternalLinkUtils";

const DiscaimerWrapper = styled(Box)`
  margin: ${props => props.theme.spacing(4, 2)};
  display: flex;
`;

const DisclaimerText = styled(Typography)`
  opacity: 0.5;
`;

export const AnalyticsDisclaimer: FunctionComponent = _ => {
  const { t } = useResource();

  return (
    <>
      <DiscaimerWrapper>
        <DisclaimerText>
          {t(Resources.AnalyticsDisclaimer.TextPart1)}
          <Typography component="span" fontWeight={700}>
            {t(Resources.AnalyticsDisclaimer.TextPart2)}
          </Typography>
          {t(Resources.AnalyticsDisclaimer.TextPart3)}
          <Link
            target="_blank"
            href={PERSONAL_DATA_ANALYTICS_URL}
            fontWeight={600}
            color="secondary"
          >
            {t(Resources.AnalyticsDisclaimer.Link)}
          </Link>
        </DisclaimerText>
      </DiscaimerWrapper>
    </>
  );
};
