import {
  Control,
  Controller,
  type FieldError,
  type FieldErrors,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";
import { Box, CheckboxProps } from "@mui/material";
import { CodeListItem } from "Models/CodeListItem";
import { CheckboxInput } from "Components/Shared/Inputs/BlCheckboxField/CheckboxInput";
import { useFieldError } from "Hooks/useFieldError";
import { CheckboxErrorWrapper } from "Components/Shared/Inputs/BlCheckboxField/CheckboxErrorWrapper";
import { FormInfo } from "Components/Shared/Inputs/FormInfo";

import { StyledFormLabelWrapper } from "Components/Shared/FormStyles";
import get from "lodash-es/get";

type Props<FormType extends FieldValues> = CheckboxProps & {
  name: FieldPath<FormType>;
  codeList: CodeListItem[];
  control: Control<FormType, object>;
  errors: FieldErrors<FormType>;
  formInfo?: { [key: string]: string };
  label?: string;
  isLabelFirst?: boolean;
};

export const BlCheckboxesFormField = <T extends FieldValues>(
  props: Props<T>,
) => {
  const { hasError, errorMessage, originalProps } = useFieldError({
    ...props,
    fieldError: get(props.errors, props.name as string) as FieldError,
  });
  const {
    control,
    name: formName,
    isLabelFirst,
    formInfo,
    label,
  } = originalProps;

  const { codeList, ...restProps } = originalProps;

  return (
    <Box marginBottom={2}>
      <Controller<T>
        control={control}
        name={formName}
        render={({ field: { onChange, value } }) => (
          <CheckboxErrorWrapper hasError={hasError} errorMessage={errorMessage}>
            <StyledFormLabelWrapper $isError={hasError}>
              <label htmlFor={formName}>{label}</label>
              <FormInfo name={formName} formInfo={formInfo} />
            </StyledFormLabelWrapper>
            {codeList.map(({ code, name }) => (
              <CheckboxInput
                key={code}
                onChange={(_, checked) =>
                  checked
                    ? onChange(
                        (Array.isArray(value) ? value : ([] as any)).concat(
                          code,
                        ),
                      )
                    : onChange(
                        Array.isArray(value)
                          ? value.filter((c: unknown) => c !== code)
                          : [],
                      )
                }
                value={code}
                checked={Array.isArray(value) ? value.includes(code) : false}
                isLabelFirst={isLabelFirst}
                {...restProps}
                label={name}
                id={String(`${formName}-${code}`)}
                name={String(`${formName}-${code}`)}
              />
            ))}
          </CheckboxErrorWrapper>
        )}
      ></Controller>
    </Box>
  );
};
