import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getBiometricSignInChallengeAsync } from "State/Auth/Biometrics/BiometricSignInChallengeState";
import { setBiometricsSignInCanBeUsed } from "State/Biometrics/BiometricsActions";
import { BiometricsSignInType } from "State/Biometrics/BiometricsReducer";
import { useEffect } from "react";

export const useBiometricSignIn = () => {
  const dispatch = useAppDispatch();
  const { isBiometricsEnabled, signIn, wasSignedOutManually } = useAppSelector(
    x => x.biometrics,
  );
  const { login, isInProgress } = signIn;
  const { isAuthenticated, isLoading } = useAppSelector(x => x.auth);
  const { canUseBiometricsForSignIn } = useBiometricsCanBeUsed();
  const { type: signInType } = useAppSelector(s => s.biometrics.signIn);

  const canBiometricSignIn =
    canUseBiometricsForSignIn &&
    isBiometricsEnabled &&
    !isInProgress &&
    !!login &&
    !isAuthenticated &&
    !isLoading &&
    wasSignedOutManually !== true &&
    signInType === BiometricsSignInType.MANUAL_WITH_PROMPT;

  // when component is mounted, set canBiometricSignIn to true - gave another try for user to sigh in with biometrics
  useEffect(() => {
    dispatch(setBiometricsSignInCanBeUsed(true));
  }, [dispatch]);

  useEffect(() => {
    if (canBiometricSignIn) {
      dispatch(
        getBiometricSignInChallengeAsync.request({
          login,
        }),
      );
    }
  }, [canBiometricSignIn, login, dispatch]);
};
