import { ContractDraftDto } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { CreateContractState } from "State/Contracts/Create/CreateState";
import { postDraftAsync } from "State/Contracts/Draft/PostDraft/PostDraftState";
import { ModelingContractState } from "State/Contracts/Modeling/ModelingState";
import Store from "State/Store";
import { useResource } from "Translations/Resources";
import { useCallback } from "react";

export type DraftJsonDataModel = {
  modeling: ModelingContractState;
  create: Pick<
    CreateContractState,
    | "actualStep"
    | "formData"
    | "isBankIDVerified"
    | "isDuplicityVerified"
    | "isIdentityNumberVerified"
    | "isPhoneVerified"
    | "isBiometrySignatureInProcess"
  >;
};

export const useSaveContractDraft = () => {
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const { isLoading } = useAppSelector(s => s.contracts.draft.postDraft);

  const postDraft = useCallback(
    (
      props: Pick<ContractDraftDto, "isContractCreated" | "isContractSigned">,
    ) => {
      const {
        contracts: { modeling, create },
      } = Store.store.getState();

      const {
        actualStep,
        formData,
        isBankIDVerified,
        isDuplicityVerified,
        isIdentityNumberVerified,
        isPhoneVerified,
        isBiometrySignatureInProcess,
      } = create;

      const jsonDataObject: DraftJsonDataModel = {
        modeling,
        create: {
          actualStep,
          formData,
          isBankIDVerified: isBankIDVerified,
          isDuplicityVerified,
          isIdentityNumberVerified,
          isPhoneVerified,
          isBiometrySignatureInProcess,
        },
      };

      dispatch(
        postDraftAsync.request({
          draft: { ...props, jsonData: JSON.stringify(jsonDataObject) },
          translate: t,
        }),
      );
    },
    [dispatch, t],
  );

  return {
    postDraft,
    isLoading,
  };
};
