import * as React from "react";
export const ArrowRightFatIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={11}
      height={11}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.97017 10.5568L4.81676 9.41335L7.84446 6.38565H0.75V4.70526H7.84446L4.81676 1.68253L5.97017 0.534091L10.9815 5.54545L5.97017 10.5568Z"
        fill="currentColor"
      />
    </svg>
  );
};
