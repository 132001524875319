import { type FunctionComponent } from "react";
import {
  ComponentTitleWrapper,
  type Props as ComponentTitleWrapperProps,
} from "Components/Shared/ComponentTitleWrapper";

type Props = { header: ComponentTitleWrapperProps["title"] } & Omit<
  ComponentTitleWrapperProps,
  "title"
>;

export const InputHeaderWrapper: FunctionComponent<Props> = ({
  header,
  marginTop = 6,
  ...props
}) => {
  return (
    <ComponentTitleWrapper {...props} marginTop={marginTop} title={header} />
  );
};
