import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put, takeLeading } from "typed-redux-saga";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import {
  ContractDeactivateBankConnectionCommandResult,
  ContractDeactivateBankConnectionRequest,
  getUserContact,
  postContractContractIDBankConnectionsDeactivate,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";

export enum ContractDeactivateBankConnectionStep {
  Information,
  Signature,
}

export type ContractDeactivateBankConnectionState = {
  isLoading: boolean;
  contractID?: number;
  bankAccountID?: number;
  actualStep: ContractDeactivateBankConnectionStep;
  phone?: string;
  requestHash?: string;
  signatureHash?: string;
  signatureType: "SMS" | "BIOMETRICS";
  error?: Error;
};

const initialState: ContractDeactivateBankConnectionState = {
  isLoading: false,
  contractID: undefined,
  bankAccountID: undefined,
  actualStep: ContractDeactivateBankConnectionStep.Information,
  phone: undefined,
  requestHash: undefined,
  signatureHash: undefined,
  signatureType: "SMS",
  error: undefined,
};

export const setContractDeactivateBankConnectionContractID = createAction(
  "@contract/SET_CONTRACT_DEACTIVATE_BANK_CONNECTION_CONTRACT_ID",
)<number>();

export const setContractDeactivateBankConnectionBankAccountID = createAction(
  "@contract/SET_CONTRACT_DEACTIVATE_BANK_CONNECTION_BANK_ACCOUNT_ID",
)<number>();

export const resetContractDeactivateBankConnectionState = createAction(
  "@contract/RESET_CONTRACT_DEACTIVATE_BANK_CONNECTION_STATE",
)<void>();

export type ContractDeactivateBankAccountStateAction =
  | ActionType<typeof setContractDeactivateBankConnectionContractID>
  | ActionType<typeof setContractDeactivateBankConnectionBankAccountID>
  | ActionType<typeof resetContractDeactivateBankConnectionState>
  | ActionType<typeof contractDeactivateBankConnectionAsync>;

export const contractDeactivateBankConnectionAsync = createAsyncAction(
  "@contract/POST_CONTRACT_DEACTIVATE_BANK_CONNECTION_REQUEST",
  "@contract/POST_CONTRACT_DEACTIVATE_BANK_CONNECTION_SUCCESS",
  "@contract/POST_CONTRACT_DEACTIVATE_BANK_CONNECTION_FAILURE",
)<
  ContractDeactivateBankConnectionRequest & { contractID: number },
  ContractDeactivateBankConnectionCommandResult & {
    phone?: string | null;
    signatureType: "SMS" | "BIOMETRICS";
  },
  Error
>();

function* contractDeactivateBankConnection(
  action: ReturnType<typeof contractDeactivateBankConnectionAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postContractContractIDBankConnectionsDeactivate,
      action.payload,
      action.payload.contractID,
    );

    if (!!error) {
      yield put(contractDeactivateBankConnectionAsync.failure(error));
      return;
    }

    if (!response.signatureHash) {
      yield put(
        contractDeactivateBankConnectionAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );
      return;
    }

    if (action.payload.isBiometry) {
      yield put(
        setBiometricsSignatureType(
          BiometricsSignatureType.DeactivateBankConnection,
        ),
      );

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: response.signatureHash,
        }),
      );

      yield put(
        contractDeactivateBankConnectionAsync.success({
          ...response,
          signatureType: "BIOMETRICS",
        }),
      );

      return;
    }

    const { response: contactResponse, error: contactError } =
      yield* safeApiCall(getUserContact);

    if (!!contactError) {
      yield put(contractDeactivateBankConnectionAsync.failure(contactError));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash: response.signatureHash,
      }),
    );

    yield put(
      contractDeactivateBankConnectionAsync.success({
        ...response,
        phone:
          contactResponse.contactInfo?.client?.phone ||
          contactResponse.contactInfo?.user.phone,
        signatureType: "SMS",
      }),
    );
  } catch (err) {
    yield put(contractDeactivateBankConnectionAsync.failure(err as Error));
  }
}

export function* contractDeactivateBankConnectionSaga() {
  yield takeLeading(
    getType(contractDeactivateBankConnectionAsync.request),
    contractDeactivateBankConnection,
  );
}

export const contractDeactivateBankConnectionReducer = createReducer<
  ContractDeactivateBankConnectionState,
  ContractDeactivateBankAccountStateAction
>(initialState)
  .handleAction(
    setContractDeactivateBankConnectionContractID,
    (state, action) => {
      return produce(state, draft => {
        draft.contractID = action.payload;
        return draft;
      });
    },
  )
  .handleAction(
    setContractDeactivateBankConnectionBankAccountID,
    (state, action) => {
      return produce(state, draft => {
        draft.bankAccountID = action.payload;
        return draft;
      });
    },
  )
  .handleAction(resetContractDeactivateBankConnectionState, (state, _) => {
    return produce(state, _ => {
      return initialState;
    });
  })
  .handleAction(
    contractDeactivateBankConnectionAsync.request,
    (state, action) => {
      return produce(state, draft => {
        draft.isLoading = true;
        draft.contractID = action.payload.contractID;
        draft.bankAccountID = action.payload.bankAccountID;
        draft.actualStep = ContractDeactivateBankConnectionStep.Signature;

        return draft;
      });
    },
  )
  .handleAction(
    contractDeactivateBankConnectionAsync.success,
    (state, action) => {
      return produce(state, draft => {
        const { phone, signatureType, signatureHash, requestHash } =
          action.payload;

        draft.isLoading = false;
        draft.phone = phone ?? undefined;
        draft.signatureHash = signatureHash ?? undefined;
        draft.requestHash = requestHash ?? undefined;
        draft.signatureType = signatureType;

        return draft;
      });
    },
  )
  .handleAction(
    contractDeactivateBankConnectionAsync.failure,
    (state, action) => {
      return produce(state, draft => {
        draft.isLoading = false;
        draft.error = action.payload;
        return draft;
      });
    },
  );
