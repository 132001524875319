import {
  ContractTransferDipCommandResultStatus,
  ContractTransferDipDto,
  postContractTransferDip,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";

export const transferDipAsync = createAsyncAction(
  "@contract/POST_TRANSFER_DIP_REQUEST",
  "@contract/POST_TRANSFER_DIP_SUCCESS",
  "@contract/POST_TRANSFER_DIP_FAILURE",
)<
  ContractTransferDipDto,
  {
    signatureHash: string | null;
    requestHash: string | null;
  },
  Error
>();

function* transferDip(
  action: ReturnType<typeof transferDipAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postContractTransferDip,
      action.payload,
    );
    if (error) {
      yield put(transferDipAsync.failure(error));
      return;
    }

    if (response.status !== ContractTransferDipCommandResultStatus.Success) {
      yield put(
        transferDipAsync.failure(
          new ApplicationError(
            `Contract transfer DIP failed, ${response.status}`,
          ),
        ),
      );
      return;
    }

    if (!response.requestHash) {
      yield put(
        transferDipAsync.failure(
          new ApplicationError("Request hash is required."),
        ),
      );

      return;
    }

    if (!response.signatureHash) {
      yield put(
        transferDipAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );

      return;
    }

    yield put(
      transferDipAsync.success({
        signatureHash: response.signatureHash,
        requestHash: response.requestHash,
      }),
    );
  } catch (err) {
    yield put(transferDipAsync.failure(err as Error));
  }
}

export function* transferDipSaga() {
  yield takeLatest(getType(transferDipAsync.request), transferDip);
}
