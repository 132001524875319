import { useMemo } from "react";
import { Resources, useResource } from "Translations/Resources";

export enum PersonalDataTabs {
  PersonalData = "personal-data",
  AML = "aml",
  Documents = "documents",
}

type MenuItem = {
  value: PersonalDataTabs;
  label: string;
};

const TabsResource = Resources.More.PersonalData.Tabs;

export const useMenuTabs = () => {
  const { t } = useResource();

  return useMemo<MenuItem[]>(
    () => [
      {
        value: PersonalDataTabs.PersonalData,
        label: t(TabsResource.PersonalData.Tab),
      },
      { value: PersonalDataTabs.AML, label: t(TabsResource.AML.Tab) },
      {
        value: PersonalDataTabs.Documents,
        label: t(TabsResource.Documents.Tab),
      },
    ],
    [t],
  );
};
