import { ContractOverviewDto } from "Api/Api";
import { useSettings } from "Hooks/App/Settings/useSettings";
import { useIsSelfNegotiatedContract } from "Hooks/Contract/Detail/useIsSelfNegotiatedContract";
import { useProductAllowedTransfers } from "Hooks/Products/useProductAllowedTransfers";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { ProductsIsin } from "Models/Products";
import { useMemo } from "react";
import { useEffectOnce } from "react-use";
import { clearAppSettings } from "State/App/Settings/GetSettingsState";

type Props = {
  isin: string | ProductsIsin | null | undefined;
  disabledFetch?: boolean;
} & Partial<Pick<ContractOverviewDto, "type" | "isDip" | "portfolioItems">>;

export const useExchangeAssets = ({
  isin,
  type: contractTypeCode,
  isDip,
  portfolioItems,
  disabledFetch,
}: Props) => {
  const dispatch = useAppDispatch();

  const { isLoading: isLoadingSettings, settings } = useSettings();
  const {
    isLoading: isLoadingAllowedProductTransfers,
    data: productAllowedTransfers,
  } = useProductAllowedTransfers({
    isin,
    type: contractTypeCode,
    isDip,
    disabledFetch,
  });

  const isSelfNegotiatedContract =
    useIsSelfNegotiatedContract(contractTypeCode);

  const portfolioItem = useMemo(
    () => portfolioItems?.find(x => x.isin === isin) ?? null,
    [isin, portfolioItems],
  );

  useEffectOnce(() => {
    dispatch(clearAppSettings());
  });

  return {
    isLoading: isLoadingAllowedProductTransfers || isLoadingSettings,
    portfolioItem,
    productAllowedTransfers,
    canBeUsed:
      !!isSelfNegotiatedContract &&
      !!portfolioItem &&
      !!productAllowedTransfers.length &&
      (!isDip || (isDip && settings?.isTransferInOneDipContractEnabled)),
  };
};
