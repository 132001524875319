import { ContactInformation } from "Components/More/Contacts/shared/ContactInformation";
import { useDealer } from "Hooks/Contract/useDealer";
import { TabProps } from "Pages/Contracts/DetailPage";
import { FunctionComponent } from "react";

type Props = TabProps;

export const Dealer: FunctionComponent<Props> = ({ contractID }) => {
  const { isLoading: loading, data } = useDealer(contractID);
  const isLoading = loading && !data;

  return (
    <ContactInformation
      title={`${data?.firstName ?? ""} ${data?.lastName ?? ""}`}
      subtitle={data?.distribution}
      email={data?.email}
      phone={data?.phone}
      isLoading={isLoading}
    />
  );
};
