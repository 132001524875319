import {
  ClientVerificationTypeQueryResult,
  getClientVerificationType,
} from "Api/Api";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type ClientVerificationTypeState = {
  isLoading: boolean;
  isBankIDVerified: boolean;
  error?: Error | null;
};

export type GetClientVerificationTypeStateType =
  FetchStateType<ClientVerificationTypeQueryResult>;

export const initialClientVerificationTypeState =
  (): GetClientVerificationTypeStateType => getFetchStateDefaultValue();

export type ClientVerificationTypeActionType = ActionType<
  typeof clientVerificationTypeAsync
>;

export const clientVerificationTypeAsync = createAsyncAction(
  "@client/GET_VERIFICATION_TYPE_REQUEST",
  "@client/GET_VERIFICATION_TYPE_SUCCESS",
  "@client/GET_VERIFICATION_TYPE_FAILURE",
)<void, ClientVerificationTypeQueryResult, Error>();

function* clientVerificationType(
  action: ActionType<typeof clientVerificationTypeAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(getClientVerificationType);

    if (error) {
      yield put(clientVerificationTypeAsync.failure(error));
      return;
    }

    yield put(clientVerificationTypeAsync.success(response));
  } catch (err) {
    yield put(clientVerificationTypeAsync.failure(err as Error));
  }
}

export function* watchClientVerificationTypeSaga() {
  yield takeLeading(
    getType(clientVerificationTypeAsync.request),
    clientVerificationType,
  );
}

export const clientVerificationTypeReducer = createReducer<
  GetClientVerificationTypeStateType,
  ClientVerificationTypeActionType
>(initialClientVerificationTypeState())
  .handleAction(clientVerificationTypeAsync.request, handleActionRequest)
  .handleAction(clientVerificationTypeAsync.failure, handleActionFailure)
  .handleAction(clientVerificationTypeAsync.success, handleActionSuccess);
