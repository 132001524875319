import { Stack, Typography } from "@mui/material";
import { StyledParagraph } from "Components/Shared/StyledComponents";
import { FunctionComponent } from "react";

export const DipDescriptionDialogCzech: FunctionComponent = () => {
  return (
    <>
      <Stack paddingY={2} marginRight={1} spacing={7}>
        <Typography>
          Od 2. 1. 2024 je možné investovat do našich fondů také v rámci
          dlouhodobého investičního produktu. Tento nový daňově zvýhodněný
          nástroj spoření na stáří je určený fyzickým osobám, které odvádějí daň
          z příjmů v České republice. Pravidelným odkládáním i relativně malé
          částky do výnosnějších produktů si můžete vybudovat majetek, který vám
          po odchodu do důchodu zajistí finanční nezávislost a udržení životní
          úrovně. To vše s daňovou úsporou až 7 200 Kč ročně.
        </Typography>

        <Stack spacing={3}>
          <StyledParagraph>
            <Typography marginBottom={4} fontWeight={700}>
              Co je DIP?
            </Typography>
            <Typography>
              DIP umožňuje spořit si na stáří s daňovou podporou státu nově i
              mimo penzijní fondy, a to ve veřejně obchodovaných investičních a
              bankovních produktech jako investiční fondy, akcie či nástroje
              peněžního trhu. Podmínkou daňového zvýhodnění je držet investice v
              rámci DIP alespoň po dobu 10 let a současně je vybrat nejdříve v
              60 letech věku.
            </Typography>
          </StyledParagraph>
          <StyledParagraph>
            <Typography marginBottom={4} fontWeight={700}>
              Jakou výhodu DIP přináší?
            </Typography>
            <Typography>
              O jakoukoli částku do 48 tisíc korun, kterou si v rámci DIP během
              roku odložíte, si snížíte základ daně z příjmů. Díky tomu
              zaplatíte na dani až o 7 200 korun za rok méně. Zaměstnavatel vám
              může do DIP přispět až 50 tisíci korun ročně. DIP otvírá cestu k
              výnosnějším produktům než penzijní připojištěním doplňkové
              penzijní spoření a investiční životní pojištění, které byly do
              roku 2024 jedinou možností, jak spořit na stáří s podporou státu.
            </Typography>
          </StyledParagraph>
          <StyledParagraph>
            <Typography marginBottom={4} fontWeight={700}>
              Kdo jej může poskytovat?
            </Typography>
            <Typography>
              Investování v rámci DIP může nabízet pouze poskytovatel, který je
              dohlížený Českou národní bankou a je uvedený na jejím seznamu
              poskytovatelů DIP.
            </Typography>
          </StyledParagraph>
          <StyledParagraph>
            <Typography marginBottom={4} fontWeight={700}>
              Do jakých fondů z rodiny INVESTIKA můžu spořit v rámci DIP?
            </Typography>
            <Typography>
              Vybrat si můžete kterýkoli z našich veřejně nabízených
              investičních fondů nebo jich zkombinovat více. Převést do DIP
              můžete i své stávající investice do investičních fondů rodiny
              INVESTIKA.
            </Typography>
          </StyledParagraph>
          <StyledParagraph>
            <Typography marginBottom={4} fontWeight={700}>
              Jak spořit v rámci DIP?
            </Typography>
            <Typography>
              Spořit můžete pravidelně či jednorázově. Odkládanou částku můžete
              libovolně upravovat či dokonce vynechávat podle své aktuální
              životní situace. Výběr je možný jak formou pravidelné renty,
              kterou si můžete přilepšit ke státnímu důchodu, tak jednorázovou
              výplatou.
            </Typography>
          </StyledParagraph>
        </Stack>
      </Stack>
    </>
  );
};
