import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

export const useBankIDCallbackParams = () => {
  const [searchParams] = useSearchParams();

  const error = searchParams.get("error");

  const location = useLocation();
  const queryString = location.hash.replace("#", "");
  const params = new URLSearchParams(queryString);

  const accessToken = params.get("access_token");
  const state = params.get("state");

  return {
    state,
    accessToken,
    error,
  };
};
