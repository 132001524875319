import {
  GetDownloadStateType,
  getDownloadReducer,
  getDownloadSaga,
  getDownloadState,
} from "State/Documents/Download/GetDownloadState";
import {
  GetListStateType,
  getListReducer,
  getListSaga,
  getListState,
} from "State/Documents/List/GetListState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type DocumentsState = {
  list: GetListStateType;
  download: GetDownloadStateType;
};

export const getInitialState = (): DocumentsState => ({
  list: getListState(),
  download: getDownloadState(),
});

export function* watchDocumentsSaga() {
  yield all([getListSaga(), getDownloadSaga()]);
}

export const documentsReducer = combineReducers<DocumentsState>({
  list: getListReducer,
  download: getDownloadReducer,
});
