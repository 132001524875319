import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getProductTilesAsync } from "State/Products/ProductTiles/GetProductTilesState";
import { useEffect, useMemo } from "react";
import { PathString } from "react-hook-form";

export type ProductListItemDto = {
  code: string;
  name: string;
  color: string | null | undefined;
  minPerformance: number;
  maxPerformance: number;
  productIsins: string[];
  isDipAllowed: boolean;
  productID: number | null | undefined;
};

export const useProductList = (mustAllowDip: boolean = false) => {
  const dispatch = useAppDispatch();
  const { data, isLoading: loading } = useAppSelector(
    e => e.products.productTiles,
  );
  const isLoading = loading && !data;

  useEffect(() => {
    if (data?.productTiles) {
      return;
    }

    dispatch(getProductTilesAsync.request());
  }, [dispatch, data?.productTiles]);

  const productList = useMemo<ProductListItemDto[]>(() => {
    if (!data?.productTiles) {
      return [];
    }

    return data.productTiles
      .filter(
        ({ items }) =>
          items?.length &&
          items.some(item => !mustAllowDip || item.isDipAllowed),
      )
      .map<ProductListItemDto>(item => ({
        code: item.code ?? "",
        name: item.name ?? "",
        color: item.items[0].color,
        minPerformance: Math.min(
          ...item.items
            .filter(
              i => i.minPerformance !== null && i.minPerformance !== undefined,
            )
            .map(({ minPerformance }) => minPerformance as number),
        ),
        maxPerformance: Math.max(
          ...item.items
            .filter(
              i => i.maxPerformance !== null && i.maxPerformance !== undefined,
            )
            .map(({ maxPerformance }) => maxPerformance as number),
        ),
        productIsins: item.items
          .filter(i => i.isin !== null && i.isin !== undefined)
          .map(({ isin }) => isin as PathString),
        isDipAllowed: item.isDipAllowed ?? false,
        productID: item.productID,
      }));
  }, [data, mustAllowDip]);

  return {
    productList,
    isLoading,
  };
};
