import { EditPersonalDataVerificationType } from "Api/Api";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import {
  PermanentAddressFormModel,
  PathSelectionFormModel,
} from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources =
  Resources.More.PersonalData.EditPersonalData.Modelling.PermanentAddress;

export const AddressesForm: FunctionComponent<PathSelectionFormModel> = ({
  verificationType,
}) => {
  const { t } = useResource();

  const countries = useCountriesList();

  const {
    control,
    formState: { errors },
  } = useFormContext<PermanentAddressFormModel>();

  return (
    <InputHeaderWrapper header={t(ComponentResources.Header)}>
      <BlInputFormField
        control={control}
        name="permanentAddress.streetName"
        errors={errors}
        label={t(ComponentResources.StreetName)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
      />

      <StyledFlex $gap={2}>
        <BlInputFormField
          control={control}
          name="permanentAddress.streetConscriptionNumber"
          errors={errors}
          label={t(ComponentResources.StreetConscriptionNumber)}
          disabled={
            verificationType === EditPersonalDataVerificationType.BankID
          }
        />

        <BlInputFormField
          control={control}
          name="permanentAddress.streetNumber"
          errors={errors}
          label={t(ComponentResources.StreetNumber)}
          disabled={
            verificationType === EditPersonalDataVerificationType.BankID
          }
        />
      </StyledFlex>

      <BlInputFormField
        control={control}
        name="permanentAddress.city"
        errors={errors}
        label={t(ComponentResources.City)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
      />

      <BlInputFormField
        control={control}
        name="permanentAddress.postalCode"
        isMaskLazy={false}
        errors={errors}
        label={t(ComponentResources.PostalCode)}
        inputMode="numeric"
        mask={true ? "000 00" : new RegExp("^[0-9]*$")}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
      />

      <DropdownInput
        options={countries}
        control={control}
        name="permanentAddress.country"
        errors={errors}
        label={t(ComponentResources.Country)}
        disabled={verificationType === EditPersonalDataVerificationType.BankID}
      />
    </InputHeaderWrapper>
  );
};
