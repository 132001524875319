export type FetchStateType<T, TError extends Error = Error> = {
  isLoading: boolean;
  data?: T | null;
  error?: TError | null;
};

export function getFetchStateDefaultValue<T>(
  isLoading = false,
): FetchStateType<T> {
  return {
    isLoading,
    data: null,
    error: null,
  };
}
