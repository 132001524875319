import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContent, Typography } from "@mui/material";
import { HiddenSubmitButton } from "Components/Shared/Buttons/HiddenSubmitButton";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledDialog } from "Components/Shared/StyledComponents";
import { PASSWORD_PLACEHOLDER } from "Constants/Inputs";
import { useChangePasswordSchema } from "Hooks/Profile/useChangePasswordSchema";
import useHandleServerError from "Hooks/useHandleServerError";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useForm } from "react-hook-form";

const PageResources = Resources.Profile.Settings;

type Props = {
  onSubmit: (data: FormModel) => void;
  serverError: Error | null;
  isLoading: boolean;
  isModalVisible: boolean;
  onModalClose: () => void;
};

export type FormModel = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const ProfileChangePasswordForm: FunctionComponent<Props> = ({
  serverError,
  isLoading,
  onSubmit,
  isModalVisible,
  onModalClose,
}) => {
  const { t } = useResource();
  const schema = useChangePasswordSchema();

  const form = useForm<FormModel>({
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  useHandleServerError({
    form,
    serverError,
    resource: PageResources.Error,
    key: "oldPassword",
    keyMap: {
      oldPassword: "currentPassword",
      newPassword: "password",
    },
  });

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageTitle showOnMobile={false} marginBottom={6}>
            {t(PageResources.ChangePassword)}
          </PageTitle>
          <BlInputFormField
            control={control}
            errors={errors}
            name="oldPassword"
            type="password"
            placeholder={PASSWORD_PLACEHOLDER}
            label={t(PageResources.OldPassword)}
            autoComplete="current-password"
          />
          <BlInputFormField
            control={control}
            errors={errors}
            name="newPassword"
            type="password"
            placeholder={PASSWORD_PLACEHOLDER}
            label={t(PageResources.NewPassword)}
            autoComplete="new-password"
          />
          <BlInputFormField
            control={control}
            errors={errors}
            name="confirmPassword"
            type="password"
            placeholder={PASSWORD_PLACEHOLDER}
            label={t(PageResources.ConfirmPassword)}
            autoComplete="new-password"
          />
          <HiddenSubmitButton />
        </form>
        <StyledDialog open={isModalVisible} maxWidth="md">
          <DialogContent>
            <Box marginBottom={5}>
              <Typography marginBottom={2} color="secondary" variant="h4">
                {t(PageResources.ChangePassword)}
              </Typography>
              <Typography>{t(PageResources.PasswordChanged)}</Typography>
            </Box>
            <PrimaryButton
              small
              color="primary"
              fullWidth
              onClick={onModalClose}
            >
              {t(PageResources.PasswordChangedButton)}
            </PrimaryButton>
          </DialogContent>
        </StyledDialog>
      </div>
      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        {t(PageResources.ChangePasswordButton)}
      </PrimaryButton>
    </>
  );
};
