import { ContractPreviewDto, postContractPreview } from "Api/Api";
import { FormModelType as ContractFormModelType } from "State/Contracts/Create/Models";
import { FormModelType as ModelingFormModelType } from "State/Contracts/Modeling/Models";
import { mapFormDataToApi } from "State/Contracts/Utils";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import { safeApiCall } from "State/Utils";

import { call, put, takeLatest } from "typed-redux-saga";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";

export type PostPreviewStateType = FetchStateType<ContractPreviewDto>;

export const postPreviewState = (): PostPreviewStateType =>
  getFetchStateDefaultValue();

export type PostPreviewActionType = ActionType<typeof postPreviewAsync>;

export const postPreviewAsync = createAsyncAction(
  "@contract/POST_PREVIEW_REQUEST",
  "@contract/POST_PREVIEW_SUCCESS",
  "@contract/POST_PREVIEW_FAILURE",
)<
  {
    modelingData: ModelingFormModelType;
    contractData: ContractFormModelType;
    isBankIDVerified: boolean;
    isDip: boolean;
    canUseBiometrics: boolean;
  },
  ContractPreviewDto,
  Error
>();

function* postPreview(
  action: ActionType<typeof postPreviewAsync.request>,
): Generator {
  try {
    const {
      modelingData,
      contractData,
      isBankIDVerified,
      isDip,
      canUseBiometrics,
    } = action.payload;

    const data = yield* call(
      mapFormDataToApi,
      modelingData,
      contractData,
      isBankIDVerified,
      isDip,
      canUseBiometrics,
    );
    const { response, error } = yield* safeApiCall(postContractPreview, data);

    if (error) {
      yield put(postPreviewAsync.failure(error));
      return;
    }

    yield put(postPreviewAsync.success(response.contractPreview));
  } catch (err) {
    console.log(err);
    yield put(postPreviewAsync.failure(err as Error));
  }
}

export function* postPreviewSaga() {
  yield takeLatest(getType(postPreviewAsync.request), postPreview);
}
