import { Typography, type TypographyProps, Box } from "@mui/material";
import { CopyIcon } from "Components/Shared/Icons";
import { type FunctionComponent, useRef } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { useToast } from "Hooks/useToast";

type Props = {
  text: string;
  copyText?: string;
} & TypographyProps;

const StyledCopyIcon = styled(CopyIcon)`
  height: 15px;
  width: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-left: ${({ theme }) => theme.spacing(1.5)};
  margin-top: ${({ theme }) => theme.spacing(0.3)};
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const CopyableText: FunctionComponent<Props> = ({
  text,
  copyText,
  ...props
}: Props) => {
  const { t } = useResource();
  const { showToast } = useToast();
  const spanRef = useRef<HTMLSpanElement | null>(null);

  const onCopy = () => {
    if (spanRef.current?.innerText) {
      navigator.clipboard.writeText(copyText || spanRef.current.innerText);
      showToast({
        content: t(Resources.Common.TextCopied),
        autoClose: 1000,
        variant: "info",
      });
    }
  };

  return (
    <Box display="flex">
      <Typography {...props}>
        <span ref={spanRef}>{text}</span>
      </Typography>

      <StyledCopyIcon onClick={onCopy} />
    </Box>
  );
};
