import { ProductTileListQueryResult, getProductTilesList } from "Api/Api";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { takeLeading } from "typed-redux-saga";

export type GetProductTilesStateType =
  FetchStateType<ProductTileListQueryResult>;

export const getProductTilesState = (): GetProductTilesStateType =>
  getFetchStateDefaultValue();

export type GetProductTilesActionType = ActionType<typeof getProductTilesAsync>;

export const getProductTilesAsync = createAsyncAction(
  "@products/GET_PRODUCT_TILES_REQUEST",
  "@products/GET_PRODUCT_TILES_SUCCESS",
  "@products/GET_PRODUCT_TILES_FAILURE",
)<void, ProductTileListQueryResult, Error>();

export const clearProductTiles = createAction(
  "@products/CLEAR_PRODUCT_TILES",
)();

function* getProductTiles(): Generator {
  try {
    const { response, error } = yield* safeApiCall(getProductTilesList);

    if (error) {
      yield put(getProductTilesAsync.failure(error));
      return;
    }

    yield put(getProductTilesAsync.success(response));
  } catch (err) {
    yield put(getProductTilesAsync.failure(err as Error));
  }
}

export function* getProductTilesSaga() {
  yield takeLeading(getType(getProductTilesAsync.request), getProductTiles);
}

export const getProductTilesReducer = createReducer<
  GetProductTilesStateType,
  GetProductTilesActionType
>(getProductTilesState())
  .handleAction(getProductTilesAsync.request, handleActionRequest)
  .handleAction(getProductTilesAsync.failure, handleActionFailure)
  .handleAction(getProductTilesAsync.success, handleActionSuccess);
