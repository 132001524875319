import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, InputAdornment, Typography } from "@mui/material";
import {
  BankAccountItemDto,
  ContractOverviewPortfolioItemDto,
  RedemptionMethod,
  RedemptionType,
} from "Api/Api";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { PageTitle } from "Components/Shared/PageTitle";
import { useProductColor } from "Hooks/useProductColor";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency, getLocalizedCurrencyCode } from "Utils/CurrencyUtils";
import { convertNanToNull, formatNumber } from "Utils/NumberUtils";
import { nameof } from "Utils/ObjectUtils";
import { type FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { ObjectSchema, boolean, mixed, number, object } from "yup";

type Props = {
  portfolioItem: ContractOverviewPortfolioItemDto;
  bankAccounts: BankAccountItemDto[];
  isLoading: boolean;
  isDip: boolean;
  onSubmit: (data: FormModel) => void;
};

export type FormModel = {
  type: RedemptionType;
  method: RedemptionMethod;
  amount: number;
  isRedeemAll: boolean;
  dipCancelAgree: boolean;
  bankAccountID: number;
};

const PortfolioInfo = styled(Typography)<{ $textAlign?: string }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: ${props => props.$textAlign ?? "left"};
`;

const PageResources = Resources.Contract.Detail.Redemption.Configuration;

export const RedemptionConfigurationStepForm: FunctionComponent<
  Props
> = props => {
  const { portfolioItem, bankAccounts, isLoading, onSubmit, isDip } = props;
  const { t } = useResource();
  const { color, contrastColor } = useProductColor(portfolioItem?.isin);

  const schema: ObjectSchema<FormModel> = object().shape({
    type: mixed<RedemptionType>()
      .oneOf(Object.values(RedemptionType))
      .defined(),
    method: mixed<RedemptionMethod>()
      .oneOf(Object.values(RedemptionMethod))
      .defined(),
    amount: number()
      .required()
      .when(nameof<FormModel>("method"), ([method], schema) =>
        method === RedemptionMethod.Amount
          ? schema
              .transform(v => convertNanToNull(Number.parseInt(v)))
              .min(100, t(PageResources.Amount.Validation.Min))
              .max(
                portfolioItem.amount ?? Number.MAX_SAFE_INTEGER,
                t(PageResources.Amount.Validation.Max, {
                  max: portfolioItem.amount ?? Number.MAX_SAFE_INTEGER,
                }),
              )
              .required()
          : schema
              .transform(v => convertNanToNull(Number.parseInt(v)))
              .min(1, t(PageResources.Pieces.Validation.Min))
              .max(
                portfolioItem.quantity ?? Number.MAX_SAFE_INTEGER,
                t(PageResources.Pieces.Validation.Max, {
                  max: portfolioItem.quantity ?? Number.MAX_SAFE_INTEGER,
                }),
              )
              .required(),
      ),
    isRedeemAll: boolean().required(),
    dipCancelAgree: isDip
      ? boolean()
          .required()
          .isTrue(t(PageResources.DipCancelAgree.DecisionRequired))
      : boolean().required(),
    bankAccountID: number().required(),
  });

  const bankAccountOptions = bankAccounts
    .filter(x => !!x.bankAccountID)
    .map(x => ({ Name: x.accountNumber ?? x.iban, Value: x.bankAccountID! }));

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormModel>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      amount: 1,
      type: RedemptionType.Single,
      method: RedemptionMethod.Pieces,
      isRedeemAll: false,
      dipCancelAgree: false,
      bankAccountID: bankAccountOptions[0]?.Value,
    },
  });

  const isRedeemAll = watch("isRedeemAll");

  const handleMethodChange = (value: RedemptionMethod) => {
    if (value === RedemptionMethod.Pieces) {
      setValue("amount", 1);
    }
    if (value === RedemptionMethod.Amount) {
      setValue("amount", 100);
    }

    handleRedeemAllChange(isRedeemAll, value);
  };

  const handleRedeemAllChange = (
    isRedeemAll: boolean,
    method: RedemptionMethod,
  ) => {
    if (isRedeemAll) {
      if (method === RedemptionMethod.Pieces && portfolioItem.quantity) {
        setValue("amount", Math.floor(portfolioItem.quantity || 0));
      }

      if (method === RedemptionMethod.Amount && portfolioItem.amount) {
        setValue("amount", Math.floor(portfolioItem.amount || 0));
      }
    }
  };

  const method = watch("method");

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{t(PageResources.Title)}</PageTitle>

        <Typography
          variant="subtitle1"
          marginBottom={1}
          textTransform="uppercase"
        >
          {t(PageResources.CPCount)}
        </Typography>

        <Grid container marginBottom={6} justifyContent="space-between" gap={3}>
          <Grid item xs={6} lg={2}>
            <PortfolioInfo>{portfolioItem.name}</PortfolioInfo>
          </Grid>
          <Grid item lg={9}>
            <PortfolioInfo $textAlign="right">
              {formatNumber(portfolioItem.quantity)}{" "}
              {t(Resources.Common.QuantityUnit)}
            </PortfolioInfo>
            <PortfolioInfo $textAlign="right">
              {formatCurrency(portfolioItem.amount, portfolioItem.currency)}
            </PortfolioInfo>
          </Grid>
        </Grid>

        <Typography
          variant="subtitle1"
          marginBottom={1}
          textTransform="uppercase"
        >
          {t(PageResources.Parameters)}
        </Typography>

        <RadioButtons
          label={t(PageResources.Type.Title)}
          control={control}
          errors={errors}
          name="type"
          codeList={[
            {
              code: RedemptionType.Single,
              name: t(PageResources.Type.Single),
            },
          ]}
        />

        <RadioButtons
          label={t(PageResources.Method.Title)}
          control={control}
          errors={errors}
          name="method"
          codeList={[
            {
              code: RedemptionMethod.Pieces,
              name: t(PageResources.Method.Pieces),
            },
            {
              code: RedemptionMethod.Amount,
              name: t(PageResources.Method.Amount),
            },
          ]}
          onChange={handleMethodChange}
        />

        <BlInputFormField
          label={
            method === RedemptionMethod.Amount
              ? t(PageResources.Amount.Title)
              : t(PageResources.Pieces.Title)
          }
          control={control}
          name="amount"
          errors={errors}
          mask={Number}
          isMaskLazy={false}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          maskPlaceholder=" "
          max={Number.MAX_SAFE_INTEGER}
          min={0}
          inputEndAdornment={
            <InputAdornment position="end">
              {method === RedemptionMethod.Amount
                ? getLocalizedCurrencyCode(portfolioItem.currency)
                : t(Resources.Common.QuantityUnit)}
            </InputAdornment>
          }
          disabled={isRedeemAll}
          hasTracking
        />

        <BlCheckboxFormField
          control={control}
          name="isRedeemAll"
          errors={errors}
          label={t(PageResources.RedeemAll)}
          onChange={v => handleRedeemAllChange(v, method)}
        />

        <br />

        <DropdownInput
          label={t(PageResources.BankAccount.Title)}
          control={control}
          errors={errors}
          name="bankAccountID"
          options={bankAccountOptions}
        />

        {isDip && (
          <>
            <Typography marginY={3}>
              {t(PageResources.DipCancelAgree.Title)}
            </Typography>

            <BlCheckboxFormField
              control={control}
              name="dipCancelAgree"
              label={t(PageResources.DipCancelAgree.Label)}
              errors={errors}
            />
          </>
        )}
      </div>
      <BlSlideButton
        onSlideDone={handleSubmit(onSubmit)}
        reset={!isValid}
        disabled={!isValid}
        isLoading={isLoading}
        trackingTag={t(PageResources.ConfirmationButton)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(PageResources.ConfirmationButton)}
      </BlSlideButton>
    </>
  );
};
