import { Button, ButtonProps } from "@mui/material";
import { track } from "Utils/TrackingUtils";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = ButtonProps & {
  trackingTag?: string | null | undefined;
  trackingValue?: string | null | undefined;
};

export const TrackedButton: FunctionComponent<
  PropsWithChildren<Props>
> = props => {
  const { children, onClick, trackingTag, trackingValue, ...rest } = props;

  return (
    <Button
      onClick={e => {
        const tag =
          trackingTag ??
          (typeof children === "string" ? `${children}` : undefined);
        track({
          category: "Button",
          event: "Click",
          tag,
          value: trackingValue,
        });
        onClick?.(e);
      }}
      {...rest}
    >
      <>{props.children}</>
    </Button>
  );
};
