import { CZECHIA_PHONE_PREFIX, SLOVAKIA_PHONE_PREFIX } from "Constants/Phones";
import { TwoFactorVerificationFormModel } from "State/Profile/TwoFactorVerification/TwoFactorVerificationReducer";
import { Resources, useResource } from "Translations/Resources";
import { object, ObjectSchema, string } from "yup";

const CommonPhonePrefix = "+";

export const useYupFormSchema =
  (): ObjectSchema<TwoFactorVerificationFormModel> => {
    const { t } = useResource();

    return object<TwoFactorVerificationFormModel>().shape({
      phone: string()
        .required()
        .test(
          "is-valid-length",
          t(Resources.Validation.InvalidPhone),
          phone => {
            const normalizedPhone = phone.startsWith(CommonPhonePrefix)
              ? phone.substring(CommonPhonePrefix.length)
              : phone;

            if (
              normalizedPhone.startsWith(CZECHIA_PHONE_PREFIX) ||
              normalizedPhone.startsWith(SLOVAKIA_PHONE_PREFIX)
            ) {
              return normalizedPhone.length === 12;
            }

            return true;
          },
        ),
    });
  };
