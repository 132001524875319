import {
  postContractDraftDocument,
  CreateContractDraftDocumentCommandResult,
} from "Api/Api";
import { postDocumentsAsync } from "State/Contracts/Shared/Draft";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { getFileBase64 } from "Utils/FileUtils";
import { produce } from "immer";

import { call, put, takeEvery } from "typed-redux-saga";
import { ActionType, createReducer, getType } from "typesafe-actions";

export type PostDocumentsStateType = {
  [key: string]: FetchStateType<CreateContractDraftDocumentCommandResult>;
};

export const postDocumentsState = (): PostDocumentsStateType => ({});

export type PostDocumentsActionType = ActionType<typeof postDocumentsAsync>;

function* postDocuments(
  action: ActionType<typeof postDocumentsAsync.request>,
): Generator {
  try {
    const fileBase64 = yield* call(getFileBase64, action.payload.file);
    const { response, error } = yield* safeApiCall(postContractDraftDocument, {
      data: fileBase64,
      documentGuid: action.meta.guid,
    });

    if (error) {
      yield put(postDocumentsAsync.failure(error, action.meta));
      return;
    }

    yield put(
      postDocumentsAsync.success(response, {
        ...action.meta,
        ...action.payload,
      }),
    );
  } catch (err) {
    yield put(postDocumentsAsync.failure(err as Error, action.meta));
  }
}

export function* postDocumentsSaga() {
  yield takeEvery(getType(postDocumentsAsync.request), postDocuments);
}

export const postDocumentsReducer = createReducer<
  PostDocumentsStateType,
  PostDocumentsActionType
>(postDocumentsState())
  .handleAction(postDocumentsAsync.request, (state, action) =>
    produce(state, draft => {
      draft[action.meta.guid] = handleActionRequest(
        getFetchStateDefaultValue(),
      );

      return draft;
    }),
  )
  .handleAction(postDocumentsAsync.failure, (state, action) =>
    produce(state, draft => {
      draft[action.meta.guid] = handleActionFailure(
        draft[action.meta.guid],
        action,
      );

      return draft;
    }),
  )
  .handleAction(postDocumentsAsync.success, (state, action) =>
    produce(state, draft => {
      draft[action.meta.guid] = handleActionSuccess(
        draft[action.meta.guid],
        action,
      );

      return draft;
    }),
  );
