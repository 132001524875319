import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CampaignDto } from "Api/Api";
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { CampaignCard } from "Components/Shared/Campaigns/CampaignCard";
import Slider from "react-slick";
import styled from "styled-components";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { hideUserCampaignAsync } from "State/Campaigns/Api/HideUserCampaignState";
import { useNavigate } from "react-router";
import { openExternalLinkTracked } from "Utils/TrackingUtils";
import { isNoU } from "Utils/ObjectUtils";

const sliderSettings = {
  className: "slider-container",
  speed: 1000,
  slidesToShow: 2,
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 8000,
};

export const CarouselItemMaxHeight = 170;

const StyledCampaignCarouselWrapper = styled.div`
  width: 190%;
`;

const StyledCampaignWrapper = styled.div<{
  $disableRightPadding?: boolean;
  $height?: number;
}>`
  padding-right: ${({ $disableRightPadding }) =>
    !!$disableRightPadding ? 0 : 20}px;
  display: flex;
  align-items: stretch;

  .campaign-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${({ $height }) => ($height ? `${$height}px` : "auto")};
  }
`;

type Props = PropsWithChildren<{
  campaigns: CampaignDto[];
}>;

export const CampaignCarousel: FunctionComponent<Props> = ({ campaigns }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const heights = Array.from(
      document.getElementsByClassName("campaign-card"),
    ).map(card => card.getBoundingClientRect().height);
    setHeight(Math.min(CarouselItemMaxHeight, Math.max(...heights)));
  }, [campaigns]);

  const handleClick = (
    channelID: string,
    action: "Open" | "Close",
    deepLink?: string | null | undefined,
  ) => {
    dispatch(hideUserCampaignAsync.request({ channelID, action }));
    if (action === "Close" || isNoU(deepLink)) {
      return;
    }

    if (!deepLink.startsWith("http://") && !deepLink.startsWith("https://")) {
      navigate(deepLink);
    } else if (deepLink.startsWith(window.location.origin)) {
      navigate(deepLink.substring(window.location.origin.length));
    } else {
      openExternalLinkTracked(deepLink);
    }
  };

  if (campaigns.length === 1) {
    return (
      <StyledCampaignWrapper $disableRightPadding $height={height}>
        <CampaignCard
          channelID={campaigns[0].channelID}
          title={campaigns[0].title}
          body={campaigns[0].body}
          deepLink={campaigns[0].deepLink}
          onOpen={(channelID, deepLink) =>
            handleClick(channelID, "Open", deepLink)
          }
          onClose={channelID => handleClick(channelID, "Close")}
        />
      </StyledCampaignWrapper>
    );
  }

  return (
    <StyledCampaignCarouselWrapper className="slider-container">
      <Slider {...sliderSettings}>
        {campaigns.map(x => (
          <StyledCampaignWrapper key={x.channelID} $height={height}>
            <CampaignCard
              channelID={x.channelID}
              title={x.title}
              body={x.body}
              deepLink={x.deepLink}
              onOpen={(channelID, deepLink) =>
                handleClick(channelID, "Open", deepLink)
              }
              onClose={channelID => handleClick(channelID, "Close")}
            />
          </StyledCampaignWrapper>
        ))}
      </Slider>
    </StyledCampaignCarouselWrapper>
  );
};
