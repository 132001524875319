import { SourceOfResourcesType } from "Api/Api";
import { BlCheckboxesFormField } from "Components/Shared/Inputs/Form/BlCheckboxesFormField";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { CodeListItem } from "Models/CodeListItem";
import { useEffect, type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";

export type SourceOfFundsFieldsProps = {
  isFundsSourceReadonly?: boolean;
  isOtherFundsSourceReadonly?: boolean;
  fundsSources: CodeListItem<string | number, string>[];
};

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

type SourceOfFundsProps = {
  onSelect: (shouldDisplaySourceOfIncome: boolean) => void;
} & SourceOfFundsFieldsProps;

export const SourceOfFunds: FunctionComponent<SourceOfFundsProps> = ({
  isFundsSourceReadonly = false,
  isOtherFundsSourceReadonly = false,
  fundsSources,
  onSelect,
}) => {
  const { t } = useResource();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<AMLQuestionsFormModel>();

  const funds = watch("sourceOfFunds");

  useEffect(() => {
    if (funds.some(fund => fund !== SourceOfResourcesType.Savings)) {
      onSelect(true);
      return;
    }

    onSelect(false);
  }, [funds, onSelect]);

  return (
    <div>
      <BlCheckboxesFormField
        control={control}
        name="sourceOfFunds"
        codeList={fundsSources}
        errors={errors}
        label={t(ComponentResources.SourceOfFunds)}
        disabled={isFundsSourceReadonly}
      />
      {funds?.includes(SourceOfResourcesType.Other) && (
        <BlInputFormField
          control={control}
          errors={errors}
          name="sourceOfFundsOther"
          variant="standard"
          placeholder={t(ComponentResources.SourceOfFundsOtherPlaceholder)}
          disabled={isOtherFundsSourceReadonly}
        />
      )}
    </div>
  );
};
