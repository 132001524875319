//import { ProductClassCode } from "Api/Api";

import { CurrencyCode } from "Api/Api";

export type Products =
  | "dynamika"
  | "investika"
  | "monetika"
  | "efektika"
  | "benefika"
  | "euromonetika";

export type ProductsIsin =
  | "CZ0008474830"
  | "CZ0008475902"
  | "CZ0008476314"
  | "CZ0008477379"
  | "CZ0008477361"
  | "CZ0008477650"
  | "CZ0008477676"
  | "CZ0008477668"
  | "CZ0008475670"
  | "CZ0008476272"
  | "CZ0008476801"
  | "CZ0008478005";

export const ProductsIsinValues: ProductsIsin[] = [
  "CZ0008474830",
  "CZ0008475902",
  "CZ0008476314",
  "CZ0008477379",
  "CZ0008477361",
  "CZ0008477650",
  "CZ0008477676",
  "CZ0008477668",
  "CZ0008475670",
  "CZ0008476272",
  "CZ0008476801",
  "CZ0008478005",
];

export enum ProductClassCode {
  CZK = "CZK",
  EUR = "EUR",
  PROFI_CZK = "PROFI_CZK",
  CZK_Hedged = "CZK_Hedged",
  FKI_CZK = "FKI_CZK",
  Benefika = "Benefika",
}

export enum ProductTradingFrequencyCode {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
}

export type ProductIsinDetail = {
  product: Products;
  code: string;
  color: string;
  contrastColor: string;
  class: ProductClassCode;
  classCode: string | null;
  tradingFrequency: ProductTradingFrequencyCode;
  bankId: {
    minSingleInvestmentDeposit: { [key in CurrencyCode]: number };
    minPeriodicalInvestmentDeposit: { [key in CurrencyCode]: number };
  };
  headerImage?: string;
};
