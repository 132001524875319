import * as React from "react";
export const GraduationCapIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 3.5C12.4238 3.5 12.3516 3.51172 12.2812 3.54688L0.28125 9.54688C0.111328 9.63281 0 9.81055 0 10C0 10.1895 0.111328 10.3672 0.28125 10.4531L2 11.3125V18.5938C1.42188 18.8027 1 19.3496 1 20C1 20.6367 1.76562 21.8066 2.09375 22.2812L2.5 22.875L2.90625 22.2812C3.23438 21.8066 4 20.6367 4 20C4 19.3496 3.57812 18.8027 3 18.5938V11.8125L4 12.3125V11C4 9.26562 6.49609 8.91406 7.5625 8.76562C9.16992 8.54297 11.1348 8.5 12.5 8.5C13.8652 8.5 15.8301 8.54297 17.4375 8.76562C18.5039 8.91406 21 9.26562 21 11V12.3125L24.7188 10.4531C24.8887 10.3691 25 10.1895 25 10C25 9.81055 24.8887 9.63281 24.7188 9.54688L12.7188 3.54688C12.6484 3.51172 12.5762 3.5 12.5 3.5ZM12.5 9.5C9.69922 9.5 5 9.69531 5 11V13.3281C6.03906 12.8848 8.16016 12.5 12.5 12.5C16.8398 12.5 18.9609 12.8848 20 13.3281V11C20 9.69531 15.3008 9.5 12.5 9.5ZM12.5 13.5C7.30469 13.5 5.14453 14.1113 5 14.5C5.00781 15.8008 9.70117 16 12.5 16C15.2402 16 19.7871 15.8086 19.9844 14.5781C19.9902 14.5488 20 14.5293 20 14.5C19.8652 14.1172 17.707 13.5 12.5 13.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
