import { Typography } from "@mui/material";
import { BankAccount } from "Components/ContractBankConnections/Shared/BankAccount";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { FunctionComponent, useMemo } from "react";
import { contractDeactivateBankConnectionAsync } from "State/Contracts/Contract/BankConnections/ContractDeactivateBankConnectionState";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  contractID: number;
  bankAccountID: number;
};

const PageResources = Resources.Contract.BankConnections.Deactivate;
const ComponentResources =
  Resources.Contract.BankConnections.Deactivate.Information;

export const BankConnectionInformation: FunctionComponent<Props> = ({
  contractID,
  bankAccountID,
}) => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();

  const dispatch = useAppDispatch();
  const bankAccounts = useAppSelector(
    s => s.investmentsDetail.bankAccounts.data,
  );

  const bankAccount = useMemo(() => {
    return bankAccounts?.filter(x => x.bankAccountID === bankAccountID)?.[0];
  }, [bankAccountID, bankAccounts]);

  const onSubmit = () => {
    dispatch(
      contractDeactivateBankConnectionAsync.request({
        contractID,
        bankAccountID,
        isBiometry: canUseBiometricsForSigning,
      }),
    );
  };

  return (
    <>
      <div>
        <PageTitle showOnMobile={isDesktop}>{t(PageResources.Title)}</PageTitle>
        <Typography marginBottom={5}>
          {t(ComponentResources.Description)}
        </Typography>
        {!!bankAccount && (
          <BankAccount
            iban={bankAccount.iban}
            bankCode={bankAccount.bankCode}
            bankNumber={bankAccount.accountNumber}
          />
        )}
      </div>
      <BlSlideButton
        minSlideVelocity={0.95}
        minSlideWidth={0.95}
        onSlideDone={onSubmit}
        trackingTag={t(ComponentResources.SwipeButtonText)}
        disabled={!bankAccount}
      >
        {t(ComponentResources.SwipeButtonText)}
      </BlSlideButton>
    </>
  );
};
