import * as React from "react";
export const DownloadIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99999 1.2002C9.77968 1.2002 9.59999 1.37988 9.59999 1.6002C9.59999 1.82051 9.77968 2.0002 9.99999 2.0002C10.2203 2.0002 10.4 1.82051 10.4 1.6002C10.4 1.37988 10.2203 1.2002 9.99999 1.2002ZM9.99999 2.8002C9.77968 2.8002 9.59999 2.97988 9.59999 3.2002C9.59999 3.42051 9.77968 3.6002 9.99999 3.6002C10.2203 3.6002 10.4 3.42051 10.4 3.2002C10.4 2.97988 10.2203 2.8002 9.99999 2.8002ZM9.99999 4.4002C9.77968 4.4002 9.59999 4.57988 9.59999 4.8002C9.59999 5.02051 9.77968 5.2002 9.99999 5.2002C10.2203 5.2002 10.4 5.02051 10.4 4.8002C10.4 4.57988 10.2203 4.4002 9.99999 4.4002ZM9.96249 5.9877C9.9453 5.99082 9.92811 5.99551 9.91249 6.0002C9.72655 6.04238 9.5953 6.20957 9.59999 6.4002V15.0252L7.08749 12.5127C6.99061 12.4127 6.84843 12.3705 6.71249 12.4002C6.56249 12.4268 6.44218 12.5361 6.39999 12.6814C6.3578 12.8283 6.40155 12.9846 6.51249 13.0877L9.66249 16.2252C9.67655 16.2471 9.69374 16.2689 9.71249 16.2877C9.7203 16.2971 9.72811 16.3049 9.73749 16.3127C9.76093 16.3314 9.78593 16.3486 9.81249 16.3627C9.8203 16.3674 9.82968 16.3721 9.83749 16.3752C9.84218 16.3752 9.8453 16.3752 9.84999 16.3752C9.8578 16.3799 9.86718 16.3846 9.87499 16.3877C9.89061 16.3924 9.9078 16.3971 9.92499 16.4002C9.96718 16.4064 10.0078 16.4064 10.05 16.4002C10.0578 16.4002 10.0672 16.4002 10.075 16.4002C10.1094 16.3924 10.1437 16.3799 10.175 16.3627C10.2281 16.3361 10.275 16.2971 10.3125 16.2502L13.4875 13.0877C13.6469 12.9283 13.6469 12.6721 13.4875 12.5127C13.3281 12.3533 13.0719 12.3533 12.9125 12.5127L10.4 15.0252V6.4002C10.4047 6.28457 10.3578 6.17363 10.275 6.09551C10.1906 6.01582 10.0766 5.97676 9.96249 5.9877ZM0.799988 13.2002V18.0002C0.799988 18.658 1.34218 19.2002 1.99999 19.2002H18C18.6578 19.2002 19.2 18.658 19.2 18.0002V13.2002H18.4V18.0002C18.4 18.2268 18.2266 18.4002 18 18.4002H1.99999C1.77343 18.4002 1.59999 18.2268 1.59999 18.0002V13.2002H0.799988Z"
        fill="currentColor"
      />
    </svg>
  );
};
