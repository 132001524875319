import { ContractDealerDto, ContractDto } from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getDealerAsync } from "State/InvestmentsDetail/Dealer/GetDealerState";
import { Resources, useResource } from "Translations/Resources";
import { DEFAULT_INVESTIKA_DEALER_ID } from "Utils/DefaultDealer";
import { useEffect, useMemo } from "react";

export const useDealer = (contractId: ContractDto["contractID"]) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const {
    isLoading: loading,
    data,
    error,
  } = useAppSelector(s => s.investmentsDetail.dealer);
  const isLoading = loading && !data;

  const dealer = useMemo<ContractDealerDto | undefined>(() => {
    if (DEFAULT_INVESTIKA_DEALER_ID === "3556884") {
      return {
        email: t(Resources.Dealers[3556884].Email),
        firstName: t(Resources.Dealers[3556884].Name),
        lastName: "",
        distribution: "INVESTIKA a.s.",
        phone: t(Resources.Dealers[3556884].Phone),
      };
    } else if (DEFAULT_INVESTIKA_DEALER_ID === "3692004") {
      return {
        email: t(Resources.Dealers[3692004].Email),
        firstName: t(Resources.Dealers[3692004].Name),
        lastName: "",
        distribution: "INVESTIKA a.s.",
        phone: t(Resources.Dealers[3692004].Phone),
      };
    }
  }, [t]);

  useEffect(() => {
    if (contractId) {
      dispatch(
        getDealerAsync.request({
          contractId,
          defaultDealer: dealer,
        }),
      );
    }
  }, [dispatch, contractId, dealer]);

  return {
    isLoading,
    data,
    error,
  };
};
