import {
  postAuthSignUp,
  SignUpCommand,
  SignUpCommandResultStatus,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { NavigateFunction } from "react-router";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { getPath, AppRouting } from "Utils/UrlUtils";

export const signUpAsync = createAsyncAction(
  "@auth/SIGN_UP_REQUEST",
  "@auth/SIGN_UP_SUCCESS",
  "@auth/SIGN_UP_FAILURE",
)<
  { model: SignUpCommand; navigate: NavigateFunction },
  { tokenID: string; email: string },
  Error
>();

function* signUp(action: ReturnType<typeof signUpAsync.request>): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postAuthSignUp,
      action.payload.model,
    );

    if (error) {
      yield put(signUpAsync.failure(error));
      return;
    }

    if (
      response.status === SignUpCommandResultStatus.Success &&
      !!response.tokenID
    ) {
      yield put(
        signUpAsync.success({
          tokenID: response.tokenID,
          email: action.payload.model.login,
        }),
      );

      yield* call(() =>
        action.payload.navigate(getPath(AppRouting.AwaitingEmailVerification)),
      );
    } else {
      yield put(signUpAsync.failure(new ApplicationError(response.status)));
    }
  } catch (err) {
    yield put(signUpAsync.failure(err as Error));
  }
}
export function* signUpSaga() {
  yield takeLatest(getType(signUpAsync.request), signUp);
}
