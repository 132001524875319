import { useModelingIsin } from "Hooks/useModelingIsin";
import { ContractModelingStep } from "State/Contracts/Modeling/Models";

export const useHeaderIsin = (actualStep: ContractModelingStep) => {
  const isin = useModelingIsin();

  if (isin && actualStep === ContractModelingStep.FundConfiguration) {
    return isin;
  }
};
