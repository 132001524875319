import { Box, Stack, Typography } from "@mui/material";
import { DashboardCard } from "Components/Dashboard/DetailDashboard/DashboardCard/DashboardCard";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { CurrencyRenderer } from "Components/Shared/CurrencyRenderer";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useUser } from "Hooks/useUser";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { useDesktop } from "Hooks/useDesktop";
import { useUserCampaignItems } from "Hooks/Campaigns/useUserCampaignItems";
import { CampaignCarousel } from "Components/Shared/Campaigns/CampaignCarousel";

const PageResources = Resources.Dashboard;

export const DetailDashboard: FunctionComponent = _ => {
  const { t } = useResource();

  const {
    isLoading: isLoadingContracts,
    data,
    error,
  } = useAppSelector(s => s.dashboard.contracts);

  const { campaigns, isLoading: isLoadingCampaigns } = useUserCampaignItems();

  const isLoading =
    (isLoadingContracts && !data) || (isLoadingCampaigns && !campaigns);
  const user = useUser();
  const { isDesktop } = useDesktop();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      justifyContent="space-between"
    >
      <div>
        <LoadingWrapper isLoading={isLoading} height={170}>
          {!!campaigns?.length && <CampaignCarousel campaigns={campaigns} />}
        </LoadingWrapper>

        <InputHeaderWrapper
          header={t(PageResources.TotalValue)}
          marginTop={
            isLoading || !!campaigns?.length || !isDesktop ? undefined : 0
          }
        />
        <LoadingWrapper isLoading={isLoading}>
          <CurrencyRenderer value={data?.totalAmount ?? 0} decimalPlaces={2} />
        </LoadingWrapper>
        <InputHeaderWrapper
          header={t(PageResources.YourGoals)}
          marginBottom={3}
        />

        <Stack gap={2}>
          <LoadingWrapper
            isLoading={isLoading}
            error={error}
            skeleton={
              <>
                <PrimarySkeleton height={225} />
                <PrimarySkeleton height={225} />
              </>
            }
          >
            {data?.contracts?.map(contract => (
              <DashboardCard
                key={contract.contractID}
                disabledPurchases={!!user?.isUnderage}
                {...contract}
              />
            ))}
          </LoadingWrapper>
        </Stack>
      </div>

      <LoadingWrapper isLoading={isLoading}>
        <Box display="flex" justifyContent="center" marginTop={5}>
          <Typography variant="subtitle1" component="span">
            {t(PageResources.ContractsUnavailabilityLabel)}
          </Typography>
          <BlInfoPopper color="secondary" icon="info">
            {t(PageResources.ContractsUnavailabilityTooltip)}
          </BlInfoPopper>
        </Box>
      </LoadingWrapper>
    </Box>
  );
};
