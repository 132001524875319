import { CheckboxInput } from "Components/Shared/Inputs/BlCheckboxField/CheckboxInput";
import { MenuItem } from "Components/Shared/MenuItem";
import { useNotification } from "Hooks/Notifications/useNotification";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { setIsNotificationsEnabled } from "State/Notifications/NotificationsReducer";
import { Resources, useResource } from "Translations/Resources";
import { debounce } from "lodash-es";
import { useCallback, type FunctionComponent } from "react";
import { useNotificationSynchronization } from "Hooks/Notifications/useNotificationSynchronization";
import { postMessageFromWebView } from "Utils/WebViewUtils";
import { WebViewMessageTypes } from "Models/WebViewModels";
import useInterval from "Hooks/useInterval";

const ComponentResources = Resources.Profile.Settings;

export const NotificationItem: FunctionComponent = _ => {
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const { isEnabled, isPermissionGranted } = useNotification();
  const { synchronizePermission } = useNotificationSynchronization();

  const checkPermission = useCallback(
    () =>
      postMessageFromWebView({
        type: WebViewMessageTypes.NOTIFICATION_TOKEN_REQUEST,
        payload: {},
      }),
    [],
  );

  useInterval(checkPermission, 250);

  const debouncedUpdateClientNotificationStatus = debounce(
    (checked: boolean) => {
      if (isPermissionGranted) {
        dispatch(setIsNotificationsEnabled(checked));
      } else {
        // Optimistic approach.
        dispatch(setIsNotificationsEnabled(true));
        synchronizePermission();
      }
    },
    200,
  );

  const actionElement = (
    <CheckboxInput
      name="notifications"
      checked={!!isEnabled && isPermissionGranted}
      onChange={(_, checked) =>
        debouncedUpdateClientNotificationStatus(checked)
      }
    />
  );

  return (
    <MenuItem
      title={t(ComponentResources.Notifications.Title)}
      description={t(ComponentResources.Notifications.Description)}
      actionElement={actionElement}
    />
  );
};
