import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { getTheme } from "Components/Layout/Themes/Themes";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { ToastContainer } from "react-toastify";
import { RootStateType } from "State/Store";
import styled, {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { setTracking as setIsTrackingEnabled } from "Utils/TrackingUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { isPwa } from "Utils/UserAgentUtils";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: ${props => props.theme.vh(100)};
  overflow: auto;
`;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: ${props => props.theme.borderRadius};
    padding: ${props => props.theme.spacing(2)};
  }
  .Toastify__toast--success {
    background-color: ${props => props.theme.palette.success.main};
  }
  .Toastify__toast--error {
    background-color: ${props => props.theme.palette.error.main};
    color: ${props => props.theme.palette.common.white};
    .Toastify__toast-icon {
      path {
        fill: ${props => props.theme.palette.common.white};
      }
    }
  }
`;

export const Layout: React.FunctionComponent<
  React.PropsWithChildren<{}>
> = props => {
  const [theme, setTheme] = React.useState<DefaultTheme | null>(null);

  React.useEffect(() => {
    setTheme(getTheme());
  }, []);

  const onRefresh = () => {
    return new Promise(resolve => {
      window.location.reload();
      setTimeout(resolve, 200);
    });
  };

  var location = useLocation();

  const isOnWhitelistedPage = () => {
    return location.pathname === getPath(AppRouting.Dashboard);
  };

  const user = useSelector((e: RootStateType) => e.auth.user);
  const isAnalyticsEnabled = user?.appData?.isAnalyticsEnabled;

  useEffect(() => {
    setIsTrackingEnabled(isAnalyticsEnabled ?? true);
  }, [isAnalyticsEnabled]);

  return (
    <>
      {theme && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <StyledThemeProvider theme={theme}>
              <StyledToastContainer />
              <PullToRefresh
                isPullable={isPwa() && isOnWhitelistedPage()}
                onRefresh={onRefresh}
                resistance={4}
                pullingContent={""}
              >
                <StyledWrapper>{props.children}</StyledWrapper>
              </PullToRefresh>
            </StyledThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};
