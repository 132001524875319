import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import React from "react";

export const EmailVerificationPage: React.FunctionComponent = _ => {
  // React.useEffect(() => {
  //   if (!!token) {
  //     dispatch(verifyEmailAsync.request({ command: { code }, navigate }));
  //   }
  // }, [dispatch, navigate, token]);

  return (
    <UnauthenticatedLayout title="Verifikace e-mailu"></UnauthenticatedLayout>
  );
};

export default EmailVerificationPage;
