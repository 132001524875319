import { CardContent, Grid, Link, Typography, styled } from "@mui/material";
import { TrackedButton } from "Components/Shared/Buttons/TrackedButton";
import { StyledDipCard } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { TransferDipIcon } from "../Shared/Icons/TransferDip";
import { getSafeHoverBackground } from "Utils/CssUtils";

const StyledButton = styled(TrackedButton)<{
  $hexColor?: string | null | undefined;
}>`
  min-width: unset;
  padding: ${({ theme }) => theme.spacing(1, 5)};
  border-radius: 100px;
  font-weight: 600;
  text-transform: none;

  background-color: ${({ theme }) => theme.palette.dip.main};
  
  ${({ theme }) => getSafeHoverBackground(theme.palette.dip.main)}  
`;

const ComponentResources = Resources.Contract.DipTransfer.PromoCard;

type Props = { onTransferDip: () => void; onMoreInformation: () => void };

export const TransferPromoCard: FunctionComponent<Props> = ({
  onTransferDip,
  onMoreInformation,
}) => {
  const { t } = useResource();

  const handleTransferDip = () => onTransferDip();
  const handleMoreInformation = () => onMoreInformation();

  return (
    <StyledDipCard>
      <CardContent>
        <Grid container justifyContent="space-between" gap={2}>
          <Grid item xs={12} display="flex" flexDirection="column">
            <Typography
              fontSize={16}
              fontWeight={700}
              color="secondary"
              marginBottom={1}
            >
              {t(ComponentResources.Title)}
            </Typography>
            <Typography marginBottom={2} color="secondary">
              {t(ComponentResources.Description)}
            </Typography>
          </Grid>
          <Grid item xs={12} display="inline-block">
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleTransferDip}
              trackingTag="ContractTransferDipPromoCard"
            >
              <TransferDipIcon />
              <Typography component="span" marginLeft={2} fontWeight={600}>
                {t(ComponentResources.Button)}
              </Typography>
            </StyledButton>
            <Link marginLeft={4} onClick={handleMoreInformation}>
              {t(ComponentResources.MoreInformation)}
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </StyledDipCard>
  );
};
