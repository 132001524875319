import { ContractOverviewDto } from "Api/Api";
import { FunctionComponent, useEffect, useMemo, useRef } from "react";
import { formatNumber, roundNumberToDecimalPlaces } from "Utils/NumberUtils";
import { numberToPercentage } from "Utils/PerformanceCalculators";
import { getProductColor } from "Utils/Products";
import { Colors } from "Components/Layout/Themes/Colors";
import { PieChart } from "@mui/x-charts";
import { InvestmentCompositionPieChartTooltipContent } from "Components/ContractDetail/Overview/components/InvestmentComposition/InvestmentCompositionPieChartTooltipContent";
import { styled } from "styled-components";

const PieChartWrapper = styled.div`
  user-select: none;
  touch-action: none;
  text-size-adjust: none;

  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */

  -moz-user-select: -moz-none;
  -ms-user-select: none;
`;

const MIN_AMOUNT_PERCENTAGE = 0.05;

type Props = Pick<ContractOverviewDto, "portfolioItems">;

export const InvestmentCompositionPieChart: FunctionComponent<Props> = ({
  portfolioItems,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const preventZoom = (event: TouchEvent) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    const preventDoubleTapZoom = (event: TouchEvent) => {
      let lastTouchEnd = 0;
      if (event.type === "touchend") {
        const now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }

        lastTouchEnd = now;
      }
    };

    const preventTextSelection = (event: Event) => {
      event.preventDefault();
    };

    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.addEventListener("touchmove", preventZoom, { passive: false });
      wrapper.addEventListener("touchend", preventDoubleTapZoom, {
        passive: false,
      });
      wrapper.addEventListener("selectstart", preventTextSelection);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("touchmove", preventZoom);
        wrapper.removeEventListener("touchend", preventDoubleTapZoom);
        wrapper.removeEventListener("selectstart", preventTextSelection);
      }
    };
  }, []);

  const totalAmount = useMemo(
    () => portfolioItems.reduce((sum, x) => sum + (x.amount || 0), 0),
    [portfolioItems],
  );

  const minAmount = totalAmount * MIN_AMOUNT_PERCENTAGE;

  const data = useMemo(() => {
    return portfolioItems.map(x => {
      const { color } = getProductColor(x.isin);

      return {
        id: x.isin,
        value: !!x.amount && x.amount > minAmount ? x.amount : minAmount,
        color: color,
      };
    });
  }, [minAmount, portfolioItems]);

  const TooltipContent = useMemo(() => {
    return portfolioItems.map((item, _) => {
      const { isin, amount, currency } = item;

      const formattedAmountPercentage = `${formatNumber(
        roundNumberToDecimalPlaces(
          Math.abs(numberToPercentage((amount || 0) / totalAmount)),
          1,
        ),
      )} %`;

      return (
        <InvestmentCompositionPieChartTooltipContent
          key={isin}
          isin={isin}
          amount={amount || 0}
          amountPercentage={formattedAmountPercentage}
          currency={currency}
        />
      );
    });
  }, [portfolioItems, totalAmount]);

  return (
    <PieChartWrapper ref={wrapperRef}>
      <PieChart
        series={[
          {
            data: data,
            innerRadius: 55,
            outerRadius: 120,
            paddingAngle: 5,
            cornerRadius: 5,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: Colors.Gray,
            },
          },
        ]}
        margin={{ left: 0, top: 5, right: 0, bottom: 0 }}
        width={250}
        height={250}
        tooltip={{
          trigger: "item",
          itemContent: props => TooltipContent[props.itemData.dataIndex || 0],
          slotProps: {
            popper: {
              placement: "top-start",
            },
          },
        }}
        slotProps={{
          pieArc: {
            strokeOpacity: 0,
          },
          popper: {
            placement: "top-start",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 50],
                },
              },
            ],
          },
        }}
      />
    </PieChartWrapper>
  );
};
