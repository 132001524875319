import { Box, Stack, Typography } from "@mui/material";
import { InvoiceQRCode } from "Components/Shared/InvoiceQrCode/InvoiceQRCode";
import { CopyableText } from "Components/Shared/CopyableText";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import {
  DefaultBankAccountDto,
  CurrencyCode,
  GenerateQrCodeCommand,
} from "Api/Api";
import { generateCzechIban, getBankCountry } from "Utils/BankUtils";
import {
  usePaymentMessage,
  useQrCodePaymentMessage,
} from "Hooks/usePaymentMessage";
import { WarningText } from "Components/Shared/WarningText";
import { Trans } from "react-i18next";
import { BankAccountWarning } from "Components/ContractCreate/Shared/BankAccountWarning";
import { isNoU, notNoU } from "Utils/ObjectUtils";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
} from "Constants/Countries";
import { useExchangeRate } from "Hooks/ExchangeRate/useExchangeRate";
import { formatCurrency } from "Utils/CurrencyUtils";

export type Props = {
  showSaveButton?: boolean;
  size?: number;
  amount: number;
  expiryDate?: Date;
  accountNumber?: string;
  iban?: string;
  currency: CurrencyCode;
  contractNo: string;
  firstName: string;
  lastName: string;
  bankCountry?: string;
  isBankIdVerified: boolean;
  isNoticeToFirstPaymentVisible: boolean;
  isBySquareQRCodeEnabled: boolean;
  isSelfNegotiatedContract: boolean;
  isSingleInvestment: boolean;
  defaultBankAccount?: DefaultBankAccountDto;
  title: string;
  showTitle?: boolean;
  bankAccountRegion?: string | null | undefined;
} & Omit<GenerateQrCodeCommand, "iban" | "messageForPayment" | "price">;

const ComponentResources = Resources.Components.PaymentInformation;

const generateIban = (accountNumber?: string) => {
  if (!accountNumber) {
    return;
  }

  const [account, bankCode] = accountNumber.split("/");

  if (!account || !bankCode) {
    return;
  }

  if (account.includes("-")) {
    const [prefix, accountNumber] = account.split("-");
    return generateCzechIban({ accountNumber, prefix, bankCode });
  }

  return generateCzechIban({ accountNumber: account, bankCode });
};

export const PaymentInformation: FunctionComponent<Props> = props => {
  const {
    contractNo,
    firstName,
    lastName,
    variableCode: vs,
    specificSymbol: ss,
    isBankIdVerified,
    isNoticeToFirstPaymentVisible,
    isSelfNegotiatedContract,
    amount,
    title,
    showTitle = true,
    defaultBankAccount,
    bankAccountRegion,
    currency,
  } = props;

  const iban = props.iban ?? generateIban(props.accountNumber);

  const { t } = useResource();

  const defaultBankAccountBankCountry =
    defaultBankAccount?.bankCountry ?? getBankCountry(defaultBankAccount?.iban);

  const isCzechBankAccount =
    defaultBankAccountBankCountry === CZECHIA_COUNTRY_CODE;

  const isSlovakiaBankAccount =
    defaultBankAccountBankCountry === SLOVAKIA_COUNTRY_CODE;

  const isDomesticPayment =
    isCzechBankAccount &&
    (isNoU(bankAccountRegion) || bankAccountRegion === CZECHIA_COUNTRY_CODE);

  const accountNumberOrIban = isDomesticPayment ? props.accountNumber : iban;

  const { data, isLoading: loadingData } = useExchangeRate({
    currencyFrom: props.defaultBankAccount?.currency,
    currencyTo: props.currency,
  });

  const exchangeRate = data?.result?.rate;
  const isLoading = loadingData && !exchangeRate;
  const price =
    notNoU(amount) && notNoU(exchangeRate)
      ? Math.floor(amount / exchangeRate)
      : null;

  const paymentMessage = usePaymentMessage(
    contractNo,
    firstName,
    lastName,
    ss,
    vs,
    isBankIdVerified,
    isNoticeToFirstPaymentVisible,
    isSelfNegotiatedContract,
    isCzechBankAccount,
    isDomesticPayment,
  );

  const qrCodePaymentMessage = useQrCodePaymentMessage(
    contractNo,
    firstName,
    lastName,
    ss,
    vs,
    isBankIdVerified,
    isNoticeToFirstPaymentVisible,
    isSelfNegotiatedContract,
    isCzechBankAccount,
    isSlovakiaBankAccount,
    isDomesticPayment,
  );

  return (
    <>
      <Stack gap={2}>
        {showTitle && (
          <Typography variant="subtitle1" textTransform="uppercase">
            {title}
          </Typography>
        )}

        {!!amount && (
          <div>
            <Typography>{t(Resources.Common.Amount)}</Typography>
            <CopyableText
              fontWeight={700}
              text={formatCurrency(amount, currency)}
              copyText={amount.toString()}
            />
          </div>
        )}

        <div>
          <Typography>{t(ComponentResources.AccountNumber)}</Typography>
          <CopyableText fontWeight={700} text={accountNumberOrIban ?? ""} />
        </div>

        <div>
          <Typography>{t(ComponentResources.VariableSymbol)}</Typography>
          <CopyableText fontWeight={700} text={props.variableCode} />
        </div>

        <div>
          <Typography>{t(ComponentResources.SpecificSymbol)}</Typography>
          <CopyableText fontWeight={700} text={props.specificSymbol} />
        </div>

        {!!paymentMessage && (
          <div>
            <Typography>{t(ComponentResources.PaymentMessage)}</Typography>
            <CopyableText fontWeight={700} text={paymentMessage} />
          </div>
        )}

        {!isDomesticPayment && (
          <WarningText marginTop={4}>
            <Trans>{t(ComponentResources.ForeignPaymentWarning)}</Trans>
          </WarningText>
        )}
      </Stack>

      {!isLoading && !!iban && (
        <>
          {notNoU(price) && (
            <Box display="flex" justifyContent="center" marginTop={3}>
              <InvoiceQRCode
                {...props}
                key={`${iban}-${price}`}
                iban={iban}
                messageForPayment={qrCodePaymentMessage}
                price={price}
                showSaveButton
                bankCountryCode={defaultBankAccountBankCountry}
                bankAccountRegion={bankAccountRegion}
              />
            </Box>
          )}
        </>
      )}

      {isNoticeToFirstPaymentVisible && (
        <Box marginBottom={2}>
          <BankAccountWarning
            withMargin={!iban || !(isCzechBankAccount || isSlovakiaBankAccount)}
            showRequireMessage={!!paymentMessage}
            defaultBankAccount={defaultBankAccount}
          />
        </Box>
      )}
    </>
  );
};
