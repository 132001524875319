import { Box, DialogContent, Stack, Typography } from "@mui/material";
import { StyledDialog } from "Components/Shared/StyledComponents";
import styled from "styled-components";
import { TrackingWrapper } from "Components/Tracking/TrackingWrapper";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, useState } from "react";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { ProductCard } from "Components/ContractExchangeAssets/components/ProductCard";
import { track } from "Utils/TrackingUtils";

const StyledDialogWithOffset = styled(StyledDialog)`
  margin-top: 90px;
  height: calc(100vh - 90px);
`;

type Props = {
  defaultIsin: string;
  isins: string[];
  isDip: boolean;
  isVisible: boolean;
  onSelect: (isin: string) => void;
  onClose: () => void;
};

const ComponentResources =
  Resources.Contract.ExchangeAssets.Modelling.FundSelection.Dialog;

export const ProductSelectorDialog: FunctionComponent<Props> = ({
  defaultIsin,
  isins,
  isDip,
  isVisible,
  onSelect,
  onClose,
}) => {
  const { t } = useResource();
  const [selectedIsin, setSelectedIsin] = useState(defaultIsin);

  return (
    <StyledDialogWithOffset
      open={isVisible}
      onClose={() => {
        onClose();
        setSelectedIsin(defaultIsin);
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <TrackingWrapper
          payload={{
            category: "ModalView",
            event: "Open",
            action: isVisible ? "Open" : "Close",
          }}
        >
          <Box marginBottom={10}>
            <Typography marginBottom={5}>
              {t(ComponentResources.Description)}
            </Typography>
            <Stack gap={2}>
              {isins.map(x => (
                <DipTagWrapper showPlain={!isDip} key={x}>
                  <ProductCard
                    isin={x}
                    key={x}
                    isChecked={selectedIsin === x}
                    onCheck={x => {
                      track({
                        category: "RadioButtons",
                        event: "Change",
                        action: "Click",
                        tag: "fundISIN",
                        value: x,
                      });

                      setSelectedIsin(x);
                    }}
                  />
                </DipTagWrapper>
              ))}
            </Stack>
          </Box>
          <PrimaryButton
            color="primary"
            onClick={() => onSelect(selectedIsin)}
            fullWidth
            disabled={!isins.some(x => x === selectedIsin)}
          >
            {t(ComponentResources.Submit)}
          </PrimaryButton>
        </TrackingWrapper>
      </DialogContent>
    </StyledDialogWithOffset>
  );
};
