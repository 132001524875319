import {
  ContractPreviewDto,
  ContractTransferDipContractPreviewCommandResult,
  ContractTransferDipContractPreviewDto,
  postContractTransferDipContractPreview,
} from "Api/Api";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import { safeApiCall } from "State/Utils";

import { put, takeLatest } from "typed-redux-saga";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";

export type contractTransferDipContractPreviewStateType =
  FetchStateType<ContractPreviewDto>;

export const transferDipPreviewState =
  (): contractTransferDipContractPreviewStateType =>
    getFetchStateDefaultValue();

export type transferDipPreviewActionType = ActionType<
  typeof transferDipPreviewAsync
>;

export const transferDipPreviewAsync = createAsyncAction(
  "@contract/POST_TRANSFER_DIP_PREVIEW_REQUEST",
  "@contract/POST_TRANSFER_DIP_PREVIEW_SUCCESS",
  "@contract/POST_TRANSFER_DIP_PREVIEW_FAILURE",
)<
  ContractTransferDipContractPreviewDto,
  ContractTransferDipContractPreviewCommandResult,
  Error
>();

function* transferDipPreview(
  action: ActionType<typeof transferDipPreviewAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postContractTransferDipContractPreview,
      action.payload,
    );

    if (error) {
      yield put(transferDipPreviewAsync.failure(error));
      return;
    }

    if (!response) {
      yield put(transferDipPreviewAsync.failure(new Error()));
      return;
    }

    yield put(transferDipPreviewAsync.success(response));
  } catch (err) {
    console.log(err);
    yield put(transferDipPreviewAsync.failure(err as Error));
  }
}

export function* transferDipPreviewSaga() {
  yield takeLatest(
    getType(transferDipPreviewAsync.request),
    transferDipPreview,
  );
}
