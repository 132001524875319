import { Typography } from "@mui/material";
import { ContractOverviewPortfolioItemDto, CurrencyCode } from "Api/Api";
import { ProductSelector } from "Components/ContractExchangeAssets/components/ProductSelector";
import { ProductSelectorDialog } from "Components/ContractExchangeAssets/components/ProductSelectorDialog";
import { ProductSelectorWidth } from "Components/ContractExchangeAssets/components/ProductSelectorPlaceholder";
import { ArrowDoubleRightIcon } from "Components/Shared/Icons/ArrowDoubleRight";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

export const StyledWrapper = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.values["sm"]}px) {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.values["sm"]}px) {
    width: 400px;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  isDip: boolean;
  defaultIsin: string;
  allowedTransfers: string[];
  portfolioItem: ContractOverviewPortfolioItemDto | null | undefined;
  onSelect: (isin: string) => void;
};

const ComponentResources =
  Resources.Contract.ExchangeAssets.Modelling.FundSelection;

export const ProductSelectors: FunctionComponent<Props> = ({
  isDip,
  defaultIsin,
  allowedTransfers,
  portfolioItem,
  onSelect,
}) => {
  const { t } = useResource();
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  return (
    <>
      <StyledWrapper>
        <StyledFlex $justifyContent="space-between">
          <Typography
            textAlign="center"
            width={ProductSelectorWidth}
            marginBottom={2}
          >
            {t(ComponentResources.Source)}
          </Typography>
          <Typography
            textAlign="center"
            width={ProductSelectorWidth}
            marginBottom={2}
          >
            {t(ComponentResources.Target)}
          </Typography>
        </StyledFlex>

        <StyledFlex $justifyContent="space-between" $alignItems="center">
          <ProductSelector {...portfolioItem} />
          <StyledIconWrapper>
            <ArrowDoubleRightIcon />
          </StyledIconWrapper>
          <ProductSelector
            isin={defaultIsin}
            currency={CurrencyCode.CZK}
            onClick={() => setIsDialogVisible(true)}
          />
        </StyledFlex>
      </StyledWrapper>
      <ProductSelectorDialog
        defaultIsin={defaultIsin}
        isins={allowedTransfers}
        isDip={isDip}
        isVisible={isDialogVisible}
        onSelect={isin => {
          setIsDialogVisible(false);
          onSelect(isin);
        }}
        onClose={() => setIsDialogVisible(false)}
      />
    </>
  );
};
