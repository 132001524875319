import { TabProps } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { ModellingStep } from "Components/More/PersonalData/EditAML/ModellingStep";
import { SignatureStep } from "Components/More/PersonalData/EditAML/SignatureStep";
import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { useIsLead } from "Hooks/User/useIsLead";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { getBusinessSectorAsync } from "State/CodeLists/BusinessSector/GetBusinessSectorState";
import {
  EditAMLSteps,
  resetEditAMLState,
  setEditAMLActualStep,
} from "State/More/PersonalData/EditAML/EditAMLReducer";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

const Components: {
  [key in EditAMLSteps]: FunctionComponent<TabProps>;
} = {
  [EditAMLSteps.Modelling]: ModellingStep,
  [EditAMLSteps.Signature]: SignatureStep,
};

const PageResources = Resources.More.PersonalData.EditAML;

export const EditAMLPage: FunctionComponent = () => {
  const { t } = useResource();
  const { actualStep } = useAppSelector(s => s.client.personalData.editAML);
  const { isDesktop } = useDesktop();

  const isLead = useIsLead();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBackClick = useCallback(() => {
    switch (actualStep) {
      case EditAMLSteps.Modelling:
        navigate(getPath(AppRouting.PersonalData, PersonalDataTabs.AML));
        return;
      case EditAMLSteps.Signature:
        dispatch(setEditAMLActualStep(EditAMLSteps.Modelling));
    }
  }, [actualStep, navigate, dispatch]);

  const Component = Components[actualStep];

  useEffect(() => {
    dispatch(resetEditAMLState());
    dispatch(getBusinessSectorAsync.request());
  }, [dispatch]);

  if (isLead) {
    return (
      <Navigate to={getPath(AppRouting.PersonalData, PersonalDataTabs.AML)} />
    );
  }
  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={handleBackClick}
    >
      {!!Component && <Component />}
    </AuthenticatedLayout>
  );
};
