import * as React from "react";
export const ArrowDoubleRightIcon = (
  props: {
    size?: number;
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 30L30 20L20 10" stroke="#F2F2F2" strokeWidth={2} />
      <path d="M10 30L20 20L10 10" stroke="#F2F2F2" strokeWidth={2} />
    </svg>
  );
};
