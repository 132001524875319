import { StyledFormControl } from "Components/Shared/FormStyles";
import { FileInput } from "Components/Shared/Inputs/FileInput/FileInput";
import { AttachmentDescriptor } from "Api/Api";
import { AllowedFileMimeTypes } from "Constants/Inputs";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { FunctionComponent, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import get from "lodash-es/get";
import { PrimaryDocumentFormModel } from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import {
  DocumentScanGuidFieldPath,
  useDocumentFile,
} from "Hooks/More/PersonalData/EditPersonalData/Modelling/useDocumentFile";
import { deleteAttachmentAsync } from "State/Attachments/Upload/DeleteAttachmentState";

type Props = {
  fieldPath: DocumentScanGuidFieldPath;
  label: string;
  descriptor: AttachmentDescriptor;
};

export const PrimaryDocumentInput: FunctionComponent<Props> = ({
  fieldPath,
  label,
  descriptor,
}) => {
  const dispatch = useAppDispatch();
  const {
    setValue,
    setError,
    formState: { errors },
  } = useFormContext<PrimaryDocumentFormModel>();

  const { isSaving, fileGuid, fileDetail, file, saveFile } = useDocumentFile(
    fieldPath,
    descriptor,
  );

  const handleChange = (file: File | null) => {
    if (file) {
      saveFile(file);
    } else {
      setValue(fieldPath, "", { shouldValidate: true });
      dispatch(deleteAttachmentAsync.request({ guid: fileGuid }));
    }
  };

  const setFormError = useCallback(
    (x: string) => setError(fieldPath, { message: x }),
    [fieldPath, setError],
  );

  return (
    <StyledFormControl>
      <FileInput
        maxSizeMB={25}
        fieldError={get(errors, fieldPath as string)}
        onChange={handleChange}
        value={file}
        label={fileDetail?.fileName || label}
        isLoading={isSaving}
        accept={AllowedFileMimeTypes}
        setFormError={setFormError}
      />
    </StyledFormControl>
  );
};
