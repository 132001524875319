import {
  ContractDto,
  CurrencyCode,
  JobType,
  NetIncomeType,
  SourceOfIncomeType,
  SourceOfResourcesType,
} from "Api/Api";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
  USA_COUNTRY_CODE,
} from "Constants/Countries";
import { ContactInformationFormModel, DocumentModel } from "Models/Forms";
import { SexType } from "Models/Inputs";

export enum NewContractStep {
  PathSelection,
  PersonalData,
  ContantVerification,
  ContactInformation,
  Documents,
  BankConnection,
  AMLQuestions,
  LastInformation,
  ContractPreview,
  ContractSignature,
  FinalPage,
  BankId,
}

export enum VerificationMode {
  BankID,
  Manual,
}

export type PathSelectionFormModel = {
  verificationMode: VerificationMode;
};

export type BankConnectionsType = "bankNumber" | "iban";

export type PhoneVerificationFormModel = {
  code: string;
};

export type PersonalDataFormModel = {
  nationalities: { value: string; bankId?: boolean }[];
  personalIdentificationNumber: string;
  birthDate: string;
  sex?: SexType;
  firstName: string;
  lastName: string;
  titleBeforeName?: string;
  titleAfterName?: string;
  birthCity?: string;
  birthCountry?: string;
  email?: string;
  phone?: string;
};

export type ContantVerificationFormModel = PhoneVerificationFormModel;

export type BankConnectionFormModel = {
  bankConnectionType: BankConnectionsType;
  bankCurrency?: CurrencyCode;
  selectedBank?: string;
  bankNumber?: string;
  swiftCode?: string;
  bankCode?: string;
  country?: string;
  iban?: string;
};

export type ScanType = {
  guid: string;
  fileName: string;
  mimeType: string;
};

export type DocumentScanSide = "frontScan" | "backScan";

export type DocumentProps = {
  [key in DocumentScanSide]?: ScanType | null;
} & Partial<DocumentModel>;

export type DocumentsFormModel = {
  firstDocument?: DocumentProps;
  secondDocument?: DocumentProps;
};

export type Documents = keyof DocumentsFormModel;

export type AMLQuestionsFormModel = {
  annualIncome: NetIncomeType;
  businessSector: number;
  sourceOfIncome: SourceOfIncomeType;
  sourceOfIncomeOther?: string;
  jobPosition: JobType;
  sourceOfFunds: SourceOfResourcesType[];
  sourceOfFundsOther?: string;
  fundsVolume?: number;
  fundsVolumeCurrency: CurrencyCode;
  politicallyExposed?: boolean;
  politicallyExposedDescription?: string;
  taxResidency?: TaxResidencyEnum;
  taxResidencyCountry?: string;
  vatId?: string;
};

export type LastInformationFormModel = {
  acceptPersonalDataMarketing?: boolean;
  voluntarilyDecision: boolean;
  clientAgreeTakeoverData?: boolean;
  declareContractingDataValidity: boolean;
};

export type ContractSignatureFormModel = {
  contractID?: ContractDto["contractID"];
  signatureHashList?: string[];
} & PhoneVerificationFormModel;

export type FormModels =
  | PathSelectionFormModel
  | PersonalDataFormModel
  | ContantVerificationFormModel
  | BankConnectionFormModel
  | ContactInformationFormModel
  | DocumentsFormModel
  | AMLQuestionsFormModel
  | LastInformationFormModel
  | ContractSignatureFormModel;

export type FormModelType = {
  [key in NewContractStep]?: {
    [NewContractStep.PathSelection]: PathSelectionFormModel;
    [NewContractStep.PersonalData]: PersonalDataFormModel;
    [NewContractStep.ContantVerification]?: ContantVerificationFormModel;
    [NewContractStep.ContactInformation]: ContactInformationFormModel;
    [NewContractStep.BankConnection]: BankConnectionFormModel;
    [NewContractStep.Documents]: DocumentsFormModel;
    [NewContractStep.AMLQuestions]: AMLQuestionsFormModel;
    [NewContractStep.LastInformation]: LastInformationFormModel;
    [NewContractStep.ContractPreview]: void;
    [NewContractStep.ContractSignature]: Partial<ContractSignatureFormModel>;
    [NewContractStep.FinalPage]: void;
    [NewContractStep.BankId]: void;
  }[key];
};

export enum TaxResidencyEnum {
  CzechRepublic = CZECHIA_COUNTRY_CODE,
  Slovakia = SLOVAKIA_COUNTRY_CODE,
  USA = USA_COUNTRY_CODE,
  Other = "__other",
}
