import { Box, Typography } from "@mui/material";
import { InfoIcon, QuestionsIcon } from "Components/Shared/Icons";
import { PrimaryPopper } from "Components/Shared/PrimaryPopper";
import { PropsWithChildren, type FunctionComponent } from "react";
import styled from "styled-components";

type Props = PropsWithChildren<{
  icon?: "question" | "info";
  size?: number;
  toggleCallback?: () => void;
  color?: "primary" | "secondary";
  marginLeft?: number;
  calcWidth?: boolean;
}>;

const StyledIconWrapper = styled.div<{
  $color?: Props["color"];
  $marginLeft: number;
}>`
  cursor: pointer;
  margin-left: ${({ theme, $marginLeft }) => theme.spacing($marginLeft)};
  display: flex;
  align-items: center;
  color: ${({ theme, $color }) => theme.palette[$color ?? "secondary"].main};
`;

export const BlInfoPopper: FunctionComponent<Props> = ({
  icon = "question",
  size = 15,
  toggleCallback,
  color,
  marginLeft = 2,
  children,
  calcWidth,
}) => {
  return (
    <Box display="inline-block">
      <PrimaryPopper
        toggleCallback={toggleCallback}
        calcWidth={calcWidth}
        btnEl={
          <StyledIconWrapper $color={color} $marginLeft={marginLeft}>
            {icon === "question" ? (
              <QuestionsIcon size={size} />
            ) : (
              <InfoIcon size={size} />
            )}
          </StyledIconWrapper>
        }
      >
        <Typography>{children}</Typography>
      </PrimaryPopper>
    </Box>
  );
};
