import { useEffect, type FunctionComponent, useMemo, useRef } from "react";
import { TabProps } from "Pages/Contracts/DetailPage";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useProductCardItems } from "Hooks/Products/useProductCardItems";
import { Typography } from "@mui/material";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { ProductWithPaymentInfo } from "Components/ContractDetail/PaymentInformation/ProductWithPaymentInfo";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getPaymentInstructionsAsync } from "State/Contracts/Contract/PaymentInstructions/GetPaymentInstructionsState";
import { useAppSelector } from "Hooks/useAppSelector";
import { Resources, useResource } from "Translations/Resources";
import { useIsSelfNegotiatedContract } from "Hooks/Contract/Detail/useIsSelfNegotiatedContract";
import { getPaymentModellingInfo } from "Utils/Contracts/ContractDetail/Overview";
import { PAYMENT_SEARCH_PRICE_TYPE } from "Constants/Contracts/Detail";
import { useSearchParams } from "react-router-dom";
import { notNoU } from "Utils/ObjectUtils";

type Props = TabProps;

const PageResources = Resources.Contract.Detail.PaymentInformation;

export const PaymentInformation: FunctionComponent<Props> = ({
  contractID,
}) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { products, isLoading: isLoadingProducts } =
    useProductCardItems(contractID);

  const { data: overview } = useAppSelector(s => s.investmentsDetail.overview);
  const { data, isLoading: isLoadingPaymentInstruction } = useAppSelector(
    s => s.contracts.contract.paymentInstruction,
  );

  const prevIsinsRef = useRef<string[]>([]);
  const isSelfNegotiatedContract = useIsSelfNegotiatedContract(overview?.type);

  const isins = useMemo(() => {
    if (isLoadingProducts || !products) {
      return [];
    }

    if (!!overview?.portfolioItems?.length) {
      return overview.portfolioItems.map(pi => pi.isin);
    }

    if (isSelfNegotiatedContract) {
      return products.filter(p => notNoU(p.isin)).map(p => p.isin!);
    }

    return [];
  }, [
    isSelfNegotiatedContract,
    isLoadingProducts,
    products,
    overview?.portfolioItems,
  ]);

  useEffect(() => {
    const isinsChanged =
      prevIsinsRef.current.length !== isins.length ||
      !isins.every((isin, index) => isin === prevIsinsRef.current[index]);

    if (contractID && isins.length > 0 && isinsChanged) {
      dispatch(
        getPaymentInstructionsAsync.request({
          contractID,
          isins,
        }),
      );
      prevIsinsRef.current = isins;
    }
  }, [contractID, isins, dispatch]);

  const showPortfolioItemsNotFoundMessage =
    !isSelfNegotiatedContract && !isins.length;

  const isLoading = isLoadingPaymentInstruction || isLoadingProducts;

  const showFullPaymentInfo =
    searchParams.get("type") === PAYMENT_SEARCH_PRICE_TYPE;

  return (
    <>
      {!isLoading && showPortfolioItemsNotFoundMessage && (
        <Typography>{t(PageResources.PortfolioItemsNotFound)}</Typography>
      )}
      {(isLoading || !showPortfolioItemsNotFoundMessage) && (
        <>
          {!showFullPaymentInfo && (
            <Typography>{t(PageResources.Title)}</Typography>
          )}

          <br />
          <br />
          <LoadingWrapper skeleton={<LoadingSkeleton />} isLoading={isLoading}>
            {isins.map((isin, _, isins) => {
              const paymentInstructions = data?.find(x => x._isin === isin);

              const modellingItem =
                showFullPaymentInfo && overview?.isFirstPayment
                  ? getPaymentModellingInfo(overview, isin)
                  : undefined;

              if (showFullPaymentInfo && !modellingItem) {
                return null;
              }

              return (
                <ProductWithPaymentInfo
                  key={isin}
                  contractID={contractID}
                  isin={isin}
                  bankAccountRegion={overview?.bankAccountRegion}
                  paymentInstructions={paymentInstructions}
                  contractTypeCode={overview?.type}
                  isDip={overview?.isDip ?? undefined}
                  modellingItem={
                    // if is not the first payment we dont want to show the modelling info
                    showFullPaymentInfo && overview?.isFirstPayment
                      ? getPaymentModellingInfo(overview, isin)
                      : undefined
                  }
                  defaultIsOpened={
                    products?.length === 1 ||
                    (showFullPaymentInfo &&
                      overview?.modelling?.modellingItems?.length === 1)
                  }
                />
              );
            })}
          </LoadingWrapper>
        </>
      )}
    </>
  );
};

const LoadingSkeleton = () => (
  <>
    <PrimarySkeleton height={50} fullHeight={false} marginBottom={10} />
    <PrimarySkeleton height={50} fullHeight={false} marginBottom={10} />
    <PrimarySkeleton height={50} fullHeight={false} marginBottom={10} />
    <PrimarySkeleton height={50} fullHeight={false} marginBottom={10} />
  </>
);
