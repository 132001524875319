import { BankIDProcessAudience, BankIDProcessStatus } from "Api/Api";
import { produce } from "immer";
import {
  getBankIDVerificationProcessAsync,
  watchGetBankIDVerificationProcessSaga,
} from "State/BankID/GetBankIDVerificationProcessState";
import {
  initBankIDVerificationProcessAsync,
  watchInitBankIDVerificationProcessSaga,
} from "State/BankID/InitializeBankIDVerificationProcess";
import {
  processBankIDNativeCallbackAsync,
  watchProcessBankIDNativeCallbackSaga,
} from "State/BankID/ProcessBankIDNativeCallbackState";
import {
  processBankIDVerificationAsync,
  watchProcessBankIDVerificationSaga,
} from "State/BankID/ProcessBankIDVerificationState";
import { all } from "typed-redux-saga";
import { createReducer, ActionType, createAction } from "typesafe-actions";

export type BankIDState = {
  isLoading: boolean;
  process?: {
    id: string;
    status?: BankIDProcessStatus | null;
    audience: BankIDProcessAudience;
  };
  error?: Error | null;
};

function getDefaultState(): BankIDState {
  return {
    isLoading: false,
    process: undefined,
    error: null,
  };
}

export const initBankIDVerificationProcess = createAction(
  "@bankID/INIT_VERIFICATION_PROCESS_FOR_WEB_REQUEST",
)<{
  processID: string;
  processAudience: BankIDProcessAudience;
}>();

export type BankIDActionType =
  | ActionType<typeof processBankIDVerificationAsync>
  | ActionType<typeof initBankIDVerificationProcess>
  | ActionType<typeof initBankIDVerificationProcessAsync>
  | ActionType<typeof getBankIDVerificationProcessAsync>
  | ActionType<typeof processBankIDNativeCallbackAsync>;

export function* watchBankIDSagas() {
  yield all([
    watchProcessBankIDVerificationSaga(),
    watchInitBankIDVerificationProcessSaga(),
    watchGetBankIDVerificationProcessSaga(),
    watchProcessBankIDNativeCallbackSaga(),
  ]);
}

export const bankIDReducer = createReducer<BankIDState, BankIDActionType>(
  getDefaultState(),
)
  .handleAction(processBankIDVerificationAsync.request, (state, action) =>
    produce(state, draft => {
      const { processID, processAudience } = action.payload;

      draft = getDefaultState();
      draft.isLoading = true;
      draft.process = { id: processID, audience: processAudience };

      return draft;
    }),
  )
  .handleAction(processBankIDVerificationAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      if (draft.process) {
        draft.process.status = action.payload;
      }

      return draft;
    }),
  )
  .handleAction(processBankIDVerificationAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;

      return draft;
    }),
  )
  .handleAction(initBankIDVerificationProcessAsync.request, (state, action) =>
    produce(state, draft => {
      const { processID, processAudience } = action.payload;

      draft = getDefaultState();
      draft.isLoading = true;
      draft.process = { id: processID, audience: processAudience };

      return draft;
    }),
  )
  .handleAction(initBankIDVerificationProcessAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      return draft;
    }),
  )
  .handleAction(initBankIDVerificationProcessAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(getBankIDVerificationProcessAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      return draft;
    }),
  )
  .handleAction(getBankIDVerificationProcessAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      if (draft.process) {
        draft.process.status = action.payload;
      }

      return draft;
    }),
  )
  .handleAction(getBankIDVerificationProcessAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;

      return draft;
    }),
  )
  .handleAction(initBankIDVerificationProcess, (state, action) =>
    produce(state, draft => {
      const { processID, processAudience } = action.payload;

      draft = getDefaultState();
      draft.isLoading = true;
      draft.process = { id: processID, audience: processAudience };

      return draft;
    }),
  );
