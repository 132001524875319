import { ContractDealerDto, getContractDealerContractID } from "Api/Api";
import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  handleCleanupAction,
  safeApiCall,
} from "State/Utils";
import {
  CleanupInvestmentStateActionType,
  cleanupInvestmentState,
} from "State/InvestmentsDetail/Shared/Actions";

export type GetDealerStateType = FetchStateType<ContractDealerDto>;

export const getDealerState = (): GetDealerStateType =>
  getFetchStateDefaultValue();

export type GetDealerActionType =
  | ActionType<typeof getDealerAsync>
  | CleanupInvestmentStateActionType;

export const getDealerAsync = createAsyncAction(
  "@investments-detail/GET_DEALER_REQUEST",
  "@investments-detail/GET_DEALER_SUCCESS",
  "@investments-detail/GET_DEALER_FAILURE",
)<
  {
    contractId: Parameters<typeof getContractDealerContractID>[0];
    defaultDealer?: ContractDealerDto;
  },
  ContractDealerDto,
  Error
>();

function* getDealer(
  action: ReturnType<typeof getDealerAsync.request>,
): Generator {
  try {
    const { response, error, status } = yield* safeApiCall(
      getContractDealerContractID,
      action.payload.contractId,
    );
    if (status === 404 && action.payload.defaultDealer) {
      yield put(getDealerAsync.success(action.payload.defaultDealer));
      return;
    }

    if (error) {
      yield put(getDealerAsync.failure(error));
      return;
    }

    yield put(getDealerAsync.success(response));
  } catch (err) {
    yield put(getDealerAsync.failure(err as Error));
  }
}

export function* getDealerSaga() {
  yield takeLatest(getType(getDealerAsync.request), getDealer);
}

export const getDealerReducer = createReducer<
  GetDealerStateType,
  GetDealerActionType
>(getDealerState())
  .handleAction(getDealerAsync.request, handleActionRequest)
  .handleAction(getDealerAsync.failure, handleActionFailure)
  .handleAction(getDealerAsync.success, handleActionSuccess)
  .handleAction(cleanupInvestmentState, handleCleanupAction(getDealerState));
