import { CurrencyCode, UserContactInfo, getUserContact } from "Api/Api";
import { ProductsIsinDetail } from "Constants/Products";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { CONTRACT_STEPS } from "State/Contracts/Create/CreateState";
import { NewContractStep } from "State/Contracts/Create/Models";
import { setContractStep } from "State/Contracts/Create/Shared/ContractStep";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import { safeApiCall } from "State/Utils";
import { isKnownIsin } from "Utils/Products";

import { put, takeLatest } from "typed-redux-saga";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";

type ProcessInformationDto = {
  steps: NewContractStep[];
  isPhoneEditable: boolean;
} & UserContactInfo;

export const getAllContractsStepsSet = () =>
  new Set<NewContractStep>(CONTRACT_STEPS);

export type GetProcessInformationStateType =
  FetchStateType<ProcessInformationDto>;

export const getProcessInformationState = (): GetProcessInformationStateType =>
  getFetchStateDefaultValue();

export type GetProcessInformationActionType = ActionType<
  typeof getProcessInformationAsync
>;

export const getProcessInformationAsync = createAsyncAction(
  "@contract/GET_PROCESS_INFORMATION_REQUEST",
  "@contract/GET_PROCESS_INFORMATION_SUCCESS",
  "@contract/GET_PROCESS_INFORMATION_FAILURE",
)<
  {
    singleInvestment: number;
    periodicalInvestment: number;
    isin: string;
    currency: CurrencyCode;
    actualStep: NewContractStep;
  },
  ProcessInformationDto,
  Error
>();

function* getProcessInformation(
  action: ActionType<typeof getProcessInformationAsync.request>,
): Generator {
  try {
    const {
      singleInvestment,
      periodicalInvestment,
      isin,
      currency,
      actualStep,
    } = action.payload;
    const { response, error } = yield* safeApiCall(getUserContact);

    if (error) {
      yield put(getProcessInformationAsync.failure(error));
      return;
    }

    if (!response.contactInfo) {
      yield put(
        getProcessInformationAsync.failure(
          new ApplicationError(
            "Failed to fetch user contact information.",
            ErrorLevel.Error,
          ),
        ),
      );
      return;
    }

    const steps = getAllContractsStepsSet();
    const isPhoneVerified =
      response.contactInfo?.client?.isPhoneVerified ||
      response.contactInfo?.user.isPhoneVerified;

    if (isPhoneVerified) {
      steps.delete(NewContractStep.ContantVerification);
    }

    // bank id is avaible only for sufficent payments
    if (isKnownIsin(isin)) {
      const product = ProductsIsinDetail[isin];
      if (
        singleInvestment <
          product.bankId.minSingleInvestmentDeposit[currency] &&
        periodicalInvestment <
          product.bankId.minPeriodicalInvestmentDeposit[currency]
      ) {
        steps.delete(NewContractStep.PathSelection);

        // set step to first step after removing item
        if (actualStep === NewContractStep.PathSelection) {
          yield put(setContractStep({ step: NewContractStep.PersonalData }));
        }
      }
    }

    const stepsArray = Array.from(steps);

    yield put(
      getProcessInformationAsync.success({
        isPhoneEditable: !isPhoneVerified,
        steps: stepsArray,
        ...response.contactInfo,
      }),
    );
  } catch (err) {
    yield put(getProcessInformationAsync.failure(err as Error));
  }
}

export function* getProcessInformationSaga() {
  yield takeLatest(
    getType(getProcessInformationAsync.request),
    getProcessInformation,
  );
}
