import { BankIDProcessAudience } from "Api/Api";
import { BankIDCallback } from "Components/BankID/BankIDCallback";
import { useAppSelector } from "Hooks/useAppSelector";
import { Resources, useResource } from "Translations/Resources";
import {
  resolveBankIDCallbackRedirectRoute,
  resolveBankIDTerminateProcessRedirectRoute,
} from "Utils/BankIDUtils";
import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router";

const ComponentResources = Resources.BankID.Callback;

export const BankIDCallbackPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { process } = useAppSelector(s => s.bankID);

  const navigate = useNavigate();
  const handleBackClick = useCallback(
    () =>
      navigate(
        resolveBankIDTerminateProcessRedirectRoute(
          process?.audience ?? BankIDProcessAudience.ContractCreate,
        ),
      ),
    [process?.audience, navigate],
  );

  const handleError = useCallback(
    () =>
      navigate(
        resolveBankIDCallbackRedirectRoute(
          process?.audience ?? BankIDProcessAudience.ContractCreate,
        ),
      ),
    [process?.audience, navigate],
  );

  return (
    <BankIDCallback
      processAudience={
        process?.audience ?? BankIDProcessAudience.ContractCreate
      }
      translations={{
        title: t(ComponentResources.Title),
        description: t(ComponentResources.Description),
        backButton: t(ComponentResources.BackButton),
        error: t(Resources.BankID.Error.General),
      }}
      onBackClick={handleBackClick}
      onError={handleError}
    />
  );
};
