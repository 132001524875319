import { LookupItem } from "Models/LookupItem";
import * as React from "react";
import styled from "styled-components";
import {
  Select as MuiSelect,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { BackIcon, ExpandArrowIcon } from "Components/Shared/Icons";
import { useFieldError } from "Hooks/useFieldError";
import { CheckboxInput } from "Components/Shared/Inputs/BlCheckboxField/CheckboxInput";
import { FieldError } from "react-hook-form";
import { track } from "Utils/TrackingUtils";

type ValueType = LookupItem["Value"] | LookupItem["Value"][];

type Props<TValue extends ValueType = ValueType> = {
  name: string;
  options: LookupItem<TValue>[];
  value: TValue;
  defaultValue?: TValue;
  onChange?: (value: TValue) => void;
  onBlur?: () => void;
  disabled?: boolean;
  multiple?: boolean;
  label?: string;
  placeholder?: string;
  allSelectedText?: string;
  hasError?: boolean;
  fieldError?: FieldError;
};

const StyledSelect = styled(MuiSelect)`
  &:before,
  &:after {
    content: none;
  }

  .MuiSelect-select {
    padding-bottom: ${props => props.theme.spacing(1)};
    color: ${props => props.theme.palette.text.primary};
    background-color: none;
    font-size: ${props => props.theme.typography.body2.fontSize}px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover &:-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px
        ${props => props.theme.palette.background.default} inset;
      color: ${props => props.theme.palette.text.primary};
    }
    &::placeholder {
      color: ${props => props.theme.palette.text.secondary};
      opacity: 0.3;
    }

    > div {
      padding-top: 2px;
    }
  }

  &:hover {
    .MuiSelect-select {
      border-color: ${props => props.theme.palette.background.default};
    }
  }

  &.Mui-focused {
    .MuiSelect-select {
      border-color: ${props => props.theme.palette.primary.main};
    }
  }

  &.Mui-error {
    .MuiSelect-select {
      border-color: ${props => props.theme.palette.error.main};
    }
  }

  &.Mui-disabled {
    .MuiSelect-select {
      background-color: ${props => props.theme.colors.gray}80;
      border-color: ${props => props.theme.colors.gray}80;
      color: ${props => props.theme.palette.text.primary}80;
    }
  }
`;

const StyledIcon = styled(BackIcon)`
  position: absolute;
  right: ${props => props.theme.spacing(4)};
  pointer-events: none;
  rotate: 270deg;
  width: 20px;
`;
const dropdownIcon = () => {
  return (
    <StyledIcon>
      <ExpandArrowIcon></ExpandArrowIcon>
    </StyledIcon>
  );
};

const StyledPlaceholder = styled.div`
  color: ${props => props.theme.palette.secondary.main};
  opacity: 0.3;
  font-weight: 400;
`;

const StyledMenuItem = styled(MenuItem)`
  .MuiMenuItem-root {
    padding: 0;
  }

  .MuiTouchRipple-root {
    color: ${props => props.theme.palette.primary.main};
  }

  .MuiCheckbox-root {
    padding: 0;
  }
`;

const StyledSelectedValue = styled.div<{ upperCase?: boolean }>`
  overflow: hidden;
  margin-right: 22px;
  text-overflow: ellipsis;
  text-transform: ${props => (props.upperCase ? "uppercase" : "none")};
  min-height: 14px;
  font-size: ${props => props.theme.typography.body2.fontSize}px;
`;

const StyledFormControl = styled(FormControl)`
  margin: ${props => props.theme.spacing(1, 0)};

  .MuiInputBase-root {
    border: 1px solid ${props => props.theme.colors.border}80;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    padding: ${props => props.theme.spacing(1)};

    &.Mui-error {
      border-color: ${props => props.theme.palette.error.main};
    }

    &::before {
      border-bottom: none;
    }
    &::after {
      border-bottom: none;
    }

    .MuiInputLabel-root {
      font-size: 18px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.colors.border};
    }

    .MuiOutlinedInput-multiline {
      padding: 0;
    }

    .MuiFormHelperText-root {
      margin: 0;
      min-height: 20px;
    }

    &.Mui-disabled {
      border-color: ${props => props.theme.colors.border}40;
      &.MuiInputBase-formControl {
        background-color: unset;

        .MuiInputBase-input {
          color: ${props => props.theme.palette.text.primary};
          background-color: unset;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.colors.border};
        }
      }
    }
  }

  .MuiInputLabel-root {
    top: ${props => props.theme.spacing(1)};
    left: ${props => props.theme.spacing(1)};
    color: ${props =>
      props.error
        ? props.theme.palette.error.main
        : props.theme.palette.text.primary};
    opacity: 0.9;

    &.Mui-disabled {
      opacity: 0.5;
    }
  }

  .MuiFormHelperText-root {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    color: ${props => props.theme.palette.error.main};
    margin: ${props => props.theme.spacing(props.error ? 1 : 2, 0, 1, 0)};
  }
`;

function selectedValue<TValue extends ValueType = ValueType>(
  value: TValue,
  props: Props<TValue>,
): React.ReactNode {
  const { placeholder, multiple, options, allSelectedText } = props;

  if (!value || (value as Array<string | number>).length === 0) {
    return placeholder ? (
      <StyledPlaceholder>{placeholder}</StyledPlaceholder>
    ) : (
      <StyledSelectedValue></StyledSelectedValue>
    );
  }

  if (multiple && (value as Array<string | number>).length === options.length) {
    return (
      <StyledSelectedValue upperCase>{allSelectedText}</StyledSelectedValue>
    );
  }
  const text = multiple
    ? options
        .filter(o => (value as number[]).some(v => v === o.Value))
        .map(x => x.Name)
        .join(", ")
    : options.find(x => x.Value?.toString() === value.toString())?.Name;

  return <StyledSelectedValue>{text}</StyledSelectedValue>;
}

export function Dropdown<TValue extends ValueType = ValueType>(
  props: Props<TValue>,
) {
  const { hasError, errorMessage, originalProps } = useFieldError(props);

  const { options, value, disabled, name, onChange, multiple, label, onBlur } =
    originalProps;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    console.log("Dropdown handleChange", event.target);

    track({
      category: "Dropdown",
      event: "Change",
      action: "Click",
      value: (event.target.value as string) ?? "",
      tag: name,
    });
    onChange?.(event.target.value as TValue);
  };

  return (
    <StyledFormControl fullWidth error={hasError} disabled={props.disabled}>
      {label && (
        <InputLabel shrink variant="filled">
          {label}
        </InputLabel>
      )}
      <StyledSelect
        value={value}
        defaultValue={props.defaultValue}
        onChange={handleChange}
        onBlur={onBlur}
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={dropdownIcon}
        disabled={disabled}
        multiple={multiple}
        error={hasError}
        fullWidth
        displayEmpty
        MenuProps={{
          variant: "menu",
          style: {
            maxHeight: "60%",
            minHeight: "160px",
          },
        }}
        renderValue={value => selectedValue<TValue>(value as TValue, props)}
        variant="filled"
      >
        {options.map(opt => (
          <StyledMenuItem
            key={`${name}-${opt.Value}`}
            value={String(opt.Value)}
          >
            {multiple ? (
              <CheckboxInput
                checked={(value as number[]).some(x => x === opt.Value)}
                label={opt.Name}
              />
            ) : (
              <span>{opt.Name}</span>
            )}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </StyledFormControl>
  );
}
