import { Stack, Typography } from "@mui/material";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { BooleanRadioButtons } from "Components/Shared/Inputs/CommonInputs/BooleanRadioButtons";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";

export type PoliticallyExposedFieldsProps = {
  isPoliticallyExposedReadonly?: boolean;
  isPoliticallyExposedDescriptionReadonly?: boolean;
};

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

export const PoliticallyExposed: FunctionComponent<
  PoliticallyExposedFieldsProps
> = ({
  isPoliticallyExposedReadonly = false,
  isPoliticallyExposedDescriptionReadonly = false,
}) => {
  const { t } = useResource();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<AMLQuestionsFormModel>();

  const isPoliticallyExposed = watch("politicallyExposed");

  return (
    <div>
      <BooleanRadioButtons
        label={
          <>
            {t(ComponentResources.PoliticallyExposed)}
            <BlInfoPopper>
              {t(ComponentResources.PoliticallyExposedPopper)}
            </BlInfoPopper>
          </>
        }
        control={control}
        errors={errors}
        name="politicallyExposed"
        disabled={isPoliticallyExposedReadonly}
      />

      {!!isPoliticallyExposed && (
        <Stack gap={2}>
          <Typography>
            {t(ComponentResources.PoliticallyExposedDescription)}
          </Typography>
          <BlInputFormField
            control={control}
            errors={errors}
            name="politicallyExposedDescription"
            variant="standard"
            multiline
            minRows={3}
            disabled={isPoliticallyExposedDescriptionReadonly}
          />
        </Stack>
      )}
    </div>
  );
};
