import {
  GetBankAccountsStateType,
  getBankAccountsReducer,
  getBankAccountsSaga,
  getBankAccountsState,
} from "State/InvestmentsDetail/BankAccounts/BankAccountsState";
import {
  cashMovementsReducer,
  watchCashMovementsSaga,
  CashMovementsState,
  initialCashMovementsState,
} from "State/InvestmentsDetail/CashMovements/CashMovementsState";
import {
  GetDealerStateType,
  getDealerReducer,
  getDealerSaga,
  getDealerState,
} from "State/InvestmentsDetail/Dealer/GetDealerState";
import {
  GetDetailStateType,
  getDetailReducer,
  getDetailSaga,
  getDetailState,
} from "State/InvestmentsDetail/Detail/GetDetailState";
import {
  GetOverviewStateType,
  getOverviewReducer,
  getOverviewSaga,
  getOverviewState,
} from "State/InvestmentsDetail/Overview/GetOverviewState";
import {
  watchTransactionsSaga,
  transactionsReducer,
  TransactionsState,
  initialTransactionsState,
} from "State/InvestmentsDetail/Transactions/TransactionsState";
import {
  GetUnsettledTradesStateType,
  getUnsettledTradesReducer,
  getUnsettledTradesSaga,
  getUnsettledTradesState,
} from "State/InvestmentsDetail/UnsettledTrades/GetUnsettledTradesState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type InvestmentsDetailState = {
  overview: GetOverviewStateType;
  cashMovements: CashMovementsState;
  dealer: GetDealerStateType;
  bankAccounts: GetBankAccountsStateType;
  transactions: TransactionsState;
  unsettledTrades: GetUnsettledTradesStateType;
  detail: GetDetailStateType;
};

export const getInitialState = (): InvestmentsDetailState => ({
  overview: getOverviewState(),
  cashMovements: initialCashMovementsState,
  dealer: getDealerState(),
  bankAccounts: getBankAccountsState(),
  transactions: initialTransactionsState,
  unsettledTrades: getUnsettledTradesState(),
  detail: getDetailState(),
});

export function* watchInvestmentsDetailSaga() {
  yield all([
    getOverviewSaga(),
    watchCashMovementsSaga(),
    getDealerSaga(),
    getBankAccountsSaga(),
    watchTransactionsSaga(),
    getUnsettledTradesSaga(),
    getDetailSaga(),
  ]);
}

export const investmentsDetailReducer = combineReducers<InvestmentsDetailState>(
  {
    overview: getOverviewReducer,
    cashMovements: cashMovementsReducer,
    dealer: getDealerReducer,
    bankAccounts: getBankAccountsReducer,
    transactions: transactionsReducer,
    unsettledTrades: getUnsettledTradesReducer,
    detail: getDetailReducer,
  },
);
