import { GetExchangeRateQueryResult, getExchangeRate } from "Api/Api";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type GetExchangeRateStateType =
  FetchStateType<GetExchangeRateQueryResult>;

export const getExchangeRateState = (): GetExchangeRateStateType =>
  getFetchStateDefaultValue();

export type GetExchangeRateActionType =
  | ActionType<typeof getExchangeRateAsync>
  | ActionType<typeof resetGetExchangeRateState>;

export const resetGetExchangeRateState = createAction(
  "@contract/RESET_EXCHANGE_RATE_STATE",
)();

export const resetGetExchangeRateError = createAction(
  "@contract/RESET_EXCHANGE_RATE_ERROR",
)();

export const getExchangeRateAsync = createAsyncAction(
  "@exchangeRate/GET_EXCHANGE_RATE_REQUEST",
  "@exchangeRate/GET_EXCHANGE_RATE_SUCCESS",
  "@exchangeRate/GET_EXCHANGE_RATE_FAILURE",
)<
  {
    currencyFrom: string;
    currencyTo: string;
  },
  GetExchangeRateQueryResult,
  Error
>();

function* getExchangeRateFunc(
  action: ActionType<typeof getExchangeRateAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      getExchangeRate,
      action.payload.currencyFrom,
      action.payload.currencyTo,
    );

    if (!!error) {
      yield put(getExchangeRateAsync.failure(error));
      return;
    }

    yield put(getExchangeRateAsync.success(response));
  } catch (err) {
    yield put(getExchangeRateAsync.failure(err as Error));
  }
}

export function* getExchangeRateSaga() {
  yield takeLeading(getType(getExchangeRateAsync.request), getExchangeRateFunc);
}

export const getExchangeRateReducer = createReducer<
  GetExchangeRateStateType,
  GetExchangeRateActionType
>(getExchangeRateState())
  .handleAction(getExchangeRateAsync.request, handleActionRequest)
  .handleAction(getExchangeRateAsync.failure, handleActionFailure)
  .handleAction(getExchangeRateAsync.success, handleActionSuccess)
  .handleAction(resetGetExchangeRateState, () => getExchangeRateState());
