import { FunctionComponent } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: none;
`;

export const HiddenSubmitButton: FunctionComponent = () => {
  return <StyledButton type="submit" hidden aria-hidden />;
};
