import * as React from "react";
export const BeachIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_22_1941)">
        <path
          d="M9.26562 0C5.91016 0 3.5 2.87695 3.5 6.54688C5.125 2.71875 9.95508 2.26172 12 4.35938C8.33008 3.93945 3.5 5.71484 3.5 10.75C3.5 12.6367 3.92383 14.373 5.07812 16.7344C5.96875 8.5 12.0508 7.17969 14.5156 6.8125C19.1836 6.8125 22.7949 10.3262 22.375 16.6719C24.1055 14.416 25 12.8926 25 10.9531C25 6.02344 17.7031 4.25195 15.3438 4.82812C15.6055 4.30469 16.8691 3.19336 18.5469 3.14062C20.1191 3.14062 22.584 3.83203 24.1562 5.5625C23.4746 1.26172 21.5449 0 19.5 0C16.9297 0 14.5156 2.04688 14.5156 2.04688C13.0996 0.525391 10.8379 0 9.26562 0ZM14.5781 7.8125C13.832 7.92773 13.1504 8.10156 12.5 8.3125C12.3145 18.3086 7.85938 21.625 5.23438 22.7188C6.05078 22.8828 6.97852 23 8 23C9.76367 23 11.293 22.5059 12.7812 22.0312C13.2793 21.873 13.7949 21.7227 14.3125 21.5781C15.7109 17.7324 15.9707 11.4277 16 7.95312C15.5488 7.86523 15.0664 7.81641 14.5781 7.8125ZM0.53125 22C0.441406 21.9941 0.347656 22.0195 0.265625 22.0625C0.101562 22.1484 0 22.3145 0 22.5V24.5C0 24.7773 0.224609 25 0.5 25H24.5C24.7188 25 24.9199 24.8516 24.9844 24.6406C25.0488 24.4297 24.9629 24.2168 24.7812 24.0938C24.6543 24.0078 21.6602 22 18 22C16.1895 22 14.6172 22.498 13.0938 22.9844C11.5352 23.4824 9.91797 24 8 24C3.58008 24 0.808594 22.1133 0.78125 22.0938C0.705078 22.041 0.621094 22.0059 0.53125 22Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1941">
          <rect width={25} height={25} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
