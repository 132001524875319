import { useForm } from "react-hook-form";
import { Box, Link, Typography } from "@mui/material";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import styled from "styled-components";

import { FullWidthDiv } from "Components/Shared/StyledComponents";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { TextButton } from "Components/Shared/Buttons/TextButton";

import { EmailVerificationCommand } from "Api/Api";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Resources, useResource } from "Translations/Resources";
import useHandleServerError from "Hooks/useHandleServerError";
import { useAppSelector } from "Hooks/useAppSelector";

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(2)};
`;

const StyledServerError = styled(Typography)`
  color: ${props => props.theme.colors.red};
`;

export type FormModel = Pick<EmailVerificationCommand, "code">;

type Props = {
  resend(): void;
  onSubmit(data: FormModel): void;
  serverError: Error | null;
  email: string;
};

const PageResources = Resources.SignUp.EmailVerification;

export function VerificationForm({
  resend,
  onSubmit,
  serverError,
  email,
}: Props) {
  const { t } = useResource();
  const schema = object<FormModel>({
    code: string()
      .matches(/^.{6}$/)
      .required(),
  });

  const isLoading = useAppSelector(s => s.auth.isLoading);

  const form = useForm<FormModel>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  useHandleServerError({
    form,
    serverError,
    resource: PageResources.Error,
    key: "code",
  });

  return (
    <>
      <div>
        <Typography component="h2" variant="h2">
          {t(PageResources.VerifyEmail)}
        </Typography>
        <Typography paddingTop={2}>
          {t(PageResources.EmailSent.PartOne)}

          <Link
            target="_blank"
            href={"mailto:" + email}
            fontWeight={600}
            color="secondary"
          >
            {email}
          </Link>

          {t(PageResources.EmailSent.PartTwo)}
        </Typography>
        <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FullWidthDiv>
            <BlInputFormField
              autoFocus
              control={control}
              name="code"
              errors={errors}
              label={t(PageResources.Code)}
              mask="***-***"
              maskPlaceholder="_"
              isMaskLazy={false}
            />

            <StyledServerError variant="subtitle1">
              {/*serverError*/}
            </StyledServerError>

            <Box display="flex" flexWrap="wrap" paddingTop={2} gap={1}>
              <Typography>{t(PageResources.ResendCode.Text)}</Typography>
              <TextButton color="secondary" onClick={resend}>
                {t(PageResources.ResendCode.Link)}
              </TextButton>
            </Box>
          </FullWidthDiv>
        </StyledForm>
      </div>
      <PrimaryButton
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
}
