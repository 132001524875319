import { Resources, useResource } from "Translations/Resources";

const HookResources = Resources.Products.Descriptions;

export const useProductDescription = (isin: string | null | undefined) => {
  const { t } = useResource();

  switch (isin) {
    case "CZ0008474830":
    case "CZ0008475902":
    case "CZ0008477650":
    case "CZ0008477676":
      const { Description } = HookResources[isin];
      return t(Description);
    default:
      return null;
  }
};
