import { Stack } from "@mui/material";
import { ContractEditForm } from "Components/ContractEdit/ContractEditForm";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { resetContractEditState } from "State/Contracts/Edit/ContractEditState";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router";
import { useEffectOnce } from "react-use";

const PageResources = Resources.Contract.Edit;

export const EditPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();

  const {
    isLoading: isLoadingOverview,
    data: overview,
    error: overviewError,
  } = useAppSelector(state => state.investmentsDetail.overview);
  const { error: renameError } = useAppSelector(s => s.contracts.contract.edit);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const contractID = Number.parseInt(params.id as string);

  useEffectOnce(() => {
    dispatch(resetContractEditState());
    dispatch(getOverviewAsync.request(contractID));
  });

  const isLoading = isLoadingOverview && !overview;

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      headerChildren={
        !!overview?.isDip && !isDesktop ? <DipTagWrapper inverse /> : undefined
      }
      onBackClick={() =>
        navigate(getPath(AppRouting.ContractDetail, contractID))
      }
    >
      <LoadingWrapper
        isLoading={isLoading}
        error={overviewError ?? renameError}
        skeleton={
          <>
            <Stack gap={2}>
              <PrimarySkeleton fullWidth fullHeight={false} height={100} />
              <PrimarySkeleton fullWidth fullHeight={false} height={100} />
            </Stack>
            <PrimarySkeleton fullWidth fullHeight={false} height={60} />
          </>
        }
      >
        {contractID && overview && (
          <ContractEditForm contractID={contractID} {...overview} />
        )}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};
