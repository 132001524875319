import { Typography } from "@mui/material";
import { DefaultBankAccountDto } from "Api/Api";
import { WarningText } from "Components/Shared/WarningText";
import { type FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  showWithoutBankAccount?: boolean;
  withMargin?: boolean;
  showRequireMessage?: boolean;
  defaultBankAccount?: DefaultBankAccountDto;
};

export const BankAccountWarning: FunctionComponent<Props> = ({
  withMargin,
  showRequireMessage,
  defaultBankAccount,
}) => {
  const { t } = useResource();

  const text = !defaultBankAccount
    ? t(Resources.Contract.NewContract.Shared.BankAccountWarning.WithoutAccount)
    : t(Resources.Contract.NewContract.Shared.BankAccountWarning.WithAccount, {
        value:
          defaultBankAccount.bankCountry !== "CZ"
            ? defaultBankAccount.iban
            : [defaultBankAccount?.accountNumber, defaultBankAccount?.bankCode]
                .filter(Boolean)
                .join("/") || "-",
      });

  return (
    <WarningText marginTop={withMargin ? 8 : undefined}>
      <Typography component="span">{text}</Typography>
      {showRequireMessage && (
        <Typography component="span">
          {" "}
          {t(
            Resources.Contract.NewContract.Shared.BankAccountWarning
              .RequireMessage,
          )}
        </Typography>
      )}
    </WarningText>
  );
};
