import { EditPersonalDataVerificationType } from "Api/Api";
import { PathSelectionFormModel } from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { Resources, useResource } from "Translations/Resources";
import { ObjectSchema, object, mixed } from "yup";

export const useYupFormSchema = (): ObjectSchema<PathSelectionFormModel> => {
  const { t } = useResource();

  return object<PathSelectionFormModel>().shape({
    verificationType: mixed<EditPersonalDataVerificationType>()
      .oneOf(
        Object.values(EditPersonalDataVerificationType),
        t(Resources.Contract.NewContract.PathSelection.Validation.Required),
      )
      .required(
        t(Resources.Contract.NewContract.PathSelection.Validation.Required),
      ),
  });
};
