import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";

import { AppRouting, getPath } from "Utils/UrlUtils";
import { type FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { Resources, useResource } from "Translations/Resources";
import { SignatureStep } from "Components/ContractUninvestedDeposits/SignatureStep";
import { useAppSelector } from "Hooks/useAppSelector";
import { SuccessStep } from "Components/ContractUninvestedDeposits/SuccessStep";
import {
  InvestUninvestedDepositsStep,
  resetInvestUninvestedDepositsState,
} from "State/Contracts/UninvestedDeposits/UninvestedDepositsReducer";
import { ConfigurationStep } from "Components/ContractUninvestedDeposits/ConfigurationStep";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useDesktop } from "Hooks/useDesktop";
import { useInvestUninvestedDepositsTitle } from "Hooks/Contract/useInvestUninvestedDepositsTitle";

const PageResources = Resources.Contract.Detail.Purchase;

export const InvestUninvestedDepositsPage: FunctionComponent = _ => {
  const navigate = useNavigate();
  const params = useParams();
  const contractID = Number.parseInt(params.id as string);

  const { t } = useResource();
  const { step } = useAppSelector(s => s.contracts.uninvestedDeposits);
  const { isDesktop } = useDesktop();

  const dispatch = useAppDispatch();
  const [selectedProductIsin, setSelectedProductIsin] = useState<string | null>(
    null,
  );

  const pageTitle = useInvestUninvestedDepositsTitle();
  const isTitleShown = !isDesktop && !!pageTitle;

  useEffect(() => {
    dispatch(resetInvestUninvestedDepositsState());
  }, [dispatch]);
  return (
    <AuthenticatedLayout
      title={t(PageResources.Unlimited.Title)}
      isTitleShown={isTitleShown}
      titleComponent={pageTitle}
      productIsin={selectedProductIsin ?? undefined}
      onBackClick={() =>
        navigate(getPath(AppRouting.ContractDetail, contractID))
      }
    >
      {step === InvestUninvestedDepositsStep.Configuration && (
        <ConfigurationStep
          contractID={contractID}
          onSelectedProductChange={(productIsin: string | null) =>
            setSelectedProductIsin(productIsin)
          }
        />
      )}

      {step === InvestUninvestedDepositsStep.Signature && (
        <SignatureStep contractID={contractID} />
      )}

      {step === InvestUninvestedDepositsStep.Success && (
        <SuccessStep contractID={contractID} />
      )}
    </AuthenticatedLayout>
  );
};

export default InvestUninvestedDepositsPage;
