import {
  SignContractCommandResult,
  SmsSignDto,
  putSignatureSms,
} from "Api/Api";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import { safeApiCall } from "State/Utils";
import { setToken } from "Utils/LocalStorageUtils";

import { put, throttle } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType,
} from "typesafe-actions";

export type SignWithSmsStateType = FetchStateType<SignContractCommandResult>;

export const signWithSmsState = (): SignWithSmsStateType =>
  getFetchStateDefaultValue();

export type PutSignatureSmsActionType = ActionType<typeof signWithSmsSmsAsync>;

export const resetSignWithSmsState = createAction(
  "@contract/RESET_SIGN_WITH_SMS_STATE",
)();

export const resetSignWithSmsError = createAction(
  "@contract/RESET_SIGN_WITH_SMS_ERROR",
)();

export const signWithSmsSmsAsync = createAsyncAction(
  "@contract/SIGN_WITH_SMS_REQUEST",
  "@contract/SIGN_WITH_SMS_SUCCESS",
  "@contract/SIGN_WITH_SMS_FAILURE",
)<
  {
    code: string;
    onSuccess: () => void;
    onError?: () => void;
  } & SmsSignDto,
  SignContractCommandResult,
  Error
>();

function* signWithSms(
  action: ActionType<typeof signWithSmsSmsAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      putSignatureSms,
      action.payload,
    );

    if (!!error) {
      yield put(signWithSmsSmsAsync.failure(error));

      if (!!action.payload.onError) {
        action.payload.onError();
      }

      return;
    }

    if (!!response.signInResult) {
      setToken(response.signInResult);
    }

    action.payload.onSuccess();

    yield put(signWithSmsSmsAsync.success(response));
  } catch (err) {
    yield put(signWithSmsSmsAsync.failure(err as Error));
  }
}

export function* signWithSmsSaga() {
  yield throttle(5000, getType(signWithSmsSmsAsync.request), signWithSms);
}
