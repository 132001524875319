import { AppData } from "Api/Api";

export type AppUser = {
  login: string;
  accessRightCodes: string[];
  hasAgreedToTermsAndConditionsOfMobileApp: boolean;
  isUnderage: boolean;
  profilePicture: string | null;
  appData: AppData;
  roles: string[];
};

export enum SignInStep {
  Credentials,
  TwoFactorVerificationToken,
}

export enum ResetPasswordStep {
  Email,
  SetPassword,
}
