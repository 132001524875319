import { ContantVerificationFormModel } from "State/Contracts/Create/Models";
import { object, ObjectSchema, string } from "yup";

export const useYupFormSchema =
  (): ObjectSchema<ContantVerificationFormModel> => {
    return object<ContantVerificationFormModel>().shape({
      code: string()
        .matches(/^.{4}$/)
        .required(),
    });
  };
