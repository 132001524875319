import { CheckClientExistsQueryResultStatus } from "Api/Api";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
} from "Constants/Countries";
import { useIsLead } from "Hooks/User/useIsLead";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  clearExistence,
  getExistenceAsync,
} from "State/Client/GetExistence/GetExistenceState";
import { setDuplicityVerified } from "State/Contracts/Create/CreateState";
import { track } from "Utils/TrackingUtils";
import { useCallback, useEffect, useRef, useState } from "react";

export const useDuplicityValidation = (
  personalIdentificationNumber?: string,
  citizenship?: string,
) => {
  const _personalIdentificationNumber = useRef(personalIdentificationNumber);
  const _citizenship = useRef(citizenship);
  const duplicityLogged = useRef(false);

  const { data: processInformation } = useAppSelector(
    s => s.contracts.create.processInformation,
  );
  const { isLoading, data, error } = useAppSelector(s => s.client.existence);
  const isDuplicityVerified = useAppSelector(
    s => s.contracts.create.isDuplicityVerified,
  );
  const dispatch = useAppDispatch();

  const [isValidated, setIsValidated] = useState(isDuplicityVerified);

  const isDuplicity =
    data?.status === CheckClientExistsQueryResultStatus.ClientExists;

  const isLead = useIsLead() ?? true;

  const existence = isLead && isDuplicity && isValidated;

  useEffect(() => {
    _personalIdentificationNumber.current = personalIdentificationNumber;
  }, [personalIdentificationNumber, dispatch]);

  useEffect(() => {
    _citizenship.current = citizenship;
  }, [citizenship, dispatch]);

  const validate = useCallback(() => {
    if (!isLead) {
      setIsValidated(true);
      return;
    }

    if (processInformation?.hasAnyContract) {
      setIsValidated(true);
      return;
    }

    dispatch(setDuplicityVerified(false));

    if (
      _personalIdentificationNumber.current &&
      (_citizenship.current?.includes(CZECHIA_COUNTRY_CODE) ||
        _citizenship.current?.includes(SLOVAKIA_COUNTRY_CODE))
    ) {
      dispatch(
        getExistenceAsync.request({
          citizenship: _citizenship.current,
          personalIdentificationNumber: _personalIdentificationNumber.current,
        }),
      );
    } else {
      dispatch(clearExistence());
      dispatch(setDuplicityVerified(true));
    }

    setIsValidated(true);
  }, [isLead, dispatch, processInformation]);

  useEffect(() => {
    return () => {
      dispatch(clearExistence());
    };
  }, [dispatch]);

  useEffect(() => {
    if (existence && !duplicityLogged.current) {
      duplicityLogged.current = true;
      track({
        category: "User",
        event: "DoubleClient",
      });
    }
  }, [existence]);

  return {
    isLoading,
    existence,
    disabledSubmit: (isLead && isDuplicity) || !!error || !isValidated,
    validate,
  };
};
