import { FeeRateProps, useFeeRate } from "Hooks/ProductFeeRate/useFeeRate";
import { Resources, useResource } from "Translations/Resources";

export const usePeriodicalInvestmentFeeRate = (props: FeeRateProps) => {
  const { t } = useResource();
  const { data, isLoading, error } = useFeeRate(props, "periodical");

  const part1 = t(Resources.Forms.Investments.PeriodicalInvestmentFeePart1);

  const part2 = t(Resources.Forms.Investments.PeriodicalInvestmentFeePart2, {
    value: data?.feeRate ?? 0,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return {
    data,
    isLoading,
    error,
    periodicalInvestmentFeeRateLabel: `${part1} ${part2}`,
    feeRate: data?.feeRate,
  };
};
