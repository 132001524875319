import {
  contractBankConnectionsReducer,
  ContractBankConnectionsState,
  watchContractBankConnectionsSaga,
} from "State/Contracts/Contract/BankConnections/ContractBankConnectionsReducer";
import {
  GetPaymentInstructionsStateType,
  getPaymentInstructionsReducer,
  getPaymentInstructionsSaga,
} from "State/Contracts/Contract/PaymentInstructions/GetPaymentInstructionsState";
import {
  contractEditReducer,
  ContractEditState,
  watchContractEditSaga,
} from "State/Contracts/Edit/ContractEditState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type ContractState = {
  paymentInstruction: GetPaymentInstructionsStateType;
  bankConnections: ContractBankConnectionsState;
  edit: ContractEditState;
};

export function* watchContractSagas() {
  yield all([
    getPaymentInstructionsSaga(),
    watchContractBankConnectionsSaga(),
    watchContractEditSaga(),
  ]);
}

export const contractReducer = combineReducers<ContractState>({
  paymentInstruction: getPaymentInstructionsReducer,
  bankConnections: contractBankConnectionsReducer,
  edit: contractEditReducer,
});
