import { type SelectProps } from "@mui/material";
import { StyledFormControl } from "Components/Shared/FormStyles";
import { Dropdown } from "Components/Shared/Inputs/Dropdown";
import { LookupItem } from "Models/LookupItem";
import get from "lodash-es/get";
import {
  Control,
  Controller,
  type FieldErrors,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";

type Props<FormType extends FieldValues> = SelectProps & {
  name: FieldPath<FormType>;
  label?: string;
  control: Control<FormType, object>;
  errors: FieldErrors<FormType>;
  formInfo?: { [key: string]: string };
  disabled?: boolean;
  options: LookupItem[];
};

export const DropdownInput = <T extends object>(props: Props<T>) => {
  const { name, control, errors, formInfo, label, disabled, ...rest } = props;

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <StyledFormControl>
              <Dropdown
                name={name}
                // @ts-ignore
                value={value}
                key={value}
                label={label}
                fieldError={get(errors, name as string)}
                disabled={disabled}
                //onBlur={onBlur}
                //onMaskInputChange={v => {
                //  onChange(isNumber ? Number(v) : v);
                //}}
                // @ts-ignore
                onChange={onChange}
                {...rest}
              />
            </StyledFormControl>
          );
        }}
      />
    </>
  );
};
