import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { PhoneInput } from "Components/Shared/Inputs/SpecificInputs/PhoneInput";
import { FunctionComponent, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { ContactInfoFormModel } from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources =
  Resources.More.PersonalData.EditPersonalData.Modelling.ContactInfo;

export const ContactInformationForm: FunctionComponent = _ => {
  const { t } = useResource();

  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<ContactInfoFormModel>();

  const handleEmailChanged = useCallback(
    (x: any) => {
      setValue("contactInfo.email", String(x), {
        shouldValidate: true,
      });
      trigger("contactInfo.phone");
    },
    [trigger, setValue],
  );

  return (
    <InputHeaderWrapper header={t(ComponentResources.Header)}>
      <PhoneInput control={control} name="contactInfo.phone" errors={errors} />
      <BlInputFormField
        control={control}
        name="contactInfo.email"
        label={t(ComponentResources.Email)}
        onChange={handleEmailChanged}
        errors={errors}
      />
    </InputHeaderWrapper>
  );
};
