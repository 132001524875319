import { CashMovementsFiltersFormModel } from "State/InvestmentsDetail/CashMovements/CashMovementsState";
import { boolean, object, ObjectSchema, string } from "yup";

export const useYupFormSchema =
  (): ObjectSchema<CashMovementsFiltersFormModel> => {
    return object<CashMovementsFiltersFormModel>().shape({
      includeFees: boolean().optional(),
      includeDeposits: boolean().optional(),
      includeWithdrawals: boolean().optional(),
      dateFrom: string().optional(),
      dateTo: string().optional(),
    });
  };
