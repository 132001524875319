import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";

export interface HandleFormSubmit {
  submit(): void;
}

type FormModel = {
  PIN: string;
};

type Props = {
  label: string;
  originalPIN?: string | null;
  translations: {
    mismatch: string;
  };
  onSuccess?: () => void;
};

const PersonalIdentificationNumberValidatorComponent: ForwardRefRenderFunction<
  HandleFormSubmit,
  Props
> = ({ label, originalPIN, translations: { mismatch }, onSuccess }, ref) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<FormModel>();

  const onSubmit = useCallback(
    (dataForm: FormModel) => {
      if (originalPIN && originalPIN === dataForm.PIN) {
        onSuccess?.();
      } else {
        setError("PIN", {
          message: mismatch,
        });
      }
    },
    [mismatch, originalPIN, onSuccess, setError],
  );

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(onSubmit)(),
  }));

  return (
    <BlInputFormField
      control={control}
      errors={errors}
      name="PIN"
      isMaskLazy={false}
      label={label}
      mask={/^.+$/}
    />
  );
};

export const PersonalIdentificationNumberValidator = forwardRef(
  PersonalIdentificationNumberValidatorComponent,
);
