import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { WebViewMessage, WebViewMessageTypes } from "Models/WebViewModels";
import { safeApiCall } from "State/Utils";
import {
  SignUpBiometricsCommandResult,
  SignUpBiometricsCommandResultStatus,
  postAuthSignUpBiometrics,
} from "Api/Api";
import { ApplicationError, ErrorLevel } from "Models/Errors/ApplicationError";
import { postMessageFromWebView } from "Utils/WebViewUtils";
import { logError } from "ErrorService";
import { select } from "typed-redux-saga";
import { RootStateType } from "State/Store";
import { NavigateFunction } from "react-router";
import { setSettingBiometricsInProgress } from "State/Biometrics/BiometricsActions";

export const signUpBiometricsAsync = createAsyncAction(
  "@auth/SIGN_UP_BIOMETRICS_REQUEST",
  "@auth/SIGN_UP_BIOMETRICS_SUCCESS",
  "@auth/SIGN_UP_BIOMETRICS_FAILURE",
)<
  Extract<
    WebViewMessage,
    { type: WebViewMessageTypes.BIOMETRIC_SIGN_UP_RESPONSE }
  >["payload"] & {
    navigate: NavigateFunction;
  },
  SignUpBiometricsCommandResult,
  Error
>();

function* signUpBiometrics(
  action: ReturnType<typeof signUpBiometricsAsync.request>,
): Generator {
  try {
    if (action.payload.isSuccess === false) {
      yield put(setSettingBiometricsInProgress(false));

      yield put(
        signUpBiometricsAsync.failure(
          new ApplicationError(
            "Biometrics sign up failed.",
            ErrorLevel.Critical,
            {
              payload: action.payload,
            },
            null,
            true
          ),
        ),
      );
      return;
    }

    const { response, error } = yield* safeApiCall(postAuthSignUpBiometrics, {
      challenge: action.payload.challenge,
      id: action.payload.id,
      publicKey: action.payload.publicKey,
      signedChallenge: action.payload.signedChallenge,
    });

    if (error) {
      yield put(setSettingBiometricsInProgress(false));
      yield put(signUpBiometricsAsync.failure(error));
      console.error("biometricSignUpVerifyAuthentication error", error);
      logError(
        new Error(`biometricsSignUpVerifyAuthentication error: ${error}`),
        {
          response,
          error,
          actionPayload: action.payload,
        },
      );
      return;
    }
    const isSuccess =
      response.status === SignUpBiometricsCommandResultStatus.Success;

    if (!isSuccess) {
      logError(
        new Error(
          `biometricsSignUpVerifyAuthentication status error: ${error}`,
        ),
        {
          response,
          error,
          actionPayload: action.payload,
        },
      );
    }

    postMessageFromWebView({
      type: WebViewMessageTypes.BIOMETRIC_SIGN_UP_RESULT_REQUEST,
      payload: {
        id: action.payload.id,
        isSuccess,
      },
    });

    const redirectUrl = yield select(
      (s: RootStateType) => s.biometrics.signUp.redirectUrl,
    );

    if (isSuccess && !!redirectUrl) {
      action.payload.navigate(redirectUrl);
    }

    yield put(signUpBiometricsAsync.success(response));
    console.log("Biometrics sign up successful");
  } catch (err: any) {
    yield put(setSettingBiometricsInProgress(false));
    console.error(err);
    logError(
      err,
      {
        actionPayload: action.payload,
        error: err,
      },
      false,
    );
    yield put(signUpBiometricsAsync.failure(err as Error));
  }
}
export function* signUpBiometricsSaga() {
  yield takeLatest(getType(signUpBiometricsAsync.request), signUpBiometrics);
}
