import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Contacts } from "Components/More/Contacts/Contacts";
import { useDesktop } from "Hooks/useDesktop";
import { Resources, useResource } from "Translations/Resources";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { type FunctionComponent } from "react";
import { useNavigate } from "react-router";

export const ContactsPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const navigate = useNavigate();

  return (
    <AuthenticatedLayout
      title={t(Resources.More.Menu.Contacts.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.More))}
    >
      <Contacts pageTitle={t(Resources.More.Menu.Contacts.Title)} />
    </AuthenticatedLayout>
  );
};
