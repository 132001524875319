import { all } from "typed-redux-saga";
import { combineReducers } from "redux";
import {
  uploadAttachmentReducer,
  UploadAttachmentsState,
  watchUploadAttachmentSaga,
} from "State/Attachments/Upload/UploadAttachmentState";
import { watchDeleteAttachmentSaga } from "State/Attachments/Upload/DeleteAttachmentState";

export type AttachmentsReducer = {
  upload: UploadAttachmentsState;
};

export function* watchAttachmentsSaga() {
  yield all([watchUploadAttachmentSaga(), watchDeleteAttachmentSaga()]);
}

export const attachmentsReducer = combineReducers<AttachmentsReducer>({
  upload: uploadAttachmentReducer,
});
