import { format } from "date-fns";

type GetPaymentDescriptorProps = {
  amount: number;
  currencyCode: string | null | undefined;
  variableCode: string;
  specificSymbol: string;
  iban: string;
  messageForPayment: string;
  isPeriodicalPayment?: boolean;
  expiryDate?: Date;
};

export const getPaymentDescriptor = ({
  amount,
  currencyCode,
  specificSymbol,
  variableCode,
  iban,
  messageForPayment,
  isPeriodicalPayment,
  expiryDate,
}: GetPaymentDescriptorProps) => {
  const formattedIban = iban.replace(/\s/g, "");

  let paymentDescriptor = `SPD*1.0*ACC:${formattedIban}*AM:${amount}.00*X-VS:${variableCode}*X-SS:${specificSymbol}`;

  if (currencyCode) {
    paymentDescriptor = `${paymentDescriptor}*CC:${currencyCode}`;
  }

  if (messageForPayment) {
    paymentDescriptor = `${paymentDescriptor}*MSG:${messageForPayment.substring(
      0,
      60,
    )}`;
  }

  if (isPeriodicalPayment) {
    paymentDescriptor = `${paymentDescriptor}*FRQ:1M`;

    if (expiryDate) {
      paymentDescriptor = `${paymentDescriptor}*DT:${format(
        new Date(),
        "yyyyMMdd",
      )}*DL:${format(expiryDate, "yyyyMMdd")}`;
    }
  }

  return paymentDescriptor;
};
