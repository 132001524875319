import { Stack, TabProps } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { BankIDCallbackStep } from "Components/More/PersonalData/EditPersonalData/BankID/BankIDCallbackStep";
import { BankIDRedirectStep } from "Components/More/PersonalData/EditPersonalData/BankID/BankIDRedirectStep";
import { ModellingStep } from "Components/More/PersonalData/EditPersonalData/Modelling/ModellingStep";
import { PathSelectionStep } from "Components/More/PersonalData/EditPersonalData/PathSelection/PathSelectionStep";
import { SignatureStep } from "Components/More/PersonalData/EditPersonalData/Signature/SignatureStep";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { useIsAnyContractBankIDVerified } from "Hooks/useIsAnyContractBankIDVerified";
import { useIsLead } from "Hooks/User/useIsLead";
import { FunctionComponent, useCallback, useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import { getClientAsync } from "State/Client/Client/GetClient";
import {
  EditPersonalDataSteps,
  resetEditPersonalDataState,
  setEditPersonalDataActualStep,
} from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { contactInfoAsync } from "State/Profile/ContactInfo/ClientContactReducer";
import { AppRouting, getPath } from "Utils/UrlUtils";

const Components: {
  [key in EditPersonalDataSteps]: FunctionComponent<TabProps>;
} = {
  [EditPersonalDataSteps.PathSelection]: PathSelectionStep,
  [EditPersonalDataSteps.BankIDRedirect]: BankIDRedirectStep,
  [EditPersonalDataSteps.BankIDCallback]: BankIDCallbackStep,
  [EditPersonalDataSteps.Modelling]: ModellingStep,
  [EditPersonalDataSteps.Signature]: SignatureStep,
};

export const EditPersonalDataPage: FunctionComponent = _ => {
  const { isDesktop } = useDesktop();
  const { actualStep } = useAppSelector(s => s.client.personalData.edit);

  const { isLoading: isLoadingClient, error: clientError } = useAppSelector(
    s => s.client.client,
  );
  const { isLoading: isLoadingContactInfo, error: contactInfoError } =
    useAppSelector(s => s.profile.contactInfo);

  const { isLoading: isLoadingVerificationType, error: verificationTypeError } =
    useAppSelector(s => s.client.verificationType);

  const isAnyContractBankIDVerified = useIsAnyContractBankIDVerified(true);
  const isLead = useIsLead();
  const pageTitle = usePageTitle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleBackClick = useCallback(() => {
    switch (actualStep) {
      case EditPersonalDataSteps.PathSelection:
        navigate(
          getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData),
        );
        return;
      case EditPersonalDataSteps.BankIDRedirect:
      case EditPersonalDataSteps.BankIDCallback:
      case EditPersonalDataSteps.Modelling:
        if (isAnyContractBankIDVerified) {
          dispatch(
            setEditPersonalDataActualStep(EditPersonalDataSteps.PathSelection),
          );
        } else {
          navigate(
            getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData),
          );
        }

        return;
      case EditPersonalDataSteps.Signature:
        dispatch(
          setEditPersonalDataActualStep(EditPersonalDataSteps.Modelling),
        );
        return;
    }
  }, [isAnyContractBankIDVerified, actualStep, navigate, dispatch]);

  useEffect(() => {
    dispatch(resetEditPersonalDataState());
    dispatch(contactInfoAsync.request());
    dispatch(getClientAsync.request());
  }, [dispatch]);

  const isLoading =
    isLoadingClient || isLoadingContactInfo || isLoadingVerificationType;

  const Component = Components[actualStep];

  if (isLead) {
    return (
      <Navigate
        to={getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData)}
      />
    );
  }

  return (
    <AuthenticatedLayout
      title={pageTitle}
      isTitleShown={!isDesktop}
      onBackClick={handleBackClick}
    >
      <LoadingWrapper
        isLoading={false || isLoading}
        skeleton={
          actualStep === EditPersonalDataSteps.BankIDCallback
            ? BankIDCallbackLoadingSkeleton
            : PathSelectionLoadingSkeleton
        }
        error={clientError || contactInfoError || verificationTypeError}
      >
        {!!Component && <Component />}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const PathSelectionLoadingSkeleton = (
  <>
    <Stack gap={5}>
      <PrimarySkeleton fullWidth fullHeight={false} height={50} />
      <PrimarySkeleton fullWidth fullHeight={false} height={150} />
    </Stack>
    <PrimarySkeleton fullWidth fullHeight={false} height={75} />
  </>
);

const BankIDCallbackLoadingSkeleton = (
  <>
    <Stack gap={3}>
      <PrimarySkeleton fullWidth fullHeight={false} height={75} />
      <PrimarySkeleton fullWidth fullHeight={false} height={75} />
    </Stack>
    <PrimarySkeleton fullWidth fullHeight={false} height={75} />
  </>
);
