import {
  postAuthSignIn,
  SignInCommand,
  SignInResult,
  SignInStatus,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { setVerificationEmail } from "State/Auth/AuthReducer";
import { NavigateFunction } from "react-router-dom";
import { afterSignInSuccess } from "Utils/AuthUtils";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { getPath, AppRouting } from "Utils/UrlUtils";
import { PasswordResetNoticeTypes } from "Components/Auth/Passwords/PasswordResetForm";
import { resendVerificationEmailAsync } from "State/Auth/Verifications/ResendVerificationEmailState";

export const signInAsync = createAsyncAction(
  "@auth/SIGN_IN_REQUEST",
  "@auth/SIGN_IN_SUCCESS",
  "@auth/SIGN_IN_FAILURE",
)<{ model: SignInCommand; navigate: NavigateFunction }, SignInResult, Error>();

function* signIn(action: ReturnType<typeof signInAsync.request>): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postAuthSignIn,
      action.payload.model,
    );

    if (error) {
      yield put(signInAsync.failure(error));
      return;
    }

    const signInResult = response.signInResult;

    if (
      signInResult.status === SignInStatus.AccountWaitingForEmailConfirmation
    ) {
      yield put(signInAsync.success(signInResult));
      yield put(setVerificationEmail(action.payload.model.login));
      yield put(
        resendVerificationEmailAsync.request({
          email: action.payload.model.login,
        }),
      );

      yield* call(() =>
        action.payload.navigate(getPath(AppRouting.AwaitingEmailVerification)),
      );
      return;
    }

    if (signInResult.status === SignInStatus.Failed) {
      yield put(signInAsync.failure(new ApplicationError(signInResult.error)));
      return;
    }

    yield put(signInAsync.success(signInResult));

    if (signInResult.status === SignInStatus.PasswordResetRequired) {
      yield put(setVerificationEmail(action.payload.model.login));
      yield* call(() =>
        action.payload.navigate(
          getPath(
            AppRouting.ForgottenPassword,
            PasswordResetNoticeTypes.OldPortalUser,
          ),
        ),
      );

      return;
    }

    if (signInResult.status === SignInStatus.Success) {
      yield* call(afterSignInSuccess, signInResult, action.payload.navigate);
      return;
    }

    // if (
    //   signInResult.status === SignInStatus.AccountWaitingForEmailConfirmation
    // ) {
    //   yield put(setVerificationEmail(action.payload.model.login));

    //   yield* call(() =>
    //     action.payload.navigate(getPath(AppRouting.AwaitingEmailVerification)),
    //   );
    //   return;
    // }
  } catch (err) {
    yield put(signInAsync.failure(err as Error));
  }
}

export function* signInSaga() {
  yield takeLatest(getType(signInAsync.request), signIn);
}
