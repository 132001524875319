import {
  BankIDCallbackLoadingWrapper,
  BankIDCallbackLoadingWrapperProps,
} from "Components/BankID/BankIDCallbackLoadingWrapper";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";

export type BankIDCallbackMobileProps = Pick<
  BankIDCallbackLoadingWrapperProps,
  "translations"
>;

export const BankIDCallbackMobile: FunctionComponent<
  BankIDCallbackMobileProps
> = props => {
  const { isLoading, error } = useAppSelector(s => s.bankID);

  const isError = !!error;
  return (
    <BankIDCallbackLoadingWrapper
      isLoading={isLoading}
      isError={isError}
      {...props}
    />
  );
};
