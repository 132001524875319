import {
  ContractPaymentInstructionsQueryResult,
  getContractPaymentInstructions,
} from "Api/Api";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { all } from "typed-redux-saga";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import { Prettify } from "Models/TypeExtensions";

export type ISINPaymentInstruction = Prettify<
  {
    _isin: string;
  } & ContractPaymentInstructionsQueryResult
>;

export type GetPaymentInstructionsStateType = FetchStateType<
  ISINPaymentInstruction[]
>;

export const getPaymentInstructionsState =
  (): GetPaymentInstructionsStateType => getFetchStateDefaultValue();

export type GetPaymentInstructionsActionType =
  | ActionType<typeof getPaymentInstructionsAsync>
  | ActionType<typeof clearPaymentInstructions>;

export const getPaymentInstructionsAsync = createAsyncAction(
  "@contracts/GET_PAYMENT_INSTRUCTIONS_REQUEST",
  "@contracts/GET_PAYMENT_INSTRUCTIONS_SUCCESS",
  "@contracts/GET_PAYMENT_INSTRUCTIONS_FAILURE",
)<{ contractID: number; isins: string[] }, ISINPaymentInstruction[], Error>();

export const clearPaymentInstructions = createAction(
  "@contracts/CLEAR_PAYMENT_INSTRUCTIONS",
)();

function* getPaymentInstructions(
  action: ActionType<typeof getPaymentInstructionsAsync.request>,
): Generator {
  try {
    const { contractID, isins } = action.payload;

    const responses = yield* all(
      isins.map(isin =>
        safeApiCall(getContractPaymentInstructions, contractID, isin),
      ),
    );
    const errorResponse = responses.find(({ error }) => !!error);
    const errorIndexes = responses
      .map((response, index) => ({ response, index }))
      .filter(({ response }) => !!response.error)
      .map(({ index }) => index);

    if (!!errorResponse?.error) {
      yield put(getPaymentInstructionsAsync.failure(errorResponse.error));
    } else {
      yield put(
        getPaymentInstructionsAsync.success(
          responses
            .filter((_, index) => !errorIndexes.includes(index))
            .map(({ response }, index) => {
              return {
                ...response!!,
                // create unique key for each payment instruction based on isin parameter
                _isin: isins[index],
              };
            }),
        ),
      );
    }
  } catch (err) {
    yield put(getPaymentInstructionsAsync.failure(err as Error));
  }
}

export function* getPaymentInstructionsSaga() {
  yield takeLatest(
    getType(getPaymentInstructionsAsync.request),
    getPaymentInstructions,
  );
}

export const getPaymentInstructionsReducer = createReducer<
  GetPaymentInstructionsStateType,
  GetPaymentInstructionsActionType
>(getPaymentInstructionsState())
  .handleAction(getPaymentInstructionsAsync.request, handleActionRequest)
  .handleAction(getPaymentInstructionsAsync.failure, handleActionFailure)
  .handleAction(getPaymentInstructionsAsync.success, handleActionSuccess)
  .handleAction(clearPaymentInstructions, getPaymentInstructionsState);
