import { useUserAgentTracking } from "Hooks/useUserAgentTracking";
import { useViewTracking } from "Hooks/useViewTracking";
import { track } from "Utils/TrackingUtils";
import { type FunctionComponent, PropsWithChildren, useEffect } from "react";
import { useEffectOnce } from "react-use";
import { getUserAgentTrackingValue } from "Utils/UserAgentUtils";

export const AppTrackingWrapper: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { device, os, osVersion } = useUserAgentTracking();

  useViewTracking(
    {
      category: "PageView",
      event: "Open",
    },
    false,
  );

  useEffectOnce(() => {
    track({
      category: "AppState",
      event: "Open",
      action: "Open",
    });

    track({
      category: "DeviceType",
      event: "Open",
      value: getUserAgentTrackingValue(device, os, osVersion),
    });
  });

  useEffect(() => {
    const unloadCallback = () => {
      track({
        category: "AppState",
        event: "Close",
        action: "Close",
      });
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  return <>{children}</>;
};
