import { MenuList, Stack, Typography } from "@mui/material";
import { ContractUnsettledTradeType } from "Api/Api";
import { MenuItem } from "Components/ContractDetail/Transactions/components/MenuItem";
import { FondName } from "Components/ContractDetail/UnsettledTrades/components/FondName";
import { LAYOUT_PADDING_X } from "Constants/Layout";

import {
  ExchangeIcon,
  PurchaseIcon,
  RedeemIcon,
} from "Components/Shared/Icons";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { TabProps } from "Pages/Contracts/DetailPage";
import { getUnsettledTradesAsync } from "State/InvestmentsDetail/UnsettledTrades/GetUnsettledTradesState";
import { useEffect, type FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";

const PageResources = Resources.Contract.Detail.UnsettledTrades;

type Props = TabProps;

const Icons: { [key in ContractUnsettledTradeType]: JSX.Element } = {
  [ContractUnsettledTradeType.BUY]: <PurchaseIcon />,
  [ContractUnsettledTradeType.SELL]: <RedeemIcon />,
  [ContractUnsettledTradeType.SWITCH]: <ExchangeIcon />,
  [ContractUnsettledTradeType.TRANSFERIN]: <ExchangeIcon />,
  [ContractUnsettledTradeType.TRANSFEROUT]: <ExchangeIcon />,
  [ContractUnsettledTradeType.UNKNOWN]: <ExchangeIcon />,
};

export const UnsettledTrades: FunctionComponent<Props> = ({ contractID }) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const { data, isLoading: loading } = useAppSelector(
    s => s.investmentsDetail.unsettledTrades,
  );
  const isLoading = loading && !data;

  useEffect(() => {
    dispatch(getUnsettledTradesAsync.request(contractID));
  }, [dispatch, contractID]);

  const displayData = !!data?.length;

  return (
    <MenuList disablePadding={!displayData}>
      <LoadingWrapper
        isLoading={isLoading}
        skeleton={
          <Stack gap={3} marginX={LAYOUT_PADDING_X}>
            <PrimarySkeleton height={60} />
            <PrimarySkeleton height={60} />
            <PrimarySkeleton height={60} />
          </Stack>
        }
      >
        {displayData &&
          data.map(
            (
              {
                amount,
                fundName,
                currency,
                targetFondName,
                type,
                instructionValueType,
                unsettledTradeID,
              },
              i,
            ) => (
              <MenuItem
                key={unsettledTradeID ?? i}
                amount={amount}
                name={
                  <FondName
                    fondName={fundName ?? ""}
                    targetFondName={targetFondName ?? ""}
                  />
                }
                icon={Icons[type]}
                currency={currency}
                instructionValueType={instructionValueType}
              />
            ),
          )}
        {!displayData && (
          <Typography paddingX={LAYOUT_PADDING_X}>
            {t(PageResources.NoneTransactionsFoundPlaceholder)}
          </Typography>
        )}
      </LoadingWrapper>
    </MenuList>
  );
};
