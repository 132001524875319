import * as React from "react";
export const QuestionsIcon = (
  props: {
    size?: number,
  } & React.SVGProps<SVGSVGElement>,
) => {
  props = {
    ...props,
    color: props.color,
    ...(props.size && {
      width: props.size,
      height: props.size,
    }),
  };
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M7.5001 0.600098C3.6955 0.600098 0.600098 3.6955 0.600098 7.5001C0.600098 11.3047 3.6955 14.4001 7.5001 14.4001C11.3047 14.4001 14.4001 11.3047 14.4001 7.5001C14.4001 3.6955 11.3047 0.600098 7.5001 0.600098ZM8.0392 11.0806C8.0392 11.1454 7.996 11.188 7.9318 11.188H7.114C7.0489 11.188 7.0063 11.1451 7.0063 11.0806V10.1554C7.0063 10.0909 7.0492 10.048 7.114 10.048H7.9318C7.9963 10.048 8.0392 10.0912 8.0392 10.1554V11.0806ZM8.9857 6.9805L8.2648 7.9705C8.0497 8.2609 7.9849 8.4115 7.9849 8.7772V9.1213C7.9849 9.1858 7.942 9.2287 7.8775 9.2287H7.1674C7.1029 9.2299 7.06 9.1867 7.06 9.1222V8.6812C7.06 8.2504 7.1566 8.0248 7.3825 7.7125L8.1037 6.7225C8.4799 6.2062 8.6089 5.9587 8.6089 5.5927C8.6089 4.9795 8.1787 4.5922 7.5652 4.5922C6.9628 4.5922 6.5755 4.9579 6.4465 5.6143C6.4357 5.6788 6.3928 5.7112 6.328 5.7004L5.6503 5.5819C5.5855 5.5711 5.5534 5.5282 5.5639 5.4634C5.7256 4.4305 6.4681 3.7525 7.5871 3.7525C8.7487 3.7525 9.5341 4.5163 9.5341 5.5816C9.5341 6.0982 9.3517 6.4747 8.9857 6.9805Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
