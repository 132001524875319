import { getProductDetail } from "Utils/Products";

export const useProductDesign = (isin?: string) => {
  const product = getProductDetail(isin);

  return {
    color: product?.color,
    backgroundImage: product?.headerImage,
  };
};
