import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { CurrencyCode } from "Api/Api";
import { AMLForm } from "Components/ContractCreate/AMLQuestions/components/AMLForm";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { useFormOptions } from "Hooks/Contract/AMLQuestions/useFormOptions";
import { useYupFormSchema } from "Hooks/Contract/AMLQuestions/useYupFormSchema";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { MapAML } from "State/Contracts/Utils";
import {
  editAMLAsync,
  setEditAMLFormData,
} from "State/More/PersonalData/EditAML/EditAMLReducer";
import { Resources, useResource } from "Translations/Resources";

const PageResources = Resources.More.PersonalData.EditAML;
const ComponentResources = PageResources.Modelling;

export const ModellingStep: FunctionComponent = () => {
  const { t } = useResource();
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();
  const { isLoading, formData: defaultValues } = useAppSelector(
    s => s.client.personalData.editAML,
  );

  const dispatch = useAppDispatch();

  const codeLists = useFormOptions();
  const countriesList = useCountriesList();

  const schema = useYupFormSchema();
  const form = useForm<AMLQuestionsFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      fundsVolumeCurrency: CurrencyCode.CZK,
      sourceOfFunds: [],
      ...defaultValues,
    },
  });

  const {
    formState: { isValid, isValidating, errors },
    handleSubmit,
  } = form;

  const onSubmit = (formData: AMLQuestionsFormModel) => {
    dispatch(setEditAMLFormData(formData));
    dispatch(
      editAMLAsync.request({
        aml: MapAML(formData),
        isBiometry: canUseBiometricsForSigning,
      }),
    );
  };

  return (
    <>
      <div>
        <PageTitle showOnDesktop showOnMobile={false}>
          {t(PageResources.Title)}
        </PageTitle>
        <Stack spacing={2}>
          <FormProvider {...form}>
            <AMLForm
              annualIncomeOptions={codeLists.annualIncomeOptions}
              businessSectors={codeLists.businessSectorOptions}
              fundsSources={codeLists.sourceOfFundsOptions}
              sourcesOfIncome={codeLists.sourceOfIncomeOptions}
              taxResidencies={codeLists.taxResidencyOptions}
              jobPositions={codeLists.jobPositionOptions}
              taxResidencyCountries={countriesList}
            />
          </FormProvider>
        </Stack>
      </div>

      <BlSlideButton
        minSlideVelocity={0.95}
        minSlideWidth={0.95}
        onSlideDone={handleSubmit(onSubmit)}
        trackingTag={t(ComponentResources.Submit)}
        disabled={
          !isValid ||
          errors?.taxResidencyCountry?.type === USA_NATIONALITY_FORBID_TYPE
        }
        isLoading={isLoading || isValidating}
      >
        {t(ComponentResources.Submit)}
      </BlSlideButton>
    </>
  );
};
