import { type BoxProps, Typography, Box } from "@mui/material";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { type FunctionComponent, type PropsWithChildren } from "react";

export type Props = PropsWithChildren<
  {
    title: string;
    tooltip?: string;
  } & BoxProps
>;

export const ComponentTitleWrapper: FunctionComponent<Props> = ({
  children,
  title,
  marginTop = 6,
  tooltip,
  ...props
}) => {
  return (
    <Box {...props} marginTop={marginTop}>
      <Typography
        variant="subtitle1"
        marginBottom={1}
        textTransform="uppercase"
      >
        {title}
        {tooltip && <BlInfoPopper color="primary">{tooltip}</BlInfoPopper>}
      </Typography>
      <>{children}</>
    </Box>
  );
};
