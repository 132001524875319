import { GetHealthStatusQueryResult, getHealthStatus } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { delay } from "typed-redux-saga";

export const getApiHealthStatusAsync = createAsyncAction(
  "@health/API_HEALTH_STATUS_REQUEST",
  "@health/API_HEALTH_STATUS_SUCCESS",
  "@health/API_HEALTH_STATUS_FAILURE",
)<void, GetHealthStatusQueryResult, Error>();

function* getApiHealthStatus(): Generator {
  while (true) {
    try {
      const { response, error } = yield* safeApiCall(getHealthStatus);
      if (error) {
        yield put(getApiHealthStatusAsync.failure(error));
        return;
      }
      yield put(getApiHealthStatusAsync.success(response));
    } catch (err) {
      yield put(getApiHealthStatusAsync.failure(err as Error));
    }
    yield delay(60000);
  }
}

export function* getApiHealthStatusSaga() {
  yield takeLatest(
    getType(getApiHealthStatusAsync.request),
    getApiHealthStatus,
  );

  yield put(getApiHealthStatusAsync.request());
}
