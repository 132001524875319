import { all } from "typed-redux-saga";
import {
  GetDraftStateType,
  getDraftReducer,
  getDraftSaga,
} from "./GetDraft/GetDraftState";
import { combineReducers } from "redux";
import {
  PostDraftStateType,
  postDraftReducer,
  postDraftSaga,
} from "State/Contracts/Draft/PostDraft/PostDraftState";
import {
  PostDocumentsStateType,
  postDocumentsReducer,
  postDocumentsSaga,
} from "State/Contracts/Draft/PostDocuments/PostDocumentsState";

export type DraftConfiguration = {
  isin: string;
  oneTimeDeposit: number;
  regularDeposit: number;
  currency: string;
};

type DraftState = {
  getDraft: GetDraftStateType;
  postDraft: PostDraftStateType;
  postDocuments: PostDocumentsStateType;
};

export function* watchDraftSagas() {
  yield all([getDraftSaga(), postDraftSaga(), postDocumentsSaga()]);
}

export const draftReducer = combineReducers<DraftState>({
  getDraft: getDraftReducer,
  postDraft: postDraftReducer,
  postDocuments: postDocumentsReducer,
});
