import { type BoxProps, Typography, Box } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { MenuIcon, PieChartIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { ReactNode, useState, type FunctionComponent } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
enum Tab {
  Cards,
  PieChart,
}

type Props = {
  investments: ReactNode;
  pieChart: ReactNode;
} & BoxProps;

const StyledWrapper = styled.div`
  display: flex;
  border-radius: 100px;
  background-color: ${props => props.theme.colors.gray};
  width: fit-content;
`;

const StyledPrimaryButton = styled(PrimaryButton)<{
  $active?: boolean;
}>`
  width: 64px;
  height: 32px;
  box-shadow: none;
  font-weight: 400;
  min-width: 0;
  font-size: ${props => props.theme.typography.body1.fontSize}px;
  transition: none;
  line-height: 1;
  text-align: "center";

  &:hover {
    box-shadow: none;
  }

  ${({ $active, theme }) =>
    $active
      ? `
          color: ${theme.palette.text.primary};
          font-weight: 600;
          border: 1px solid  ${theme.palette.grey[700]};
          background-color: ${theme.palette.grey[700]};
          &:hover {
            color: ${theme.palette.text.primary};
            background-color: ${theme.palette.grey[700]};
            cursor: default;
          }
        `
      : `
       color: ${theme.palette.text.disabled};
          &:hover {
             color: ${theme.palette.text.primary};
          }
          border: none;
        `}
`;

const ContentContainer = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
`;

const ComponentResources = Resources.Contract.Detail.Overview;

export const InvestmentCompositionWrapper: FunctionComponent<Props> = ({
  marginTop = 6,
  investments,
  pieChart,
  ...props
}) => {
  const { t } = useResource();
  const [actualTab, setActualTab] = useState(Tab.Cards);

  return (
    <Box {...props}>
      <StyledFlex $justifyContent="space-between" $alignItems="center">
        <Typography
          variant="subtitle1"
          textTransform="uppercase"
          textAlign="center"
        >
          {t(ComponentResources.InvestmentComposition)}
        </Typography>
        <StyledWrapper>
          <StyledPrimaryButton
            $active={actualTab === Tab.Cards}
            onClick={() => setActualTab(Tab.Cards)}
          >
            <MenuIcon
              color={
                actualTab === Tab.Cards
                  ? Colors.PrimaryText
                  : Colors.TextDisabled
              }
            />
          </StyledPrimaryButton>
          <StyledPrimaryButton
            $active={actualTab === Tab.PieChart}
            onClick={() => setActualTab(Tab.PieChart)}
          >
            <PieChartIcon
              color={
                actualTab === Tab.PieChart
                  ? Colors.PrimaryText
                  : Colors.TextDisabled
              }
            />
          </StyledPrimaryButton>
        </StyledWrapper>
      </StyledFlex>
      <ContentContainer>
        {actualTab === Tab.Cards && investments}
        {actualTab === Tab.PieChart && (
          <StyledFlex $justifyContent="center">{pieChart}</StyledFlex>
        )}
      </ContentContainer>
    </Box>
  );
};
