import { ClientDocumentsQueryResult, getClientDocuments } from "Api/Api";
import { produce } from "immer";
import { safeApiCall } from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type ClientDocumentsState = {
  isLoading: boolean;
  error?: Error | null;
} & Pick<ClientDocumentsQueryResult, "documents">;

const initialClientDocumentsState: ClientDocumentsState = {
  isLoading: false,
  error: null,
};

export const resetClientDocumentsState = createAction(
  "@personal-data/RESET_CLIENT_DOCUMENTS_STATE",
)<void>();

export const clientDocumentsAsync = createAsyncAction(
  "personal-data/GET_CLIENT_DOCUMENTS_REQUEST",
  "personal-data/GET_CLIENT_DOCUMENTS_SUCCESS",
  "personal-data/GET_CLIENT_DOCUMENTS_FAILURE",
)<void, ClientDocumentsQueryResult, Error>();

export type ClientDocumentsActions =
  | ActionType<typeof clientDocumentsAsync>
  | ActionType<typeof resetClientDocumentsState>;

function* clientDocuments(
  action: ReturnType<typeof clientDocumentsAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(getClientDocuments);

    if (!!error) {
      yield put(clientDocumentsAsync.failure(error));
      return;
    }

    yield put(clientDocumentsAsync.success(response));
  } catch (err) {
    yield put(clientDocumentsAsync.failure(err as Error));
  }
}

export function* watchClientDocumentsSaga() {
  yield takeLeading(getType(clientDocumentsAsync.request), clientDocuments);
}

export const clientDocumentsReducer = createReducer<
  ClientDocumentsState,
  ClientDocumentsActions
>(initialClientDocumentsState)
  .handleAction(clientDocumentsAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(clientDocumentsAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.documents = action.payload.documents;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(clientDocumentsAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(resetClientDocumentsState, (state, action) =>
    produce(state, draft => {
      return initialClientDocumentsState;
    }),
  );
