import {
  AttachmentDescriptor,
  AttachmentFormat,
  AttachmentType,
} from "Api/Api";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import {
  PrimaryDocumentFormModel,
  EditPersonalDataFormModel,
} from "State/More/PersonalData/EditPersonalData/EditPersonalDataReducer";
import { useEffect, useMemo, useState } from "react";
import { FieldPath, useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { uploadAttachmentAsync } from "State/Attachments/Upload/UploadAttachmentState";

export type DocumentScanGuidFieldPath = Extract<
  FieldPath<EditPersonalDataFormModel>,
  `primaryDocument.frontScan.guid` | `primaryDocument.backScan.guid`
>;

export type DocumentScanFileNameFieldPath = Extract<
  FieldPath<EditPersonalDataFormModel>,
  `primaryDocument.frontScan.fileName` | `primaryDocument.backScan.fileName`
>;

export type DocumentScanFileMimeTypeFieldPath = Extract<
  FieldPath<EditPersonalDataFormModel>,
  `primaryDocument.frontScan.mimeType` | `primaryDocument.backScan.mimeType`
>;

export const useDocumentFile = (
  fieldPath: DocumentScanGuidFieldPath,
  descriptor: AttachmentDescriptor,
) => {
  const { watch, setValue } = useFormContext<PrimaryDocumentFormModel>();

  const dispatch = useAppDispatch();
  const defaultGuid = watch(fieldPath);

  const [guid, setGuid] = useState<string>(defaultGuid);
  const [fileNameFieldPath] = useState<DocumentScanFileNameFieldPath>(
    descriptor === AttachmentDescriptor.PRIMARY_IDENTITY_DOCUMENT_FRONT_SCAN
      ? "primaryDocument.frontScan.fileName"
      : "primaryDocument.backScan.fileName",
  );

  const [mimeTypeFieldPath] = useState<DocumentScanFileMimeTypeFieldPath>(
    descriptor === AttachmentDescriptor.PRIMARY_IDENTITY_DOCUMENT_FRONT_SCAN
      ? "primaryDocument.frontScan.mimeType"
      : "primaryDocument.backScan.mimeType",
  );

  const state = useAppSelector(s => s.attachments.upload)?.[guid];
  const detail = state?.data;

  const isLoading = !!state?.isLoading;

  const file = useMemo(() => {
    if (detail?.fileName) {
      return new File([""], detail.fileName);
    }

    return null;
  }, [detail?.fileName]);

  useEffect(() => {
    if (!isLoading) {
      setValue(fieldPath, guid, {
        shouldValidate: !!detail,
      });

      setValue(fileNameFieldPath, detail?.fileName);
      setValue(mimeTypeFieldPath, detail?.mimeType);
    }
  }, [
    fieldPath,
    detail,
    guid,
    isLoading,
    setValue,
    fileNameFieldPath,
    mimeTypeFieldPath,
  ]);

  const saveFile = (file: File) => {
    const newGuid = uuid();
    dispatch(
      uploadAttachmentAsync.request(
        {
          type: AttachmentType.IDENTITYDOCUMENT,
          format: AttachmentFormat.BASE64_ENCODED,
          descriptor: descriptor,
          file: file,
          fileName: file.name,
          mimeType: file.type,
        },
        { attachmentGuid: newGuid },
      ),
    );

    setGuid(newGuid);
  };

  return {
    isSaving: isLoading,
    file,
    fileGuid: guid,
    fileDetail: detail,
    saveFile,
  };
};
