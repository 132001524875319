import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";
import { useSearchParams } from "react-router-dom";

const DEFAULT_TAB = PersonalDataTabs.PersonalData;

export const useActualTab = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: PersonalDataTabs.PersonalData,
  });

  const actualTab = (searchParams.get("tab") ??
    DEFAULT_TAB) as PersonalDataTabs;

  const setTab = (
    tab: PersonalDataTabs,
    params: Record<string, string> = {},
  ) => {
    setSearchParams({ tab, ...params });
  };

  return [actualTab, setTab] as const;
};
