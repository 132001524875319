import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";

import { AppRouting, getPath } from "Utils/UrlUtils";
import { useState, type FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router";
import { Resources, useResource } from "Translations/Resources";
import { useAppSelector } from "Hooks/useAppSelector";
import { ConfigurationStep } from "Components/ContractPurchase/ConfigurationStep";
import { SuccessStep } from "Components/ContractPurchase/SuccessStep";
import {
  PurchaseStep,
  setPurchaseStep,
} from "State/Contracts/Purchase/PurchaseState";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useEffectOnce } from "react-use";
import { useDesktop } from "Hooks/useDesktop";
import { usePurchaseTitle } from "Hooks/Contract/usePurchaseTitle";

const PageResources = Resources.Contract.Detail.Purchase;

export const PurchasePage: FunctionComponent = _ => {
  const navigate = useNavigate();
  const params = useParams();

  const contractID = Number.parseInt(params.id as string);
  const defaultIsin = params.isin as string;

  const { t } = useResource();
  const { step } = useAppSelector(s => s.contracts.purchase);
  const { isDesktop } = useDesktop();

  const dispatch = useAppDispatch();
  const [selectedProductIsin, setSelectedProductIsin] = useState<string | null>(
    null,
  );

  const pageTitle = usePurchaseTitle();
  const showPageTitle = !isDesktop && !!pageTitle;

  useEffectOnce(() => {
    return () => {
      dispatch(setPurchaseStep(PurchaseStep.Configuration));
    };
  });

  return (
    <AuthenticatedLayout
      title={t(PageResources.Unlimited.Title)}
      isTitleShown={showPageTitle}
      titleComponent={pageTitle}
      productIsin={selectedProductIsin || defaultIsin}
      onBackClick={() =>
        navigate(getPath(AppRouting.ContractDetail, contractID))
      }
    >
      {step === PurchaseStep.Configuration ? (
        <ConfigurationStep
          contractID={contractID}
          defaultIsin={defaultIsin}
          onSelectedProductChange={(productIsin: string | null) =>
            setSelectedProductIsin(productIsin)
          }
        />
      ) : (
        <SuccessStep contractID={contractID} />
      )}
    </AuthenticatedLayout>
  );
};

export default PurchasePage;
