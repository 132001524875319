import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";

export const ModuleLoadingFallback: React.FunctionComponent = _ => {
  const { isAuthenticated } = useSelector((e: RootStateType) => e.auth);

  if (!isAuthenticated) {
    return null;
  }

  return <AuthenticatedLayout title=""></AuthenticatedLayout>;
};
